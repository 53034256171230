<template>
  <drag-dialog
    v-model:visible="dialogVisible"
    :right="60"
    :top="3.8"
    :width="21.25"
    :height="30"
    :title="t('514')"
  >
    <div class="log_box">
      <ul class="log_ul" id="log_ul">
        <li v-for="(item, index) in logList" :key="index">
        <h5 v-if="item.date">{{ item.date }}</h5>
          <dl  class="wrap_dl">
            <dt>{{ item.operateTime }}</dt>
            <dd>
              <b v-if="item.operateStatus === true" class="success_info"
                >{{ t('964') }}</b
              >
              <b v-if="item.operateStatus === false" class="error_info">{{ t('965') }}</b>
              <span>{{ item.logContent }}</span>
            </dd>
          </dl>
        </li>
      </ul>
    </div>
  </drag-dialog>
</template>

<script setup>
import { t } from '../../../languages';
import { ref, toRefs, watch,reactive,onMounted,onBeforeUnmount,nextTick } from "vue";
import task from '@/network/task'
import emitter from '@/utils/mitt';
const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
   sn: {
    type: String,
    default: "",
  },
   websocketsend: {
    type: Function,
  },
});
const { sn,isVisible } = toRefs(props);


const logList = ref([])
emitter.on('logs',res=>{
  logList.value.push(res)
  console.log(res);
})
/* 获取操作日志 */
async function getLog() {
  const logData = await task.getLog(sn.value);
  if (logData.resultData instanceof Array) {
    const logArr = [];
    let date = "";
    for (let i = 0; i < logData.resultData.length; i++) {
      const currDate = logData.resultData[i].totalOperateTime.split("-")[2];
      if (currDate !== date) {
        logArr.push({
          date: logData.resultData[i].totalOperateTime
        });
      }
      date = currDate;
      logArr.push(logData.resultData[i]);
    }
    logList.value = logArr;
    console.log(logList.value);
    const log_ul = document.getElementById("log_ul");
    nextTick(() => {
      log_ul.scrollTop = log_ul.scrollHeight;
    })
  }
}
getLog()

onMounted(() => {
  const controlLogs = {
    action: "ACCEPT",
    code: "",
    data: ['STA_LOG'],
  }
  props.websocketsend(controlLogs);
})
onBeforeUnmount(() => {
  console.log('关闭了操作日志模块');
})


const emit = defineEmits(["update:isVisible"]);
const dialogVisible = ref(null);
dialogVisible.value = isVisible.value;
watch(dialogVisible, (newProps) => {
  dialogVisible.value = newProps;
  emit("update:isVisible", false);
});
</script>

<style scoped lang="less">
.log_box {
  width: 100%;
  height: 100%;
   
   .log_ul {
    height: 26.875rem;
    overflow: auto;
    padding: 1.25rem 1.375rem;

    li:not(:last-child) {
      margin-bottom: .4375rem;
    }

    h5 {
      text-align: center;
      font-size: .875rem;
      color: #36e5ff;
    }
  }
}
.log_box:hover{
  .log_ul::-webkit-scrollbar {
    width: 0.6rem;
  }
}
/* 换行dl */
.wrap_dl {
  position: relative;
  padding-left: 2.5rem;
  font-size: .875rem;

  dt {
    position: absolute;
    top: 0;
    left: 0;
    width: 3.75rem;
    color: #00F1FB;
  }

  dd {
    line-height: 1.4;
  }

  .success_info {
    color: #67c529;
  }

  .error_info {
    color: #e11414;
  }

  b {
    /* 解决行内元素间距问题 */
    position: relative;
    margin-right: .875rem;
  }
  span{
    color: #FFFFFF ;
  }
}
</style>
