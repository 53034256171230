import { t } from '../languages';
import service from "./service.js";

const fence = {
  //新增限飞区
  addFlyLimit: (data) => {
    return service.post({
      url: "/api/v1/fence/flyLimit",
      data
    })
  },
  //新增可飞区
  addFlyAble: (data,sn) => {
    return service.post({
      url: `/api/v1/fence/flyable/${sn}`,
      data
    })
  },
  //修改可飞区
  editFlyAble: (data,regionId) => {
    return service.put({
      url: `/api/v1/fence/flyable/${regionId}`,
      data
    })
  },
  //新增限高区
  addHeightLimit: (data) => {
    return service.post({
      url: `/api/v1/fence/heightLimit/${data.fenceName}`,
      data
    })
  },
  //获取围栏列表
  getFenceList: (params) => {
    return service.get({
      url: `/api/v1/fence/list`,
      params
    })
  },
  //设置围栏显示
  setFenceIsShow: (params) => {
    return service.get({
      url: `/api/v1/fence/setShow`,
      params
    })
  },
  //删除围栏
  deleteFence: (id) => {
    return service.post({
      url: `/api/v1/fence/${id}`,
    })
  },
}
export default fence