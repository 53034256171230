<template>
  <drag-dialog
    v-model:visible="dialogVisible"
    :right="25"
    :top="3.8"
    :width="18.1875"
    :height="29"
    :title="t('371')"
  >
    <div class="container">
      <div class="box">
        <p class="item-title">{{ t('1011') }}</p>
        <div class="common-item">
          <p>{{ t('1012') }}:</p>
          <p>{{ UAVModel ? UAVModel : "-" }}</p>
        </div>
        <div class="common-item">
          <p>{{ t('1013') }}:</p>
          <p>{{ uavInfo.rtkStatusName ? uavInfo.rtkStatusName : "-" }}</p>
        </div>
        <div class="common-item">
          <p>{{ t('837') }}:</p>
          <p>
            {{
              typeof uavInfo.rtkSatCount !== "undefined"
                ? uavInfo.rtkSatCount
                : "-"
            }}
          </p>
        </div>
        <div class="common-item">
          <p>{{ t('838') }}:</p>
          <p>
            {{
              typeof uavInfo.rtkReady !== "undefined"
                ? uavInfo.rtkReady === true
                  ? t('1014')
                  : t('1015')
                : "-"
            }}
          </p>
        </div>
        <div class="common-item">
          <p>{{ t('73') }}:</p>
          <p>{{ uavInfo.afLong ? uavInfo.afLong : "-" }}</p>
        </div>
        <div class="common-item">
          <p>{{ t('74') }}:</p>
          <p>{{ uavInfo.afLat ? uavInfo.afLat : "-" }}</p>
        </div>
        <div class="common-item">
          <p>{{ t('839') }}:</p>
          <p>
            {{
              typeof uavInfo.flyDirection !== "undefined"
                ? uavInfo.flyDirection + "°"
                : "-"
            }}
          </p>
        </div>
        <div class="common-item">
          <p>{{ t('305') }}:</p>
          <p>
            {{
              typeof uavInfo.cameraPitch !== "undefined"
                ? uavInfo.cameraPitch + "°"
                : "-"
            }}
          </p>
        </div>
        <div class="common-item">
          <p>{{ t('840') }}:</p>
          <p>
            {{ typeof uavInfo.charge !== "undefined" ? uavInfo.charge : "-" }}
          </p>
        </div>
        <div class="common-item flex-a">
          <p>RCLTE:</p>
          <div
            class="signal-wrapper"
          >
            <span v-show="uavInfo.rcLte===0||uavInfo.rcLte===undefined" class="iconfont icon-guanbi1 close flex-fac"></span>
            <ul>
              <li
                v-for="i in 5"
                :key="i"
                :style="{
                  background:
                    '' + (i <= 0 ? '#5cf548' : 'rgba(0, 0, 0, 0.4)') + ''
                }"
              ></li>
            </ul>
          </div>
        </div>
        <div class="common-item flex-a">
          <p>UAVLTE:</p>
          <div
            class="signal-wrapper"
          >
            <span v-show="uavInfo.uavLte===0||uavInfo.uavLte===undefined" class="iconfont icon-guanbi1 close flex-fac"></span>
            <ul>
              <li
                v-for="i in 5"
                :key="i"
                :style="{
                  background:
                    '' + (i <= uavInfo.uavLte ? '#5cf548' : 'rgba(0, 0, 0, 0.4)') + ''
                }"
              ></li>
            </ul>
          </div>
        </div>
        <div class="common-item flex-a">
          <p>ocuSync:</p>
          <div
            class="signal-wrapper"
          > 
            <span v-show="uavInfo.ocuSync===0||uavInfo.ocuSync===undefined" class="iconfont icon-guanbi1 close flex-fac"></span>
            <ul>
              <li
                v-for="i in 5"
                :key="i"
                :style="{
                  background:
                    '' + (i <= uavInfo.ocuSync ? '#5cf548' : 'rgba(0, 0, 0, 0.4)') + ''
                }"
              ></li>
            </ul>
          </div>
        </div>
        <div class="common-item flex-a">
          <p></p>
          <div
            class="increased-map hover_opacity"
            @click="increased"
          > 
            <img src="../../../asset/img/increased.png" alt="">
          </div>
        </div>
      </div>
      <div class="box-middle">
        <p class="item-title">{{ t('1016') }}</p>
        <div class="common-item">
          <p>{{ t('380') }}:</p>
          <p>{{ airportInfo.nStatusName ? airportInfo.nStatusName : "-" }}</p>
        </div>
        <div class="common-item">
          <p>{{ t('1017') }}:</p>
          <p>
            {{ airportInfo.batteryStatus1 ? airportInfo.batteryStatus1 : "-" }}
          </p>
        </div>
        <div class="common-item">
          <p>{{ t('840') }}:</p>
          <p>
            {{
              airportInfo.elecQuantity1 !== undefined
                ? airportInfo.elecQuantity1 + "%"
                : "-"
            }}
          </p>
        </div>
      </div>
      <div class="box-bottom">
        <p class="item-title">{{ t('1018') }}</p>
        <div class="common-item">
          <p>{{ t('382') }}:</p>
          <p>{{ taskName || "-" }}</p>
        </div>
        <div class="common-item">
          <p>{{ t('385') }}:</p>
          <p>{{ taskInfo.taskStatusName ? taskInfo.taskStatusName : "-" }}</p>
        </div>
      </div>
    </div>
    <div class="increased-pop" v-if="increasedShow">
      <div class="header flex-sb">
        <p>{{ t('1019') }}</p>
        <span class="iconfont icon-guanbi1 close flex-fac hover_opacity" @click="closeIncreased"></span>
      </div>
      <div class="content">
        <p class="flex-a">{{ t('1020') }}  
          <el-switch
            v-model="isOpneLte"
            size="small"
            style="--el-switch-on-color: #52ef40;
                  --el-switch-off-color: #707275;margin-left:14px;"
            @change="openLte"
          />
        </p>
        <p>{{ t('1021') }}：{{lteInfo.lteConnection? t('1022') : t('1023') }}</p>
        <p class="flex-sb">{{ t('1024') }}：{{lteInfo.lteAuthenticated? t('1025'):t('1026')}}  
          <el-tooltip
          class="box-item"
          popper-class="codeOutToolTip"
          placement="bottom"
          :content="t('1027')"
        >
          <el-icon class="icon-refresh hover_opacity" @click="phoneRefresh(airportInfo.isAircraftConnected)"><Refresh /></el-icon>
        </el-tooltip>
        </p>
        <p class="flex-a">{{ t('1028') }}：<span class="phone" :title="lteInfo.lteAuthenticatedPhoneNumber">{{lteInfo.lteAuthenticatedPhoneNumber?lteInfo.lteAuthenticatedPhoneNumber:'-'}}</span></p>
        <p>{{ t('1029') }}：{{lteInfo.lteAuthenticatedRemainingTime?convertTime(lteInfo.lteAuthenticatedRemainingTime):'-'}}</p>
      </div>
    </div>

    <!-- 认证 -->
    <div class="dialog_box" v-if="isPhone">
    <el-dialog
      v-model="isPhone"
      :width="'446px'"
      :custom-class="isCenter ? 'center_dialog common-dialog' : 'common-dialog'"
      :append-to-body="false"
      lock-scroll
      :top="'35Vh'"
      destroy-on-close
      ref="eleDialog"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <template #title>
        <div class="my-header">
            <span class="flex-fac">{{ t('1027') }}</span><span class="iconfont icon-guanbi1 close hover_opacity" @click="closePhone"></span>
        </div>
      </template>
      <div class="lte-content">
        <el-form 
          ref="elForms"
          :model="ruleForm"
          :rules="rules"
          >
          <p>{{ t('665') }}</p>
          <el-form-item
            prop="phone"
            >
            <div class="phone flex-a">
            <div class="left">+86</div>
            <el-input
                :placeholder="t('1030')"
                v-model.trim="ruleForm.phone"
                class="biaozu"
              ></el-input>
          </div>
          </el-form-item>
          <p>{{ t('1031') }}</p>
           <el-form-item
            prop="code"
            >
              <div class="certificate flex-a">
              <div class="left">
                <el-input
                  :placeholder="t('1032')"
                  v-model.trim="ruleForm.code"
                  maxlength="6"
                  class="biaozu"
                ></el-input>
              </div>
              <div v-if="isMsg" class="right hover_opacity" @click="getMsg">{{ t('1033') }}</div>
              <div v-else class="right hover_opacity">{{msgNumber}}s</div>
            </div>
          </el-form-item>
        </el-form>
        <div v-if="!isRecognition" class="submit hover_opacity" @click.prevent="submitPhone">
            {{ t('1034') }}
        </div>
        <div v-else class="submit flex-fac">
            <el-icon class="is-loading"><Loading /></el-icon>{{ t('1035') }}
        </div>
      </div>
    </el-dialog>
    </div>
  </drag-dialog>
</template>

<script setup>
import { t } from '../../../languages';
import { ref, toRefs, watch, onMounted, onBeforeUnmount, reactive } from "vue";
import taskManage from "@/network/task";
import { ElMessage } from "element-plus";
const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  UAVModel: {
    type: String,
    default: "",
  },
  airportInfo: {
    type: Object,
  },
  uavInfo: {
    type: Object,
  },
  taskInfo: {
    type: Object,
  },
  taskName: {
    type: String,
    default: "",
  },
  lteInfo: {
    type: Object,
  },
  sn: {
    type: String,
    default: "",
  },
  websocketsend: {
    type: Function,
  },
});

onMounted(() => {
  const statuMessgae = {
    action: "ACCEPT",
    code: "",
    data: [
      "STA_RTK",
      "STA_AF",
      "STA_GIM",
      "STA_AF_BATTERY",
      "STA_BASIC",
      "STA_N_BATTERY",
      "STA_WP",
      "STA_SIGNAL",
    ],
  };
  props.websocketsend(statuMessgae);
  console.log(t("1036"));
});
onBeforeUnmount(() => {
  console.log(t("1037"));
});
const emit = defineEmits(["update:isVisible"]);
const { isVisible,lteInfo,sn } = toRefs(props);
const dialogVisible = ref(null);
dialogVisible.value = isVisible.value;
watch(dialogVisible, (newProps) => {
  dialogVisible.value = newProps;
  emit("update:isVisible", false);
});
const increasedShow = ref(false);
const isPhone = ref(false);
const isMsg = ref(true);
const msgNumber = ref(60);
const elForms = ref(null);
const isRecognition = ref(false);
const isOpneLte = ref(false);
isOpneLte.value = lteInfo.value.lteEnabled;
const ruleForm = reactive({
  phone:'',
  code:''
});
const rules = reactive({
  phone: [
    { validator: isPhoneVal, trigger: ["blur","change"] }

  ],
  code: [
    {required:true, message: t('1032'), trigger: ["blur","change"]},
    { validator: isNumber, trigger: ["blur","change"] }
    ],
});
function isNumber(rule,value,callback) {
  let strRegex = /^[0-9a-zA-Z-_]+$/;
  if (!strRegex.test(value)) {
    return callback(new Error(t(`验证不能输入中午和特殊字符`)));
  }else{
    return callback();
  }
}
function isPhoneVal(rule,value,callback) {
  let strRegex = /^1[3456789]\d{9}$/;
  if (!strRegex.test(value)) {
    return callback(new Error(t(`请输入正确的手机号码`)));
  } else {
    return callback();
  }
}


/**
 * @description: 增强图传
 * @param {*} 
 * @return {*}
 */
function increased() {
  increasedShow.value = !increasedShow.value;
}
/**
 * @description: 关闭开启增强图传
 * @return {*}
 */
function closeIncreased() {
  increasedShow.value = false;
}

/**
 * @description: 认证手机号码
 * @return {*}
 */
function phoneRefresh(status) {
  if(!status)return ElMessage.warning(t('1038'));
  isPhone.value = !isPhone.value;
  ruleForm.code = '';
}
/**
 * @description: 关闭
 * @return {*}
 */
function closePhone() {
  isPhone.value = false;
}

/**
 * @description: 获取验证码
 * @return {*}
 */
function getMsg() {
  let strRegex = /^1[3456789]\d{9}$/;
  if(ruleForm.phone==='')return ElMessage.warning(t('1030'));
  if(!strRegex.test(ruleForm.phone))return ElMessage.warning(t('1039'));
  let getCode = {
    action: "ACTIVATE_LET",
    nestSn: sn.value,
    data: ruleForm.phone
  };
  taskManage.airportControl(getCode).then(res=>{
    if(res.resultStatus){
      isMsg.value = false;
      percentage();
      ElMessage.success(t('1040'));
    }
  });

}
/**
 * @description: 倒计时
 * @return {*}
 */
function percentage() {
  let test = setInterval(() => {
    if (msgNumber.value  > 0){
      msgNumber.value -=1
    }else {
      clearInterval(test);
      isMsg.value = true;
      msgNumber.value = 60;
    }
  }, 1000)
}
/**
 * @description: 认证
 * @return {*}
 */
function submitPhone() {
  elForms.value.validate(valid => {
    if(valid){
      isRecognition.value = true;
      let isRecognitionAuth = {
        action: "ACTIVATE_LET_AUTH",
        nestSn: sn.value,
        data: `${ruleForm.phone},${ruleForm.code}`
      };
      taskManage.airportControl(isRecognitionAuth,2).then(res=>{
        if(res.resultStatus){
          isRecognition.value = false;
          ElMessage.success(t('1041'));
        }else{
          isRecognition.value = false;
        }
      })
      return true;
    }else{
      return false;
    }
  });
}
/**
 * @description: 开启增强图传
 * @param {*} 
 * @return {*}
 */
async function openLte(value) {
  let isClose = {
    action: "ACTIVATE_LET_OPEN_OR_CLOSE",
    nestSn: sn.value,
    data: `${value}`
  };
  await taskManage.airportControl(isClose);
}

/**
 * @description: 时间转换显示
 * @param {*} time
 * @return {*}
 */
function convertTime(time) {
  const days = Math.floor(time / 86400);  
  const hours = Math.floor((time % 86400) / 3600);  
  const minutes = Math.floor((time % 3600) / 60); 
  return `${days}${ t('1042') }${hours}${ t('1043') }${minutes}${ t('1044') }`;  
}
</script>

<style scoped lang="less">
.container {
  height: 26rem;
  overflow: auto;
  position: relative;
  .box {
    color: #ffffff;

    .item-title {
      padding: 1.3125rem 0.8125rem 0 0.8125rem;
      font-size: 1rem;
      margin-bottom: 0.9375rem;
    }

    .common-item {
      padding: 0.375rem 0.8125rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.75rem;
      }
    }

    .common-item:last-child {
      border-bottom: 1px solid rgba(35, 163, 236, 0.4);
    }
  }

  .box-middle {
    color: #ffffff;

    .item-title {
      padding: 0.8125rem 0.8125rem 0 0.8125rem;
      font-size: 1rem;
      margin-bottom: 0.4375rem;
    }

    .common-item {
      padding: 0.375rem 0.8125rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.75rem;
      }
    }

    .common-item:last-child {
      border-bottom: 1px solid rgba(35, 163, 236, 0.4);
    }
  }

  .box-bottom {
    color: #ffffff;

    .item-title {
      padding: 0.8125rem 0.8125rem 0 0.8125rem;
      font-size: 1rem;
      margin-bottom: 0.4375rem;
    }

    .common-item {
      padding: 0.375rem 0.8125rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.75rem;
      }
    }
  }
  .signal-wrapper {
    width: 30px;
    height: 15px;
    position: relative;
    .close{
      position: absolute;
      top: 0;
      left: 0;
      color: red;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: #fff;
      font-size: 8px;
    }
  }
  ul {
    position: relative;
    width: 100%;
    height: 100%;
  }
  li {
    width: 5px;
    position: absolute;
    bottom: 0;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
  }
  ul li:nth-child(1) {
    height: 3px;
    left: 0px;
  }
  ul li:nth-child(2) {
    height: 6px;
    left: 6px;
  }
  ul li:nth-child(3) {
    height: 9px;
    left: 12px;
  }
  ul li:nth-child(4) {
    height: 12px;
    left: 18px;
  }
  ul li:nth-child(5) {
    height: 15px;
    left: 24px;
  }

  .increased-map{
    width: 1.25rem;
    height: 1.0625rem;
    img{
      width: 1.25rem;
      height: 1.0625rem;
    }
  }
  
}
.increased-pop{
    width: 13.375rem;
    height: 11.4375rem;
    background: rgba(11,79,122,0.9);
    position: absolute;
    top: 17.6rem;
    right: -13.875rem;
    .header{
      width: 100%;
      height: 2rem;
      border-bottom: 1px solid #077796;
      padding: 0.4375rem .625rem .5625rem .6875rem;
    }
    .content{
      padding: 1.0625rem .9375rem 0 .75rem;
      width: 100%;
      height: calc(100% - 2rem);
      p{
        font-size: .75rem;
        margin-bottom: .5rem;
        width: 100%;
        span{
          width: 50%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      p:last-child{
        margin-bottom: 0;
      }
      .icon-refresh{
        font-size: 1.125rem;
        color: #35e3fd;
      }
      /deep/.el-switch__core {
        width: 2.125rem;
      }
    }
  }
.dialog_box :deep(.common-dialog)  {
  background: rgba(11,79,122,0.9);

  .el-dialog__header {
    height: 3.5rem;;
    padding: .6875rem 1.25rem;
    border-bottom: 1px solid #077796;
    margin-right: 0;
    background: rgba(11,79,122,0.9);
    text-align: center;
    .el-dialog__title {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #fff;
    }
  }
  .el-dialog__body {
    padding: 1.25rem 1.25rem 0 1.25rem;
    color: #fff;
  }
}

/* 居中弹窗 */
.dialog_box /deep/ .center_dialog {
  margin-top: 0 !important;
  top: 50%;
  transform: translateY(-50%);
}
.my-header{
  height: 100%;
  line-height: 2.0625rem;
  position: relative;
  .close{
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5rem;
    font-size: 1.125rem;
  }
}
.lte-content{
  height: 17.1875rem;
  padding: 0 1.125rem 0 1.125rem;
  .phone{
    width: 100%;
    height: 2.5rem;
    margin-top: .6875rem;
    .left{
      width: 3.625rem;
      height: 100%;
      line-height: 2.5rem;
      text-align: center;
      background: #001C31;
      border: 1px solid #51D2FF;
      border-right: 0;
    }
    .right{
      width: calc(100% - 3.625rem);
      // border: 1px solid #51D2FF;
      height: 100%;
    }
  }
  /deep/.el-input__wrapper {
    height: 2.5rem !important;
    background: #001c31;
    box-shadow: 0 0 0 0 #001c31;
    border-radius:0;
    border: 1px solid #51D2FF;
  }

  /deep/.el-input__inner {
    height: 2.5rem !important;
    border: none;
    color: #fff;
  }
  .certificate{
    width: 100%;
    height: 2.5rem;
    margin-top: .6875rem;
    .left{
      width: 15.5625rem;
      height: 100%;
      margin-right: .9375rem;
      // border: 1px solid #51D2FF;
    }

    .right{
      width: calc(100% - 16.5rem);
      height: 100%;
      line-height: 2.5rem;
      text-align: center;
      background: #001C31;
      border: 1px solid #51D2FF;
      color: #51D2FF;
    }
  }
  .submit{
    width: 100%;
    height: 2.5rem;
    background: #00959B;
    border: 1px solid #00F5FF;
    text-align: center;
    line-height: 2.5rem;
    margin-top: 2.125rem;
  }
}
</style>
