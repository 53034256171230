<template>
  <drag-dialog
    v-model:visible="dialogVisible"
    :right="'44.5%'"
    :top="4.25"
    :width="20"
    :isTrain="true"
    :title="t('1002')"
  >
  <!-- 51.25 -->
    <div class="content flex-sb">
      <div
        class="box"
        v-for="item in navData"
        :key="item.id"
        :class="item.id == navId ? 'box_bg' : ''"
        @click="Jump(item)"
      >
        <div class="flex-dc flex-a">
          <img :src="item.url" alt="" />
          <div class="li">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </drag-dialog>
</template>

<script setup>
import { t } from '../../../languages';
import { router } from "@/router";
import { ref, toRefs, watch } from "vue";
const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:isVisible"]);
const { isVisible } = toRefs(props);
const dialogVisible = ref(null);
dialogVisible.value = isVisible.value;
watch(dialogVisible, (newProps) => {
  dialogVisible.value = newProps;
  emit("update:isVisible", false);
});
const navId = ref(1);
const navData = [
  // {
  //   id: 1,
  //   title: t("1003"),
  //   path: "/homeIndex",
  //   url: require("../../../asset/img/centerPlan/tilt.png"),
  // },
  {
    id: 2,
    title: t("257"),
    path: "/commandcenterIndex",
    url: require("../../../asset/img/centerPlan/route.png"),
  },
  {
    id: 3,
    title: t("1004"),
    path: "/dataMangeIndex",
    url: require("../../../asset/img/centerPlan/multilateral.png"),
  },
  // {
  //   id: 4,
  //   title: t("1005"),
  //   path: "/deviceManageIndex",
  //   url: require("../../../asset/img/centerPlan/annular.png"),
  // },
  // {
  //   id: 5,
  //   title: t("1006"),
  //   path: "/deviceManageIndex",
  //   url: require("../../../asset/img/centerPlan/panorama.png"),
  // },
  // {
  //   id: 6,
  //   title: t("1007"),
  //   path: "/deviceManageIndex",
  //   url: require("../../../asset/img/centerPlan/threeD.png"),
  // },
];
const Jump = (item) => {
  navId.value = item.id;
  item.title === t("257") && router.push({name:"airlinePlan",query:{value:'addRoutePlan'}});
  item.title === t("1004") && router.push("polygonMapping");
};
</script>





<style scoped lang="less">
/deep/.drag_dialog_top {
  border-bottom: 1px solid rgba(0, 245, 255, 0.4);
  background: rgba(1, 78, 115, 0.8) !important;
}
/deep/.iconfont {
  color: #ffffff !important;
}
.content {
  width: 20rem;
  height: 14.5625rem;
  background: rgba(1, 78, 115, 0.8);
  // padding: 1.625rem 1.125rem;
  padding: 1.625rem 1.4375rem;
  .box {
    width: 7.9375rem;
    height: 8.75rem;
    background: #168aa3;
    border-radius: 0.25rem;
    // margin-right: 0.3125rem;
    margin-right: 1.25rem;
    cursor: pointer;
    .li {
      font-size: 0.8125rem;
      color: #ffffff;
    }
  }
  // .box:nth-child(1) {
  //   img {
  //     width: 2.875rem;
  //     height: 3.5625rem;
  //     margin-top: 1.375rem;
  //   }
  //   .li {
  //     margin-top: 1.5625rem;
  //   }
  // }
  .box:nth-child(1) {
    img {
      width: 3.25rem;
      height: 2.25rem;
      margin-top: 2.4375rem;
    }
    .li {
      margin-top: 1.8125rem;
    }
  }
  .box:nth-child(2) {
    img {
      width: 2.6875rem;
      height: 2.6875rem;
      margin-top: 2.25rem;
    }
    .li {
      margin-top: 1.5625rem;
    }
  }
  // .box:nth-child(4) {
  //   img {
  //     width: 3rem;
  //     height: 2.625rem;
  //     margin-top: 2.3125rem;
  //   }
  //   .li {
  //     margin-top: 1.5625rem;
  //   }
  // }
  // .box:nth-child(5) {
  //   img {
  //     width: 2.4375rem;
  //     height: 2.3125rem;
  //     margin-top: 2.4375rem;
  //   }
  //   .li {
  //     margin-top: 1.75rem;
  //   }
  // }
  // .box:nth-child(6) {
  //   img {
  //     width: 2.3125rem;
  //     height: 2.3125rem;
  //     margin-top: 2.4375rem;
  //   }
  //   .li {
  //     margin-top: 1.75rem;
  //   }
  // }
  .box:last-child {
    margin-right: 0;
  }
  .box_bg {
    background: #38adf9;
  }
}
</style>
