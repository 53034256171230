<template>
  <div class="container">
    <div class="top">
      <div class="flex-st flex-js">
        <div class="left flex-a cuor" @click="deviceChange">
          <div class="left_box"></div>
          <div class="left_right">
            <div class="top_box"></div>
            <div class="top_center">
              <span>{{ t('685') }}</span>
            </div>
            <div class="btn_box"></div>
          </div>
        </div>
        <div class="hx cuor" @click="routeChange">
          <div class="left flex-a">
            <div class="left_right">
              <div class="top_box"></div>
              <div class="top_center">
                <span>{{ t('1002') }}</span>
              </div>
              <div class="btn_box"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="right">
      <div class="flex-fsb flex-dc">
      </div>
    </div> -->
  </div>
</template>

<script>
import { t } from '../../../languages';
import { defineComponent, reactive, toRefs } from "@vue/runtime-core";
export default defineComponent({
  setup(props, { emit }) {
    const methods = reactive({
      deviceChange: () => {
        emit("deviceChange");
      },
      routeChange: () => {
        emit("routeChange");
      },
      broadCast: () => {
        emit("broadCast");
      },
    });
    return {
      ...toRefs(methods),
    };
  },
});
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
}
.top {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  .left {
    .left_box {
      width: 6px;
      height: 29px;
      background: #61ebff;
      margin-right: 0.375rem;
    }
  }
  .left_right {
    height: 3.1875rem;
    .top_center {
      height: 2.875rem;
      width: 9.4375rem;
      background: linear-gradient(
        90deg,
        #126a9c 0%,
        rgba(70, 131, 246, 0.2) 100%
      );
      span {
        margin-left: 1rem;
        line-height: 2.875rem;
        color: #ffffff;
        font-size: 1.125rem;
      }
    }
    .top_box {
      width: 8.75rem;
      height: 0.125rem;
      background: linear-gradient(
        90deg,
        #5fa0f3 0%,
        rgba(36, 97, 181, 0.33) 100%
      );
      opacity: 0.5;
    }
    .btn_box {
      width: 8.75rem;
      height: 0.1875rem;
      background: linear-gradient(90deg, #489bea 0%, #1d4e95 100%);
    }
  }
  .hx {
    margin-left: 1.125rem;
  }
}
.right {
  position: absolute;
  top: .9375rem;
  right: 1.3125rem;
  z-index: 10;
  .right_box {
    width: .375rem;
    height: 29px;
    background: #61ebff;
    margin-left: 0.375rem;
  }
  .right_top {
    margin-top: 0.5rem;
  }
}
.right_center {
  height: 2.875rem;
  width: 8.75rem;
  background: linear-gradient(90deg, rgba(70, 131, 246, 0.2) 0%, #126a9c 100%);
  span {
    margin-left: 2.6875rem;
    line-height: 2.875rem;
    color: #ffffff;
    font-size: 1.125rem;
  }
}
</style>