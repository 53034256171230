<template>
  <div class="image_viewer__wrapper">
    <span
      class="switch_btn hover_opacity"
      v-show="dispalyWay === 'image'"
      @click="
        dispalyWay = 'panorama';
        switchDisplay();
      "
      >{{ t('950') }}</span
    >
    <span
      class="switch_btn hover_opacity"
      v-show="dispalyWay === 'panorama'"
      @click="dispalyWay = 'image'"
      >{{ t('951') }}</span
    >
    <div class="el-image-viewer__canvas">
      <div
        class="img_content"
        :class="[dispalyWay === 'image' ? 'image_box' : 'panorama_box']"
      >
        <div class="container" id="imageContainer"></div>
        <el-image-viewer
          :url-list="urlList"
          :initial-index="initialIndex"
          @close="$emit('update:isVisible', false)"
          @switch="switchImage"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { t } from '../../../languages';
import { toRefs, ref, nextTick, onUnmounted, onMounted } from "vue-demi";

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    previewSrcList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    initialIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const panolens = PANOLENS; // eslint-disable-line no-undef
    const three = THREE; // eslint-disable-line no-undef
    let panorama = null;

    const { previewSrcList, initialIndex } = toRefs(props);

    const urlList = [];

    for (let i = 0; i < previewSrcList.value.length; i++) {
      urlList.push(previewSrcList.value[i].imageUrl);
    }

    const imgSrc = ref("");
    const dispalyWay = ref("image");
    const imgIndex = ref(initialIndex.value);

    /* 修改图片地址 */
    function updateImgSrc() {
      imgSrc.value = urlList[imgIndex.value];
      if (dispalyWay.value === "panorama") {
        changePanorama();
      }
    }

    updateImgSrc();

    let mainViewer = null;

    onMounted(() => {
      nextTick(() => {
        initPanorama();
      });
    });
    /* 初始化全景 */
    function initPanorama() {
      document.getElementById("imageContainer").innerHTML = "";
      mainViewer = new panolens.Viewer({
        container: document.querySelector("#imageContainer"), //html容器
        // reverseDragging: true, //反向拖拽
        controlBar: false, //控制栏
      });
      const control = mainViewer.getControl();
      control.momentumDampingFactor = 0.9;
      panorama = new panolens.ImagePanorama(imgSrc.value);
      mainViewer.add(panorama);
    }

    /* 修改全景照片贴图 */
    function changePanorama() {
      const textureLoader = new three.TextureLoader();
      const newTexture = textureLoader.load(imgSrc.value);
      panorama.updateTexture(newTexture);
    }

    /* 切换显示方式 */
    function switchDisplay() {
      nextTick(() => {
        if (dispalyWay.value === "panorama") {
          changePanorama();
        }
      });
    }

    onUnmounted(() => {
      if (mainViewer !== null) {
        mainViewer.remove(panorama);
        mainViewer.destroy();
      }
    });

    /* 切换图片 */
    function switchImage(index) {
      imgIndex.value = index;
      updateImgSrc();
    }

    return {
      imgSrc,
      imgIndex,
      dispalyWay,
      updateImgSrc,
      changePanorama,
      switchDisplay,
      urlList,
      switchImage,
    };
  },
};
</script>


<style scoped lang="less">
.image_viewer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.5);
}
.el-image-viewer__btn {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  width: 44px;
  height: 44px;
  font-size: 24px;
  color: #fff;
  background-color: #606266;
  border-color: #fff;
  .svg_icon {
    width: 24px;
    height: 24px;
  }
}
.el-image-viewer__close {
  top: 40px;
  right: 40px;
}
.el-image-viewer__prev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}
.el-image-viewer__next {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  text-indent: 2px;
}
.el-image-viewer__canvas {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  .img_content {
    position: relative;
    width: 86%;
    height: 80%;
    > img,
    .container {
      width: 100%;
      height: 100%;
    }
    .img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  :deep(.el-image-viewer__mask) {
    background: transparent;
  }
}
.switch_btn {
  position: absolute;
  top: 50px;
  left: 40px;
  font-size: 14px;
  color: #fff;
  background-color: #0090da;
  border-color: #fff;
  width: 7.1875rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
  text-align: center;
  z-index: 10000;
}

.image_box {
  :deep(.el-image-viewer__wrapper) {
    pointer-events: auto;
    .el-image-viewer__mask,
    .el-image-viewer__actions {
      display: block;
    }
    .el-image-viewer__canvas {
      display: flex;
    }
  }
  .container {
    opacity: 0;
  }
}
.panorama_box {
  :deep(.el-image-viewer__wrapper) {
    pointer-events: none;
    .el-image-viewer__mask,
    .el-image-viewer__canvas,
    .el-image-viewer__actions {
      display: none;
    }
    .el-image-viewer__btn {
      pointer-events: auto;
    }
  }
  .container {
    opacity: 1;
  }
}
</style>