import { t } from '../../languages';
import { onBeforeUnmount, ref, nextTick } from "vue";
import emitter from "@/utils/mitt";
import { websocketsend } from "@/utils/websocket";
import { ElMessage, ElNotification } from "element-plus";
import { dateFormatter } from "@/utils/utilFun";
import { store } from "@/store";

/**
 *
 * @param {* 添加实时轨迹函数} trajectoryAdd
 * @param {* 切换任务id的航线和轨迹函数} changeTaskID
 * @param {* 同步绘制函数} drawSync
 * @returns
 */
const socketUtils = (trajectoryAdd, changeTaskID, drawSync, delDraw) => {
  // websocketsend();  //创建机场连接
  const uavInfo = ref({}); //STA_AF数据
  const weatherInfo = ref({}); //STA_WS数据
  const airportInfo = ref({}); //STA_BASIC数据
  const taskInfo = ref({}); //STA_WP数据
  const controInfo = ref({}); //STA_USER数据
  const logList = ref([]); //操作日志数据
  const uavPanel = ref({}); //STA_INFO数据
  const uavOnLine = ref({}); //机场在线离线
  const lteInfo = ref({}); //远程认证
  const platformsInfo = ref();//起飞、控制权限弹窗信息
  /* RTK相关数据 */
  const trajectory = ref({});
  let currentTaskId = ""; //当前任务id
  const rtkStatusDic = new Map(
    Object.entries({
      NONE: t("76"),
      SINGLE_POINT: t("77"),
      FLOAT: t("78"),
      FIXED_POINT: t("79"),
      UNKNOWN: t("80"),
    })
  );
  /* end */

  const airportstatusDic = new Map(
    Object.entries({
      INIT: t("81"),
      SELF_TEST: t("82"),
      WAIT_RESET: t("83"),
      RESETTING: t("84"),
      STANDBY: t("85"),
      PREPARING: t("86"),
      READY_TO_GO: t("87"),
      EXECUTING: t("88"),
      GOING_HOME: t("89"),
      LANDING: t("90"),
      RECOVERING: t("91"),
      BATTERY_LOADING: t("92"),
      BATTERY_UNLOADING: t("93"),
      BATTERY_LOADED: t("94"),
      ERROR: t("95"),
      TO_CONFIRM: t("96"),
      ENMERGENCY: t("97"),
      READY_FOR_LANDING: t("98"),
      WAIT_CHARGING: t("99"),
    })
  );

  const airportBatteryDic = new Map(
    Object.entries({
      "00": t("81"),
      "01": t("100"),
      "02": t("101"),
      "03": t("102"),
      "04": t("95"),
      "05": t("103"),
      "06": t("104"),
      "07": t("105"),
      "08": t("106"),
      "09": t("107"),
      10: t("108"),
      11: t("109"),
      12: t("110"),
    })
  );
  /* 根据度数返回风向 */
  function getDirecByDegree(degree) {
    degree = degree / 10;
    if (degree === 0 || degree === 360) {
      return t("111");
    } else if (degree > 0 && degree < 90) {
      return t("112");
    } else if (degree === 90) {
      return t("113");
    } else if (degree > 90 && degree < 180) {
      return t("114");
    } else if (degree === 180) {
      return t("115");
    } else if (degree > 180 && degree < 270) {
      return t("116");
    } else if (degree === 270) {
      return t("117");
    } else if (degree > 270 && degree < 360) {
      return t("118");
    } else {
      return t("80");
    }
  }
  const taskStatusDic = new Map(
    Object.entries({
      UNKNOWN: t("80"),
      DISCONNECTED: t("119"),
      NOT_SUPPORTED: t("120"),
      RECOVERING: t("121"),
      READY_TO_UPLOAD: t("122"),
      UPLOADING: t("123"),
      READY_TO_EXECUTE: t("124"),
      DOWNLOAD_PHOTOS: t("125"),
      EXECUTING: t("88"),
      EXECUTION_PAUSED: t("126"),
      START: t("127"),
      FINISH: t("128"),
    })
  );
  //获取用户id
  let createUserId =
    store.state.user.userInfo.id ||
    (localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")).id
      : "");
  let getHistoryDraw = false; //是否已获取历史绘制
  function nestMessage(dataInfo) {
    switch (dataInfo.action) {
      // 绑定机场
      case "BIND":
        if (dataInfo.data === "GENERAL_CHANNEL" || dataInfo.data === "DEFAULT")
          return;
        ElMessage.success(t("129"));
        break;
      // 机场信息
      case "STA_BASIC":
        if (dataInfo.data) {
          airportInfo.value.nAlt = dataInfo.data.nestLocationAltitude; //海拔高度
          if (airportInfo.value.nSta !== dataInfo.data.nestStateConstant) {
            airportInfo.value.nSta = dataInfo.data.nestStateConstant;
            if (airportstatusDic.has(airportInfo.value.nSta)) {
              airportInfo.value.nStatusName = airportstatusDic.get(
                airportInfo.value.nSta
              );
            }
          }
          airportInfo.value.uavState = dataInfo.data.aircraftStateConstant;
          airportInfo.value.isAircraftConnected = dataInfo.data.isAircraftConnected;//无人机是否开机
          // 无人机状态未知 -- 清除主题数据
          if (airportInfo.value.uavState === "UNKNOWN") {
            uavInfo.value.rtkStatusName = "-";
            uavInfo.value.rtkSatCount = "-";
            uavInfo.value.rtkReady = undefined;
            uavInfo.value.afLong = "-";
            uavInfo.value.afLat = "-";
            uavInfo.value.flyDirection = "-";
            uavInfo.value.cameraPitch = "-";
            taskInfo.value.taskStatusName = "-";
            uavPanel.value.index = 0;
            uavPanel.value.uCount = 0;
          }
        }
        break;
      //无人机飞行时间,速度等等
      case "STA_AF":
        if (Object.keys(dataInfo.data).length === 0) {
          uavInfo.value.alt = 0; //无人机绝对高度
          uavInfo.value.rlt = 0; //无人机相对高度
          uavInfo.value.afLat = 0; //无人机纬度
          uavInfo.value.afLong = 0; //无人机经度
          uavInfo.value.hDist = 0; //无人机水平距离
          uavInfo.value.hSpeed = 0; //无人机水平速度
          uavInfo.value.vSpeed = 0; //无人机垂直速度
          uavInfo.value.flyTime = 0; //无人机飞行时长\
          uavInfo.value.yaw = 0; //无人机航向（偏航）
          uavInfo.value.pitch = 0; //无人机俯仰角
          uavInfo.value.roll = 0; //无人机翻滚角
          uavInfo.value.flyDirection = 0; //航向
        }
        if (dataInfo.data) {
          // 无人机状态未知
          if (airportInfo.value.uavState === "UNKNOWN") {
            return;
          }
          uavInfo.value.alt = dataInfo.data.aircraftAltitude; //无人机绝对高度
          uavInfo.value.rlt = dataInfo.data.relativeAltitude; //无人机相对高度
          uavInfo.value.hDist = dataInfo.data.aircraftDistToHomePoint; //无人机水平距离
          uavInfo.value.flyTime = dataInfo.data.aircraftFlyInSecond; //无人机飞行时长\
          uavInfo.value.yaw = dataInfo.data.aircraftYaw; //无人机航向（偏航）
          uavInfo.value.pitch = dataInfo.data.aircraftPitch; //无人机俯仰角
          uavInfo.value.roll = dataInfo.data.aircraftRoll; //无人机翻滚角
          uavInfo.value.afLat = dataInfo.data.aircraftLocationLatitude; //无人机纬度
          uavInfo.value.afLong = dataInfo.data.aircraftLocationLongitude; //无人机经度
          uavInfo.value.flyDirection = dataInfo.data.aircraftBaseHeadDirection; //航向
          uavInfo.value.hSpeed = dataInfo.data.aircraftHSpeed; //无人机水平速度
          uavInfo.value.vSpeed = dataInfo.data.aircraftVSpeed; //无人机垂直速度
        }
        break;
      // 气象台状态信息
      case "STA_WS":
        if (dataInfo.data) {
          // console.log(dataInfo.data);
          weatherInfo.value.temp = dataInfo.data.temperature / 10; //温度
          weatherInfo.value.humi = dataInfo.data.humidity / 10; //湿度
          weatherInfo.value.speed = dataInfo.data.speed / 10; //风速
          // 风向
          if (weatherInfo.value.dire !== dataInfo.data.direction) {
            weatherInfo.value.dire = dataInfo.data.direction;
            weatherInfo.value.speedName = getDirecByDegree(
              weatherInfo.value.dire
            );
          }
          weatherInfo.value.ill = dataInfo.data.illumination;
          weatherInfo.value.rain = dataInfo.data.rainfall / 10; //雨量
        }
        break;

      /* RTK状态信息*/
      case "STA_RTK":
        if (dataInfo.data) {
          // 无人机状态未知
          if (airportInfo.value.uavState === "UNKNOWN") {
            return;
          }
          // 状态信息rtk数据
          uavInfo.value.rtkStatus = dataInfo.data.positioningSolution;
          if (rtkStatusDic.has(uavInfo.value.rtkStatus)) {
            uavInfo.value.rtkStatusName = rtkStatusDic.get(
              uavInfo.value.rtkStatus
            );
          }
          uavInfo.value.rtkReady = dataInfo.data.isRtkReady;
          uavInfo.value.rtkSatCount = dataInfo.data.aircraftSatelliteCount;
          // end
        }
        break;
      // 云台状态信息
      case "STA_GIM":
        if (dataInfo.data) {
          // 无人机状态未知
          if (airportInfo.value.uavState === "UNKNOWN") {
            return;
          }
          uavInfo.value.cameraPitch = dataInfo.data.gimbalPitch;
        }
        break;
      // 无人机电池状态
      case "STA_AF_BATTERY":
        if (dataInfo.data) {
          uavInfo.value.charge = dataInfo.data.aircraftBatteryChargeInPercent; //无人机电量
          uavInfo.value.con = dataInfo.data.isBatteryConnected; //电池是否在位
        }
        break;
      // 机场电池信息
      case "STA_N_BATTERY":
        if (dataInfo.data) {
          /* 
            数值为HEX格式 前两位为电池状态,后两位表示电池百分比
            例如传612 代表02 64, 02是状态 64（转十进制）是电量百分比
            elecQuantity表示电量
          */
          const nestBatteryState = [];
          for (let i = 1; i <= 6; i++) {
            if (
              typeof dataInfo.data["nestBattery" + i + "State"] !== "undefined"
            ) {
              nestBatteryState[i] =
                dataInfo.data["nestBattery" + i + "State"].toString(16);
              if (nestBatteryState[i].length === 3) {
                //newNum为hex格式的4位数
                const newNum = "0" + nestBatteryState[i];
                //截取前两位表示电池状态
                airportInfo.value["batteryStatus" + i] = airportBatteryDic.get(
                  newNum.substr(0, 2)
                );
                //截取后两位表示电池电量
                const elecQuantity = newNum.substr(2, 2);
                airportInfo.value["elecQuantity" + i] = parseInt(
                  elecQuantity,
                  16
                );
              } else if (nestBatteryState[i].length <= 2) {
                // 初始化时可能出现电池状态位00时省略状态位的情况
                airportInfo.value["batteryStatus" + i] =
                  airportBatteryDic.get("00");
                airportInfo.value["elecQuantity" + i] = parseInt(
                  nestBatteryState[i],
                  16
                );
              }
            }
            //如果其中某块电池不在位了，就吧某块电池的电量赋值为undefined
            else if (
              typeof dataInfo.data["nestBattery" + i + "State"] === "undefined"
            ) {
              airportInfo.value["elecQuantity" + i] = undefined;
            }
          }
        }
        break;
      // 任务航点状态
      case "STA_WP":
        if (dataInfo.data) {
          // console.log(dataInfo.data);
          // 任务id不同
          const execMissionID = dataInfo.data.execMissionID;
          if (
            execMissionID !== undefined &&
            trajectory.value.currentTaskId !== execMissionID
          ) {
            //修改因任务不同的航线和轨迹
            changeTaskID(execMissionID);
            // taskInfo.value.execID = dataInfo.data.execMissionID;
          }
          taskInfo.value.index = dataInfo.data.missionReachIndex;
          taskInfo.value.uCount = dataInfo.data.missionUploadedIndex;
          // 任务状态不同
          if (taskInfo.value.sta !== dataInfo.data.missionState) {
            taskInfo.value.sta = dataInfo.data.missionState;
            // 无人机状态未知
            if (airportInfo.value.uavState === "UNKNOWN") {
              return;
            }
            if (taskStatusDic.has(taskInfo.value.sta)) {
              taskInfo.value.taskStatusName = taskStatusDic.get(
                taskInfo.value.sta
              );
              console.log(taskInfo.value.taskStatusName);
            }
            // 刷新任务列表状态
            // updateTaskStatus(taskInfo.value.execID, taskInfo.value.sta);
          }
        }
        break;
      // 控制信息
      case "STA_USER":
        if (dataInfo.data) {
          controInfo.value.userId = dataInfo.data.userId;
          controInfo.value.userName = dataInfo.data.userName;
          controInfo.value.reason = dataInfo.data.reason;
          console.log(controInfo.value);
        }
        break;
      // 气象台状态信息
      // RTK状态信息（有开始结束限制）
      case "STA_RTK_START":
        if (dataInfo.data) {
          /* 轨迹 */
          trajectory.value.alt = dataInfo.data.aircraftRtkAltitude; //无人机高度
          trajectory.value.afLat = dataInfo.data.aircraftRtkLatitude; //无人机纬度
          trajectory.value.afLong = dataInfo.data.aircraftRtkLongitude; //无人机经度

          const currPosi = {
            longitude: dataInfo.data.aircraftRtkLongitude,
            latitude: dataInfo.data.aircraftRtkLatitude,
            altitude: dataInfo.data.aircraftRtkAltitude,
            heading: dataInfo.data.aircraftRtkYaw,
            pitch: 0,
            roll: 0,
          };

          trajectoryAdd(currPosi); //添加实时轨迹

          /* end */
        }
        break;
      // 机场面板数据状态
      case "STA_INFO":
        if (dataInfo.data) {
          uavPanel.value.aircraftBattery =
            dataInfo.data.aircraftBatteryChargeInPercent;
          uavPanel.value.upload = dataInfo.data.upload;
          uavPanel.value.uploadTotal = dataInfo.data.uploadTotal;
          if (taskStatusDic.has(dataInfo.data.missionState)) {
            uavPanel.value.taskStatusName = taskStatusDic.get(
              dataInfo.data.missionState
            );
          }
          // 无人机状态未知
          if (airportInfo.value.uavState === "UNKNOWN") {
            return;
          }
          uavPanel.value.index = dataInfo.data.missionReachIndex;
          uavPanel.value.uCount = dataInfo.data.missionUploadedIndex;
        }
        break;
      // 在线离线
      case "ON_OFF_LINE":
        if (dataInfo.data) {
          uavOnLine.value.sn = dataInfo.data.nestSn;
          uavOnLine.value.state = dataInfo.data.state;
          uavOnLine.value.isShow = dataInfo.data.isShow;
        }
        break;
      case "STA_LOG":
        if (dataInfo.data) {
          // 离线通知
          // if (dataInfo.data.isSign === "offLine" || dataInfo.data.isSign === "onLine") {
          //   ElNotification({
          //     title: t("130"),
          //     message: dataInfo.data.logContent,
          //     duration: 3000,
          //   });
          // }
          logList.value.push({
            logContent: dataInfo.data.logContent,
            operateTime: dateFormatter(dataInfo.data.operateTime, "HH:mm:ss"),
            operateStatus: dataInfo.data.operateStatus,
          });
          // console.log(dataInfo.data.operateTime);
          // 每一次只发送最后一条数据
          emitter.emit("logs", logList.value[logList.value.length - 1]);
          const log_ul = document.getElementById("log_ul");
          nextTick(() => {
            if (log_ul) {
              log_ul.scrollTop = log_ul.scrollHeight;
            }
          });
        }
        break;
      //获取历史绘制数据
      case "DRAW_SYNC_HISTORY":
        if (dataInfo.data) {
          if (!getHistoryDraw) {
            const lineList = dataInfo.data.lineList;
            const spotList = dataInfo.data.spotList;
            const noodlesList = dataInfo.data.noodlesList;

            //回调绘制点
            spotList.length > 0 && drawSync("spot", spotList);

            //回调绘制线
            lineList.length > 0 && drawSync("line", lineList);

            //回调绘制面
            noodlesList.length > 0 && drawSync("noodles", noodlesList);

            getHistoryDraw = true;
          }
        }
        break;

      //获取实时绘制数据
      case "DRAW_SYNC_NEW":
        if (dataInfo.data) {
          const lineList = dataInfo.data.lineList;
          const spotList = dataInfo.data.spotList;
          const noodlesList = dataInfo.data.noodlesList;

          //回调绘制点
          if (spotList !== undefined && spotList.length > 0) {
            let drawSpot = spotList.filter(
              (item) => item.createUserId !== createUserId
            );
            drawSpot.length > 0 && drawSync("spot", drawSpot);
          }

          //回调绘制线
          if (lineList !== undefined && lineList.length > 0) {
            let drawLine = lineList.filter(
              (item) => item.createUserId !== createUserId
            );
            console.log(drawLine);
            drawLine.length > 0 && drawSync("line", drawLine);
          }

          //回调绘制面
          if (noodlesList !== undefined && noodlesList.length > 0) {
            let drawNoodles = noodlesList.filter(
              (item) => item.createUserId !== createUserId
            );
            drawNoodles.length > 0 && drawSync("noodles", drawNoodles);
          }
        }
        break;
      case "DRAW_SYNC_DELETE":
        if (dataInfo.data) {
          console.log();
          delDraw(dataInfo.data);
        }
        break;
      case "STA_SIGNAL":
        // 遥控图传信号
        if (dataInfo.data) {
          uavInfo.value.rcLte = dataInfo.data.rcLte;
          uavInfo.value.ocuSync = dataInfo.data.ocuSync;
          uavInfo.value.uavLte = dataInfo.data.uavLte;
        }
        break;
      case "STA_TASKS":
        // 控制台的暂停继续
        if (dataInfo.data) {
          console.log(t('131'));
          emitter.emit('updataStatu',dataInfo.data.msg)
        }
        break;
      case "REMOTE_STATUS":
        if (dataInfo.data) {
          uavOnLine.value.remoteStatu = dataInfo.data.state;
        }
        break;
      case "STA_LTE":
        // 远程认证
        if (dataInfo.data) {
          lteInfo.value.lteAuthenticated = dataInfo.data.lteAuthenticated;
          lteInfo.value.lteAuthenticatedPhoneNumber = dataInfo.data.lteAuthenticatedPhoneNumber;
          lteInfo.value.lteAuthenticatedRemainingTime = dataInfo.data.lteAuthenticatedRemainingTime;
          lteInfo.value.lteConnection = dataInfo.data.lteConnection;
          lteInfo.value.lteEnabled = dataInfo.data.lteEnabled;
        }
        break;
      case "ZS_AUTH_APPLICATION":
        // 授权弹窗
        if (dataInfo.data) {
          platformsInfo.value = dataInfo.data;
        }
        break;
      default:
        break;
    }
  }
  //清空数据
  function clearData() {
    uavInfo.value = {}; //STA_AF数据
    weatherInfo.value = {}; //STA_WS数据
    airportInfo.value = {}; //STA_BASIC数据
    taskInfo.value = {}; //STA_WP数据
    controInfo.value = {}; //STA_USER数据
    logList.value = []; //操作日志数据
    uavPanel.value = {}; //STA_INFO数据
    trajectory.value = {};
    lteInfo.value = {};
    platformsInfo.value = {};
  }

  emitter.on("nest-message", nestMessage);

  onBeforeUnmount(() => {
    emitter.off("nest-message", nestMessage);
  });

  return {
    nestMessage,
    uavInfo,
    weatherInfo,
    airportInfo,
    taskInfo,
    controInfo,
    trajectory,
    logList,
    uavPanel,
    uavOnLine,
    createUserId,
    lteInfo,
    platformsInfo,
    clearData,
  };
};

export default socketUtils;
