<template>
  <div class="container">
    <div class="top">
      <TopNav @deviceChange="deviceChange" @routeChange="routeChange" @broadCast="broadCast" />
      <div class="messageNav flex">
        <div class="messageNav_left hover_background" @mouseenter="navListShows()" @mouseleave="navListHide()"
          v-show="weatherShow">
          <!-- :style="{ visibility: controlShow === false ? 'hidden' : 'visible' }" -->
          {{ t("365") }}
          <img src="@/asset/img/control-box/sanjiao.png" />
          <transition name="el-zoom-in-top">
            <ul class="dropdown-data-style" v-if="navListShow">
              <li class="hover_background" @click="showDragDialog(index, trajectory.currentTaskId)"
                v-for="(item, index) in navLists" :key="index">
                {{ item.name }}
              </li>
            </ul>
          </transition>
        </div>
        <div class="messageNav_middle flex-sa" v-show="weatherShow">
          <el-tooltip class="box-item" effect="dark" :content="t('366')" placement="bottom">
            <div class="temperature commonNavItem">
              <img src="@/asset/img/control-box/wendu.png" />
              <span>{{ weatherInfo.temp ? weatherInfo.temp : "0" }} ℃</span>
            </div>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" :content="t('367')" placement="bottom">
            <div class="humidity commonNavItem">
              <img src="@/asset/img/control-box/shidu.png" />
              <span>{{ weatherInfo.humi ? weatherInfo.humi : "0" }} %</span>
            </div>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" :content="t('368')" placement="bottom">
            <div class="wind commonNavItem">
              <img src="@/asset/img/control-box/fengsu.png" />
              <span>{{ weatherInfo.speed ? weatherInfo.speed : "0" }} m/s</span>
            </div>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" :content="t('369')" placement="bottom">
            <div class="rain commonNavItem">
              <img src="@/asset/img/control-box/yuliang.png" />
              <span>{{ weatherInfo.rain ? weatherInfo.rain : "0" }} mm/s</span>
            </div>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" :content="t('370')" placement="bottom">
            <div class="direction commonNavItem">
              <img src="@/asset/img/control-box/fangwei.png" />
              <span style="position: relative; top: -0.125rem">{{
                weatherInfo.speedName ? weatherInfo.speedName : "-"
              }}</span>
            </div>
          </el-tooltip>
        </div>
        <div class="messageNav_right hover_background" @click="showStatuMessage()" v-show="weatherShow">
          <!-- v-show="controlShow" -->
          {{ t("371") }}
        </div>
      </div>
    </div>
    <!-- 设备列表 -->
    <div id="deviceBox" class="flex clearfix" v-if="deviceShow">
      <!-- 设备左侧 -->
      <transition name="leftSlide">
        <div class="device_left" v-show="deviceLeftShow">
          <div class="deviceTop">
            <div class="tab flex-a">
              <div class="nav flex-fac" :class="item.id == navId ? 'navtive' : ''" v-for="(item, index) in navList"
                :key="item.id">
                <!-- @click="navChange(item)" -->
                {{ item.name }}
                <span v-if="navId == 2 && index == 1"><img src="../../asset/img/personal-icon/error.png" alt="" /></span>
              </div>
            </div>
            <div class="search flex-sb">
              <div class="listCity" @click="showDress">
                <img src="../../asset/img/centerCity.png" alt="" />
                <div class="citylist" v-if="dressShow">
                  <div class="citytop"></div>
                  <div class="cityBox">
                    <div :class="item.value == dressVal ? 'cityitem_bg' : ''" class="cityitem" v-for="item in dressList"
                      :key="item.value" @click.stop="dressChange(item)">
                      {{ item.desc }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="searchs flex-a">
                <el-input class="filePut m2 common-input" type="text" :placeholder="navId == 1 ? t('372') : t('373')
                  " v-model.trim="centerListFrom.deviceName" clearable @clear="inputClean('AirUan')"
                  @keyup.enter="centerSearch" />
                <el-icon class="file_icon" style="cursor: pointer" @click="centerSearch">
                  <Search />
                </el-icon>
              </div>
            </div>
          </div>
          <div class="listBox">
            <div v-if="centerAirUavList.length != 0">
              <el-collapse v-model="activeNum" @change="handleChange">
                <el-collapse-item :name="index" v-for="(item, index) in centerAirUavList" :key="index">
                  <template #title>
                    <div class="collTitle flex-a">
                      <img src="../../asset/img/li_icon.png" alt="" />
                      <span>{{ item.region }}</span>
                      <span>{{ item.devices.length }}{{
                        navId == 1 ? t('1362') : t('1363')
                      }}</span>
                    </div>
                  </template>
                  <div class="coollItem flex-sb" style="cursor: pointer" :class="items.id == itemId ? 'coll_bg' : ''"
                    v-for="items in item.devices" :key="items.id" @click="itemChange(items)">
                    <!-- 
                    @dblclick="dbItemChange(items)" -->
                    <div class="flex-a">
                      <!-- 这里到时候加！图片移动端的 -->
                      <div class="iconfont icon-dingwei dingwei_icon dingwei_hover" @click.stop="goAirpot(items)"></div>
                      <img :class="navId == 1 ? 'img_one' : 'img_two'" :src="navId == 1
                          ? require('../../asset/img/air.png')
                          : require('../../asset/img/phone.png')
                        " alt="" />
                      <span v-if="items.deviceName.length > 10" class="collLeft">
                        <el-popover trigger="hover" placement="top" :width="200" :content="items.deviceName">
                          <template #reference>
                            <div class="right" style="cursor: pointer">
                              {{ items.deviceName }}
                            </div>
                          </template>
                        </el-popover>
                      </span>
                      <span v-else class="collLeft">{{
                        items.deviceName
                      }}</span>
                    </div>
                    <div class="collRight">
                      <!-- 这个也有两套类型，机场的和移动端的 -->
                      <!-- 机场  执行中，在线，离线 故障 -->
                      <!-- 移动端  连接无人机，在线，离线 -->
                      <!-- 长度问题 ,后面有状态标识在解决，现在定死collLeft宽 -->
                      <span :style="{ color: uavTypeColor[items.state] }">{{
                        uavType[items.state]
                      }}</span>
                      <!-- <span
                      class="span_r"
                      @click.stop="assignMent(false, distributeType)"
                      >{{ t('376') }}</span
                    > -->
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div v-else class="empty">
              {{ t("377") }}{{ navId == 1 ? t("374") : t("375") }}
            </div>
          </div>
          <div class="gull_left hover_opacity" @click.stop="aleft('left')">
            <el-icon>
              <Fold />
            </el-icon>
          </div>
        </div>
      </transition>

      <!-- 设备右侧机场信息 -->
      <transition name="leftSlide">
        <div class="device_right" v-if="itemShow">
          <!-- 机场，移动端 单击选中显示 -->
          <div class="itemContent">
            <!-- 视频监控 远程控制-->
            <div class="centerControl">
              <div class="top flex-dc flex-fac" :class="currentPlayArr.length !== 0 ? 'tp_bg' : ''"
                @click="monitorChange('MONITOR', centerRouteFrom.sn)">
                <img src="../../asset/img/monitor.png" alt="" />
                <span>{{ t("378") }}</span>
              </div>
              <div class="top flex-dc flex-fac" :class="controlShow ? 'tp_bg' : ''"
                @click="monitorChange('CENTERMONITOR', centerRouteFrom.sn)">
                <img src="../../asset/img/centerMonitor.png" alt="" />
                <span>{{ t("379") }}</span>
              </div>
            </div>
            <div class="contentTopBox">
              <div class="itemTop">
                <div class="itemName">{{ UAVName }}</div>
                <div class="itemClose">
                  <el-icon class="icon_close" @click="closeItemShow">
                    <CloseBold />
                  </el-icon>
                </div>
              </div>
              <!-- 详情 -->
              <div class="itemUser">
                <!-- 机场头部 -->
                <div v-if="navId == 1" class="itemFly">
                  <div class="flex margin_btm15">
                    <div class="itemLeft flex flex-jcsb flex-dc">
                      <div class="margin_btm5">
                        <div style="margin-bottom: 0.3125rem; position: relative" class="closeSeleUse">
                          {{ centerRouteFrom.sn }}
                          <span class="uavSpan">{{ t('380') }}:&nbsp;&nbsp;&nbsp;
                            {{
                              airportInfo.nStatusName && onlineState === 1
                              ? airportInfo.nStatusName
                              : t('85')
                            }}
                          </span>
                        </div>
                        <div style="color: #19ff19">{{ UAVModel }}</div>
                      </div>
                      <div class="margin_btm5">
                        <div style="margin-bottom: 0.3125rem">
                          {{ t("381") }}：
                        </div>
                        <div class="progress">
                          <el-progress :percentage="typeof uavPanel.index !== 'undefined' &&
                              typeof uavPanel.uCount !== 'undefined' &&
                              uavPanel.uCount !== 0 &&
                              onlineState === 1
                              ? Number(
                                parseFloat(
                                  (uavPanel.index / uavPanel.uCount) * 100
                                ).toFixed(0)
                              )
                              : 0
                            " />
                        </div>
                      </div>
                      <div>
                        <div style="margin-bottom: 0.3125rem">{{ t('382') }}：</div>
                        <div class="overflow_ellipsis" :title="trajectory.taskName" style="color: #19ff19">
                          {{ trajectory.taskName || "-" }}
                        </div>
                      </div>
                    </div>
                    <div class="itemRight">
                      <img src="../../asset/img/fly_u.png " alt="" />
                    </div>
                  </div>
                  <div class="flex flex-jcsb flex-dc">
                    <div class="height_30 flex-sb">
                      <div>{{ t("383") }}：</div>
                      <div class="flex-a">
                        <span style="color: #19ff19">{{
                          uavPanel.upload && onlineState === 1
                          ? uavPanel.upload
                          : 0
                        }}</span>/{{
  uavPanel.uploadTotal && onlineState === 1
  ? uavPanel.uploadTotal
  : 0
}}
                        <div style="color: #00f5ff; margin-left: 0.3125rem" class="cuor"
                          @click.stop="lookPhoto(trajectory.currentTaskId)">
                          {{ t('384') }}>>
                        </div>
                      </div>
                    </div>
                    <div class="height_30 flex-sb">
                      <div>{{ t("385") }}：</div>
                      <div>
                        {{
                          taskInfo.taskStatusName && onlineState === 1
                          ? taskInfo.taskStatusName
                          : "-"
                        }}
                      </div>
                    </div>
                    <div class="height_30 flex-sb">
                      <div>{{ t("386") }}：</div>
                      <div style="color: #19ff19">
                        {{
                          uavPanel.aircraftBattery && onlineState === 1
                          ? uavPanel.aircraftBattery + "%"
                          : "-"
                        }}
                      </div>
                    </div>
                    <!-- <div class="flex-sb height_30">
                      <div class="energy" @click="energyChange">
                        {{ t("387") }}
                        <img src="../../asset/img/energy.png" alt="" />
                      </div>
                    </div> -->
                  </div>
                </div>
                <!-- 终端头部 -->
                <div v-if="navId == 2" class="itemAir">
                  <div class="flex margin_btm15">
                    <div class="itemLeft flex flex-jcsb flex-dc">
                      <div class="margin_btm5">
                        <div style="margin-bottom: 0.3125rem" class="closeSeleUse">
                          {{ UAVName }}
                        </div>
                        <div style="color: #19ff19">{{ UAVModel }}</div>
                      </div>
                      <div class="margin_btm5">
                        <div style="margin-bottom: 0.3125rem">
                          {{ t("381") }}：
                        </div>
                        <div class="progress">
                          <el-progress :percentage="50" />
                        </div>
                      </div>
                      <div>
                        <div style="margin-bottom: 0.3125rem">
                          {{ t("382") }}：
                        </div>
                        <div style="color: #19ff19">
                          {{ t("388") }}
                        </div>
                      </div>
                    </div>
                    <div class="itemRight">
                      <img src="../../asset/img/center_air.png" alt="" />
                    </div>
                  </div>
                  <div class="flex flex-jcsb flex-dc">
                    <div class="flex-sb height_30">
                      <div>{{ t("383") }}：</div>
                      <div class="flex-a">
                        <span style="color: #19ff19">30</span>/100
                        <div style="color: #00f5ff; margin-left: 0.3125rem" class="cuor" @click.stop="lookPhoto">
                          {{ t('384') }}>>
                        </div>
                      </div>
                    </div>
                    <div class="flex-sb height_30">
                      <div>{{ t("386") }}：</div>
                      <div style="color: #19ff19">80%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="navSearchListBox">
              <!-- 航线 任务 飞行记录切换 -->
              <div class="itemNav flex-sb">
                <div class="itemList cuor" :class="item.id == itemNavId ? 'item_bg' : ''" v-for="item in itemNavList"
                  :key="item.id" @click="itemNavChange(item)">
                  {{ item.name }}
                </div>
              </div>
              <!-- 航线list 任务  飞行记录 -->
              <div class="routeList">
                <!-- 航线 -->
                <div v-if="itemNavType == 'ROUTE'" class="renwu">
                  <div class="routeSearch flex-a">
                    <div class="searchs flex-a">
                      <el-input class="filePut m2 common-input" type="text" :placeholder="t('226')"
                        v-model.trim="centerRouteFrom.routeName" clearable @clear="inputClean('ROUTE')"
                        @keyup.enter="centerRouteSearch" />
                      <el-icon class="file_icon" style="cursor: pointer" @click="centerRouteSearch">
                        <Search />
                      </el-icon>
                    </div>
                  </div>
                  <div class="routeItemList">
                    <div v-if="airUavList.length != 0">
                      <div :class="airUavIndex == index ? 'listItem_bg' : ''" class="listItem flex-sb"
                        v-for="(item, index) in airUavList" :key="item.routeId" @click="routeItemChange(index, 'AITUAV')">
                        <div class="flex-a">
                          <span>
                            <img src="../../asset/img/route_item.png" alt="" /></span>
                          <div v-if="item.routeName.length > 15">
                            <el-popover trigger="hover" placement="top" :width="200" :content="item.routeName">
                              <template #reference>
                                <div class="right" style="cursor: pointer">
                                  {{ textHide(item.routeName) }}
                                </div>
                              </template>
                            </el-popover>
                          </div>
                          <div v-else class="right">{{ item.routeName }}</div>
                        </div>
                        <div v-if="airUavIndex == index" class="tuo flex-fe">
                          <el-popover placement="right-start" :show-arrow="false" trigger="hover"
                            popper-class="nopadding_popover">
                            <template #reference>
                              <el-icon>
                                <MoreFilled />
                              </el-icon>
                            </template>
                            <ul class="popover_ul">
                              <li @click="modify(item)">{{ t("389") }}</li>
                              <li @click="routeLinePreview(item)">
                                {{ t("390") }}
                              </li>
                              <li @click="routeLineDetail(item)">
                                {{ t("391") }}
                              </li>
                              <li @click="centerRouteDel(item, false)">
                                {{ t("392") }}
                              </li>
                              <li @click="centerRouteImport(item.routeId)">
                                {{ t("393") }}
                              </li>
                            </ul>
                          </el-popover>
                        </div>
                      </div>
                    </div>
                    <div v-else class="empty flex-fac" style="font-size: 0.875rem">
                      {{ navId == 1 ? t('374') : t('375') }}航线为空
                    </div>
                  </div>
                </div>

                <!-- 任务 -->
                <div v-if="itemNavType == 'TASK'" class="renwu">
                  <div class="execute">
                    <div class="exeTitle">{{ t('394') }}</div>
                    <div class="executeList" v-if="executeList !== null &&
                      Object.keys(executeList).length !== 0
                      ">
                      <div class="listItem flex-sb listItem_bg" @click="
                        routeItemChange(0, 'UAVEXECUTE', executeList.taskId)
                        ">
                        <!--
                    :class="executeListIndex == index ? 'listItem_bg' : ''"
                    v-for="(item, index) in executeList"
                    :key="index" -->
                        <div class="flex-a">
                          <span>
                            <img src="../../asset/img/putong_jh.png" alt="" /></span>
                          <div class="flex flex-dc">
                            <div style="
                                font-size: 0.875rem;
                                height: 1.5625rem;
                                line-height: 1.5625rem;
                              ">
                              {{ executeList.taskName }}
                            </div>
                            <div style="font- size: 0.75rem;height:1.5625rem;line-height:1.5625rem">
                              {{ t('395') }}：{{ executeList.execTime }}
                            </div>
                          </div>
                        </div>
                        <div class="tuo flex-fe">
                          <!-- v-if="executeListIndex == index" -->
                          <el-popover placement="right-start" :show-arrow="false" trigger="hover"
                            popper-class="nopadding_popover">
                            <template #reference>
                              <el-icon>
                                <MoreFilled />
                              </el-icon>
                            </template>
                            <ul class="popover_ul">
                              <li @click="
                                cancelTask(
                                  'TERMINATION_TASKS',
                                  executeList.recordId
                                )
                                ">
                                {{ t('396') }}
                              </li>
                              <!-- <li @click="deletePending(item)">{{ t('210') }}</li> -->
                            </ul>
                          </el-popover>
                        </div>
                      </div>
                    </div>
                    <div v-else class="empty flex-fac" style="font-size: 0.875rem">
                      {{ t('397') }}
                    </div>
                  </div>

                  <!-- 待执行任务/任务详情 -->
                  <div v-if="taskDetail" class="taskDetail">
                    <h4>
                      {{ t('398') }}<el-icon class="close_icon" @click="closeTaskDetail">
                        <Close />
                      </el-icon>
                    </h4>
                    <div>{{ t("399") }}：{{ getTaskDetail.taskName }}</div>
                    <div>
                      {{ t("400") }}：{{ getTaskDetail.airwayName }}
                    </div>
                    <div>{{ t("401") }}：{{ getTaskDetail.taskBind }}</div>
                    <div>
                      {{ t("402") }}：{{ getTaskDetail.execCount }}
                    </div>
                    <div>
                      {{ t("403") }}：{{ getTaskDetail.execTime }}
                    </div>
                    <div>
                      {{ t("404") }}：{{
                        taskState(
                          getTaskDetail.taskAttributeContent.taskPriority
                        )
                      }}
                    </div>
                    <div>
                      {{ t("405") }}：{{
                        taskState(
                          getTaskDetail.taskAttributeContent.remotePolicy
                        )
                      }}
                    </div>
                    <div>
                      {{ t("406") }}：{{
                        getTaskDetail.taskAttributeContent.signals
                        ? t('407')
                        : t('408')
                      }}
                    </div>
                    <div>
                      {{ t("409") }}：{{
                        getTaskDetail.taskAttributeContent.retryCount
                      }}
                    </div>
                    <div>
                      {{ t("410") }}：{{
                        getTaskDetail.taskAttributeContent.deadlineTime
                      }}
                    </div>
                    <div>{{ t("411") }}：{{ getTaskDetail.taskOwner }}</div>
                    <div>
                      {{ t("412") }}：{{ getTaskDetail.updateTime }}
                    </div>
                    <div>
                      {{ t("413") }}：{{ getTaskDetail.taskRemark }}
                    </div>
                  </div>
                  <!-- 机场任务 -->
                  <div v-if="navId == 1" class="jizang">
                    <div class="routeSearch flex-a">
                      <div class="renWuAdd cuor" @click="addExecution">
                        <img src="../../asset/img/add.png" alt="" />
                      </div>
                      <div class="searchRw flex-a">
                        <el-input class="filePut m2 common-input" type="text" :placeholder="t('414')"
                          v-model.trim="taskRecordFrom.keyword" clearable @clear="inputClean('TASK')"
                          @keyup.enter="centerTaskSearch" />
                        <el-icon class="file_icon" style="cursor: pointer" @click="centerTaskSearch">
                          <Search />
                        </el-icon>
                      </div>
                      <div class="dingshi_rw cuor" @click="executionList">
                        <img src="../../asset/img/jihua.png" alt="" />
                      </div>
                    </div>
                    <div class="renwu_box">
                      <div class="exeTitle">{{ t("415") }}</div>
                      <div class="renwuList" v-if="taskList.length > 0">
                        <el-collapse @change="handleChange">
                          <el-collapse-item :name="index" v-for="(items, index) in taskList" :key="index">
                            <template #title>
                              <div class="renwuTitle flex-a">
                                <span>{{ items.createUserName }}&nbsp;</span>
                                <span>{{ t("416") }}</span>
                              </div>
                            </template>
                            <div :class="taskListIndex == item.taskId
                                ? 'listItem_bg'
                                : ''
                              " class="listItem flex-sb" v-for="(item, index) in items.children" :key="index" @click="
    routeItemChange(index, 'UAVTASK', item.taskId)
    ">
                              <div class="flex-a">
                                <span>
                                  <img src="../../asset/img/putong_jh.png" alt="" /></span>
                                <div class="flex flex-dc">
                                  <div class="overflow_ellipsis task_name" style="
                                      font-size: 0.875rem;
                                      height: 1.5625rem;
                                      line-height: 1.5625rem;
                                    " :title="item.taskName">
                                    {{ item.taskName }}
                                  </div>
                                  <div style="font- size: 0.75rem;height:1.5625rem;line-height:1.5625rem">
                                    {{ t('403') }}：{{ item.execTime }}
                                  </div>
                                </div>
                              </div>
                              <div v-if="taskListIndex == item.taskId" class="tuo flex-fe">
                                <el-popover placement="right-start" :show-arrow="false" trigger="hover"
                                  popper-class="nopadding_popover">
                                  <template #reference>
                                    <el-icon>
                                      <MoreFilled />
                                    </el-icon>
                                  </template>
                                  <ul class="popover_ul">
                                    <li @click="routeLinePreview(item, 'task')">
                                      {{ t("390") }}
                                    </li>
                                    <li @click="routeLineDetail(item, 'task')">
                                      {{ t("391") }}
                                    </li>
                                    <li @click="issueTask(item)">
                                      {{ t("417") }}
                                    </li>
                                    <li @click="centerDeleteTask(item)">
                                      {{ t("210") }}
                                    </li>
                                  </ul>
                                </el-popover>
                              </div>
                            </div>
                          </el-collapse-item>
                        </el-collapse>
                      </div>
                      <div v-else class="empty flex-fac" style="font-size: 0.875rem">
                        {{ t('418') }}
                      </div>
                    </div>
                  </div>
                  <!-- 终端任务 -->
                  <div v-else class="zhongduan">
                    <div class="routeSearch flex-a">
                      <div class="renWuAdd cuor Auv_mr" @click="addExecution">
                        <img src="../../asset/img/add.png" alt="" />
                      </div>
                      <div class="searchAuv flex-a">
                        <el-input class="filePut m2 common-input" type="text" :placeholder="t('414')"
                          v-model.trim="centerRouteFrom.routeName" clearable @clear="inputClean('ROUTE')"
                          @keyup.enter="centerRouteSearch" />
                        <el-icon class="file_icon" style="cursor: pointer" @click="centerRouteSearch">
                          <Search />
                        </el-icon>
                      </div>
                    </div>
                    <div class="renwu_box">
                      <div class="exeTitle">{{ t("415") }}</div>
                      <div class="renwuList">
                        <div :class="routeItemList == index ? 'listItem_bg' : ''" class="listItem flex-sb"
                          v-for="(item, index) in taskList" :key="index" @click="routeItemChange(index, 'UAVTASK')">
                          <div class="flex-a">
                            <span>
                              <img src="../../asset/img/putong_jh.png" alt="" /></span>
                            <div class="flex flex-dc">
                              <div class="overflow_ellipsis task_name" style="
                                  font-size: 0.875rem;
                                  height: 1.5625rem;
                                  line-height: 1.5625rem;
                                " :title="t('419')">
                                {{ t('419') }}
                              </div>
                              <div style="font- size: 0.75rem;height:1.5625rem;line-height:1.5625rem">
                                {{ t('403') }}：15:47
                              </div>
                            </div>
                          </div>
                          <div v-if="routeItemList == index" class="tuo flex-fe">
                            <el-popover placement="right-start" :show-arrow="false" trigger="hover"
                              popper-class="nopadding_popover">
                              <template #reference>
                                <el-icon>
                                  <MoreFilled />
                                </el-icon>
                              </template>
                              <ul class="popover_ul">
                                <li @click="issueTask(index, item.id)">
                                  {{ t("417") }}
                                </li>
                                <li @click="del(index, item.id)">
                                  {{ t("210") }}
                                </li>
                              </ul>
                            </el-popover>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 飞行记录 -->
                <div v-if="itemNavType == 'AIRLOG'" class="renwu">
                  <div class="routeSearch flex-a">
                    <div class="searchs flex-a">
                      <el-input class="filePut m2 common-input" type="text" :placeholder="t('420')"
                        v-model.trim="taskRecordFrom.keyword" clearable @clear="inputClean('AIRLOG')"
                        @keyup.enter="centerFlySearch" />
                      <el-icon class="file_icon" style="cursor: pointer" @click="centerFlySearch">
                        <Search />
                      </el-icon>
                    </div>
                  </div>
                  <div class="routeItemList">
                    <div v-if="flyList.length > 0">
                      <div class="fly flex-a" v-for="(item, index) in flyList" :key="item.recordId">
                        <div class="flyLeft flex-a">
                          <div class="left flex-fac">
                            <img src="../../asset/img/fly.png" alt="" />
                          </div>
                          <div class="center flex-dc flex flex-jcsb">
                            <div style="color: #19ff19">
                              {{ item.execTime }}
                            </div>
                            <div class="overflow_ellipsis" :title="item.taskName">
                              {{ item.taskName }}
                            </div>
                            <div>
                              {{ t('421') }}&nbsp;{{ item.photoNumber }}&nbsp;{{ t('422') }}
                              <span style="color: #00f5ff; margin-left: 0.3125rem" class="cuor"
                                @click.stop="lookPhoto(item.recordId)">{{ t('384') }}>></span>
                            </div>
                            <div>
                              {{ t("385") }}：<span
                                style="color: #19ff19"
                                >{{ taskState(item.taskStatus) }}</span
                              >
                            </div>
                            <div>{{ t("423") }}：{{ item.issueMember }}</div>
                            <div>
                              {{ t("424") }}：{{
                                item.endWaypoint ? item.endWaypoint : `0/0`
                              }}
                            </div>
                            <div>
                              {{ t("425") }}：{{
                                item.totalTime ? item.totalTime : "-"
                              }}&nbsp;{{ t("426") }}
                            </div>
                          </div>
                        </div>
                        <div class="flyRight">
                          <div :class="(item.id == flyLogId) & (index == flyLogIndex)
                              ? 'rightList_bg'
                              : ''
                            " class="rightList flex-fac" v-for="items in airLogNav" :key="items.id"
                            @click="flyLogChange(items, index, item)">
                            {{ items.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="empty flex-fac" style="font-size: 0.875rem">
                      {{ t('427') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 飞行记录 -->
            <div class="itemFlyLog" v-if="flyLogShow">
              <div class="header">{{ flyLogList.startDate }}</div>
              <div class="content">
                <div class="contentList flex-a" v-for="item in flyLogList.datalist" :key="item">
                  <div class="left">{{ item.execTime }}</div>
                  <div v-if="item.content.length > 46">
                    <el-popover trigger="hover" placement="top" :width="200">
                      <template #reference>
                        <div class="right" style="cursor: pointer">
                          {{ item.content }}
                        </div>
                      </template>
                    </el-popover>
                  </div>
                  <div v-else class="right">{{ item.content }}</div>
                </div>
              </div>
            </div>
            <!-- 再次飞行 -->
            <div class="againFly" v-if="againFly">
              <div class="header">{{ t("428") }}</div>
              <div class="againBox">
                <div class="content flex-fac">
                  <span>{{ t('429') }}：</span>
                  <el-input-number v-model="againNum" :min="0" controls-position="right" size="large"
                    @change="againHandleChange" />
                </div>
                <div class="add_btn flex-a flex-je">
                  <div class="lBtn cuor" @click="closeAgainFly">
                    {{ t("217") }}
                  </div>
                  <div class="rBtn cuor" @click="submitAgainFly">
                    {{ t("218") }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 计划任务列表 -->
            <div class="executionList_box" v-if="executionListShow">
              <div class="renwuList">
                <div :class="taskCycleIndex == index ? 'listItem_bg' : ''" class="listItem flex-sb"
                  v-for="(item, index) in scheduleTaskList" :key="item.id" @click="routeItemChange(index, 'TASKCYCLE')">
                  <div class="flex-a">
                    <span>
                      <img src="../../asset/img/jihua_rw.png" alt="" /></span>
                    <div class="flex flex-dc">
                      <div class="overflow_ellipsis task_name" style="
                          font-size: 0.875rem;
                          height: 1.5625rem;
                          line-height: 1.5625rem;
                        " :title="item.taskName">
                        {{ item.taskName }}
                      </div>
                      <div style="font- size: 0.75rem;height:1.5625rem;line-height:1.5625rem;color: #19ff19">
                        {{ t('403') }}：{{ item.cycleParamTime }}
                      </div>
                    </div>
                  </div>
                  <div v-if="taskCycleIndex == index" class="tuo flex-fe">
                    <el-popover placement="right-start" :show-arrow="false" trigger="hover"
                      popper-class="nopadding_popover">
                      <template #reference>
                        <el-icon>
                          <MoreFilled />
                        </el-icon>
                      </template>
                      <ul class="popover_ul">
                        <li @click="deleteTask(item.id)">{{ t("210") }}</li>
                        <li @click="stopTaskCycle(index, item.id)">
                          {{ t("430") }}
                        </li>
                      </ul>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>

            <!-- 机场终端任务 新增任务 -->
            <div class="addExecution" v-if="addExecutionShow">
              <div class="add_header flex-sb">
                <div class="title">{{ t("431") }}</div>
                <div class="icon cuor" @click="closeAddExecution">
                  <el-icon>
                    <Close />
                  </el-icon>
                </div>
              </div>
              <div class="add_content">
                <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="80px" label-position="top">
                  <el-form-item :label="t('432')" prop="airwayId">
                    <el-select class="m2 common-input" style="width: 100%" :placeholder="t('175')"
                      v-model="addForm.airwayId" clearable @change="addSelectAirLine">
                      <el-option class="el_text" :class="item.routeId == addForm.airwayId ? 'el_active' : ''
                        " v-for="item in airUavList" :key="item.routeId" :label="item.routeName"
                        :value="item.routeId" />
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="t('280')" prop="taskName">
                    <el-input class="m2 common-input in_bg" v-model.trim="addForm.taskName"
                      onkeyup="this.value=this.value.replace(/\ +/g, '')" show-word-limit maxlength="20"
                      :placeholder="t('414')">
                    </el-input>
                  </el-form-item>
                  <el-form-item :label="t('433')" prop="taskRemark">
                    <el-input type="textarea" class="m2 common-input in_bg" v-model.trim="addForm.taskRemark"
                      onkeyup="this.value=this.value.replace(/\ +/g, '')" show-word-limit maxlength="60" autosize rows="5"
                      :placeholder="t('434')"></el-input>
                  </el-form-item>
                  <el-form-item :label="t('435')" prop="execTime">
                    <el-time-picker class="common-input" v-model="addForm.execTime" :placeholder="t('436')"
                      format="HH:mm:ss" value-format="HH:mm:ss" clearable style="width: 581px" />
                  </el-form-item>
                  <el-form-item :label="t('437')" prop="deadLineTime">
                    <el-time-picker class="common-input" v-model="addForm.deadLineTime" :placeholder="t('436')"
                      format="HH:mm:ss" value-format="HH:mm:ss" clearable style="width: 581px" />
                  </el-form-item>
                  <div @click="operationChange" class="flex-a operation">
                    {{ t('438') }}<el-icon v-if="!operationShow">
                      <CaretBottom />
                    </el-icon><el-icon v-else>
                      <CaretTop />
                    </el-icon>
                  </div>
                  <div v-show="operationShow" class="operation-box">
                    <div v-show="!accessorySn" class="flex-sa operation-empty">
                      {{ t("439") }}
                    </div>
                    <div v-show="accessorySn" class="operation-content">
                      <div class="box-item flex flex-je">
                        <div class="audio-width">
                          <el-select class="m2 common-input" style="width: 100%" :placeholder="t('175')"
                            v-model="audioVal" popper-class="notice-select" @change="audiaoChange">
                            <el-option class="el_text" v-for="item in audioOptions" :key="item.Val" :label="item.label"
                              :value="item.value" />
                          </el-select>
                        </div>
                      </div>
                      <div class="box-item" v-show="audioVal === 1">
                        <div class="flex-sb operation-14size">
                          {{ t('440') }}：
                          <span class="flex-a audio-files">{{ t('441') }}<el-switch v-model="audio" class="ml-2" style="
                                --el-switch-on-color: #52ef40;
                                --el-switch-off-color: #333333;
                              " /></span>
                        </div>
                        <el-select class="m2 common-input" style="width: 100%" :placeholder="t('442')"
                          popper-class="notice-select" clearable v-model="bgmFrom.audionPlay">
                          <el-option class="el_text" v-for="item in audioOption" :key="item.audioId"
                            :label="item.audioName" :value="item.audioId" />
                        </el-select>
                      </div>
                      <div class="box-item" v-show="audioVal === 2">
                        <div class="flex-sb operation-14size">
                          TTS：
                          <div class="flex-a">
                            <div class="tts-language" @click.self="chooseLanguage">
                              {{ t('443') }}
                              <div class="language-content" v-show="chooseLanguageShow">
                                <div class="header">
                                  {{ t('443') }}<span @click="chooseLanguageShow = false" class="header-close"><el-icon>
                                      <CloseBold />
                                    </el-icon></span>
                                </div>
                                <div class="content">
                                  <p>
                                    {{
                                      t("444")
                                    }}！
                                  </p>
                                  <ul class="lang_ul">
                                    <li class="hover_opacity overflow_text" v-for="item in languageList" :key="item.id"
                                      :class="{
                                        active_li: currLanguage.includes(
                                          item.id
                                        ),
                                      }" @click="changeLang(item.id)">
                                      {{ item.name }}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <span class="tts-speed flex-a">{{ t('445') }}
                              <span class="tts-select">
                                <el-select class="m2 common-input" style="width: 100%" :placeholder="t('442')"
                                  v-model="addForm.loadOperationInfoDTO.ttsDTO.speed
                                    " popper-class="notice-select">
                                  <el-option class="el_text" v-for="item in ttsList" :key="item.value" :label="item.label"
                                    :value="item.value" />
                                </el-select>
                              </span>
                            </span>
                            <span class="flex-a audio-files">{{ t('441') }}<el-switch v-model="tts" class="ml-2" style="
                                  --el-switch-on-color: #52ef40;
                                  --el-switch-off-color: #333333;
                                " /></span>
                          </div>
                        </div>
                        <el-input type="textarea" class="m2 common-input in_bg" v-model.trim="bgmFrom.tts"
                          onkeyup="this.value=this.value.replace(/\ +/g, '')" show-word-limit autosize rows="5"
                          :placeholder="t('446')"></el-input>
                      </div>
                      <div class="box-item">
                        <div class="flex-sb operation-14size">
                          {{ t('447') }}：
                          <span class="flex-a audio-files">{{ t('441') }}<el-switch v-model="bgAudio" class="ml-2" style="
                                --el-switch-on-color: #52ef40;
                                --el-switch-off-color: #333333;
                              " /></span>
                        </div>
                        <el-select class="m2 common-input" style="width: 100%" :placeholder="t('448')"
                          popper-class="notice-select" clearable v-model="bgmFrom.bgmPlay">
                          <el-option class="el_text" v-for="item in bgmOptions" :key="item.bgmId" :label="item.bgmName"
                            :value="item.bgmId" />
                        </el-select>
                      </div>
                      <div class="box-item">
                        <div class="load-route-text">{{ t("449") }}：</div>
                        <div class="flex-a load-route">
                          <div class="load-route-width">
                            <el-select class="m2 common-input" style="width: 100%" :placeholder="t('450')"
                              v-model="addForm.loadOperationInfoDTO.startPoint" popper-class="notice-select" clearable
                              @change="startPointChange">
                              <el-option class="el_text" v-for="item in routePointOptions" :key="item.value"
                                :label="item.label" :value="item.value" />
                            </el-select>
                          </div>
                          <div class="empty-div"></div>
                          <div class="load-route-width">
                            <el-select class="m2 common-input" style="width: 100%" :placeholder="t('451')"
                              v-model="addForm.loadOperationInfoDTO.endPoint" popper-class="notice-select" clearable
                              @change="endPointChange">
                              <el-option class="el_text" v-for="item in routePointOptions" :key="item.value"
                                :label="item.label" :value="item.value" />
                            </el-select>
                          </div>
                        </div>
                      </div>
                      <div class="flex-a operation-14size">
                        <span v-show="tzdType === 'ABL120'" class="flex-a audio-files switch-mr45">{{ t('452')
                        }}：<el-switch v-model="searchlight" class="ml-2" style="
                              --el-switch-on-color: #52ef40;
                              --el-switch-off-color: #333333;
                            " /></span>
                        <span class="flex-a audio-files">{{ t('453') }}：<el-switch v-model="warninglights" class="ml-2"
                            style="
                              --el-switch-on-color: #52ef40;
                              --el-switch-off-color: #333333;
                            " /></span>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
              <div class="add_btn flex-a flex-je">
                <div class="lBtn cuor" @click="closeAddExecution">
                  {{ t("217") }}
                </div>
                <div class="rBtn cuor" @click="roleEditSubmit">
                  {{ t("225") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- 收缩按钮 -->
    <div class="left_menu">
      <div v-if="!deviceLeftShow" class="gull_right hover_opacity" @click.stop="aleft('right')">
        <el-icon>
          <Expand />
        </el-icon>
      </div>
    </div>
    <!-- 地球 -->
    <div class="cesium_box" @contextmenu.prevent @click="hideRightMenuBox">
      <div id="cesiumContainers" @dragover.prevent @drop.prevent="spotDrop($event)"></div>
      <div class="right_menu_box" id="rightMenuBox">
        <ul class="right_menu">
          <li @click="deleteDraw">{{ t("210") }}</li>
          <li id="markChat" @click="sendChat">{{ t("454") }}</li>
        </ul>
      </div>
      <!-- 机场模型内容 -->
      <div class="model_box" id="modelText">
        <ul class="ul_box">
          <li>{{ modeSn }}</li>
          <li>{{ modeName }}</li>
        </ul>
      </div>
    </div>
    <!-- 左上角区域 -->
    <div class="region_div">
      <div class="region_div_list">
        <span class="region_text" @click="openNoFlyShow">
          <el-checkbox v-model="checkAll" class="checkbox1" :indeterminate="isIndeterminate"
            @change="handleCheckAllChange">{{ t('455') }}</el-checkbox>
          <span class="icon_style"><el-icon v-if="no_fly_div_show">
              <ArrowUp />
            </el-icon>
            <el-icon v-else>
              <ArrowDown />
            </el-icon>
          </span>
        </span>
        <div class="no_fly_div" v-show="no_fly_div_show">
          <div class="checkBox_div">
            <div class="flex flex-a itemTop" v-for="item in noFlyList" :key="item.regionId">
              <el-checkbox v-model="item.visible" @change="changeNoFly(item)"></el-checkbox>
              <span :title="item.fenceName" class="overflow_text region_text_text">{{ item.fenceName }}</span>
            </div>
            <div style="width: 100%; height: 100%" class="fullHeight flex flex-fac" v-if="noFlyList.length === 0">
              {{ t('456') }}
            </div>
          </div>
        </div>
      </div>
      <div class="region_div_list">
        <span class="region_text" @click="openHeightLimitShow">
          <el-checkbox v-model="checkAllHeightLimit" :indeterminate="isIndeterminateHeightLimit" class="checkbox1"
            @change="handleCheckAllChangeHeightLimit">{{ t('457') }}</el-checkbox>
          <span class="icon_style"><el-icon v-if="height_limit_div_show">
              <ArrowUp />
            </el-icon>
            <el-icon v-else>
              <ArrowDown />
            </el-icon>
          </span>
        </span>
        <div class="no_fly_div" v-show="height_limit_div_show">
          <div class="checkBox_div">
            <div class="flex flex-a itemTop" v-for="item in heightLimitList" :key="item.regionId">
              <el-checkbox v-model="item.visible" @change="changeHeightLimit(item)"></el-checkbox>
              <span :title="item.fenceName" class="overflow_text region_text_text">{{ item.fenceName }}</span>
            </div>
            <div style="width: 100%; height: 100%" class="fullHeight flex flex-fac" v-if="heightLimitList.length === 0">
              {{ t('456') }}
            </div>
          </div>
        </div>
      </div>
      <!-- 可飞区 -->
      <div class="region_div_list" v-if="itemId !== 0 && yesFlyList.length !== 0">
        <span class="region_text" @click="openYesFlyShow">
          <el-checkbox v-model="checkAllYesFly" class="checkbox1" @change="handleCheckAllChangeYesFly">{{ t('69')
          }}</el-checkbox>
          <el-checkbox v-show="yesFlyList.length !== 0" v-model="checkAllGird" class="checkbox2"
            @change="handleCheckAllChangeGird">{{ t('458') }}</el-checkbox>
        </span>
      </div>
    </div>
    <!-- 右下侧边栏 -->
    <transition :duration="{ enter: 50, leave: 50 }">
      <div ref="rightRef" class="centerMenus" @mouseenter="moveIn()" v-show="rightMenuShow">
        <div class="arrows">
          <el-icon>
            <DArrowRight />
          </el-icon>
        </div>
      </div>
    </transition>

    <transition :duration="{ enter: 100, leave: 50 }">
      <div ref="cesiumRef" class="cesiumNav flex-dc flex-a" v-show="cesiumMenuShow">
        <!-- @mouseleave="leaveOut()" -->
        <div class="list" v-for="item in csiumNav" :key="item.id" @click="cesiumChange(item)">
          <div v-if="item.id == 2" class="listIcon">
            <el-popover placement="left-start" :width="299" trigger="click" popper-class="layoutTip elpopIcon"
              :visible="cesiumPunctuation">
              <template #reference>
                <div>
                  <el-tooltip :content="t('274')" placement="top" effect="light" popper-class="tip-class">
                    <img :src="item.id == cesiumId ? item.url1 : item.url" alt="" />
                  </el-tooltip>
                </div>
              </template>
              <el-form>
                <div class="popover_content">
                  <div class="content_top">
                    <div style="margin: 0">{{ t("275") }}</div>
                  </div>
                  <el-form-item>
                    <div>{{ t("276") }}</div>
                    <div class="flex-sb">
                      <div style="margin-right: 0.3125rem">
                        <div class="flex-a">
                          <span>{{ t('73') }}</span>
                          <input class="filePut" type="number" :placeholder="t('277')" v-model.trim="punctuationLng"
                            @blur="updateMarkParam" />
                        </div>
                        <div class="flex-a">
                          <span>{{ t('74') }}</span>
                          <input class="filePut" type="number" :placeholder="t('278')" v-model.trim="punctuationLat"
                            @blur="updateMarkParam" />
                        </div>
                      </div>
                      <img src="@/asset/img/airlinePlan/ears.png" draggable="true" />
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div>
                      <div>{{ t("279") }}</div>
                      <div>
                        <span>{{ t('280') }}</span>
                        <el-input :placeholder="t('281')" class="biaozu" v-model.trim="punctuationName"
                          @blur="updateMarkParam"></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div>{{ t("282") }}</div>
                    <div class="flex-sb colorSelect">
                      <span>{{ t('283') }}</span>
                      <div :style="{ background: item }" v-for="(item, index) in punctuationColorList"
                        :class="index == punctuationIndex ? 'border_bg' : ''" :key="index"
                        @click="colorChange(item, index)"></div>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div style="display: flex; justify-content: end">
                      <el-button @click="closePunctuation">{{
                        t("217")
                      }}</el-button>
                      <el-button @click="punctuationSubmit">{{
                        t("284")
                      }}</el-button>
                    </div>
                  </el-form-item>
                </div>
              </el-form>
            </el-popover>
          </div>

          <div v-else-if="item.id == 3" class="listLayout">
            <el-popover placement="left" :width="299" trigger="click" popper-class="layoutTip nopadding_popover"
              class="elpop" :visible="cesiumLayout">
              <template #reference>
                <div>
                  <el-tooltip :content="t('285')" placement="top" effect="light" popper-class="tip-class"><img
                      :src="item.id == cesiumId ? item.url1 : item.url" alt="" /></el-tooltip>
                </div>
              </template>
              <div class="codeImg">
                <div class="elsearch flex-a">
                  <el-input class="filePut" type="text" :placeholder="t('286')" clearable v-model="layerFilterWord"
                    @change="layerChange" />
                  <div class="file_icon flex-fac" @click="filterLayer">
                    <el-icon style="cursor: pointer">
                      <Search />
                    </el-icon>
                  </div>
                </div>
                <div class="laylist">
                  <div class="flex flex-sb itemTop" v-for="item in filterLayerList" :key="item.layerId"
                    @click.self="zoomToLayer(item)">
                    <span @click="zoomToLayer(item)" class="overflow_text" :title="item.layerName">{{ item.layerName
                    }}</span>
                    <el-checkbox v-model="item.display" @change="changeLayarVisible(item)"></el-checkbox>
                  </div>
                </div>
              </div>
            </el-popover>
          </div>
          <div v-else-if="item.id == 6" @mousedown="cesiumCA(item)" @mouseup="End()" @dragend="End">
            <el-tooltip :content="t('289')" placement="top" effect="light" popper-class="tip-class">
              <img :src="item.id == cesiumId ? item.url1 : item.url" alt="" />
            </el-tooltip>
          </div>
          <div v-else-if="item.id == 7" @mousedown="cesiumReduce(item)" @mouseup="End()" @dragend="End">
            <el-tooltip :content="t('290')" placement="top" effect="light" popper-class="tip-class">
              <img :src="item.id == cesiumId ? item.url1 : item.url" alt="" />
            </el-tooltip>
          </div>
          <div v-else>
            <el-tooltip :content="item.title" placement="top" effect="light" popper-class="tip-class">
              <img :src="item.id == cesiumId ? item.url1 : item.url" alt="" />
            </el-tooltip>
          </div>
        </div>
      </div>
    </transition>

    <!-- 搜索 -->
    <div class="cesiumSearch" v-if="cesiumSearchIn">
      <MapSearch @flyToPlace="flyToPlace" />
    </div>
    <!-- 标点 -->
    <div class="punctuation" v-if="cesiumPunctuation"></div>
    <!-- 图层 -->
    <div class="layout" v-if="cesiumLayout"></div>
    <!-- 2D 3D -->
    <div class="two_three flex-a" v-if="cesiumTwoThree">
      <div class="list" :class="item.id == twoThreeId ? 'list_bg' : ''" v-for="item in twoThreeNav" :key="item.id"
        @click="twoThreeChange(item)">
        {{ item.name }}
      </div>
      <div class="list" @click="sceneModeChange()">
        {{ sceneMode }}
      </div>
    </div>

    <!-- 测距 -->
    <div class="stadio flex-a" v-if="cesiumRang">
      <div class="list" :class="item.id == rangId ? 'list_bg' : ''" v-for="item in rangNav" :key="item.id"
        @click="rangChange(item)">
        {{ item.name }}
      </div>
    </div>
  </div>
  <!-- 控制台 -->
  <div class="control_box" v-if="controlShow">
    <div class="control_box_top">
      <button class="iconfont icon-kongzhi commonbtn hover_opacity" :class="{ activeBtn: changeBg === true }"
        @click="changeBackground(true)">
        {{ t('379') }}
      </button>
      <button class="iconfont icon-ceshi commonbtn airplane_test hover_opacity" :class="{ activeBtn: changeBg === false }"
        @click="changeBackground(false)" v-checkAuth.hide="'airport-test'">
        {{ t('459') }}
      </button>
      <div class="airplane_message">
        <span class="common_span">{{ t('460') }} {{ uavInfo.flyTime ? uavInfo.flyTime : "0" }}s
        </span>
        <span class="common_span">{{ t('461') }} {{ uavInfo.alt ? uavInfo.alt.toFixed(1) : "0" }}m</span>
        <span class="common_span">{{ t('462') }} {{ uavInfo.rlt ? uavInfo.rlt.toFixed(1) : "0" }}m</span>
        <span class="common_span">{{ t('463') }} {{ uavInfo.hDist ? uavInfo.hDist.toFixed(1) : "0" }}m</span>
        <span class="common_span">{{ t('464') }}
          {{ uavInfo.hSpeed ? uavInfo.hSpeed.toFixed(1) : "0" }}m/s</span>
        <span class="common_span">{{ t('465') }}
          {{ uavInfo.vSpeed ? uavInfo.vSpeed.toFixed(1) : "0" }}m/s</span>
      </div>
      <button class="arrow iconfont icon-jiantouxia hover_opacity" :class="{ rotateBtn: isShow === false }"
        @click="activeShow"></button>
    </div>

    <!-- animate__animated -->
    <!-- 远程遥控面板 -->
    <div class="control_box_content" v-if="changeBg === true" v-show="isShow">
      <div class="item clearfix">
        <p class="fl">{{ t('466') }}</p>
        <span class="icon iconfont icon-yaogan fr hover_opacity" @click="connectDevice"></span>
      </div>
      <div class="item flex-sb">
        <p>{{ t('467') }}</p>
        <ele-select :lableshow="false" :labelname="'value'" :value="'id'" :options="cameraType"
          v-model:selectVal="changeCamerType" @on-select="submitCamerType">
        </ele-select>
      </div>
      <div class="item">
        <p>{{ t("468") }}</p>
      </div>
      <div class="item">
        <p>{{ t("469") }}</p>
      </div>
      <div class="item flex-sb">
        <div class="flex-a">
          <p style="margin-right: 0.75rem">{{ t('470') }}</p>
          <el-switch v-model="openOrClose" style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            " @change="handleSwitchChange" />
        </div>
        <p class="hover_opacity" @click="recover" style="border: 1px solid #57ed3f; padding: 3px">
          {{ t('471') }}
        </p>
        <!-- <p>{{ t('472') }}</p> -->
      </div>
      <div class="item item6 flex-fac">
        <div class="sector flex-fac">
          <div class="box s1" @click="airplaneControl('gimbalup')" @mousedown="longpress('gimbalup')"
            @mouseup="stopsend()" @dragend="stopsend()" @mouseleave="stopsend()">
            <p><img src="@/asset/img/control-box/upcontrol.png" /></p>
          </div>
          <div class="box s2" @click="airplaneControl('gimbalright')" @mousedown="longpress('gimbalright')"
            @mouseup="stopsend()" @dragend="stopsend()" @mouseleave="stopsend()">
            <p><img src="@/asset/img/control-box/rightcontrol.png" /></p>
          </div>
          <div class="box s3" @click="airplaneControl('gimbaldown')" @mousedown="longpress('gimbaldown')"
            @mouseup="stopsend()" @dragend="stopsend()" @mouseleave="stopsend()">
            <p><img src="@/asset/img/control-box/downcontrol.png" /></p>
          </div>
          <div class="box s4" @click="airplaneControl('gimballeft')" @mousedown="longpress('gimballeft')"
            @mouseup="stopsend()" @dragend="stopsend()" @mouseleave="stopsend()">
            <p><img src="@/asset/img/control-box/leftcontrol.png" /></p>
          </div>
          <div class="center">
            <img src="@/asset/img/control-box/udrl.png" />
          </div>
        </div>
        <div class="zoom flex-a">
          <div class="zoom-text">{{ t("473") }}</div>
          <div>
            <div class="zoom-top hover_opacity" @click="changeZoom(1)">+</div>
            <div class="zoom-center hover_opacity" @click="changeZoom(0)">
              <el-icon>
                <Refresh />
              </el-icon>
            </div>
            <div class="zoom-bottom hover_opacity" @click="changeZoom(2)">
              -
            </div>
          </div>
        </div>
      </div>
      <div class="item item7">
        <div class="btnList flex-fsb">
          <button class="item7_btn hover_background" :class="{ activeBtn: photoOrVideo === true }" @click="photoModel()">
            {{ t('474') }}
          </button>
          <button class="item7_btn hover_background" :class="{ activeBtn: photoOrVideo === false }" @click="videoModel()">
            {{ t('475') }}
          </button>
        </div>
        <div class="bottom">
          <div class="photo flex-fac" v-if="showPhoto">
            <span class="iconfont icon-paizhao hover_opacity" @click="takephoto"></span>
          </div>
          <div class="video flex-fac" v-if="showVideo">
            <p class="record">{{ t("476") }}</p>
            <p class="record-time">{{ recordTime }}</p>
            <span class="iconfont icon-record-video-full hover_opacity record-icon" @click="beginRecord()"
              v-if="startRecord"></span>
            <div class="stop flex-fac hover_opacity" @click="stopRecord()" v-if="finishRecord">
              <div class="stop-btn"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="item item8">
        <button class="common_btn hover_background" @click="openStage">
          {{ t("477") }}
        </button>
        <!-- <button class="common_btn hover_background">{{ t('478') }}</button> -->
        <button class="common_btn hover_background" @click="showPointingFlight()">
          {{ t('479') }}
        </button>
      </div>
      <div class="item item9">
        <button class="common_btn hover_background" @click="controlFly('STOP_TASK')">
          {{ t('480') }}
        </button>
        <button
          class="common_btn hover_background"
          @click="controlFly('pauseContinue')"
        >
          {{ suspendContinue ? t("430") : t("481") }}
        </button>
        <button class="common_btn hover_background" @click="controlFly('REMOTE_CONTROL_RETURN')">
          {{ t('482') }}
        </button>
      </div>
      <div class="item item10 flex-sa">
        <div class="sector flex-fac">
          <img class="cool_background" src="@/asset/img/control-box/coolbackground.png" />
          <div class="box s1" @click="airplaneControl('vtup')" @mousedown="longpress('vtup')" @mouseup="stopsend()"
            @dragend="stopsend()" @mouseleave="stopsend()">
            <p><img src="@/asset/img/control-box/upup.png" /></p>
          </div>
          <div class="box s5" @click="airplaneControl('yawright')" @mousedown="longpress('yawright')"
            @mouseup="stopsend()" @dragend="stopsend()" @mouseleave="stopsend()">
            <p>
              <img style="width: 0.92rem; height: 0.495rem" src="@/asset/img/control-box/rightright.png" />
            </p>
          </div>
          <div class="box s3" @click="airplaneControl('vtdown')" @mousedown="longpress('vtdown')" @mouseup="stopsend()"
            @dragend="stopsend()" @mouseleave="stopsend()">
            <p><img src="@/asset/img/control-box/downdown.png" /></p>
          </div>
          <div class="box s4" @click="airplaneControl('yawleft')" @mousedown="longpress('yawleft')" @mouseup="stopsend()"
            @dragend="stopsend()" @mouseleave="stopsend()">
            <p>
              <img style="width: 0.92rem; height: 0.495rem" src="@/asset/img/control-box/rightright.png" />
            </p>
          </div>
          <div class="center">
            <img src="@/asset/img/control-box/wasd.png" />
          </div>
        </div>
        <div class="sector flex-fac">
          <img class="cool_background" src="@/asset/img/control-box/coolbackground.png" />
          <div class="box s1" @click="airplaneControl('pitchup')" @mousedown="longpress('pitchup')" @mouseup="stopsend()"
            @dragend="stopsend()" @mouseleave="stopsend()">
            <p><img src="@/asset/img/control-box/upcontrol.png" /></p>
          </div>
          <div class="box s2" @click="airplaneControl('rollright')" @mousedown="longpress('rollright')"
            @mouseup="stopsend()" @dragend="stopsend()" @mouseleave="stopsend()">
            <p><img src="@/asset/img/control-box/rightcontrol.png" /></p>
          </div>
          <div class="box s3" @click="airplaneControl('pitchdown')" @mousedown="longpress('pitchdown')"
            @mouseup="stopsend()" @dragend="stopsend()" @mouseleave="stopsend()">
            <p><img src="@/asset/img/control-box/downcontrol.png" /></p>
          </div>
          <div class="box s4" @click="airplaneControl('rollleft')" @mousedown="longpress('rollleft')"
            @mouseup="stopsend()" @dragend="stopsend()" @mouseleave="stopsend()">
            <p><img src="@/asset/img/control-box/leftcontrol.png" /></p>
          </div>
          <div class="center">
            <img src="@/asset/img/control-box/udrl.png" />
          </div>
        </div>
      </div>
    </div>

    <!-- 机场测试面板 -->
    <div class="control_box_content1" v-if="changeBg === false" v-show="isShow">
      <div class="item">
        <p>{{ t("483") }}</p>
      </div>
      <div class="item">
        <p>{{ t("484") }}</p>
      </div>
      <div class="item">
        <p>{{ t("485") }}</p>
      </div>
      <div class="item">
        <p>{{ t("486") }}</p>
      </div>
      <div class="item">
        <p>{{ t("487") }}</p>
      </div>
      <div class="item">
        <button class="hover_background" @click="controlFly('OPEN_AIRPORT_GATE')">
          {{ t('488') }}
        </button>
        <button class="hover_background" @click="controlFly('CLOSE_AIRPORT_GATE')">
          {{ t('483') }}
        </button>
        <button class="hover_background" @click="controlFly('PUSH_BUTTON_START')">
          {{ t('489') }}
        </button>
        <button class="hover_background" @click="controlFly('PUSH_BUTTON_CLOSE')">
          {{ t('490') }}
        </button>
      </div>
      <div class="item">
        <button class="hover_background" @click="controlFly('X_RELEASE')">
          {{ t("491") }}
        </button>
        <button class="hover_background" @click="controlFly('X_TIGHTEN_UP')">
          {{ t("492") }}
        </button>
        <button class="hover_background" @click="controlFly('Y_RELEASE')">
          {{ t("493") }}
        </button>
        <button class="hover_background" @click="controlFly('Y_TIGHTEN_UP')">
          {{ t("494") }}
        </button>
      </div>
      <div class="item">
        <button class="hover_background" @click="controlFly('ONE_POWER_EXCHANGE')">
          {{ t('495') }}
        </button>
        <el-popover :show-arrow="false" placement="top-end" :width="popoverWidth" trigger="click"
          popper-class="resetPopover" :offset="2">
          <template #reference>
            <button class="hover_background">{{ t("496") }}</button>
          </template>
          <template #default>
            <div class="popover-btn-list">
              <button class="hover_background" v-for="item in buttonList" :key="item.id"
                @click="controlFly('RESET_BATTERY', item.id)">
                {{ item.id }}
              </button>
            </div>
            <div class="allBtn">
              <button class="hover_background" @click="controlFly('RESET_BATTERY', '-1')">
                全<br />部<br />复<br />位
              </button>
            </div>
          </template>
        </el-popover>
        <button class="hover_background" @click="controlFly('RETURN_BATTERY')">
          {{ t("497") }}
        </button>
        <button class="hover_background" @click="controlFly('PUSH_BUTTON_RESET')">
          {{ t('498') }}
        </button>
      </div>
      <div class="item">
        <button class="hover_background" @click="controlFly('LIFTING_PLATFORM')">
          {{ t('499') }}
        </button>
        <button class="hover_background" @click="controlFly('LANDING_PLATFORM')">
          {{ t('500') }}
        </button>
        <button class="hover_background" @click="controlFly('POWER_ON')">
          {{ t("501") }}
        </button>
        <button class="hover_background" @click="controlFly('POWER_DOWN')">
          {{ t("502") }}
        </button>
      </div>
      <div class="item hiddenScrollbar" style="overflow: auto">
        <button class="hover_background" @click="controlFly('CHARGE_NOW')">
          {{ t("503") }}
        </button>
        <button class="hover_background" @click="controlFly('STOP_CHARGING')">
          {{ t("504") }}
        </button>
        <button class="hover_background" @click="controlFly('UAV_OPEN')">
          {{ t("505") }}
        </button>
        <button class="hover_background" @click="controlFly('UAV_SHUTDOWN')">
          {{ t("506") }}
        </button>
        <button class="hover_background" @click="controlFly('BACKUP_LANDING', '100')" style="margin-top: 10px">
          {{ t('507') }}
        </button>
        <button class="hover_background" @click="controlFly('DROP_DOWN', '100')" style="margin-top: 10px">
          {{ t('508') }}
        </button>
      </div>
    </div>
  </div>

  <!-- 推流视频区域 -->
  <div v-for="i in currentPlayArr" :style="[
    { top: i.flag > 3 ? (i.flag - 4) * 8 + 'rem' : i.flag * 8 + 'rem' },
    { right: i.flag > 3 ? i.flag * 12 + 'rem' : i.flag * 8 + 'rem' },
    { position: 'absolute' },
  ]" :key="i.id">
    <Jessibuca :currentPlay="currentPlay" :UAVName="UAVName" :optionArr="optionArr" :aiArr="aiArr"
      :aiIsPlayIng="aiIsPlayIng" :playerId="i.id" :playerPosition="i.flag" :sn="centerRouteFrom.sn"
      :width="jessibucawidth" :height="jessibucaheight" :isStop="isStop" @addCurrentPlaySel="addCurrentPlaySel"
      @addCurrentPlayJec="addCurrentPlayJec" @addAiPlay="addAiPlay" @destroy="destroy" @bindContorlWS="bindContorlWS"
      @planeSendWs="planeSendWs" @closeFlyContorl="closeFlyContorl" />
  </div>

  <!-- 任务面板 -->
  <transition name="el-zoom-in-top">
    <Test v-if="visibleTest" v-model:isVisible="visibleTest" :sn="taskRecordFrom.sn" @reloadTable="reloadTaskList">
    </Test>
  </transition>
  <!-- 操作日志 -->
  <transition name="el-zoom-in-top">
    <OperationLogs v-if="visible" v-model:isVisible="visible" :sn="centerRouteFrom.sn" :websocketsend="websocketsend">
    </OperationLogs>
  </transition>
  <!-- 自动机场 -->
  <transition name="el-zoom-in-top">
    <AutoAirplane v-if="visibleAuto" v-model:isVisible="visibleAuto" :sn="centerRouteFrom.sn">
    </AutoAirplane>
  </transition>
  <!-- 适航条件 -->
  <transition name="el-zoom-in-top">
    <AirConditions v-if="visibleAir" v-model:isVisible="visibleAir" :sn="centerRouteFrom.sn">
    </AirConditions>
  </transition>
  <!-- 绕飞任务 -->
  <transition name="el-zoom-in-top">
    <DetourMissionVue v-if="detourMission" v-model:isVisible="detourMission" :sn="centerRouteFrom.sn">
    </DetourMissionVue>
  </transition>
  <!-- 状态信息 -->
  <transition name="el-zoom-in-top">
    <StatuMessgae v-if="visibleMessage" v-model:isVisible="visibleMessage" :UAVModel="UAVModel"
      :taskName="trajectory.taskName" :airportInfo="airportInfo" :uavInfo="uavInfo" :taskInfo="taskInfo"
      :lteInfo="lteInfo" :sn="energySn" :websocketsend="websocketsend">
    </StatuMessgae>
  </transition>
  <!-- 航线规划 -->
  <transition name="el-zoom-in-top">
    <RoutePlan v-if="routeShow" v-model:isVisible="routeShow"> </RoutePlan>
  </transition>
  <!-- 回传照片 -->
  <transition name="el-zoom-in-top">
    <SendPicture v-if="visiblePicture" v-model:isVisible="visiblePicture" :recordId="recordId">
    </SendPicture>
  </transition>

  <!-- 能耗信息 -->
  <transition name="el-zoom-in-top">
    <Energy v-if="showEnergy" v-model:isVisible="showEnergy" :sn="energySn">
    </Energy>
  </transition>
  <transition name="el-zoom-in-top">
    <ControlStage v-if="visibleStage" v-model:isVisible="visibleStage" :sn="accessorySn">
    </ControlStage>
  </transition>
  <!-- 预览信息 -->
  <drag-dialog @close="delAllRoute" v-if="previewLineShow" v-model:visible="previewLineShow" :right="47" :top="3.8"
    :width="23.875" :title="t('509')">
    <div class="preview_line_box">
      <div class="box_hd">
        <div class="row full_height clearfix">
          <div class="col fl col1">{{ t("222") }}</div>
          <div class="col fl col2">{{ t("510") }}</div>
          <div class="col fl col3">{{ t("511") }}</div>
          <div class="col fl col4">{{ t("512") }}</div>
        </div>
      </div>
      <div class="box_bd">
        <div class="row clearfix" v-for="(item, index) in previewLineList" :key="item.routeId">
          <div class="col fl col1">{{ index + 1 }}</div>
          <div class="col fl col2 overflow_text" :title="item.finishMode">
            {{ item.routeName || item.taskName }}
          </div>
          <div class="col fl col3">
            <span class="iconfont icon-a-ziyuan24 hover_opacity"
              @click="locationLine(item.routeId || item.airwayId)"></span>
          </div>
          <div class="col fl col4">
            <span class="iconfont icon-shanchu hover_opacity" @click="delLine(item.routeId || item.airwayId)"></span>
          </div>
        </div>
      </div>
    </div>
  </drag-dialog>
  <!-- 详情 -->
  <div class="preview" v-if="previewShow">
    <div class="close flex-fe">
      <el-icon class="cicon" style="cursor: pointer" @click="closePreview">
        <CloseBold />
      </el-icon>
    </div>
    <div class="previewBox">
      <AirCesium :previewType="previewType" :previewId="previewId" />
    </div>
  </div>
  <!-- 指点飞行 -->
  <transition name="el-zoom-in-top">
    <pointingFlight v-if="visiblePointingFlight" v-model:isVisible="visiblePointingFlight"
      :punctuationLng="pointFlightPunctuationLng" :punctuationLat="pointFlightPunctuationLat" :uavInfo="uavInfo"
      :taskInfo="taskInfo" :airportInfo="airportInfo" @updateMarkParam="updateMarkParamPoint">
    </pointingFlight>
  </transition>

  <!-- 起飞授权 -->
  <flyAuth v-if="openFlyAuth" :isVisible="openFlyAuth" :platformsInfo="platformsInfo" :UAVName="UAVName" :sn="energySn"
    @closeAuth="closeAuth">
  </flyAuth>
  <!-- 控制授权 -->
  <controlAuth v-if="openControlAuth" :isVisible="openControlAuth" :platformsInfo="platformsInfo" :UAVName="UAVName"
    :sn="energySn" @closeAuth="closeAuth">
  </controlAuth>
</template>

<script>
import { t } from '../../languages';
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
  inject,
  onBeforeUnmount,
  nextTick,
} from "vue";

import "animate.css";
import { ElMessageBox, ElMessage, ElNotification } from "element-plus";
import TopNav from "./components/top_nav.vue";
import OperationLogs from "./components/operationLogs.vue";
import Test from "./components/test.vue";
import AutoAirplane from "./components/autoAirplane.vue";
import AirConditions from "./components/airConditions.vue";
import device from "../../network/device";
import taskManage from "../../network/task";
import dataRoute from "../../network/data_route";
import downListExcel from "../../network/download";
import StatuMessgae from "./components/statuMessgae.vue";
import pointingFlight from "./components/pointingFlight.vue";
import Jessibuca from "./components/jessibuca.vue";
import RoutePlan from "./components/routePlan.vue";
import SendPicture from "./components/sendPicture.vue";
import cesiumOperation from "./cesium-operation.js";
import hardwareDevice from "./device.js";
import socket from "@/utils/websocket";
import commandCenter from "./command-center";
import MapSearch from "./components/mapSearch.vue";
import lodash from "lodash";
import data from "@/network/data";
import fence from "@/network/fence";
import { v4 as uuidv4 } from "uuid";
import emitter from "../../utils/mitt";
import AirCesium from "@/components/page/data-manage/components/airCesium.vue";
import ControlStage from "./components/control-stage.vue";
import { useRoute } from "vue-router";
import { store } from "@/store";
import Energy from "./components/energy.vue";
import flyAuth from "./components/fly-auth.vue";
import controlAuth from "./components/control-auth.vue";
import DetourMissionVue from "./components/detourMission.vue"

import platforms from "@/network/platforms"
export default defineComponent({
  data() {
    return {
      navLists: [
        { name: t("513") },
        { name: t("514") },
        { name: t("515") },
        { name: t("516") },
        { name: t("517") },
        { name: t("绕飞任务") },
      ],
    };
  },
  components: {
    TopNav,
    OperationLogs,
    Test,
    AutoAirplane,
    AirConditions,
    StatuMessgae,
    pointingFlight,
    Jessibuca,
    RoutePlan,
    SendPicture,
    MapSearch,
    AirCesium,
    ControlStage,
    Energy,
    flyAuth,
    controlAuth,
    DetourMissionVue

  },
  setup() {
    const { websocketsend, getWsState } = socket();
    const {
      uavInfo,
      weatherInfo,
      taskInfo,
      controInfo,
      trajectory,
      airportInfo,
      uavPanel,
      uavOnLine,
      createUserId,
      lteInfo,
      platformsInfo,
      clearData,
    } = commandCenter(trajectoryAdd, changeTaskID, drawSync, delDraw);
    const route = useRoute();
    onMounted(() => {
      methods.getCenterDress();
      methods.getCenterList();
      methods.getAirportList();
      methods.getAllLayers();
      initEarth("cesiumContainers");
    });

    let lock = false;

    emitter.on("ws-open", () => {
      initDraw();
    });

    function initDraw() {
      nextTick(() => {
        if (!lock) {
          onLineOrOffLine();
          groupDrawTheme(); //开启绘制通道
          lock = true;
        }
      });
    }

    if (getWsState()) {
      initDraw();
    }

    onBeforeUnmount(() => {
      destoryEearth();
      cancelWebsocketAction();
      emitter.off("ws-open");
      emitter.off("position-mark");
      emitter.off("stopJessibuca");
      emitter.off("startJessibuca");
      emitter.off("updataStatu");
      emitter.off("speaker-audio-info", audioInfoHandle);
      window.onresize = null;
    });

    //打开监控中心暂停所有指挥中心的监控
    emitter.on("stopJessibuca", () => {
      state.isStop = true;
    });
    //关闭监控中心开始所有指挥中心的监控
    emitter.on("startJessibuca", () => {
      state.isStop = false;
    });

    const visible = ref(false); //操作日志
    const visibleTest = ref(false); //任务面板
    const visibleAuto = ref(false); //自动机场
    const visibleAir = ref(false); //任务面板
    const visibleMessage = ref(false); //状态信息
    const visiblePointingFlight = ref(false); //指点飞行
    const visiblePicture = ref(false); //回传图片
    const visibleStage = ref(false); //负载操作台
    const detourMission = ref(false);//绕飞任务
    const addFormRef = ref(null);
    const height = ref("calc(100% - 19.75rem)"); //切换航线和终端 航线高度不一样  316px
    const rightRef = ref(null);
    const cesiumRef = ref(null);
    // 拖动改变盒子长度
    const maxHeight = ref(null);
    const cesiumMenuShow = ref(false); //显示右下侧边栏按钮
    const rightMenuShow = ref(true); //
    const cesiumId = ref(0);
    const cesiumTimer = ref(null);
    const showin = ref("in");
    const showflight = ref("flight");
    const showout = ref("out");
    const accessorySn = ref(""); //设备sn
    const state = reactive({
      isStop: false,
      deviceShow: true, //设备
      flgShow: true, //是否执行切换
      routeShow: false, //规划航线
      itemShow: false, //显示列表选中内容
      deviceLeftShow: true, //是否收缩
      flyLogShow: false, //飞行记录
      navListShow: false,
      controlShow: false, //是否显示远程遥控
      weatherShow: false, //是否显示气象信息
      videoShow: false, //推流视频
      executionListShow: false, //计划任务
      taskDetail: false, //任务详情
      addExecutionShow: false, //机场终端新增任务
      cesiumtrigger: false, //事件是否被触发
      againFly: false, //再次飞行
      dressShow: false,
      dressList: [], //筛选列表
      dressVal: 0, //筛选标识
      dressType: ["WHOLE", "PROVINCE", "CITY", "AREA"], //筛选类型
      centerListFrom: {
        regionType: "WHOLE",
        type: "AIRPORT",
        deviceName: "",
      }, //机场终端参数
      UAVName: "", //机场终端名字
      UAVModel: "", //无人机型号
      onlineState: 0,
      centerAirUavList: [],
      activeNum: [],
      centerRouteFrom: {
        sn: "",
        routeName: "",
      }, //机场终端航线列表
      // 任务列表
      taskRecordFrom: {
        sn: "",
        keyword: "",
      },
      getTaskDetail: {
        taskAttributeContent: {
          deadlineTime: "",
          remotePolicy: "",
          retryCount: "",
          signals: false,
          taskPriority: "",
        },
      }, //详情对象
      executeList: {}, // 待执行任务
      taskList: [], // 任务列表
      scheduleTaskList: [], // 计划任务列表
      flyList: [], //飞行记录
      airUavList: [], //航线列表
      airUavIndex: 0, //航线列表索引
      popoverWidth: "11.6875rem", //电池复位弹出框的宽度
      buttonList: [
        { id: 5 },
        { id: 6 },
        { id: 3 },
        { id: 4 },
        { id: 1 },
        { id: 2 },
      ], //电池复位弹出框的电池
      navList: [
        { name: t("164"), id: 1, type: "AIRPORT" },
        // { name: t("165"), id: 2, type: "UAV" },
      ],
      itemNavList: [
        { name: t("510"), id: 1, type: "ROUTE" },
        { name: t("518"), id: 2, type: "TASK" },
        { name: t("519"), id: 3, type: "AIRLOG" },
      ],
      // 机场状态
      uavType: [t("520"), t("521"), t("88")],
      uavTypeColor: ["#F64040", "#18E036", "#FFEF3B"],
      airLogNav: [
        {
          name: t("428"),
          id: 1,
          type: "FLYAGAIN",
        },
        {
          name: t("162"),
          id: 2,
          type: "AIRLOG",
        },
      ], //飞行记录 假数据
      // 侧边栏list
      csiumNav: [
        {
          id: 1,
          url: require("../../asset/img/centerPlan/search.png"),
          url1: require("../../asset/img/centerPlan/search_bg.png"),
          title: t("273"),
        },
        {
          id: 2,
          url: require("../../asset/img/centerPlan/mark.png"),
          url1: require("../../asset/img/centerPlan/mark_bg.png"),
          title: t("274"),
        },
        {
          id: 3,
          url: require("../../asset/img/centerPlan/layer.png"),
          url1: require("../../asset/img/centerPlan/layer_bg.png"),
          title: t("285"),
        },
        {
          id: 4,
          url: require("../../asset/img/centerPlan/twoThree.png"),
          url1: require("../../asset/img/centerPlan/twoThree_bg.png"),
          title: t("287"),
        },
        {
          id: 5,
          url: require("../../asset/img/centerPlan/measure.png"),
          url1: require("../../asset/img/centerPlan/measure_bg.png"),
          title: t("288"),
        },
        {
          id: 6,
          url: require("../../asset/img/centerPlan/add.png"),
          url1: require("../../asset/img/centerPlan/add.png"),
          title: t("289"),
        },
        {
          id: 7,
          url: require("../../asset/img/centerPlan/reduce.png"),
          url1: require("../../asset/img/centerPlan/reduce.png"),
          title: t("290"),
        },
      ],
      // 2d3d按钮
      twoThreeNav: [
        {
          id: 1,
          name: t("144"),
          type: "satellite",
        },
        {
          id: 2,
          name: t("145"),
          type: "administration",
        },
      ],
      // 测距按钮
      rangNav: [
        {
          id: 1,
          name: t("146"),
        },
        {
          id: 2,
          name: t("147"),
        },
      ],
      flyLogList: {
        startDate: "",
        datalist: [],
      },
      navId: 1, //飞行终端
      itemNavId: 1,
      itemNavType: "", //航线 任务 飞行记录标识
      flyLogId: 0, //飞行记录id
      flyLogIndex: -1, //飞行记录索引
      routeItemList: 0, //航线列表index标识
      // executeListIndex: 0, //待执行任务列表index标识
      taskListIndex: 0, //任务列表index标识
      taskCycleIndex: 0, //计划任务标识
      itemId: 0, //暂时的选项标识
      dbTime: 0, //单击双击定时器
      addForm: {
        taskName: "",
        taskRemark: "",
        airwayId: "",
        execTime: "",
        deadLineTime: "",
        taskBind: "",
        loadOperationInfoDTO: {
          accessorySn: "",
          audionPlay: "",
          bgmPlay: "",
          ctl: "",
          endPoint: "",
          startPoint: "",
          tzd: "",
          ttsDTO: {
            cycleStatus: "",
            peopleType: [],
            speed: "",
            tts: [],
          },
        },
      }, //新增任务表单
      bgmFrom: {
        bgmPlay: "",
        audionPlay: "",
        tts: "",
      }, //负载操作表单合并
      addFormRules: {
        taskName: [
          { required: true, message: t("414"), trigger: "change" },
        ],
        airwayId: [
          { required: true, message: t("522"), trigger: "change" },
        ],
        execTime: [
          {
            required: true,
            message: t("523"),
            trigger: "change",
          },
        ],
        deadLineTime: [
          {
            required: true,
            message: t("524"),
            trigger: "change",
          },
        ],
      }, //新增任务表单规则校验
      operationShow: false, //负载操作
      operationSn: null, //
      audioOptions: [
        { label: t("440"), value: 1 },
        { label: "TTS", value: 2 },
      ], //下拉列表
      audioVal: 1,
      ttsList: [
        { label: "0.75X", value: 0.75 },
        { label: "1X", value: 1 },
        { label: "1.5X", value: 1.5 },
        { label: "2X", value: 2 },
      ],
      chooseLanguageShow: false,
      languageList: [
        {
          id: 0,
          name: t("525"),
        },
        {
          id: 1,
          name: t("526"),
        },
        {
          id: 2,
          name: t("527"),
        },
        {
          id: 3,
          name: t("528"),
        },
        {
          id: 4,
          name: t("529"),
        },
        {
          id: 5,
          name: t("530"),
        },
        {
          id: 6,
          name: t("531"),
        },
        {
          id: 7,
          name: t("532"),
        },
        {
          id: 8,
          name: t("533"),
        },
        {
          id: 9,
          name: t("534"),
        },
        {
          id: 10,
          name: t("535"),
        },
      ], //语言列表
      currLanguage: [0], //语种男女
      tts: false,
      audio: false, //音频
      bgAudio: false, //背景音
      searchlight: false, //探照灯
      warninglights: false, //警示灯
      againNum: 0, //再次飞行航线点数
      maxAgainNum: 0, //最大航点数
      cesiumSearchIn: false, // 地球输入框
      cesiumPunctuation: false, // 地球标点
      cesiumLayout: false, // 地球图层
      cesiumTwoThree: false, // 地球2D3D
      twoThreeId: 1,
      sceneMode: "3D", //2d/3d变量
      cesiumRang: false, // 地球测距
      rangId: 0,
      punctuationLng: "", //标点经度
      punctuationLat: "", //标点纬度
      pointFlightPunctuationLng: "", //指点飞行标点经度
      pointFlightPunctuationLat: "", //指点飞行标点纬度
      punctuationName: "", //标点名称
      punctuationColor: "#de2d2d", //标点color
      punctuationColorList: [
        "#de2d2d",
        "#de2db7",
        "#150fff",
        "#25eeb3",
        "#50ee25",
        "#eec125",
      ], //颜色列表
      punctuationIndex: 0, //标点索引
      recordId: "", //任务执行id
      layarList: [], //图层列表
      filterLayerList: [], //图层筛选列表
      suspendContinue: true, //控制面板-暂停/继续
      //下拉框选择列表
      optionArr: [],
      aiArr: [],
      //当前正在播放的列表
      currentPlayArr: [],
      currentPlayFlag: [],
      currentPlay: {},
      aiIsPlayIng: false,
      jessibucawidth: 406,
      jessibucaheight: 270,
      previewShow: false, //详情的开关
      previewType: "", //详情的类型
      previewId: "", //详情的id
      showEnergy: false, //能耗信息
      energySn: "", //机场SN
      openFlyAuth: false,//起飞授权
      openControlAuth: false,//控制授权
    });
    const {
      initEarth,
      destoryEearth,
      createAirportEntity,
      positionAirport,
      enlargeEarth,
      shrinkEarth,
      addAirportLine,
      drawLineH,
      addTrajectory,
      posiMinDistance,
      posiMaxDistance,
      updateMap,
      toggleSceneMode,
      cameraPosition,
      finishTask,
      clearAirway,
      addImagery,
      toggleImagery,
      zoomToImagery,
      add3DTiles,
      toggle3DTiles,
      zoomTo3DTiles,
      canMark,
      registerMarkPoint,
      unRegisterMarkPoint,
      comfirmMarkPoint,
      updateCurrMark,
      getMarkInfo,
      positionMark,
      dbPositionAirport,
      updateCircle,
      drawMark,
      startMap,
      endMap,
      drawLine,
      delLineEntity,
      drawFace,
      createPreviewLine,
      delPreviewLine,
      findRouteLine,
      modelSn,
      modelName,
      createNoFlyCylinder,
      delNoFlyCylinder,
      createYesFlyCylinder,
      createOrNocreateCylinder,
      createOrNocreateYesFlyCylinder,
      createYesFlyGrid,
    } = cesiumOperation(drawDataSendWs);

    //功能窗的模块展示
    const showDragDialog = (index, recordId) => {
      if (index === 0) {
        visibleTest.value = !visibleTest.value;
      } else if (index === 1) {
        visible.value = !visible.value;
      } else if (index === 2) {
        visibleAuto.value = !visibleAuto.value;
      } else if (index === 3) {
        visibleAir.value = !visibleAir.value;
      } else if (index === 4) {
        state.recordId = recordId;
        visiblePicture.value = !visiblePicture.value;
      }else if (index === 5) {
        detourMission.value = !detourMission.value;
      }
    };

    //打开负载操作台
    const openStage = () => {
      visibleStage.value = !visibleStage.value;
    };

    //状态信息
    const showStatuMessage = () => {
      visibleMessage.value = !visibleMessage.value;
    };
    //指点飞行
    const showPointingFlight = () => {
      state.cesiumPunctuation = false;
      visiblePointingFlight.value = !visiblePointingFlight.value;
      if (visiblePointingFlight.value) {
        cesiumStopMeasure();
        // 注册地图标点事件
        registerMarkPoint((lng, lat, height) => {
          state.pointFlightPunctuationLng = lng;
          state.pointFlightPunctuationLat = lat;
        });
      } else {
        // 关闭标点
        cesiumId.value = 0;
        // 注销地图标点事件
        unRegisterMarkPoint();
      }
    };
    //指点飞行的标点改变
    const updateMarkParamPoint = (data, color) => {
      updateCurrMark({
        lng: data.longitude,
        lat: data.latitude,
        text: data.taskName,
        color: "#409eff",
      });
      pointFlightIng(data);
    };
    // 向后端发送信息
    const pointFlightIng = async (data) => {
      let flightData = {};
      flightData.name = data.taskName; //航线名称
      flightData.autoFlightSpeed = ""; //航线最大飞行速度
      flightData.clearHomeLocation = ""; //是否刷新返航点位置
      flightData.executeTime = ""; //时间
      flightData.flightPathMode = ""; //任务路径模式
      flightData.gotoFirstWaypointMode = ""; //前往第一个航点的行动模式
      flightData.headingMode = ""; //执行任务时的航向模式
      flightData.homePointLatitude = ""; //返航点纬度
      flightData.homePointLongitude = ""; //返航点经度
      flightData.missionID = ""; //航线id
      flightData.relativeAltitude = true; //是否相对高度
      flightData.useHomeSeaLevelInRtkUnable = true; //是否相对高度
      if (data.action === "") {
        flightData.finishAction = 1;
      } else {
        flightData.finishAction = data.action; //任务完成的行动模式
      }
      flightData.mission = []; //航点内容
      let objMission = {};
      objMission.gimbalPitch = ""; //云台俯仰
      objMission.shootPhotoDistanceInterval = ""; //定距拍照间隔
      objMission.shootPhotoTimeInterval = ""; //定时拍照间隔
      objMission.wayPointSpeed = Number(data.speed); //航点速度
      objMission.wayPointLatitude = Number(data.latitude); //航点纬度
      objMission.wayPointLongitude = Number(data.longitude); //航点经度
      objMission.wayPointAltitude = Number(data.height); //航点海拔高度
      if (data.behavior == 1) {
        if (data.PTZangle === "") {
          objMission.wayPointActionParam = "-45" + "," + "0"; //航点动作参数
        } else {
          objMission.wayPointActionParam = data.PTZangle + "," + "0"; //航点动作参数
        }
      } else {
        if (data.PTZangle === "") {
          objMission.wayPointActionParam = "-45" + "," + "0"; //航点动作参数
        } else {
          objMission.wayPointActionParam = data.PTZangle + "," + "0"; //航点动作参数
        }
      }
      objMission.heading = Number(data.course); //航向
      if (data.behavior === "") {
        objMission.wayPointAction = "5" + "," + "1"; //航点动作
      } else {
        objMission.wayPointAction = "5" + "," + data.behavior; //航点动作
      }
      flightData.mission.push(objMission);
      let pointVO = {};
      pointVO = flightData;
      let res = await device.addPointFlight(pointVO, state.centerRouteFrom.sn);
      if (res.resultStatus) {
        ElMessage.success(t("536"));
        methods.getExecutionList();
        methods.getTasklist();
        methods.getFlyList();
      }
    };
    //切换远程遥控和机场测试
    const changeBg = ref(true);
    const changeBackground = async (active) => {
      if (!active) {
        let res = await device.getTestAirport();
        if (res.resultStatus) {
          changeBg.value = active;
        }
      } else {
        changeBg.value = active;
      }
    };
    const isShow = ref(true);
    const activeShow = () => {
      isShow.value = !isShow.value;
    };
    const openOrClose = ref(false); //el-switch的变量

    //鼠标移入移出展示隐藏下拉菜单
    const navListShows = () => {
      state.navListShow = true;
    };
    const navListHide = () => {
      state.navListShow = false;
    };
    //切换拍照模式和录制模式
    const photoOrVideo = ref(true);
    const showPhoto = ref(true);
    const showVideo = ref(false);
    const startRecord = ref(true);
    const finishRecord = ref(false);
    //正计时器的实现
    const timeFlag = ref(null);
    const hour = ref(0);
    const minute = ref(0);
    const second = ref(0);
    const recordTime = ref("00:00:00");
    const photoModel = () => {
      photoOrVideo.value = true;
      showPhoto.value = true;
      showVideo.value = false;
    };
    const videoModel = () => {
      photoOrVideo.value = false;
      showPhoto.value = false;
      showVideo.value = true;
    };
    const beginRecord = () => {
      startRecord.value = false;
      finishRecord.value = true;
      hour.value = 0;
      minute.value = 0;
      second.value = 0;
      clearInterval(timeFlag.value);
      timeFlag.value = setInterval(() => {
        second.value = second.value + 1;
        if (second.value >= 60) {
          second.value = 0;
          minute.value = minute.value + 1;
        }

        if (minute.value >= 60) {
          minute.value = 0;
          hour.value = hour.value + 1;
        }
        recordTime.value =
          complZero(hour.value) +
          ":" +
          complZero(minute.value) +
          ":" +
          complZero(second.value);
      }, 1000);
    };
    const stopRecord = () => {
      startRecord.value = true;
      finishRecord.value = false;
      clearInterval(timeFlag.value);
      recordTime.value = "00:00:00";
    };
    //补零
    const complZero = (n) => {
      return n < 10 ? "0" + n : "" + n;
    };

    //机场控制
    const controlFly = (order, id) => {
      let action = order;
      let canSend = true;
      let data = id;
      let messageWord = "";
      switch (order) {
        // 暂停继续
        case "pauseContinue":
          if (state.suspendContinue) {
            action = "SUSPEND_TASKS";
            messageWord = t("430");
          } else {
            action = "CONTINUE_TASKS";
            messageWord = t("481");
          }
          break;
        // 终止任务
        case "STOP_TASK":
          // 执行中、暂停、开始、准备上传、正在上传  状态下才可终止
          if (
            typeof taskInfo.value.sta === "undefined" &&
            taskInfo.value.sta !== "EXECUTING" &&
            taskInfo.value.sta !== "EXECUTION_PAUSED" &&
            taskInfo.value.sta !== "START" &&
            taskInfo.value.sta !== "READY_TO_UPLOAD" &&
            taskInfo.value.sta !== "UPLOADING"
          ) {
            canSend = false;
            ElMessage({
              message: t("537"),
              type: "error",
            });
          } else {
            messageWord = t("480");
          }
          break;
        case "REMOTE_CONTROL_RETURN":
          messageWord = t("89");
          break;
        case "OPEN_AIRPORT_GATE":
          messageWord = t("488");
          break;
        case "CLOSE_AIRPORT_GATE":
          messageWord = t("483");
          break;
        case "PUSH_BUTTON_START":
          messageWord = t("489");
          break;
        case "PUSH_BUTTON_CLOSE":
          messageWord = t("490");
          break;
        case "X_RELEASE":
          messageWord = t("538");
          break;
        case "X_TIGHTEN_UP":
          messageWord = t("539");
          break;
        case "Y_RELEASE":
          messageWord = t("540");
          break;
        case "Y_TIGHTEN_UP":
          messageWord = t("541");
          break;
        case "CHARGE_NOW":
          messageWord = t("503");
          break;
        case "STOP_CHARGING":
          messageWord = t("504");
          break;
        case "UAV_OPEN":
          messageWord = t("542");
          break;
        case "UAV_SHUTDOWN":
          messageWord = t("543");
          break;
        case "ONE_POWER_EXCHANGE":
          messageWord = t("495");
          break;
        case "RETURN_BATTERY":
          messageWord = t("497");
          break;
        case "PUSH_BUTTON_RESET":
          messageWord = t("498");
          break;
        case "LIFTING_PLATFORM":
          messageWord = t("499");
          break;
        case "LANDING_PLATFORM":
          messageWord = t("500");
          break;
        case "POWER_ON":
          messageWord = t("501");
          break;
        case "POWER_DOWN":
          messageWord = t("502");
          break;
        case "RESET_BATTERY":
          data != "-1"
            ? (messageWord = data + t("544") + t("496"))
            : (messageWord = t("545"));
          break;
        case "BACKUP_LANDING":
          messageWord = t("507");
          break;
        case "DROP_DOWN":
          messageWord = t("508");
          break;
        default:
          break;
      }
      if (canSend) {
        ElMessageBox.confirm(
          t("546") + messageWord + t("547"),
          t("130"),
          {
            confirmButtonText: t("218"),
            cancelButtonText: t("217"),
          }
        )
          .then(() => {
            taskManage
              .airportControl({
                action: action,
                nestSn: state.taskRecordFrom.sn,
                protocolType: "PROTOCOL_STANDARD",
                data: data,
              })
              .then((res) => {
                res.resultStatus &&
                  ElMessage({
                    message: t("548") + messageWord,
                    type: "success",
                  });
                if (order === "pauseContinue") {
                  if (res.resultStatus) {
                    // state.suspendContinue = !state.suspendContinue;
                  }
                }
              });
          })
          .catch(() => {
            ElMessage(t("549"));
          });
      }
    };

    const openOrCloseChange = ref(false); //el-switch的变量

    //打开关闭无人机控制

    function handleSwitchChange(val) {
      emitter.emit("btn-switch", val);
      if (state.onlineState != 1) {
        ElMessage({
          message: t("550"),
          type: "error",
        });
        openOrClose.value = false;
        return;
      } else {
        emitter.emit("btn-switch", val);
      }
      if (openOrClose.value) {
        openOrClose.value = !val;
        ElMessageBox.confirm(t("551"), t("130"), {
          confirmButtonText: t("218"),
          cancelButtonText: t("217"),
          type: "warning",
        })
          .then(() => {
            openOrClose.value = true;
            const enterControl = {
              action: "REMOTE_CONTROL",
              code: "ENTER_EXIT_REMOTE",
              sn: state.centerRouteFrom.sn,
              data: {
                status: 1,
              },
            };
            websocketsend(enterControl);
            openOrClose.value = true;
            ElMessage({
              message: t("552"),
              type: "success",
            });
          })
          .catch(() => {
            openOrClose.value = false;
          });
      } else {
        const exitControl = {
          action: "REMOTE_CONTROL",
          code: "ENTER_EXIT_REMOTE",
          sn: state.centerRouteFrom.sn,
          data: {
            status: 0,
          },
        };
        websocketsend(exitControl);
        openOrClose.value = false;
        ElMessage({
          message: t("553"),
          type: "success",
        });
      }
    }

    //拍照
    function takephoto() {
      const getphoto = {
        action: "REMOTE_CONTROL",
        code: "NEST_PHOTOGRAPH",
        sn: state.nestSn,
        data: {},
      };
      websocketsend(getphoto);
    }
    //云台操作,无人机控制
    let timer;
    let timeStart; //鼠标按下时间
    let timeEnd; //鼠标抬起时间
    let control = {
      action: "REMOTE_CONTROL",
      code: "CONTROLLER_YUNTAI",
      sn: "",
      data: {},
    };
    //获取此刻时间
    function getTimeNow() {
      let now = new Date();
      return now.getTime();
    }
    //无人机轮盘点按控制
    const airplaneControl = lodash.throttle((data) => {
      let action = JSON.parse(JSON.stringify(control));
      switch (data) {
        case "gimbalup":
          // 云台控制 -- 俯仰
          if (timeEnd - timeStart < 1000) {
            action.data["pitch"] = 1;
            // console.log("up");
            websocketsend(action);
          }
          break;
        case "gimbalright":
          // 云台控制 -- 偏航
          if (timeEnd - timeStart < 1000) {
            action.data["yaw"] = 1;
            // console.log("right");
            websocketsend(action);
          }
          break;
        case "gimbaldown":
          // 云台控制 -- 俯仰
          if (timeEnd - timeStart < 1000) {
            action.data["pitch"] = -1;
            // console.log("down");
            websocketsend(action);
          }
          break;
        case "gimballeft":
          // 云台控制 -- 偏航
          if (timeEnd - timeStart < 1000) {
            action.data["yaw"] = -1;
            // console.log("left");
            websocketsend(action);
          }
          break;
        case "vtup":
          // 姿态控制  -- 油门速度
          if (timeEnd - timeStart < 1000) {
            action.code = "CONTROLLER_NEST_ATTITUDE";
            action.data["vt"] = 1;
            // console.log("vtup");
            websocketsend(action);
          }
          break;
        case "yawright":
          // 姿态控制  -- 偏航
          if (timeEnd - timeStart < 1000) {
            action.code = "CONTROLLER_NEST_ATTITUDE";
            action.data["yaw"] = 4;
            // console.log("yawright");
            websocketsend(action);
          }
          break;
        case "vtdown":
          // 姿态控制  -- 油门速度
          if (timeEnd - timeStart < 1000) {
            action.code = "CONTROLLER_NEST_ATTITUDE";
            action.data["vt"] = -1;
            // console.log("vtdown");
            websocketsend(action);
          }
          break;
        case "yawleft":
          // 姿态控制  -- 偏航
          if (timeEnd - timeStart < 1000) {
            action.code = "CONTROLLER_NEST_ATTITUDE";
            action.data["yaw"] = -4;
            // console.log("yawleft");
            websocketsend(action);
          }
          break;
        case "pitchup":
          // 姿态控制  -- 俯仰
          if (timeEnd - timeStart < 1000) {
            action.code = "CONTROLLER_NEST_ATTITUDE";
            action.data["pitch"] = 1;
            // console.log("pitchup");
            websocketsend(action);
          }
          break;
        case "rollright":
          // 姿态控制  -- 横滚
          if (timeEnd - timeStart < 1000) {
            action.code = "CONTROLLER_NEST_ATTITUDE";
            action.data["roll"] = 1;
            // console.log("rollright");
            websocketsend(action);
          }
          break;
        case "pitchdown":
          // 姿态控制  -- 俯仰
          if (timeEnd - timeStart < 1000) {
            action.code = "CONTROLLER_NEST_ATTITUDE";
            action.data["pitch"] = -1;
            // console.log("pitchdown");
            websocketsend(action);
          }
          break;
        case "rollleft":
          // 姿态控制  -- 横滚
          if (timeEnd - timeStart < 1000) {
            action.code = "CONTROLLER_NEST_ATTITUDE";
            action.data["roll"] = -1;
            // console.log("rollleft");
            websocketsend(action);
          }
          break;
      }
    }, 500);
    //无人机轮盘长按控制
    const longpress = (data) => {
      if (!openOrClose.value) {
        ElMessage.info(t("136"));
        return;
      }
      let action = JSON.parse(JSON.stringify(control));
      switch (data) {
        case "gimbalup":
          timeStart = getTimeNow();
          action.data["pitch"] = 20;
          timer = setInterval(function () {
            console.log("longpress");
            websocketsend(action);
          }, 500);
          break;
        case "gimbalright":
          timeStart = getTimeNow();
          action.data["yaw"] = 20;
          timer = setInterval(function () {
            console.log("longpress");
            websocketsend(action);
          }, 500);
          break;
        case "gimbaldown":
          timeStart = getTimeNow();
          action.data["pitch"] = -20;
          timer = setInterval(function () {
            console.log("longpress");
            websocketsend(action);
          }, 500);
          break;
        case "gimballeft":
          timeStart = getTimeNow();
          action.data["yaw"] = -20;
          timer = setInterval(function () {
            console.log("longpress");
            websocketsend(action);
          }, 500);
          break;
        case "vtup":
          timeStart = getTimeNow();
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["vt"] = 1;
          timer = setInterval(function () {
            console.log("longpress");
            websocketsend(action);
          }, 500);
          break;
        case "yawright":
          timeStart = getTimeNow();
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["yaw"] = 50;
          timer = setInterval(function () {
            console.log("longpress");
            websocketsend(action);
          }, 500);
          break;
        case "vtdown":
          timeStart = getTimeNow();
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["vt"] = -1;
          timer = setInterval(function () {
            console.log("longpress");
            websocketsend(action);
          }, 500);
          break;
        case "yawleft":
          timeStart = getTimeNow();
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["yaw"] = -50;
          timer = setInterval(function () {
            console.log("longpress");
            websocketsend(action);
          }, 500);
          break;
        case "pitchup":
          timeStart = getTimeNow();
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["pitch"] = 1;
          timer = setInterval(function () {
            console.log("longpress");
            websocketsend(action);
          }, 500);
          break;
        case "rollright":
          timeStart = getTimeNow();
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["roll"] = 1;
          timer = setInterval(function () {
            console.log("longpress");
            websocketsend(action);
          }, 500);
          break;
        case "pitchdown":
          timeStart = getTimeNow();
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["pitch"] = -1;
          timer = setInterval(function () {
            console.log("longpress");
            websocketsend(action);
          }, 500);
          break;
        case "rollleft":
          timeStart = getTimeNow();
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["roll"] = -1;
          timer = setInterval(function () {
            console.log("longpress");
            websocketsend(action);
          }, 500);
          break;
        default:
          break;
      }
    };

    //抬起取消长按事件
    const stopsend = () => {
      clearInterval(timer);
      timeEnd = getTimeNow();
      // console.log(t("554"));
    };

    // 相机模式
    const changeCamerType = ref("");
    const cameraType = [
      { id: 0, value: t("555") },
      { id: 1, value: t("556") },
      { id: 2, value: t("557") },
      { id: 3, value: t("558") },
    ];
    const submitCamerType = async () => {
      if (changeCamerType.value === undefined) return;
      let camerObj = {
        action: "UPDATE_CAMERA_MODE",
        nestSn: control.sn,
        data: changeCamerType.value,
      };
      await taskManage.airportControl(camerObj);
    };

    // 变焦
    const changeZoom = async (val) => {
      let zoomObj = {
        action: "TOGGLE_ZOOM",
        nestSn: control.sn,
        data: val,
      };
      await taskManage.airportControl(zoomObj);
    };

    //当前机场数据
    const airportData = reactive({
      airportHeading: 0, //机场的方向
      airportHeight: 0, //机场的高度
    });

    // 更新控制台暂停继续状态
    emitter.on("updataStatu", (statu) => {
      console.log(statu);
      state.suspendContinue = statu === 0 ? false : true;
      console.log(state.suspendContinue);
    });

    /* 获取机场信息 */
    async function getNestDetail() {
      // const nestData = await monitor.getNestInfo(selectSn.value);
      // const modalData = await monitor.getModalParam(selectSn.value);
      // trajectory.value.droneModel = nestData.droneModel;
      // trajectory.value.airportPosi = {
      //   longitude: nestData.locationLongitude,
      //   latitude: nestData.locationLatitude,
      //   altitude: modalData.altitude,
      // };
      // airportData.airportHeight = modalData.altitude;
      // airportData.airportHeading = modalData.direction;
      // nextTick(() => {
      //   /* 计算地形高程 */
      //   var terrainProvider = Cesium.createWorldTerrain();
      //   const terrarnPosi = [
      //     Cesium.Cartographic.fromDegrees(
      //       nestData.locationLongitude,
      //       nestData.locationLatitude
      //     ),
      //   ];
      //   const promise = Cesium.sampleTerrainMostDetailed(
      //     terrainProvider,
      //     terrarnPosi
      //   ).then((updatedPositions) => {
      //     minTerrainHeight = updatedPositions[0].height + 2;
      //     if (state.airportHeight < minTerrainHeight) {
      //       airportEntity.position = Cesium.Cartesian3.fromDegrees(
      //         nestData.locationLongitude,
      //         nestData.locationLatitude,
      //         minTerrainHeight
      //       );
      //     }
      //   }); //其中terrainProvider是当前场景使用的高程Provider
      //   // Cesium.when(promise, function (updatedPositions) {
      //   // });
      // });
    }

    // let currentTaskId = ""; //机场的当前任务记录id

    //任务id不同 ---切换任务
    async function changeTaskID(execMissionID) {
      // 去除历史轨迹和航线
      if (
        trajectory.value.currentTaskId !== undefined &&
        trajectory.value.currentTaskId !== ""
      ) {
        finishTask();
        clearAirway();
      }

      if (
        execMissionID !== "" &&
        execMissionID !== null &&
        execMissionID !== undefined
      ) {
        trajectory.value.currentTaskId = execMissionID;
        // 任务id不同获取航线
        getAirportLine(execMissionID);
        //任务id获取历史轨迹
        getTrajectory(state.centerRouteFrom.sn);
        trackCommand();

        //重新获取任务名称
        let nestSnData = await taskManage.executionTask({
          deviceSn: state.centerRouteFrom.sn,
        });
        trajectory.value.taskName =
          nestSnData.resultData != null ? nestSnData.resultData.taskName : "-";
      }
    }

    //sn获取当前任务
    async function getAirportTask(nestSn) {
      let nestSnData = await taskManage.executionTask({ deviceSn: nestSn });

      // 去除之前的历史轨迹和航线
      if (
        trajectory.value.currentTaskId !== undefined &&
        trajectory.value.currentTaskId !== ""
      ) {
        finishTask();
        clearAirway();
      }

      if (nestSnData.resultData !== null) {
        let recordId = nestSnData.resultData.recordId;
        if (
          trajectory.value.currentTaskId !== recordId &&
          recordId !== "" &&
          recordId !== null &&
          recordId !== undefined
        ) {
          // 任务id不同获取航线
          getAirportLine(recordId);
          //任务id获取历史轨迹
          getTrajectory(nestSn);
          //实时轨迹主题订阅
          trackCommand();
        }

        trajectory.value.currentTaskId = recordId;
        trajectory.value.taskName = nestSnData.resultData.taskName;
      } else {
        trajectory.value.currentTaskId = "";
        trajectory.value.taskName = "-";
      }
    }

    //获取机场航线
    async function getAirportLine(recordId) {
      let airwayData = await taskManage.airwayTask({ recordId: recordId });
      airwayData = airwayData.resultData;

      if (airwayData instanceof Array && airwayData.length > 0) {
        const firstPoint = airwayData[0];
        const lastPoint = airwayData[airwayData.length - 1];
        const cloneArr = airwayData.slice(1, airwayData.length - 1);

        //画航线
        addAirportLine(firstPoint, lastPoint, cloneArr, airwayData);
      }
    }

    //获取已巡检的轨迹
    async function getTrajectory(nestSn) {
      if (nestSn || nestSn === 0) {
        let trajectoryData = await taskManage.getFlightTrajectory(nestSn);
        // console.log(trajectoryData);
        //添加历史轨迹
        trajectoryData.resultStatus && drawLineH(trajectoryData.resultData);
      }
    }

    let rtkOldTime = 0; //获取轨迹点上个时间
    let rtkLastPosi = null; //存储上一个rtk位置

    //添加实时轨迹
    function trajectoryAdd(position) {
      const currentTime = new Date().getTime();
      const timeInterval =
        rtkOldTime !== 0 && (currentTime - rtkOldTime) / 1000 < 2.2
          ? (currentTime - rtkOldTime) / 1000
          : 2;

      /* rtk为固定解，此时精度较高 */
      // if (uavInfo.value.rtkStatus === "FIXED_POINT") {
      // 经纬度不为0，高度大于-200小于500，距离机场不超过5公里，上个点距离此时的点的距离大于0.5m
      if (
        position.longitude != 0 &&
        position.latitude != 0 &&
        position.altitude < 500 &&
        position.altitude > -200 &&
        posiMaxDistance(trajectory.value.airportPosi, position, 5000) &&
        posiMinDistance(rtkLastPosi, position, 0.5)
      ) {
        addTrajectory(position, timeInterval);
      }
      // }
      rtkLastPosi = position;
      rtkOldTime = currentTime;
    }

    const previewLineList = ref([]); //预览的航线列表
    const previewLineShow = ref(false); //预览的航线列表

    const methods = reactive({
      // 获取所有区域
      getFenceListData: async (data) => {
        let resFence = await fence.getFenceList({
          sn: data,
          data1: data,
        });
        if (resFence.resultStatus) {
          yesFlyList.value = resFence.resultData[1];
          noFlyList.value = resFence.resultData[2];
          heightLimitList.value = resFence.resultData[3];
          if (Array.isArray(noFlyList.value) && noFlyList.value.length !== 0) {
            noFlyList.value.forEach((e) => {
              if (e.visible) {
                createOrNocreateCylinder(e, "red");
              }
            });
          }
          if (
            Array.isArray(heightLimitList.value) &&
            heightLimitList.value.length !== 0
          ) {
            heightLimitList.value.forEach((e) => {
              if (e.visible) {
                createOrNocreateCylinder(e, "yellow");
              }
            });
          }
          if (
            Array.isArray(yesFlyList.value) &&
            yesFlyList.value.length !== 0
          ) {
            yesFlyList.value.forEach(async (e) => {
              if (e.visible) {
                checkAllYesFly.value = true;
                const data = await device.refreshPosition(control.sn);
                createOrNocreateYesFlyCylinder(
                  true,
                  data.resultData.longitude,
                  data.resultData.latitude,
                  yesFlyList.value[0].coordinateObj.radius,
                  yesFlyList.value[0].coordinateObj.height,
                  yesFlyList.value[0].coordinateObj.gird
                );
              } else {
                checkAllYesFly.value = false;
                createYesFlyCylinder(
                  false,
                  0,
                  0,
                  yesFlyList.value[0].coordinateObj.radius,
                  yesFlyList.value[0].coordinateObj.height,
                  yesFlyList.value[0].coordinateObj.gird
                );
              }
            });
          }
          checkAllGird.value = false;
          createYesFlyGrid(false, {}, {});
        }
      },
      // 获取机场终端列表
      getCenterList: async () => {
        let res = await device.getCenterDeviveList(state.centerListFrom);
        if (res.resultStatus) {
          state.centerAirUavList = res.resultData;
          const { sn } = route.query;
          if (sn != undefined) {
            const item = automatic(state.centerAirUavList, sn);
            if (!item) return;
            methods.itemChange(item);
            setTimeout(() => {
              positionAirport(item.sn);
              methods.monitorChange('CENTERMONITOR', item.sn)
            }, 500)
          }
        }
      },
      // 获取筛选列表
      getCenterDress: async () => {
        let res = await device.getDressDevive();
        if (res.resultStatus) {
          state.dressList = res.resultData;
        }
      },
      // 获取所有机场
      getAirportList: async () => {
        const res = await device.getDeviceListByType({
          deviceType: "AIRPORT",
        });
        if (res.resultStatus) {
          const data = await device.getAllAirportParam();
          const airportList = res.resultData;
          const modelParams = data.resultData;
          for (let i = 0; i < airportList.length; i++) {
            const airportItem = airportList[i];
            if (modelParams[airportItem.sn]) {
              const modelParam = modelParams[airportItem.sn];
              airportItem.altitude = modelParam.altitude;
              airportItem.direction = modelParam.direction;
            }
          }
          createAirportEntity(airportList);
        }
      },
      // 获取所有图层
      getAllLayers: async () => {
        const res = await data.getAllLayers();
        if (res.resultStatus) {
          const layarData = res.resultData;
          state.layarList = layarData;
          state.filterLayerList = state.layarList;
          nextTick(() => {
            for (let i = 0; i < layarData.length; i++) {
              const item = layarData[i];
              const displayFlag = item.display;
              if (displayFlag) {
                const layerId = item.layerId;
                const layerUrl = item.layerUrl;
                const layerType = item.layerType;
                visibleLayar.push(layerId);
                // 初始化点云、b3dms或tif
                if (layerType === "TIF") {
                  console.log(item);
                  addImagery(layerId, layerUrl, item.tifMetaInfo, false);
                } else {
                  add3DTiles(layerId, layerUrl, false);
                }
              }
            }
          });
        }
      },
      // 获取当前设备型号
      getDeviceModel: async (sn) => {
        let res = await device.getDeviceModel(sn);
        if (res.resultStatus) {
          state.UAVModel = res.resultData.model;
        }
      },
      // 显示筛选列表
      showDress: () => {
        state.dressShow = !state.dressShow;
      },
      // 机场终端筛选列表事件
      dressChange: (item) => {
        state.dressVal = item.value;
        state.centerListFrom.regionType = state.dressType[item.value];
        methods.getCenterList();
      },
      // 查看照片 机场终端飞行记录
      lookPhoto: (recordId) => {
        console.log(recordId);
        state.recordId = recordId;
        visiblePicture.value = !visiblePicture.value;
      },
      //机场终端搜索
      centerSearch: () => {
        methods.getCenterList();
      },
      // 清除请求
      inputClean: (type) => {
        if (type == "AirUan") {
          methods.getCenterList();
        }
        if (type == "ROUTE") {
          methods.getItemRouteList();
        }
        if (type == "TASK") {
          methods.getTasklist();
        }
        if (type == "AIRLOG") {
          methods.getFlyList();
        }
      },
      // taskManage
      // 获取执行中或待执行的任务记录
      getExecutionList: async () => {
        let res = await taskManage.getExecution(state.taskRecordFrom.sn);
        if (res.resultStatus) {
          state.executeList = res.resultData;
          if (state.taskDetail) {
            state.taskDetail = false;
            // state.executeListIndex = 0;
          }
        }
      },
      // 获取任务记录列表
      getTasklist: async () => {
        let res = await taskManage.getCenterTaskList(state.taskRecordFrom);
        if (res.resultStatus) {
          state.taskList = methods.sort_pro(res.resultData, ["createUserName"]);
        }
      },
      sort_pro(data, keys = []) {
        //keys可以传一个数组
        var c = [];
        var d = {};
        for (var element of data) {
          let element_keyStr = "";
          let element_key = [];
          let element_keyObj = {};
          for (var key of keys) {
            element_key.push(element[key]);
            element_keyObj[key] = element[key];
          }
          element_keyStr = element_key.join("_");
          if (!d[element_keyStr]) {
            c.push({
              ...element_keyObj,
              children: [element],
            });
            d[element_keyStr] = element;
          } else {
            for (var ele of c) {
              let isTrue = keys.some((key) => {
                return ele[key] != element[key];
              });
              if (!isTrue) {
                ele.children.push(element);
              }
            }
          }
        }
        return c;
      },
      // 删除任务列表任务
      centerDeleteTask: (item) => {
        ElMessageBox.confirm(t("559"), t("130"), {
          confirmButtonText: t("218"),
          cancelButtonText: t("217"),
          type: "warning",
        })
          .then(async () => {
            let ids = [item.taskId];
            let res = await taskManage.centerDeleteTask({ ids });
            if (res.resultStatus) {
              ElMessage.success(t("560"));
              methods.getTasklist();
            }
          })
          .catch(() => {
            ElMessage.info(t("561"));
          });
      },
      // 执行任务
      issueTask: (item) => {
        ElMessageBox.confirm(`${t('562')} ${item.taskName} ${t('518')}！`, t("130"), {
          confirmButtonText: t("218"),
          cancelButtonText: t("217"),
          type: "warning",
        })
          .then(async () => {
            let time = "";
            time = methods.getDateTime();
            let obj = {
              operateType: "ISSUE",
              taskId: item.taskId,
              issueDate: time,
            };
            let res = await taskManage.issueTask(obj);
            if (res.resultStatus) {
              ElMessage.success(t("563"));
              methods.getExecutionList();
            }
          })
          .catch(() => {
            // ElMessage.info(t("396"));
          });
        return;
      },
      // 取消执行
      cancelTask: async (type, taskId) => {
        let res = await taskManage.airportControl({
          action: type,
          nestSn: state.taskRecordFrom.sn,
        });
        const res1 = await taskManage.cancelTask(taskId);
        if (res1.resultStatus) {
          ElMessage.success(t("564"));
          methods.getExecutionList();
          methods.getTasklist();
          state.taskDetail = false;
        }
      },
      // 删除待执行任务
      deletePending: async (item) => {
        let res = await taskManage.deletePending(item.recordId);
        if (res.resultStatus) {
          // state.executeList.splice(index,1)
          ElMessage.success(t("560"));
          methods.getExecutionList();
          state.taskDetail = false;
        }
      },
      // 飞行记录列表
      getFlyList: async () => {
        let res = await taskManage.getFlyList(state.taskRecordFrom);
        if (res.resultStatus) {
          state.flyList = res.resultData;
        }
      },
      // 计划任务
      getPlanList: async () => {
        let res = await taskManage.getExecutionList(state.taskRecordFrom.sn);
        if (res.resultStatus) {
          state.scheduleTaskList = res.resultData;
        }
      },
      // 删除计划任务
      deleteTask: async (id) => {
        let res = await taskManage.deleteTask(id);
        if (res.resultStatus) {
          ElMessage.success(t("560"));
          methods.getPlanList();
        }
      },
      // 暂停计划任务
      stopTaskCycle: () => { },
      // 任务详情
      getTaskMessage: async (id) => {
        let res = await taskManage.getTaskMessage(id);
        if (res.resultStatus) {
          state.getTaskDetail = res.resultData;
        }
      },
      // 关闭任务详情
      closeTaskDetail: () => {
        state.taskDetail = false;
      },
      // 获取当前时间
      getDateTime: () => {
        const yy = new Date().getFullYear();
        const MM =
          new Date().getMonth() + 1 < 10
            ? "0" + (new Date().getMonth() + 1)
            : new Date().getMonth() + 1;
        const dd =
          new Date().getDate() < 10
            ? "0" + new Date().getDate()
            : new Date().getDate();
        const HH =
          new Date().getHours() < 10
            ? "0" + new Date().getHours()
            : new Date().getHours();
        const mm =
          new Date().getMinutes() < 10
            ? "0" + new Date().getMinutes()
            : new Date().getMinutes();
        const ss =
          new Date().getSeconds() < 10
            ? "0" + new Date().getSeconds()
            : new Date().getSeconds();
        let time = yy + "-" + MM + "-" + dd + " " + HH + ":" + mm + ":" + ss;
        return time;
      },
      // 任务列表截取时间
      getTaskTime: (time) => {
        let timeArr = time.replace(" ", ":").replace(/\:/g, "-").split("-");
        let yy = timeArr[0];
        let mm = timeArr[1];
        let dd = timeArr[2];
        let timer = yy + "-" + mm + "-" + dd;
        return timer;
      },
      // 获取时分秒
      getMinuteTime: (time) => {
        let timeArr = time.replace(" ", ":").replace(/\:/g, "-").split("-");
        let HH = timeArr[3];
        let mm = timeArr[4];
        let ss = timeArr[5];
        let timer = HH + ":" + mm + ":" + ss;
        return timer;
      },
      // 展开折叠面板
      handleChange: () => { },
      //设备列表
      deviceChange: () => {
        state.deviceShow = !state.deviceShow;
        // 这里可设置监听 那个主体关闭了，就把子弹窗关闭
        // 重置机场终端列表
        state.navId = 1;
        state.centerListFrom.regionType = "WHOLE";
        state.centerListFrom.type = "AIRPORT";
        methods.getCenterList();

        state.itemShow = false;
        state.itemNavId = 1; //重置航线 任务 飞行记录选中状态
        state.flyLogShow = false; //关闭飞行记录
        state.executionListShow = false; //关闭计划任务
        state.addExecutionShow = false; //关闭新增任务
        state.airUavIndex = 0;
        state.againFly = false; //关闭再次飞行
        state.taskDetail = false; //关闭详情
      },
      // 航线规划
      routeChange: () => {
        state.routeShow = !state.routeShow;
      },
      //直播视频
      broadCast: () => { },
      // 终端切换
      navChange: (item) => {
        state.centerAirUavList = [];
        state.navId = item.id;
        state.centerListFrom.type = item.type;
        // 记得补上切换的时候要把打开的内容都关掉
        state.itemShow = false;
        state.weatherShow = false;
        state.controlShow = false;
        state.currentPlayArr = [];
        state.currentPlayFlag = [];
        changeBg.value = true;
        state.itemNavId = 1; //重置航线 任务 飞行记录选中状态
        state.flyLogShow = false; //关闭飞行记录
        state.executionListShow = false; //关闭计划任务
        state.addExecutionShow = false; //关闭新增任务
        state.airUavIndex = 0;
        height.value =
          state.navId == 1 ? "calc(100% - 19.75rem)" : "calc(100% - 16rem)"; //处理机场终端和航线高度不一样导致滚动条没到底部问题
        methods.getCenterList();
      },
      //航线 任务 飞行记录切换
      itemNavChange: (item) => {
        state.itemNavId = item.id;
        state.itemNavType = item.type;
        state.flyLogId = 0; //切换重置飞行记录 再次飞行和执行记录
        state.executionListShow = false; //关闭计划任务
        state.addExecutionShow = false; ////关闭新增任务
        closeBgmAduio(); //关闭订阅
        emptyData();
        state.flyLogShow = false;
        state.taskRecordFrom.keyword = "";
        state.taskCycleIndex = 0; //计划任务列表
        state.taskListIndex = 0; //任务列表
        // state.executeListIndex = 0; //待执行计划列表
        state.routeItemList = 0; //航线列表
        state.againFly = false;
        state.againNum = 0;
        if (item.id == 1 && item.type == "ROUTE") {
          methods.getItemRouteList();
        }
        // 任务列表请求
        if (item.id == 2 && item.type == "TASK") {
          methods.getExecutionList();
          methods.getTasklist();
          if (tzdType.value === "") {
            emitter.on("speaker-audio-info", audioInfoHandle);
          }
        }
        // 飞行记录
        if (item.id == 3 && item.type == "AIRLOG") {
          methods.getFlyList();
        }
      },
      // 航线列表、待执行任务、任务列表点击事件
      routeItemChange: (index, type, id) => {
        state.airUavIndex = 0;
        if (type == "AITUAV") {
          state.airUavIndex = index;
        }
        // 待执行列表
        if (type == "UAVEXECUTE") {
          // state.executeListIndex = index;
          state.taskDetail = true;
          methods.getTaskMessage(id);
        }
        // 任务列表
        if (type == "UAVTASK") {
          state.taskListIndex = id;
        }
        // 计划任务列表
        if (type == "TASKCYCLE") {
          state.taskCycleIndex = index;
        }
      },
      // 设备列表内选项事件 单击事件
      itemChange: (items) => {
        state.currentPlayArr = [];
        state.currentPlayFlag = [];
        clearData();
        if (state.flgShow) {
          state.flgShow = false; //关闭
          setTimeout(() => {
            state.flgShow = true;
          }, 2000);
          if (items.sn != state.centerRouteFrom.sn) {
            // 切换机场隐藏
            emitter.emit("send-sn", items.sn);
            const modelBox = document.getElementById("modelText");
            modelBox.style.display = "none";
            state.onlineState = items.state; //获取当前机场状态（离线/在线）
            state.UAVName = items.deviceName; //机场名称
            state.centerRouteFrom.sn = items.sn; //航线列表参数
            state.taskRecordFrom.sn = items.sn; //任务列表参数
            control.sn = items.sn;
            state.energySn = items.sn;
            methods.getItemRouteList(); //获取航线列表
            positionAirport(items.sn); //定位
            websocketAction(items.sn); // 绑定机场发送指令
            getAirportTask(items.sn); //获取机场当前任务
            methods.getStatusBySn(items.sn);
            methods.getDeviceModel(items.sn); //获取无人机型号
            methods.getRemoteStatu(items.sn); //获取远控开关状态
            getFlyControlList(items.sn);//获取授权弹窗列表
            onLineOrOffLine();
            clearBeforeFlightPath(); //清空上一个机场的航线轨迹
            methods.getFenceListData(items.sn);
          }
          accessorySn.value = items.accessorySn; //设备sn
          // positionAirport(items.sn); //定位
          state.itemId = items.id; //选项
          state.itemShow = true;
          state.itemNavId = 1;
          state.flyList = []; //清空飞行记录
          // state.executeList = []; // 清空待执行任务
          state.taskList = []; // 清空任务列表
          state.scheduleTaskList = []; // 清空计划任务列表
          state.executionListShow = false; //切换机场终端选项 复位导航线 关闭计划任务列表
          state.addExecutionShow = false; //关闭新建任务
          closeBgmAduio();
          emptyData();
          state.flyLogShow = false; //关闭飞行记录的执行记录
          state.againFly = false; //关闭再次飞行
          state.itemNavType = "ROUTE";
          state.taskDetail = false; //关闭详情
          visible.value = false; //操作日志
          visibleTest.value = false; //任务面板
          visibleAuto.value = false; //自动机场
          visibleAir.value = false; //任务面板
          detourMission.value = false;// 绕飞任务
          visibleMessage.value = false; //状态信息
          visiblePointingFlight.value = false; //指点飞行
          visiblePicture.value = false; //回传图片
          state.controlShow = false; //远程遥控面板
          state.weatherShow = true; //气象信息面板
          visibleStage.value = false; //负载控制台
          changeBg.value = true;
          state.showEnergy = false;
          const planeStatu = {
            action: "ACCEPT",
            code: "",
            data: ["STA_WS"],
          };
          websocketsend(planeStatu);
          state.videoShow = false; //视频监控
        } else {
          return;
        }
      },
      // 查询当前sn的暂停和继续状态
      getStatusBySn: async (sn) => {
        const res = await data.getStatus(sn);
        state.suspendContinue = res.resultData === 0 ? false : true;
      },
      // 双击事件
      goAirpot: (item) => {
        dbPositionAirport(item.sn); //定位
      },
      // 获取机场终端每个的航线信息
      getItemRouteList: async () => {
        let res = await dataRoute.getCenterRoute(state.centerRouteFrom);
        if (res.resultStatus) {
          state.airUavList = res.resultData;
        }
      },
      // 航线查询enter
      centerRouteSearch: () => {
        methods.getItemRouteList();
      },
      // 任务查询enter
      centerTaskSearch: () => {
        methods.getTasklist();
      },
      // 飞行记录搜索
      centerFlySearch: () => {
        methods.getFlyList();
      },
      // 航线下载
      centerRouteImport: (id) => {
        let ids = [id];
        downListExcel.downloadSource(
          "/api/v1/export/route/zip",
          {
            ids: ids,
          },
          "application/zip",
          "post"
        );
      },
      /* 预览航线 */
      routeLinePreview: async (item, type = "route") => {
        if (type === "route") {
          const res = await dataRoute.previewRoute(item.routeId);
          if (res.resultStatus) {
            console.log(res.resultData);
            createPreviewLine(
              item.routeId,
              res.resultData.previewMissionList,
              res.resultData.name
            );
            previewLineList.value.push(item);
            previewLineShow.value = true;
          }
        } else {
          console.log(item);
          const res = await dataRoute.taskPreviewRoute(item.taskId);
          if (res.resultStatus) {
            console.log(res.resultData);
            createPreviewLine(
              item.airwayId,
              res.resultData.previewMissionList,
              res.resultData.name
            );
            previewLineList.value.push(item);
            previewLineShow.value = true;
          }
        }
      },
      /* 定位航线 */
      locationLine: (id) => {
        findRouteLine(id);
      },
      /* 删除预览的航线 */
      delLine: (id) => {
        const index = previewLineList.value.findIndex((i) => {
          if (i.routeId) {
            return i.routeId === id;
          } else {
            return i.airwayId === id;
          }
        });
        if (index !== -1) {
          previewLineList.value.splice(index, 1);
          delPreviewLine(id);
        }
        if (previewLineList.value.length <= 0) {
          previewLineShow.value = false;
        }
      },
      /* 关闭删除所有的航线 */
      delAllRoute: () => {
        const allRouteId = previewLineList.value.map(
          (item) => item.routeId || item.airwayId
        );
        for (let i = 0; i < allRouteId.length; i++) {
          delPreviewLine(allRouteId[i]);
          previewLineList.value = [];
        }
      },
      // 关闭详情
      closePreview: () => {
        state.previewShow = false;
      },
      /* 航线详情 */
      routeLineDetail: async (item, type = "route") => {
        if (type === "route") {
          state.previewId = item.routeId;
          state.previewType = "route";
        } else {
          state.previewId = item.taskId;
          state.previewType = "task";
        }
        state.previewShow = true;
      },
      // 航线删除
      centerRouteDel: (item, type) => {
        let obj = {};
        obj.routeSn = state.centerRouteFrom.sn;
        obj.routeId = item.routeId;
        obj.isDistribution = type;
        // let ids = [id];
        // let res = await dataRoute.routeSetting({ ids });
        // if (res.resultStatus) {
        //   ElMessage.success(t("560"));
        //   methods.getItemRouteList();
        // }
        dataRoute.routeSetting(obj).then((res) => {
          if (res.resultStatus) {
            ElMessage.success(t("565"));
            methods.getItemRouteList();
          }
        });
      },
      // 机场计划任务列表
      executionList: () => {
        state.executionListShow = !state.executionListShow;
        methods.getPlanList();
      },
      // 机场终端任务 新增任务
      addExecution: () => {
        state.addExecutionShow = !state.addExecutionShow;
        const time = methods.getDateTime();
        const isTime = methods.getMinuteTime(time);
        state.addForm.execTime = isTime;
        state.addForm.deadLineTime = "19:00:00";
        methods.getItemRouteList();
        if (state.addExecutionShow && accessorySn.value != null) {
          getAudioList();
          emitter.on("speaker-bgm-list", bgmListHandle);
          emitter.on("speaker-audio-list", audioListHandle);
        }
      },
      //新建任务选择航线
      addSelectAirLine: (e) => {
        getRoutePoint(e);
        state.airUavList.map((item) => {
          if (item.routeId === e) {
            if (item.routeName.length > 20) {
              state.addForm.taskName = item.routeName.slice(0, 20);
            } else {
              state.addForm.taskName = item.routeName;
            }
          }
        });
      },
      // 参考时间
      // 关闭新增任务
      closeAddExecution: () => {
        state.addExecutionShow = false;
        closeBgmAduio();
        emptyData();
      },
      // 规则校验
      roleEditSubmit: () => {
        addFormRef.value.validate((valid) => {
          if (valid) {
            state.addForm.taskBind = state.centerRouteFrom.sn;
            state.addForm.loadOperationInfoDTO.accessorySn =
              accessorySn.value === null ? "" : accessorySn.value;
            if (state.audioVal === 2) {
              const tts = getTTS();
              state.addForm.loadOperationInfoDTO.ttsDTO.cycleStatus = tts;
              state.addForm.loadOperationInfoDTO.ttsDTO.peopleType =
                state.currLanguage;
              state.addForm.loadOperationInfoDTO.ttsDTO.tts = [
                state.bgmFrom.tts,
              ];
            } else {
              const audio = getAudio();
              state.addForm.loadOperationInfoDTO.audionPlay =
                state.bgmFrom.audionPlay != ""
                  ? state.bgmFrom.audionPlay + "," + audio
                  : "";
            }
            state.addForm.loadOperationInfoDTO.tzd = getTzd();
            state.addForm.loadOperationInfoDTO.ctl = getCtl();
            const bgm = getBgAudio();
            state.addForm.loadOperationInfoDTO.bgmPlay =
              state.bgmFrom.bgmPlay != ""
                ? state.bgmFrom.bgmPlay + "," + bgm
                : "";
            methods.editSubmit();
          } else {
            return false;
          }
        });
      },
      //保存新建任务
      editSubmit: async () => {
        const obj = state.addForm;
        let data = JSON.stringify(obj);
        let res = await taskManage.createTask(data);
        if (res.resultStatus) {
          ElMessage.success(t("566"));
          state.addExecutionShow = false;
          methods.getTasklist();
          closeBgmAduio();
          emptyData();
        }
      },
      // 视频监控 远程控制
      monitorChange: async (type, sn) => {
        if (type == "MONITOR") {
          if (state.currentPlayArr.length === 0) {
            // 视频监控
            sessionStorage.setItem("jessibucaZindex", 12);
            let res = await taskManage.getLiveBySn(state.centerRouteFrom.sn);
            if (res.resultStatus) {
              if (res.resultData.length <= 0) {
                ElMessage.info(t("567"));
                return;
              }
              state.aiArr = [];
              let obj = {};
              obj.id = "1";
              obj.playUrl = "";
              obj.playUrl = "";
              obj.liveName = t("568");
              obj.isAi = true;
              obj.isPlaying = false;
              state.aiArr.push(obj);
              state.videoShow = !state.videoShow;
              state.optionArr = res.resultData;
              state.optionArr[0].isShow = true;
              state.optionArr.forEach((e) => {
                if (!e.isShow) {
                  e.isShow = false;
                }
                e.Isclose = false;
              });
              state.currentPlayArr = [];
              state.currentPlayFlag = [];
              // state.videoShow = !state.videoShow;
              res.resultData[0].flag = 0;
              state.currentPlayFlag.push(0);
              state.currentPlayArr.push(res.resultData[0]);
              state.currentPlay = res.resultData[0];
            }
          } else {
            state.currentPlayArr = [];
            state.currentPlayFlag = [];
          }
        } else {
          //远程控制
          state.controlShow = !state.controlShow;
          if (state.controlShow === false) {
            visibleStage.value = false;
          }
          changeBg.value = true;
          const planeStatu = {
            action: "ACCEPT",
            code: "",
            data: ["STA_AF"],
          };
          websocketsend(planeStatu);
        }
      },
      // 飞行记录
      flyLogChange: (item, index, items) => {
        state.flyLogId = item.id;
        state.flyLogIndex = index;
        if (item.type == "AIRLOG") {
          state.flyLogList.datalist = [];
          state.flyLogShow = !state.flyLogShow;
          state.againFly = false;
          methods.getListExecLog(items.recordId);
          state.flyLogList.startDate = methods.getTaskTime(items.execTime);
        } else {
          // 再次飞行
          state.flyLogShow = false;
          state.againFly = !state.againFly;
          state.flyLogList.startDate = methods.getTaskTime(items.execTime);
          let endWaypointArr = [];
          if (items.endWaypoint == "") {
            state.againNum = 0;
            state.maxAgainNum = 0;
          } else {
            endWaypointArr = items.endWaypoint.split("/"); //切割赋值
            state.maxAgainNum = endWaypointArr[1];
          }
        }
      },
      // 执行记录
      getListExecLog: async (recordId) => {
        let res = await taskManage.getListExecLog(recordId);
        if (res.resultStatus) {
          state.flyLogList.datalist = res.resultData;
        }
      },
      // 再次飞行航线点数加减
      againHandleChange: (value) => {
        state.againNum = value;
      },
      // 取消再次飞行
      closeAgainFly: () => {
        state.flyLogShow = false;
        state.againFly = false;
      },
      // 确认再次飞行
      submitAgainFly: () => {
        //这个if航点数据超过就警告
        // 否则就成功
        if (state.againNum > state.maxAgainNum || state.againNum == 0) {
          ElNotification({
            title: t("130"),
            message: t("569"),
            type: "warning",
            customClass: "notifyClass",
            showClose: false,
            duration: 3000,
          });
        } else {
          ElNotification({
            title: t("130"),
            message: t("570"),
            type: "success",
            customClass: "notifyClass",
            showClose: false,
            duration: 3000,
          });
        }
        state.againFly = false;
      },

      // 右下侧边栏
      //显示
      showCesium: () => { },
      // 隐藏
      hidenCesium: () => { },
      //关闭表体
      closeItemShow: () => {
        state.itemShow = false;
        state.weatherShow = false;
        state.controlShow = false;
        state.currentPlayArr = [];
        state.currentPlayFlag = [];
        changeBg.value = true;
        state.routeItemList = 0; //关闭后默认点亮第一
        state.itemNavId = 1; //重置航线 任务 飞行记录选中状态
        state.addExecutionShow = false; //关闭新增任务
        state.flyLogShow = false; //关闭飞行记录
        state.executionListShow = false; //关闭周期计划任务列表
        state.againFly = false; //再次飞行
      },
      // 鼠标拖动事件
      // getHeight: (e) => {
      //   // 这里有问题
      //   const height = document.getElementById("deviceBox");
      //   const startY = e.clientY;
      //   const heightH = height.offsetHeight;
      //   if (maxHeight.value == null || maxHeight.value < heightH) {
      //     sessionStorage.setItem("maxHeight", height.offsetHeight);
      //     maxHeight.value = height.offsetHeight;
      //   }
      //   document.onmousemove = function (e) {
      //     e.preventDefault();
      //     const distY = e.clientY - startY;
      //     let moveHeight = heightH + distY;

      //     moveHeight = moveHeight < 250 ? 250 : moveHeight; //设置最小高度
      //     moveHeight =
      //       moveHeight > maxHeight.value ? maxHeight.value : moveHeight; //最大高度

      //     height.style.height = moveHeight + "px";
      //   };
      //   document.onmouseup = function () {
      //     document.onmousemove = null;
      //     maxHeight.value = null;
      //   };
      // },

      // 隐藏机场列表
      aleft: (e) => {
        if (e === "left") {
          state.deviceLeftShow = false;
        } else {
          state.deviceLeftShow = true;
        }
      },
      // 移入
      moveIn: () => {
        rightMenuShow.value = false;
        cesiumMenuShow.value = true;
      },
      // 操作cesium点击事件
      cesiumChange: (item) => {
        switch (item.id) {
          case 1:
            methods.cesiumSearch(item);
            break;
          case 2:
            methods.cesiumPlace(item);
            break;
          case 3:
            methods.cesiumLayer(item);
            break;
          case 4:
            methods.cesiumTTD(item);
            break;
          case 5:
            methods.cesiumMapp(item);
            break;
          case 6:
            methods.cesiumEnlarge(item);
            break;
          case 7:
            methods.cesiumNarrow(item);
            break;
          default:
            break;
        }
      },
      //搜索
      cesiumSearch: (item) => {
        cesiumMenuShow.value = true;
        state.cesiumSearchIn = !state.cesiumSearchIn;
        state.cesiumTwoThree = false;
        state.cesiumRang = false;
        state.cesiumPunctuation = false;
        state.cesiumLayout = false;
        if (state.cesiumSearchIn) {
          cesiumId.value = item.id;
          cesiumStopMeasure();
        } else {
          cesiumId.value = 0;
        }
      },
      // 搜索定位
      flyToPlace: (e) => {
        cameraPosition(e);
      },
      spotDrop: () => {
        // 设置允许拖拽
        canMark.value = true;
      },
      //标点
      cesiumPlace: (item) => {
        cesiumId.value = item.id;
        cesiumMenuShow.value = true;
        state.cesiumPunctuation = !state.cesiumPunctuation;
        visiblePointingFlight.value = false;
        state.cesiumSearchIn = false;
        state.cesiumTwoThree = false;
        state.cesiumRang = false;
        state.cesiumLayout = false;
        if (state.cesiumPunctuation) {
          cesiumId.value = item.id;
          cesiumStopMeasure();
          // 注册地图标点事件
          registerMarkPoint((lng, lat, height) => {
            state.punctuationLng = lng;
            state.punctuationLat = lat;
          });
        } else {
          // 关闭标点
          cesiumId.value = 0;
          // 注销地图标点事件
          unRegisterMarkPoint();
        }
      },
      // 改变标记点信息-同步到cesium地球
      updateMarkParam: () => {
        updateCurrMark({
          lng: state.punctuationLng,
          lat: state.punctuationLat,
          text: state.punctuationName,
          color: state.punctuationColor,
        });
      },
      // 标点颜色选择
      colorChange: (item, index) => {
        state.punctuationIndex = index;
        state.punctuationColor = item;
        methods.updateMarkParam();
      },
      // 确定标点
      punctuationSubmit: () => {
        const uuid = uuidv4();
        // 提交标记，清除临时标记
        comfirmMarkPoint(
          uuid,
          state.punctuationLng,
          state.punctuationLat,
          (height) => {
            // 在这里进行后台提交操作

            // 标注点数据
            const pointData = {
              longitude: state.punctuationLng,
              latitude: state.punctuationLat,
              altitude: height,
              color: state.punctuationColor,
              name: state.punctuationName,
            };
            //发送实时绘制点数据
            drawDataSendWs("SPOT", uuid, pointData);
          }
        );
      },
      // 取消标点
      closePunctuation: () => {
        state.cesiumPunctuation = false;
        cesiumId.value = 0;
        state.punctuationIndex = 0;
        state.punctuationLng = "";
        state.punctuationLat = "";
        state.punctuationName = "";
        // state.punctuationColor = "";
        unRegisterMarkPoint();
      },
      //图层
      cesiumLayer: (item) => {
        cesiumId.value = item.id;
        cesiumMenuShow.value = true;
        state.cesiumLayout = !state.cesiumLayout;
        state.cesiumPunctuation = false;
        state.cesiumSearchIn = false;
        state.cesiumTwoThree = false;
        state.cesiumRang = false;
        if (state.cesiumLayout) {
          cesiumId.value = item.id;
          cesiumStopMeasure();
        } else {
          cesiumId.value = 0;
        }
      },
      //2D 3D 平面转换
      cesiumTTD: (item) => {
        cesiumMenuShow.value = true;
        state.cesiumTwoThree = !state.cesiumTwoThree;
        state.cesiumSearchIn = false;
        state.cesiumRang = false;
        state.cesiumPunctuation = false;
        state.cesiumLayout = false;
        if (state.cesiumTwoThree) {
          cesiumId.value = item.id;
          cesiumStopMeasure();
        } else {
          cesiumId.value = 0;
        }
      },
      // 切换地图
      twoThreeChange: (item) => {
        if (state.twoThreeId == item.id) {
          return;
        } else {
          state.twoThreeId = item.id;
          updateMap(item.type);
        }
      },
      /* 切换2d/3d */
      sceneModeChange: () => {
        const flag = toggleSceneMode(state.sceneMode);
        if (!flag) return;
        state.sceneMode = state.sceneMode === "2D" ? "3D" : "2D";
      },
      //
      // 测绘menu
      rangChange: (item) => {
        if (state.rangId === item.id) {
          // 不可重复点击
          return;
        }
        state.rangId = item.id;
        if (item.id == 1) {
          // 测距
          startMap("line");
        } else {
          // 侧面
          startMap("face");
        }
      },
      //测绘
      cesiumMapp: (item) => {
        cesiumMenuShow.value = true;
        state.cesiumRang = !state.cesiumRang;
        state.cesiumTwoThree = false;
        state.cesiumSearchIn = false;
        state.cesiumPunctuation = false;
        state.cesiumLayout = false;
        if (state.cesiumRang) {
          cesiumId.value = item.id;
          state.rangId = 0;
        } else {
          cesiumId.value = 0;
          endMap();
        }
      },

      //放大单击
      cesiumEnlarge: (item) => {
        cesiumId.value = item.id;
        cesiumMenuShow.value = true;
        state.cesiumRang = false;
        state.cesiumTwoThree = false;
        state.cesiumSearchIn = false;
        state.cesiumPunctuation = false;
        state.cesiumLayout = false;
        // 放大
        enlargeEarth();
        cesiumStopMeasure();
      },
      //缩小单击
      cesiumNarrow: (item) => {
        cesiumId.value = item.id;
        cesiumMenuShow.value = true;
        state.cesiumRang = false;
        state.cesiumTwoThree = false;
        state.cesiumSearchIn = false;
        state.cesiumPunctuation = false;
        state.cesiumLayout = false;
        // 缩小
        shrinkEarth();
        cesiumStopMeasure();
      },
      // // 放大长按
      cesiumCA: (item) => {
        cesiumId.value = item.id; //防止直接长按按钮消失
        clearInterval(cesiumTimer.value);
        cesiumTimer.value = setInterval(() => {
          enlargeEarth();
        }, 250);
      },
      // 长按缩小
      cesiumReduce: (item) => {
        cesiumId.value = item.id;
        clearInterval(cesiumTimer.value);
        cesiumTimer.value = setInterval(() => {
          shrinkEarth();
        }, 250);
      },
      // 松开
      End: () => {
        clearInterval(cesiumTimer.value);
      },
      getRemoteStatu: async (sn) => {
        const res = await device.getRemoteStatu(sn);
        openOrClose.value = res.resultData?.state;
        if (openOrClose.value) {
          const enterControl = {
            action: "REMOTE_CONTROL",
            code: "ENTER_EXIT_REMOTE",
            sn: sn,
            data: {
              status: 1,
            },
          };
          websocketsend(enterControl);
        }
      },
    });
    // 切换停止测距测面
    function cesiumStopMeasure() {
      endMap();
      state.rangId = 0;
    }
    /**
     * 文本超出拼接
     * @param {* 文本内容} text
     */
    function textHide(text) {
      if (text.length > 15) {
        return text.slice(0, 15) + "...";
      }
    }
    //监听右下侧边栏按钮
    watch([cesiumMenuShow, cesiumId], ([newName, newId]) => {
      let id = newId;
      let flg = false;
      if (id != 0) {
        flg = true;
      } else {
        flg = false;
      }

      if (flg) {
        cesiumMenuShow.value = true;
        rightMenuShow.value = false;
      } else {
        setTimeout(() => {
          cesiumMenuShow.value = false;
          rightMenuShow.value = true;
        }, 10000);
      }
    });
    // 飞行记录任务状态
    const strategy = {
      READY_TO_ISSUE: t("571"),
      UPLOADING: t("572"),
      READY_TO_EXECUTE: t("573"),
      START: t("563"),
      EXECUTING: t("88"),
      ROUTE_FINISH: t("574"),
      PHOTO_UPLOAD: t("575"),
      FINISH: t("576"),
      CANCEL: t("217"),
      EXECUTION_PAUSED: t("430"),
      TERMINATION: t("577"),
      FAIL: t("578"),
      WAIT_RETRY: t("579"),
      UNKNOWN: t("80"),
      TIME_OUT: t("580"),
      //任务详情
      CONTINUE: t("581"),
      STOP: t("582"),
      FIRST: t("583"),
      POSTPONE: t("584"),
    };
    const taskState = function (type) {
      return strategy[type];
    };
    // 监听改变盒子高度
    watch(
      () => maxHeight.value,
      (newName) => {
        let max = sessionStorage.getItem("maxHeight");
        if (newName != max) {
          maxHeight.value = max;
        }
      }
    );
    //关闭清空新建任务里面表单数据
    watch(
      () => state.addExecutionShow,
      (newVal) => {
        if (newVal == false) {
          state.addForm.taskName = "";
          state.addForm.taskRemark = "";
          state.addForm.airwayId = "";
          state.addForm.execTime = "";
          // state.addForm.deadLineTime = "";
          state.addForm.taskBind = "";
        }
      }
    );
    // 监听当前执行任务状态
    watch(
      () => airportInfo.value.nStatusName,
      (newName, oldName) => {
        if (oldName === t("91") && newName != t("91")) {
          setTimeout(() => {
            methods.getExecutionList();
            methods.getFlyList();
          }, 5000);
        }
      }
    );
    //监听在线离线
    watch(
      () => uavOnLine,
      (newName) => {
        if (newName.value.state == 0) {
          nextTick(() => {
            if (state.centerAirUavList.length > 0) {
              state.centerAirUavList.map((item) => {
                item.devices.map((items) => {
                  if (items.sn === newName.value.sn) {
                    items.state = newName.value.state;
                    updateCircle(newName.value.sn, newName.value.state); //刷新地球机场离线在线状态
                    onLineOffLine(0, items.deviceName, newName.value.isShow);
                  }
                });
              });
            }
          });
        }
        if (newName.value.state == 1) {
          nextTick(() => {
            if (state.centerAirUavList.length > 0) {
              state.centerAirUavList.map((item) => {
                item.devices.map((items) => {
                  if (items.sn === newName.value.sn) {
                    items.state = newName.value.state;
                    updateCircle(newName.value.sn, newName.value.state); //刷新地球机场离线在线状态
                    onLineOffLine(1, items.deviceName, newName.value.isShow);
                  }
                });
              });
            }
          });
        }
      },
      { deep: true }
    );
    function onLineOffLine(state, name, isShow) {
      if (isShow != 1) return;
      ElNotification({
        title: t("130"),
        message: `${state == 1 ? `${name}${t('585')}` : `${name}${t('586')}`}`,
        duration: 3000,
        customClass: "notifyStyles",
      });
    }
    watch(
      () => uavOnLine.value.remoteStatu,
      () => {
        openOrClose.value = uavOnLine.value.remoteStatu;
      }
    );

    // 绑定机场发送指令
    function websocketAction(sn) {
      //点击机场连接机场
      const action = {
        action: "BIND",
        code: "",
        data: sn,
      };
      websocketsend(action);
      //点开获取机场基本信息
      const planeStatu = {
        action: "ACCEPT",
        code: "",
        data: ["STA_BASIC", "STA_RTK", "STA_AF_BATTERY", "STA_WP", "STA_INFO"],
      };
      websocketsend(planeStatu);
    }

    //发送轨迹指令
    function trackCommand() {
      const planeStatu = {
        action: "ACCEPT",
        code: "",
        data: ["STA_RTK_START"],
      };
      websocketsend(planeStatu);
    }

    // 切换取消指令
    function cancelWebsocketAction() {
      const planeStatu = {
        action: "UN_ACCEPT_ALL",
        code: "",
      };
      websocketsend(planeStatu);
    }
    // 获取机场在线离线
    function onLineOrOffLine() {
      const action = {
        action: "BIND",
        code: "",
        data: "GENERAL_CHANNEL",
      };
      websocketsend(action);
    }

    const visibleLayar = []; //已经实例化过的图层列表
    /* 切换图层显示 */
    function changeLayarVisible(item) {
      const layerId = item.layerId;
      const isCheck = item.display;
      const layerUrl = item.layerUrl;
      const layerType = item.layerType;
      data.updateLayerDisplay(layerId, {
        display: isCheck,
      });
      if (visibleLayar.includes(layerId)) {
        // 选中过
        if (layerType === "TIF") {
          toggleImagery(layerId, isCheck);
        } else {
          toggle3DTiles(layerId, isCheck);
        }
      } else {
        // 没有选中过
        visibleLayar.push(layerId);
        // 初始化点云、b3dms或tif
        if (layerType === "TIF") {
          addImagery(layerId, layerUrl, item.tifMetaInfo, true);
        } else {
          add3DTiles(layerId, layerUrl, true);
        }
      }
    }

    /* 定位图层 */
    function zoomToLayer(item) {
      const layerId = item.layerId;
      if (visibleLayar.includes(layerId)) {
        if (item.layerType === "TIF") {
          zoomToImagery(layerId);
        } else {
          zoomTo3DTiles(layerId);
        }
      } else {
        ElMessage.warning(t("148"));
      }
    }

    const layerFilterWord = ref(""); //筛选图层关键词
    /* 筛选图层列表 */
    function filterLayer() {
      state.filterLayerList = state.layarList.filter((item) => {
        if (item.layerName.indexOf(layerFilterWord.value) !== -1) {
          return item;
        }
      });
    }

    /* 筛选图层输入框改变 */
    function layerChange() {
      if (layerFilterWord.value === "") {
        state.filterLayerList = state.layarList;
      }
    }
    //获取子组件传递过来的需要播放的ai数据  增加窗口所用
    const addAiPlay = (data, otherObj) => {
      nextTick(() => {
        data.isPlaying = true;
        let findResult = state.currentPlayArr.find(
          (item) => item.isAi === true
        );
        if (findResult) {
          return;
        }
        state.currentPlayArr.forEach((e, index) => {
          state.currentPlayFlag.push(e.flag);
          state.currentPlayFlag = Array.from(new Set(state.currentPlayFlag));
          //   data.flag = state.currentPlayArr.length
        });
        let arr = [];
        state.optionArr.forEach((a, index) => {
          arr.push(index);
        });
        state.currentPlayFlag.map((q) => {
          arr.splice(arr.indexOf(q), 1);
        });
        data.flag = arr[0];
        state.currentPlayArr.push(data);
        state.currentPlayFlag.push(data.flag);
        data.playUrl = otherObj.liveRtmpPath;
        data.wsPlayUrl = otherObj.playPath;

        data.rtcApi = otherObj.rtcApi;
        state.currentPlay = data;
        state.aiIsPlayIng = true;
      });
    };
    //获取子组件传递过来的需要播放的数据  增加窗口所用
    const addCurrentPlayJec = (data) => {
      nextTick(() => {
        state.currentPlayArr.forEach((e, index) => {
          state.currentPlayFlag.push(e.flag);
          state.currentPlayFlag = Array.from(new Set(state.currentPlayFlag));
          //   data.flag = state.currentPlayArr.length
        });
        let arr = [];
        state.optionArr.forEach((a, index) => {
          arr.push(index);
        });
        state.currentPlayFlag.map((q) => {
          arr.splice(arr.indexOf(q), 1);
        });
        data.flag = arr[0];
        state.currentPlayArr.push(data);
        state.currentPlayFlag.push(data.flag);
        state.currentPlay = data;
        changeStatusJec();
      });
    };
    //获取子组件传递过来的需要播放的数据  改变选择项所用
    const addCurrentPlaySel = (playId, data) => {
      nextTick(() => {
        state.currentPlayArr.forEach((e, index) => {
          if (e.id === playId) {
            if (state.currentPlayArr.length === 1) {
              data.flag = 0;
            } else {
              data.flag = index;
            }
            state.currentPlayArr[index] = data;
          }
        });
        state.currentPlay = data;
        changeStatusSel(playId);
      });
    };

    //接受视频组件的值  改变状态
    const changeStatusJec = () => {
      state.optionArr.forEach((e) => {
        state.currentPlayArr.forEach((a) => {
          if (e.liveName === a.liveName && e.id === a.id) {
            e.isShow = true;
          }
        });
      });
    };
    //接受视频组件的值  改变状态
    const changeStatusSel = (playId) => {
      state.optionArr.forEach((e) => {
        state.currentPlayArr.forEach((a) => {
          if (e.liveName === a.liveName && e.id === a.id) {
            e.isShow = true;
          }
          if (e.id === playId) {
            e.isShow = false;
          }
        });
      });
    };
    //接受视频组件的值  进行销毁
    const destroy = (data) => {
      state.currentPlayArr.forEach((e, index) => {
        if (e.id === data) {
          if (e.isAi) {
            state.aiIsPlayIng = false;
            e.isPlaying = false;
          }
          state.currentPlayArr.splice(index, 1);
          state.currentPlayFlag.splice(
            state.currentPlayFlag.indexOf(e.flag),
            1
          );
        }
      });
      state.optionArr.forEach((e) => {
        if (e.id === data) {
          e.isShow = false;
        }
      });
      if (state.currentPlayArr.length === 0) {
        sessionStorage.removeItem("jessibucaZindex");
        emitter.off("controlStreaing");
      }
    };
    const bodyWidth = ref(document.body.clientWidth); //获取屏幕宽度
    if (bodyWidth.value <= 1536) {
      state.jessibucawidth = 406;
      state.jessibucaheight = 270;
    } else {
      state.jessibucawidth = 525;
      state.jessibucaheight = 340;
    }
    window.onresize = function () {
      const bodyWidth = ref(document.body.clientWidth); //获取屏幕宽度
      if (bodyWidth.value <= 1536) {
        state.jessibucawidth = 406;
        state.jessibucaheight = 270;
      } else {
        state.jessibucawidth = 525;
        state.jessibucaheight = 340;
      }
    };
    let tenant = localStorage.getItem("tenant");
    tenant = tenant ? tenant : "DEFAULT";
    //绘制同步
    function groupDrawTheme() {
      // 获取租户
      //绑定通道
      const enterclose = {
        action: "BIND",
        code: "",
        data: tenant,
      };
      websocketsend(enterclose);

      //通知后端获取对应组绘制数据1
      const action = {
        action: "DRAW_SYNC",
        code: "NEED_DRAW",
        sn: tenant,
        data: { group: tenant },
      };
      websocketsend(action);
    }

    /**
     * 提交实时绘制数据
     * @param {*数据类型} typeValue
     * @param {*数据对象id} id
     * @param {*点/线数据} data
     * @param {*额外的数据} extraData (object | undefined)
     *        @param {*周长} perimeter
     *        @param {*面积} measureArea
     */
    function drawDataSendWs(typeValue, id, data, extraData) {
      const action = {
        action: "DRAW_SYNC",
        code: "SUBMIT_DRAW",
        sn: tenant,
        data: {
          type: typeValue,
          id: id,
          group: tenant,
          createUserId,
          param: {
            [typeValue.toLowerCase()]: data,
          },
        },
      };
      if (extraData) {
        const result = Object.assign(action.data, extraData);
        action.data = result;
      }
      console.log(action);
      websocketsend(action);
    }

    /**
     * websocket回调绘制同步点线
     * @param {*数据类型} typeValue
     * @param {*数据} data
     */
    function drawSync(typeValue, data) {
      if (typeValue === "spot") {
        data.forEach((item) => {
          drawMark(
            item.id,
            item.longitude,
            item.latitude,
            item.altitude,
            item.name,
            item.color
          );
        });
      }

      if (typeValue === "line") {
        drawLine(data);
      }
      if (typeValue === "noodles") {
        drawFace(data);
      }
    }

    function delDraw(data) {
      delLineEntity(data.id);
    }

    const mapTypeObj = {
      line: "LINE",
      face: "NOODLES",
      markPoint: "SPOT",
    };
    /* 删除绘制实体 */
    function deleteDraw(e) {
      const rightMenuBox = document.getElementById("rightMenuBox");
      const id = rightMenuBox.getAttribute("data-id"); //数据对象id
      const entityType = rightMenuBox.getAttribute("data-entityType"); //数据类型
      const type = mapTypeObj[entityType];
      const action = {
        action: "DRAW_SYNC",
        code: "DELETE_DRAW",
        sn: tenant,
        data: {
          group: tenant,
          id,
          type,
        },
      };
      websocketsend(action);
      delLineEntity(id);
      rightMenuBox.style.display = "none";
    }

    function hideRightMenuBox() {
      const rightMenuBox = document.getElementById("rightMenuBox");
      rightMenuBox.style.display = "none";
    }

    // 发送到聊天室
    function sendChat() {
      const id = rightMenuBox.getAttribute("data-id"); //数据对象id
      getMarkInfo(id, (data) => {
        emitter.emit("open-chatRoom", data);
      });
    }

    //刷新任务列表以及待执行列表
    function reloadTaskList() {
      methods.getTasklist();
      methods.getExecutionList();
    }

    //恢复航线
    function recover() {
      ElMessageBox.confirm(
        t("546") + t("471") + t("547"),
        t("130"),
        {
          confirmButtonText: t("218"),
          cancelButtonText: t("217"),
        }
      )
        .then(() => {
          const goAround = {
            action: "REMOTE_CONTROL",
            code: "NEST_GO_AROUND",
            sn: state.centerRouteFrom.sn,
            data: {},
          };
          websocketsend(goAround);
          ElMessage({
            message: t("587"),
            type: "success",
          });
        })
        .catch(() => {
          ElMessage(t("549"));
        });
    }

    emitter.on("position-mark", (data) => {
      console.log(data);
      positionMark(data.lng, data.lat, data.alt);
    });

    const { requestDevice } = hardwareDevice();
    /* 连接设备 */
    function connectDevice() {
      requestDevice();
      emitter.emit("ws-send", websocketsend);
    }

    const modeSn = ref(modelSn);
    const modeName = ref(modelName);

    // 清空上一个机场的航线轨迹
    function clearBeforeFlightPath() {
      finishTask();
      clearAirway();
    }
    /**
     * 可飞区 禁飞区 限高区逻辑代码 开始
     */

    const noFlyList = ref([]); //禁飞区数据集合
    const checkAll = ref(false);
    const isIndeterminate = ref(true);
    const handleCheckAllChange = (val) => {
      if (noFlyList.value.length === 0) {
        ElMessage.error(t("588"));
        checkAll.value = false;
        return;
      }
      if (val) {
        noFlyList.value.forEach((e) => {
          if (e.visible) {
            e.visible = false;
            changeNoFly(e);
          }
          e.visible = true;
          changeNoFly(e);
        });
      } else {
        noFlyList.value.forEach((e) => {
          e.visible = false;
          changeNoFly(e);
        });
      }
      isIndeterminate.value = false;
    };
    const no_fly_div_show = ref(false);
    function openNoFlyShow() {
      no_fly_div_show.value = !no_fly_div_show.value;
    }
    async function changeNoFly(data) {
      let showStatus = false;
      if (data.visible) {
        showStatus = true;
        createNoFlyCylinder(data, 500, "red");
      } else {
        showStatus = false;
        delNoFlyCylinder(data);
      }
      let res = await fence.setFenceIsShow({
        id: data.id,
        show: showStatus,
      });
    }
    //禁飞区结束
    const checkAllHeightLimit = ref(false);
    const isIndeterminateHeightLimit = ref(true);
    const handleCheckAllChangeHeightLimit = (val) => {
      if (heightLimitList.value.length === 0) {
        ElMessage.error(t("589"));
        checkAllHeightLimit.value = false;
        return;
      }
      if (val) {
        heightLimitList.value.forEach((e) => {
          if (e.visible) {
            e.visible = false;
            changeHeightLimit(e);
          }
          e.visible = true;
          changeHeightLimit(e);
        });
      } else {
        heightLimitList.value.forEach((e) => {
          e.visible = false;
          changeHeightLimit(e);
        });
      }
      isIndeterminateHeightLimit.value = false;
    };
    const heightLimitList = ref([]);
    const height_limit_div_show = ref(false);
    function openHeightLimitShow() {
      height_limit_div_show.value = !height_limit_div_show.value;
    }
    const heightLimitRegionHeight = ref(""); //限高区高度
    async function changeHeightLimit(data) {
      let showStatus = false;
      if (data.visible) {
        showStatus = true;
        createNoFlyCylinder(data, data.height, "yellow");
      } else {
        showStatus = false;
        delNoFlyCylinder(data);
      }
      let res = await fence.setFenceIsShow({
        id: data.id,
        show: showStatus,
      });
    }
    //限高区结束
    const yesFlyList = ref([]); //可飞区列表
    const checkAllYesFly = ref(false);
    const handleCheckAllChangeYesFly = async (val) => {
      let showStatus = false;
      if (val) {
        if (yesFlyList.value.length === 0) {
          ElMessage.error(t("590"));
          checkAllYesFly.value = false;
          return;
        } else {
          const data = await device.refreshPosition(control.sn);
          createYesFlyCylinder(
            true,
            data.resultData.longitude,
            data.resultData.latitude,
            yesFlyList.value[0].coordinateObj.radius,
            yesFlyList.value[0].coordinateObj.height,
            yesFlyList.value[0].coordinateObj.gird
          );
          showStatus = true;
        }
      } else {
        createYesFlyCylinder(
          false,
          0,
          0,
          yesFlyList.value[0].coordinateObj.radius,
          yesFlyList.value[0].coordinateObj.height,
          yesFlyList.value[0].coordinateObj.gird
        );
        showStatus = false;
      }
      let res = await fence.setFenceIsShow({
        id: yesFlyList.value[0].id,
        show: showStatus,
      });
    };
    //网格
    const checkAllGird = ref(false);
    const handleCheckAllChangeGird = async (val) => {
      if (val) {
        const airportData = await device.refreshPosition(control.sn);
        createYesFlyGrid(true, airportData, yesFlyList.value[0]);
      } else {
        createYesFlyGrid(false, airportData, yesFlyList.value[0]);
      }
    };

    // 自动登录定位机场
    function automatic(list, sn) {
      for (let i = 0; i < list.length; i++) {
        const device = list[i].devices;
        for (let j = 0; j < device.length; j++) {
          if (device[j].sn === sn) {
            state.activeNum.push(i);
            return device[j]
          }
        }
      }
      return null;
    }

    //新建任务负载操作
    function operationChange() {
      state.operationShow = !state.operationShow;
      nextTick(() => {
        let element = document.querySelector(".add_content");
        if (state.operationShow) {
          element.scrollTop = element.scrollHeight - element.clientHeight;
        }
      });
    }
    // 选择语种
    function chooseLanguage() {
      state.chooseLanguageShow = !state.chooseLanguageShow;
    }
    function changeLang(id) {
      let result = state.currLanguage.findIndex((item) => item == id);
      if (result !== -1) {
        state.currLanguage.splice(result, 1);
        return;
      }
      if (state.currLanguage.length < 3) {
        state.currLanguage.push(id);
      } else {
        state.currLanguage.shift();
        state.currLanguage.push(id);
      }
    }
    // 获取背景音

    // 获取音频
    // 请求获取音频列表数据
    function getAudioList() {
      const state = store.state;
      const vuexUserId = state.user.userInfo.id; //用户id
      websocketsend({
        action: "GET_AUDIO_LIST",
        code: vuexUserId,
        sn: accessorySn.value,
      });
    }
    function closeAudio() {
      websocketsend({
        action: "UN_ACCEPT",
        code: "",
        data: "OPUS_LIST",
      });
    }

    // 背景音乐列表
    const bgmOptions = ref([]);
    function bgmListHandle(dataInfo) {
      if (dataInfo.target === accessorySn.value) {
        // 机场sn一致
        const newList = [];
        for (let i = 0; i < dataInfo.data.length; i++) {
          const splitList = dataInfo.data[i].split("||");
          const item = {};
          item.bgmId = i + 1;
          item.bgmName = splitList[0];
          newList.push(item);
        }
        bgmOptions.value = newList;
      }
    }
    // 音频列表
    const audioOption = ref([]);
    function audioListHandle(dataInfo) {
      if (dataInfo.target === accessorySn.value) {
        // 机场sn一致
        const newList = [];
        for (let i = 0; i < dataInfo.data.length; i++) {
          const splitList = dataInfo.data[i].split("||");
          const item = {};
          item.audioId = i + 1;
          item.audioName = splitList[0];
          newList.push(item);
        }
        audioOption.value = newList;
      }
    }

    const tzdType = ref("");
    //获取喊话器型号
    function audioInfoHandle(datainfo) {
      tzdType.value = datainfo.data[4];
    }

    // 关闭订阅背景音和音频列表
    function closeBgmAduio() {
      emitter.off("speaker-audio-list", audioListHandle);
      emitter.off("speaker-bgm-list", bgmListHandle);
      closeAudio();
    }

    // 获取航点信息
    const routePointOptions = ref([]);
    async function getRoutePoint(id) {
      const res = await dataRoute.routeDetails(id);
      if (res.resultStatus) {
        const data = res.resultData.previewMissionList;
        const newList = [];
        for (let i = 0; i < data.length; i++) {
          const item = {};
          item.value = data[i].missionIndex;
          item.label = `${t('300')}${data[i].missionIndex}`;
          newList.push(item);
        }
        routePointOptions.value = newList;
      }
    }
    // 选择音频还是TTS
    function audiaoChange(e) {
      if (e === 2) {
        state.addForm.loadOperationInfoDTO.ttsDTO.speed = 1;
        state.addForm.loadOperationInfoDTO.audionPlay = "";
      } else {
        state.addForm.loadOperationInfoDTO.ttsDTO.speed = "";
        state.addForm.loadOperationInfoDTO.ttsDTO.cycleStatus = "";
        state.addForm.loadOperationInfoDTO.ttsDTO.tts = [];
        state.addForm.loadOperationInfoDTO.ttsDTO.peopleType = [];
      }
    }
    //TTS
    function getTTS() {
      return state.tts ? 1 : 0;
    }
    // 警示灯
    function getCtl() {
      return state.warninglights ? 1 : 0;
    }
    // 探照灯
    function getTzd() {
      return state.searchlight ? 1 : 0;
    }
    // 音频
    function getAudio() {
      return state.audio ? 1 : 0;
    }
    // 背景音
    function getBgAudio() {
      return state.bgAudio ? 1 : 0;
    }
    // 清空负载操作数据
    function emptyData() {
      state.operationShow = false;
      state.addForm.loadOperationInfoDTO.ttsDTO.speed = "";
      state.addForm.loadOperationInfoDTO.ttsDTO.cycleStatus = "";
      state.addForm.loadOperationInfoDTO.ttsDTO.tts = [];
      state.addForm.loadOperationInfoDTO.ttsDTO.peopleType = [];
      state.addForm.loadOperationInfoDTO.audionPlay = "";
      state.addForm.loadOperationInfoDTO.bgmPlay = "";
      state.addForm.loadOperationInfoDTO.ctl = "";
      state.addForm.loadOperationInfoDTO.endPoint = "";
      state.addForm.loadOperationInfoDTO.startPoint = "";
      state.addForm.loadOperationInfoDTO.tzd = "";
      state.bgmFrom.bgmPlay = "";
      state.bgmFrom.audionPlay = "";
      state.bgmFrom.tts = "";
      state.currLanguage = [0];
    }
    function startPointChange(e) {
      if (
        (e >= state.addForm.loadOperationInfoDTO.endPoint) &
        (state.addForm.loadOperationInfoDTO.endPoint != "")
      ) {
        ElMessage.warning(t("591"));
        state.addForm.loadOperationInfoDTO.startPoint = "";
      }
    }
    function endPointChange(e) {
      if (
        (e <= state.addForm.loadOperationInfoDTO.startPoint) &
        (state.addForm.loadOperationInfoDTO.startPoint != "")
      ) {
        ElMessage.warning(t("592"));
        state.addForm.loadOperationInfoDTO.endPoint = "";
      }
    }
    /* ------ 视频监控-面板控制 ------ */
    //绑定websocket的远程控制
    function bindContorlWS() {
      //打开无人机远控模式
      const enterControl = {
        action: "REMOTE_CONTROL",
        code: "ENTER_EXIT_REMOTE",
        sn: state.centerRouteFrom.sn,
        data: {
          status: 1,
        },
      };
      websocketsend(enterControl);
      openOrClose.value = true;
      ElMessage.success(t("552"));

      //打开控制主题
      const planeStatu = {
        action: "ACCEPT",
        code: "",
        data: ["STA_AF"],
      };
      websocketsend(planeStatu);
    }

    //关闭远控模式
    function closeFlyContorl() {
      const exitControl = {
        action: "REMOTE_CONTROL",
        code: "ENTER_EXIT_REMOTE",
        sn: state.centerRouteFrom.sn,
        data: {
          status: 0,
        },
      };
      websocketsend(exitControl);
      openOrClose.value = false;
      ElMessage.success(t("553"));
    }

    //点击面板发送WS数据
    function planeSendWs(yawVlaue, pitchValue) {
      let action = JSON.parse(JSON.stringify(control));
      action.code = "CONTROLLER_NEST_ATTITUDE";
      action.data["yaw"] = yawVlaue; // 云台控制 -- 偏航
      websocketsend(action);

      let action1 = JSON.parse(JSON.stringify(control));
      action1.data["pitch"] = pitchValue; // 云台控制 --俯仰
      websocketsend(action1);
    }
    /*------ 视频监控-面板控制end------ */

    // 能耗信息

    function energyChange() {
      state.showEnergy = !state.showEnergy;
    }

    // 起飞or控制授权
    async function getFlyControlList(sn) {
      const result = await platforms.getCommandcenter(sn);
      if (result.resultStatus) {
        console.log(result.resultData);
        if (result.resultData.length != 0) {
          for (let i = 0; i < result.resultData.length; i++) {
            const item = result.resultData[i];
            // 0起飞授权、1控制授权
            if (item.type === 1) {
              state.openControlAuth = true;//控制授权
              platformsInfo.value = item;
            } else {
              state.openFlyAuth = true;//起飞授权
              platformsInfo.value = item;
            }
          }
        }
      }
    }
    watch(
      () => platformsInfo, (newVal) => {
        if (Object.keys(newVal.value).length != 0) {
          if (newVal.value.type === 1) {
            state.openControlAuth = true;//控制授权
            platformsInfo.value = newVal.value;
          } else {
            state.openFlyAuth = true;//起飞授权
            platformsInfo.value = newVal.value;
          }
        }
      }, {
      deep: true
    })

    function closeAuth(type,result) {
      if (type === 'fly') {
        state.openFlyAuth = false;//起飞授权
        if(result){
          ElMessage.success(t("确认授权成功"));
        }else{
          ElMessage.success(t("取消授权成功"));
        }
      } else {
        state.openControlAuth = false;//控制授权
        if(result){
          ElMessage.success(t("确认授权成功"));
        }else{
          ElMessage.success(t("取消授权成功"));
        }
      }
    }

    return {
      ...toRefs(methods),
      ...toRefs(state),
      trajectory,
      height,
      cesiumMenuShow,
      rightMenuShow,
      cesiumId,
      cesiumTimer,
      showin,
      showflight,
      showout,
      maxHeight,
      addFormRef,
      rightRef,
      cesiumRef,
      changeBg,
      changeBackground,
      updateMarkParamPoint,
      isShow,
      activeShow,
      openOrClose,
      navListShows,
      navListHide,
      visible,
      visibleTest,
      visibleAuto,
      visibleAir,
      visibleMessage,
      visiblePointingFlight,
      visiblePicture,
      showDragDialog,
      showStatuMessage,
      showPointingFlight,
      controlFly,
      uavInfo,
      weatherInfo,
      taskInfo,
      controInfo,
      taskState,
      airplaneControl,
      longpress,
      stopsend,
      getTimeNow,
      photoOrVideo,
      showPhoto,
      showVideo,
      photoModel,
      videoModel,
      beginRecord,
      stopRecord,
      startRecord,
      finishRecord,
      timeFlag,
      recordTime,
      hour,
      minute,
      second,
      complZero,
      airportInfo,
      takephoto,
      handleSwitchChange,
      websocketsend,
      changeLayarVisible,
      zoomToLayer,
      layerFilterWord,
      filterLayer,
      layerChange,
      uavPanel,
      onLineOrOffLine,
      uavOnLine,
      deleteDraw,
      textHide,
      hideRightMenuBox,
      changeStatusJec,
      changeStatusSel,
      addCurrentPlayJec,
      addCurrentPlaySel,
      addAiPlay,
      destroy,
      sendChat,
      previewLineList,
      previewLineShow,
      reloadTaskList,
      recover,
      openStage,
      visibleStage,
      connectDevice,
      cameraType,
      changeCamerType,
      submitCamerType,
      changeZoom,
      // getModelContent,
      modeSn,
      modeName,
      checkAllGird,
      handleCheckAllChangeGird,
      checkAllYesFly,
      handleCheckAllChangeYesFly,
      yesFlyList,
      checkAllHeightLimit,
      isIndeterminateHeightLimit,
      handleCheckAllChangeHeightLimit,
      heightLimitList,
      height_limit_div_show,
      openHeightLimitShow,
      changeHeightLimit,
      checkAll,
      isIndeterminate,
      handleCheckAllChange,
      openNoFlyShow,
      changeNoFly,
      no_fly_div_show,
      noFlyList,
      accessorySn,
      operationChange,
      chooseLanguage,
      changeLang,
      bgmOptions,
      audioOption,
      routePointOptions,
      audiaoChange,
      startPointChange,
      endPointChange,
      tzdType,
      bindContorlWS,
      planeSendWs,
      closeFlyContorl,
      energyChange,
      lteInfo,
      platformsInfo,
      getFlyControlList,
      closeAuth,
      detourMission
    };
  },
});
</script>

<style lang="less">
.layoutTip {
  background: rgba(11, 79, 122, 1) !important;
  border: 1px solid rgba(11, 79, 122, 1) !important;
}

//标点
.elpopIcon.el-popover {
  --el-popover-padding: 0 !important;
}

.layoutTip.el-popper.is-light .el-popper__arrow::before {
  border: 1px solid rgba(11, 79, 122, 1) !important;
  background: rgba(11, 79, 122, 1) !important;
}

.notifyClass {
  background-color: #004375;
  border: 1px solid #004375;

  h2 {
    color: #ffffff;
  }

  p {
    color: #ffffff;
  }
}

//新建任务下拉框高度
.el-select-dropdown__wrap {
  max-height: 9.375rem !important;
}
</style>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
}

.popover_ul {
  color: #fff;
  border-radius: 2px;
  background: rgba(11, 79, 122, 0.8);
  // background: rgba(0, 28, 49, 0.8);
  border: 1px solid #51d2ff;

  li {
    width: 7.5rem;
    text-align: center;
    padding: 0.4375rem 0;
    cursor: pointer;
  }

  li:hover {
    background: rgba(11, 79, 122, 0.8);
    opacity: 0.9;
  }
}

.top {
  position: relative;

  .messageNav {
    width: 65rem;
    height: 2.8563rem;
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 11;

    &_left {
      display: flex;
      width: 8.125rem;
      height: 100%;
      background: linear-gradient(90deg,
          #126a9c 0%,
          rgba(70, 131, 246, 0) 100%);
      color: #ffffff;
      text-shadow: 0px 2px 6px #091c3b;
      padding: 0.8125rem 0.9375rem;
      position: relative;

      img {
        position: relative;
        top: 0.25rem;
        margin-left: 0.75rem;
      }

      .dropdown-data-style {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 1px;
        width: 8.13rem;
        margin-top: 2.25rem;
        z-index: 999;

        li {
          color: #ffffff;
          padding: 0.875rem 0.9375rem;
          text-decoration: none;
          text-shadow: 0px 2px 6px #091c3b;
          background: linear-gradient(90deg,
              #126a9c 0%,
              rgba(70, 131, 246, 0) 100%);
          margin-top: 0.1875rem;
        }
      }
    }

    &_middle {
      width: 42.875rem;
      height: 100%;
      background: radial-gradient(circle,
          #0786ae 0%,
          rgba(7, 134, 172, 0.2) 100%);
      margin-left: 0.1875rem;
      padding: 0.375rem 0 0.0625rem 0;

      .commonNavItem {
        display: flex;
        align-items: center;

        span {
          margin-left: 0.3125rem;
          text-shadow: 0px 2px 6px #091c3b;
          font-size: 1rem;
          color: #ffffff;
        }
      }
    }

    &_right {
      width: 8.125rem;
      height: 100%;
      background: linear-gradient(90deg,
          #126a9c 0%,
          rgba(70, 131, 246, 0) 100%);
      padding: 0.8125rem 0.9375rem;
      font-size: 1.125rem;
      color: #ffffff;
      text-shadow: 0px 2px 6px #091c3b;
      margin-left: 0.375rem;
    }
  }
}

#deviceBox {
  position: absolute;
  top: 3.75rem;
  left: 0.75rem;
  bottom: 0.9375rem;
  right: 0;
  width: auto;
  height: auto;
  z-index: 10;
  overflow: hidden;
  pointer-events: none;

  .device_left {
    width: 19.9375rem;
    height: 100%;
    background: rgba(11, 79, 122, 0.8);
    margin-right: 0.5rem;
    pointer-events: auto;
    z-index: 2;
    position: relative;

    .gull_left {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 1.375rem;
      height: 1.375rem;
      color: #ffffff;
      font-size: 1.375rem;
    }
  }

  .device_right {
    width: 19.9375rem;
    height: 100%;
    background: rgba(11, 79, 122, 0.1);
    position: relative;
    pointer-events: auto;
    z-index: 1;
  }

  /* 左移动画 */

  .leftSlide-enter-active {
    animation: left-slide-in 0.5s;
  }

  .leftSlide-leave-active {
    animation: left-slide-in 0.5s reverse;
  }

  @keyframes left-slide-in {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0%);
    }
  }

  .deviceTop {
    width: 100%;
    box-sizing: border-box;

    .tab {
      width: 100%;
      height: 3.5rem;
      box-sizing: border-box;

      .nav {
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 3.5rem;
        color: #ffffff;
        font-size: 1rem;

        span {
          width: 1.125rem;
          height: 1.125rem;
          border-radius: 50%;
          display: block;

          img {
            height: 100%;
            width: 100%;
            display: block;
            margin-left: 0.6875rem;
          }
        }
      }

      .navtive {
        background: #0e99c1;
      }
    }

    .search {
      height: 2.5rem;
      width: 100%;
      padding: 0.625rem 0.625rem;
      box-sizing: border-box;
      background: rgba(35, 163, 236, 0.4);

      /deep/.search-bar-wrap {
        height: 2rem !important;
      }

      /deep/.el-input__wrapper {
        height: 2rem !important;
        position: relative;
      }

      /deep/.el-input__inner {
        height: 2rem !important;
      }
    }

    .searchs {
      width: 100%;
      height: 2rem;
      line-height: 2.5rem;

      .filePut {
        width: 85%;
        height: 2rem;
        box-sizing: border-box;
        border: 0px solid #51d2ff;
        color: #ffffff;
        outline: 0;
        background: rgba(35, 163, 236, 0);
      }

      input::-webkit-input-placeholder {
        color: #a9b8c3;
      }

      .file_icon {
        width: 15%;
        height: 100%;
        font-size: 1.375rem;
        color: #ffffff;
        font-weight: 500;
        border-radius: 0px 0.25rem 0.25rem 0px;
      }
    }
  }

  .listBox {
    width: 100%;
    height: calc(100% - 6rem);
    overflow: hidden;
    overflow: auto;
    box-sizing: border-box;

    .empty {
      height: 3.75rem;
      width: 100%;
      padding: 0.625rem 0.625rem;
      color: #ffffff;
      line-height: 3.75rem;
      text-align: center;
      font-size: 0.875rem;
    }
  }

  .listCity {
    margin-right: 1rem;
    width: 1.1875rem;
    height: 1.1875rem;
    cursor: pointer;
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .citylist {
      .citytop {
        position: absolute;
        top: 1.25rem;
        left: 0;
        height: 0;
        width: 0;
        border-bottom: 0.9375rem solid #ffffff;
        border-left: 0.625rem solid transparent;
        border-right: 0.625rem solid transparent;
      }

      .cityBox {
        position: absolute;
        top: 1.875rem;
        left: -0.625rem;
        background: #ffffff;
        width: 7.5rem;

        .cityitem {
          width: 100%;
          height: 1.5625rem;
          line-height: 1.5625rem;
          text-align: center;
          font-size: 0.875rem;
          color: #333333;
        }

        .cityitem_bg {
          background: #cacaca;
          color: #138fcf;
        }
      }
    }
  }

  .collTitle {
    img {
      display: block;
      width: 1.4375rem;
      height: 0.9375rem;
      margin-left: 0.8125rem;
    }

    span {
      font-size: 0.875rem;
      margin-left: 1.0625rem;
      color: #00f5ff;
    }

    span:last-child {
      margin-left: 1.125rem;
      color: #ffffff;
    }
  }

  .coollItem {
    height: 4.1875rem;
    border-top: 1px solid #166fa8;
    box-sizing: border-box;

    .img_one {
      width: 1.5rem;
      height: 1.3125rem;
      margin-left: 0.125rem;
    }

    .img_two {
      width: 1.25rem;
      height: 1.5625rem;
      margin-left: 0.125rem;
    }

    .dingwei_icon {
      width: 3.125rem;
      height: 4.1875rem;
      line-height: 4.1875rem;
      text-align: center;
      color: #00f5ff;
      cursor: pointer;
    }

    .dingwei_hover:hover {
      background: rgba(35, 163, 236, 0.05);
    }

    .collLeft {
      color: #ffffff;
      //这里可能要做动态变化
      width: 10.625rem;
      font-size: 0.875rem;
      margin-left: 1.125rem;
    }

    .right {
      // width: calc(100% - 3.625rem- 2.3125rem);
      line-height: 1.25rem;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      display: -webkit-box;
      -webkit-line-clamp: 1; // 超出多少行
      -webkit-box-orient: vertical;
    }

    .collRight {
      margin-right: 1.25rem;
      font-size: 0.875rem;

      // .span_r {
      //   color: #e3232c;
      // }

      // .span_g {
      //   color: #23e350;
      // }
    }
  }

  .coollItem:last-child {
    border-bottom: 0;
  }

  .coll_bg {
    width: 100%;
    background: #0e99c1 !important;
  }

  // 折叠面板主体样式修改
  /deep/ .el-collapse {
    border-top: 0px solid #06151e !important;
  }

  /deep/.el-collapse .el-collapse-item {
    --el-collapse-border-color: #166fa8;
    --el-collapse-header-bg-color: rgba(11, 79, 122, 0) !important;
    --el-collapse-content-bg-color: rgba(11, 79, 122, 0) !important;
  }

  // 右侧图标
  /deep/ .el-collapse-item__arrow {
    font-weight: 500;
    // margin: 0 1.875rem 0 auto;
    color: #0e99c1;
    font-size: 1.25rem;
  }

  // 每个内容
  /deep/.el-collapse-item__content {
    padding-bottom: 0;
  }
}

.left_menu {
  position: absolute;
  left: 0.75rem;
  bottom: 0.75rem;
  z-index: 10;

  .gull_right {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1.375rem;
    height: 1.375rem;
    color: #ffffff;
    font-size: 1.375rem;
  }
}

//三维地球
.cesium_box {
  margin: 0 auto;
  height: 100%;

  #cesiumContainers {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

//列表内容
.itemContent {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  background: rgba(11, 79, 122, 0.9);

  // 监控
  .centerControl {
    position: absolute;
    top: 5.625rem;
    left: 20.3125rem;
    width: 3.125rem;
    height: 18.0625rem;

    .top {
      height: 8.75rem;
      width: 100%;
      margin-bottom: 0.5rem;
      background: rgba(11, 79, 122, 0.9);
      cursor: pointer;

      img {
        width: 1.8125rem;
        height: 1.6875rem;
        display: block;
      }

      span {
        font-size: 1rem;
        color: #ffffff;
        display: block;
        margin-top: 0.6875rem;
        writing-mode: vertical-lr;
      }
    }

    .tp_bg {
      background: rgba(14, 153, 193, 0.9);
    }

    .top:last-child {
      margin-bottom: 0;
    }
  }

  // 取消文字选中
  .closeSeleUse {
    -webkit-user-select: text !important;
    /* Safari */
    -ms-user-select: text !important;
    /* IE 10+ and Edge */
    user-select: text !important;
    /* Standard syntax */
  }

  // 头部容器
  .contentTopBox {
    width: 100%;
    box-sizing: border-box;

    // 标题
    .itemTop {
      padding-top: 0.5rem;
      height: 2.0625rem;
      margin-bottom: 0.5rem;

      // 监控
      .itemName {
        color: #ffffff;
        width: 18.0625rem;
        height: 1.4375rem;
        background: linear-gradient(to right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 10%,
            rgba(243, 255, 47, 0.3) 20%,
            rgba(243, 255, 47, 0.4) 30%,
            rgba(243, 255, 47, 0.5) 40%,
            rgba(243, 255, 47, 0.65) 50%,
            rgba(243, 255, 47, 0.5) 60%,
            rgba(243, 255, 47, 0.4) 70%,
            rgba(243, 255, 47, 0.3) 80%,
            rgba(0, 0, 0, 0) 90%,
            rgba(0, 0, 0, 0) 100%);
        text-align: center;
        margin-left: 1.125rem;
        box-sizing: border-box;
      }

      .itemClose {
        position: absolute;
        top: 0;
        right: 0;
        width: 0px;
        height: 0px;
        border-left: 2.5rem solid rgba(14, 153, 193, 0.4);
        border-right: 0 solid transparent;
        border-bottom: 2.6875rem solid transparent;
        height: 2.6875rem;
        transform: rotateY(180deg);

        .icon_close {
          position: absolute;
          top: 0;
          right: 1.25rem;
          color: rgba(54, 229, 255, 1);
          font-size: 1.25rem;
          cursor: pointer;
        }
      }
    }

    .itemUser {
      padding: 0 0.8125rem 0.3125rem 1.125rem;
      font-size: 0.75rem;
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid #166fa8;
      color: #ffffff;

      // 机场
      .itemFly {
        max-height: 19.75rem;
        box-sizing: border-box;

        .energy {
          color: #00ecf7;
          font-size: 0.875rem;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            margin-left: 0.3125rem;
            width: 19px;
            height: 18.3px;
          }
        }
      }

      // 终端
      .itemAir {
        max-height: 16rem;
        box-sizing: border-box;
      }

      // 终端机场信息
      .itemLeft {
        width: 10.8125rem;
      }

      .uavSpan {
        display: inline-block;
        position: absolute;
        top: -0.1875rem;
        left: 8.875rem;
        width: 88%;
      }

      // img
      .itemRight {
        width: 7.0625rem;
        height: 8.375rem;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          display: block;
        }
      }

      // 进度条
      .progress {
        /deep/.el-progress-bar__inner {
          background-color: #19ff19 !important;
        }

        /deep/.el-progress__text {
          color: #19ff19 !important;
          font-size: 0.75rem !important;
        }
      }
    }
  }

  //整体航线任务飞行记录整体容器
  .navSearchListBox {
    height: v-bind(height);
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    // border-bottom: 1px solid #166fa8;
    color: #ffffff;

    .itemLeft {
      width: 10.8125rem;
    }

    .itemRight {
      width: 7.0625rem;
      height: 8.375rem;

      img {
        height: 100%;
        width: 100%;
        display: block;
      }
    }

    .progress {
      /deep/.el-progress-bar__inner {
        background-color: #19ff19 !important;
      }

      /deep/.el-progress__text {
        color: #19ff19 !important;
      }
    }
  }

  .itemNav {
    margin-top: 0.125rem;
    margin-bottom: 0.1875rem;
    width: 100%;
    height: 3rem;
    background: #1d73ab;
    padding: 0 0.1875rem;
    color: #ffffff;

    .itemList {
      width: 6.625rem;
      text-align: center;
      line-height: 3rem;
    }

    .item_bg {
      width: 6.625rem;
      height: 2.625rem;
      line-height: 2.625rem;
      background: #0e99c1;
      border-radius: 0.5rem;
    }
  }

  .routeList {
    height: calc(100% - 3.125rem);
    overflow: hidden;

    .routeSearch {
      height: 2.375rem;
      background: rgba(35, 163, 236, 0.4);
      width: 100%;
      padding: 0.625rem 0.625rem;
      box-sizing: border-box;
      background: rgba(35, 163, 236, 0.4);

      /deep/.search-bar-wrap {
        height: 2rem !important;
      }

      /deep/.el-input__wrapper {
        height: 2rem !important;
      }

      /deep/.el-input-group__append {
        height: 2rem !important;
      }

      .listCity {
        margin-left: 0.875rem;
        margin-right: 0.5rem;
        width: 1.375rem;
        height: 1.375rem;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .searchs,
      .searchRw {
        width: 100%;
        height: 2rem;
        line-height: 2.5rem;
      }

      .searchRw {
        width: 15.625rem;
        height: 2rem;
        line-height: 2.5rem;
      }

      .searchAuv {
        width: 16.875rem;
        height: 2rem;
        line-height: 2.5rem;
      }

      .filePut {
        width: 85%;
        height: 2rem;
        box-sizing: border-box;
        border: 0px solid #51d2ff;
        color: #ffffff;
        outline: 0;
        background: rgba(35, 163, 236, 0);
        font-size: 0.875rem;
      }

      input::-webkit-input-placeholder {
        color: #a9b8c3;
      }

      .file_icon {
        width: 15%;
        height: 100%;
        font-size: 1.375rem;
        color: #ffffff;
        font-weight: 500;
        border-radius: 0px 0.25rem 0.25rem 0px;
      }
    }

    .taskDetail {
      position: absolute;
      left: 23.75rem;
      top: 12.5rem;
      height: 26.25rem;
      width: 21.875rem;
      background: rgba(11, 79, 122, 0.8);

      h4 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
        padding: 0.3125rem 0 0.3125rem 0;
        font-size: 0.875rem;

        .close_icon {
          cursor: pointer;
          margin-right: 0.625rem;
          margin-left: 7.5rem;
          color: #ffffff;
          font-size: 1rem;
        }
      }

      div {
        padding: 0.3125rem 0.625rem 0.3125rem 0.625rem;
        font-size: 0.875rem;
      }
    }

    // 待执行任务
    .renwu {
      height: 100%;

      .exeTitle {
        color: #00f5ff;
        height: 1.875rem;
        margin-left: 0.875rem;
        line-height: 1.875rem;
      }

      .executeList {
        height: 8.375rem;
        overflow: hidden;
        overflow: auto;
        color: #ffffff;

        .listItem {
          width: 100%;
          height: 4.1875rem;
          line-height: 2.625rem;
          font-size: 0.875rem;
          cursor: pointer;

          span {
            width: 1.6875rem;
            height: 1.6875rem;
            display: block;
            margin-left: 0.875rem;
            margin-right: 1.0625rem;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .tuo {
            height: 100%;
            width: 2.5rem;
            font-size: 1.25rem;
            color: #ffffff;

            .el-icon {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }

        .listItem_bg {
          width: 100%;
          background: #0e99c1;
        }
      }
    }

    .execute {
      height: 10.25rem;
    }

    // 终端任务列表
    .zhongduan {
      height: calc(100% - 10.25rem);

      .renwu_box {
        height: 100%;

        .renwuList {
          height: calc(100% - 4.25rem);
          overflow: hidden;
          overflow: auto;
          color: #ffffff;

          // 公共css
          .listItem {
            width: 100%;
            height: 4.1875rem;
            line-height: 2.625rem;
            font-size: 0.875rem;
            cursor: pointer;

            span {
              width: 1.6875rem;
              height: 1.6875rem;
              display: block;
              margin-left: 0.875rem;
              margin-right: 1.0625rem;

              img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }

            .tuo {
              height: 100%;
              width: 2.5rem;
              font-size: 1.25rem;
              color: #ffffff;

              .el-icon {
                width: 100% !important;
                height: 100% !important;
              }
            }
          }

          .listItem_bg {
            width: 100%;
            background: #0e99c1;
          }
        }
      }

      .renWuAdd {
        margin-right: 0.5rem;
        width: 1.375rem;
        height: 1.375rem;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    // 机场任务列表
    .jizang {
      height: calc(100% - 10.25rem);

      .dingshi_rw {
        height: 1.375rem;
        width: 1.375rem;

        // margin-left: 0.625rem;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .renwu_box {
        height: 100%;

        .renwuList {
          height: calc(100% - 4.25rem);
          overflow: hidden;
          overflow: auto;
          color: #ffffff;

          :deep(.el-collapse-item__header.is-active) {
            border-bottom-color: transparent;
            background: rgba(25, 151, 223, 0.2);
          }

          :deep(.el-collapse-item__header) {
            height: 2.8rem;
          }

          .renwuTitle {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            height: 1.875rem;
            margin-left: 0.875rem;
            line-height: 1.875rem;
          }

          // 公共css
          .listItem {
            width: 100%;
            height: 4.1875rem;
            line-height: 2.625rem;
            font-size: 0.875rem;
            cursor: pointer;
            color: #fff;
            border-top: 1px solid #166fa8;

            span {
              width: 1.6875rem;
              height: 1.6875rem;
              display: block;
              margin-left: 0.875rem;
              margin-right: 1.0625rem;

              img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }

            .tuo {
              height: 100%;
              width: 2.5rem;
              font-size: 1.25rem;
              color: #ffffff;

              .el-icon {
                width: 100% !important;
                height: 100% !important;
              }
            }
          }

          .listItem_bg {
            width: 100%;
            background: #0e99c1;
          }
        }
      }

      .renWuAdd {
        margin-right: 0.5rem;
        width: 1.375rem;
        height: 1.375rem;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    // 航线
    .routeItemList {
      margin-top: 0.3125rem;
      color: #ffffff;
      height: calc(100% - 2.6875rem);
      overflow: hidden;
      overflow: auto;

      .listItem {
        width: 100%;
        height: 2.625rem;
        line-height: 2.625rem;
        font-size: 0.875rem;
        cursor: pointer;

        span {
          width: 1.125rem;
          height: 1.25rem;
          display: block;
          margin-left: 0.875rem;
          margin-right: 1.0625rem;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        .tuo {
          height: 100%;
          width: 2.5rem;
          font-size: 1.25rem;
          color: #ffffff;

          .el-icon {
            width: 100% !important;
            height: 100% !important;
          }
        }

        .right {
          // width: calc(100% - 3.625rem- 2.3125rem);
          line-height: 1.25rem;
          // overflow: hidden; //超出的文本隐藏
          // text-overflow: ellipsis; //溢出用省略号显示
          // display: -webkit-box;
          // -webkit-line-clamp: 1; // 超出多少行
          // -webkit-box-orient: vertical;
        }
      }

      .listItem_bg {
        width: 100%;
        background: #0e99c1;
      }

      // 飞行记录
      .fly {
        margin-left: 0.25rem;
        box-sizing: border-box;
        height: 12.1875rem;
        margin-bottom: 0.625rem;

        .flyLeft {
          height: 100%;

          .left {
            width: 3.125rem;
            height: 100%;
            background: rgba(35, 163, 236, 0.6);

            img {
              width: 2.4375rem;
              height: 1.4375rem;
              display: block;
            }
          }

          .center {
            width: 13.6875rem;
            height: 100%;
            padding-left: 0.6875rem;
            padding-top: 0.6875rem;
            padding-bottom: 0.6875rem;
            background: rgba(35, 163, 236, 0.5);
            font-size: 0.75rem;
          }
        }

        .flyRight {
          height: 100%;
          padding: 0.125rem 0 0.125rem 0.125rem;

          .rightList {
            width: 2.25rem;
            height: 5.875rem;
            cursor: pointer;
            background: #23a3ec;
            margin-bottom: 0.125rem;
            writing-mode: vertical-lr;
          }

          .rightList_bg {
            background: rgba(35, 163, 236, 0.2);
          }

          .rightList:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// 飞行记录
.itemFlyLog {
  position: absolute;
  top: 28.125rem;
  left: 21.5625rem;
  bottom: 0.9375rem;
  width: 21.25rem;
  height: auto;
  font-size: 0.75rem;
  color: #ffffff;
  background: rgba(11, 79, 122, 0.9);

  .header {
    width: 100%;
    height: 1.8125rem;
    background: #2186c2;
    text-align: center;
    line-height: 1.8125rem;
  }

  .content {
    height: calc(100% - 1.8125rem);
    overflow: hidden;
    overflow: auto;

    .contentList {
      padding: 0.3125rem 0.3125rem;
      box-sizing: border-box;
      border: 1px solid #1a72a9;
      font-size: 0.75rem;
      border-top: 0px;

      .left {
        width: 5.125rem;
        text-align: center;
      }

      .right {
        width: 15.25rem;
        line-height: 1.25rem;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box;
        -webkit-line-clamp: 2; // 超出多少行
        -webkit-box-orient: vertical;
      }
    }
  }
}

// 再次飞行
.againFly {
  position: absolute;
  top: 30rem;
  left: 21.5625rem;
  bottom: 0.9375rem;
  width: 18.75rem;
  height: 12.375rem;
  font-size: 0.75rem;
  color: #ffffff;
  background: rgba(11, 79, 122, 0.9);

  .header {
    width: 100%;
    height: 1.8125rem;
    background: #2186c2;
    text-align: center;
    line-height: 1.8125rem;
    transition: all 1s;
  }

  .againBox {
    width: 100%;
    height: calc(100% - 1.8125rem);
    padding: 0.625rem 0.625rem;
  }

  .content {
    padding-top: 0.8125rem;
    margin-bottom: 4.0625rem;

    span {
      display: inline-block;
      margin-right: 0.375rem;
    }

    /deep/.el-input {
      --el-input-border-color: #51d2ff !important;
    }

    /deep/.el-input-number--large {
      width: 13.1875rem !important;
      height: 2.5rem !important;
      line-height: 2.5rem !important;
    }

    /deep/.el-input__wrapper {
      background-color: #001c31;
      height: 2.5rem !important;
    }

    /deep/.el-input__inner {
      height: 2.5rem !important;
      color: #ffffff;
    }

    /deep/.el-input-number__decrease {
      background: #002b4b;
      border-left: 1px solid #51d2ff !important;
      border-top: 1px solid #51d2ff !important;
    }

    /deep/.el-input-number__increase {
      background: #002b4b;
      border-left: 1px solid #51d2ff !important;
      border-bottom: 1px solid #51d2ff !important;
    }

    /deep/.el-input-number.is-controls-right .el-input-number__increase {
      height: 1.25rem;
      line-height: 1.25rem;
    }

    /deep/.el-input-number.is-controls-right .el-input-number__decrease {
      height: 1.25rem;
      line-height: 1.25rem;
    }
  }

  .add_btn {
    .lBtn {
      width: 4.625rem;
      height: 2rem;
      color: #ffffff;
      line-height: 2rem;
      text-align: center;
      background: #001c31;
      border: 1px solid #00f5ff;
    }

    .rBtn {
      margin-left: 1.625rem;
      width: 4.625rem;
      height: 2rem;
      color: #ffffff;
      line-height: 2rem;
      text-align: center;
      background: #00959b;
      border: 1px solid #00f5ff;
    }
  }
}

// 机场执行任务列表
.executionList_box {
  position: absolute;
  top: 37.1875rem;
  left: 21.5625rem;
  bottom: 0.9375rem;
  width: 21.25rem;
  height: auto;
  font-size: 0.75rem;
  color: #ffffff;
  background: rgba(11, 79, 122, 0.9);

  .renwuList {
    height: 100%;
    overflow: hidden;
    overflow: auto;
    color: #ffffff;

    .listItem {
      width: 100%;
      height: 4.1875rem;
      line-height: 2.625rem;
      font-size: 0.875rem;
      cursor: pointer;

      span {
        width: 1.6875rem;
        height: 1.6875rem;
        display: block;
        margin-left: 0.875rem;
        margin-right: 1.0625rem;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .tuo {
        height: 100%;
        width: 2.5rem;
        font-size: 1.25rem;
        color: #ffffff;

        .el-icon {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    .listItem_bg {
      width: 100%;
      background: #0e99c1;
    }
  }
}

// 机场终端任务新增任务
.addExecution {
  position: absolute;
  top: 0.625rem;
  left: 24.375rem;
  max-height: 41.8125rem;
  width: 37.5rem;
  font-size: 0.75rem;
  color: #ffffff;
  background: rgba(11, 79, 122, 0.9);
  box-sizing: border-box;
  z-index: 12;

  /deep/.common-input .el-input__wrapper {
    background: #001c31 !important;
  }

  .add_header {
    height: 3.25rem;
    padding: 1.1875rem 1.375rem;

    .title {
      font-weight: 400;
      color: #ffffff;
      font-size: 1rem;
    }

    .icon {
      font-weight: 400;
      color: #ffffff;
      font-size: 1.25rem;
    }
  }

  .add_content {
    box-sizing: border-box;
    padding: 0 1.375rem 0 1.375rem;
    height: auto;
    min-height: 442px;
    max-height: 29rem;
    overflow-y: scroll;

    /deep/.el-form-item .el-form-item__label {
      color: #ffffff;
      margin-bottom: 0.3125rem !important;
    }

    /deep/.el-form-item:nth-child(5) {
      margin-bottom: 0 !important;
    }

    /deep/ .el-input__inner {
      color: #ffffff;
    }

    /deep/.el-input .el-input__count {
      color: #ffffff !important;
    }

    /deep/.el-input .el-input__count .el-input__count-inner {
      background: #001c31 !important;
    }

    /deep/.el-textarea__inner {
      height: 5rem !important;
      background: #001c31 !important;
      color: #ffffff;
    }

    /deep/.el-textarea .el-input__count {
      color: #ffffff !important;
      background: #001c31 !important;
    }

    /deep/.el-date-editor.el-input {
      width: 100% !important;
    }

    .operation {
      margin-top: 0.3125rem;
      color: #11bbfa;
      font-size: 14px;
      cursor: pointer;

      .el-icon {
        font-size: 1.25rem;
        margin-left: 0.875rem;
      }
    }

    .operation-box {
      .operation-14size {
        font-size: 14px;
      }

      .audio-width {
        width: 135px;
      }

      .operation-empty {
        color: #f41313;
      }

      .operation-content {}

      .box-item {
        margin-bottom: 1.125rem;

        /deep/.el-textarea__inner {
          height: 7.5625rem !important;
        }
      }

      .audio-files {
        margin-right: 0.875rem;

        .el-switch {
          margin-left: 0.875rem;
        }
      }

      .tts-language {
        width: 5.1875rem;
        height: 1.5rem;
        background: #12caff;
        margin-right: 2.5rem;
        text-align: center;
        line-height: 1.5rem;
        cursor: pointer;
        position: relative;
      }

      .language-content {
        cursor: default;
        z-index: 999;
        position: absolute;
        top: 2.0625rem;
        left: -2.6875rem;
        width: 336px;
        height: 161px;
        overflow-y: auto;
        border-radius: 10px;
        background: #001c31;
        border: 1px solid #51d2ff;
        position: relative;

        .header {
          font-size: 12px;
          height: 1.875rem;
          line-height: 1.875rem;
          border-bottom: 0.0625rem solid #24b5ee;
        }

        .header-close {
          height: 1.875rem;
          line-height: 1.875rem;
          position: absolute;
          top: 0.25rem;
          right: 0.875rem;
          font-size: 16px;
          cursor: pointer;

          .el-icon {
            color: #00ecf7;
          }
        }

        .content {
          height: calc(100% - 1.875rem);
          overflow-y: auto;
          padding: 0 16px 0 18px;

          p {
            margin: 0.625rem auto 0.8125rem auto;
            color: #12caff;
            font-size: 12px;
          }

          .lang_ul {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            li {
              width: 60px;
              height: 26px;
              line-height: 26px;
              background: #24b5ee;
              border-radius: 5px;
              text-align: center;
              color: #fff;
              margin-bottom: 10px;
              margin-right: 18px;
              font-size: 12px;
            }

            li:nth-of-type(4n) {
              margin-right: 0;
            }

            li.active_li {
              background: #fff;
              color: #24b5ee;
            }
          }
        }
      }

      .tts-select {
        width: 5.25rem;
        height: 1.5rem;
        margin-left: 0.875rem;

        /deep/.el-input {
          --el-input-height: 1.5rem !important;
        }
      }

      .tts-speed {
        margin-right: 2.5rem;
      }

      .switch-mr45 {
        margin-right: 2.8125rem;
      }

      .load-route-text {
        font-size: 14px;
        margin-bottom: 0.625rem;
      }

      .load-route {
        padding: 0 1.5625rem 0 1.5625rem;
      }

      .load-route-width {
        width: 12.5rem;
      }

      .empty-div {
        margin: 0 0.625rem 0 0.625rem;
        width: 9.375rem;
        height: 0.25rem;
        background: #0a98c1;
      }
    }
  }

  .add_btn {
    margin-right: 1.25rem;
    margin-bottom: 1.25rem;
    margin-top: 0.625rem;

    .lBtn {
      width: 6.25rem;
      height: 2.5rem;
      color: #ffffff;
      line-height: 2.5rem;
      text-align: center;
      background: #001c31;
      border: 1px solid #00f5ff;
    }

    .rBtn {
      margin-left: 1.625rem;
      width: 6.25rem;
      height: 2.5rem;
      color: #ffffff;
      line-height: 2.5rem;
      text-align: center;
      background: #00959b;
      border: 1px solid #00f5ff;
    }
  }
}

.region_div {
  position: absolute;
  top: 0;
  right: 0.25rem;
  z-index: 12;

  .region_div_list {
    margin-top: 10px;
    width: 12rem;

    .no_fly_div {
      width: 12rem;
      height: 12rem;
      background: #0b476b;
      margin-top: 5px;
      border-radius: 5px;
      position: relative;

      .checkBox_div {
        height: 80%;
        overflow-y: scroll;
        box-sizing: border-box;

        .itemTop {
          padding: 0 0.875rem;
          margin-top: 1rem;
          position: relative;
          cursor: pointer;

          .region_text_text {
            color: #00f5ff;
            font-size: 1rem;
            display: inline-block;
            width: 45%;
            margin-left: 10px;
          }

          .region_text_del {
            color: #ffffff;
            font-size: 1rem;
            display: inline-block;
            position: absolute;
            right: 10px;
          }
        }

        .itemTop:hover {
          background: #38adf9;

          .region_text_text {
            color: #fff;
          }
        }

        .no_fly_name {
          width: 100%;
          padding: 10px 10px 0px 10px;

          span {
            display: inline-block;
            width: 30%;
          }

          :deep(.el-input__inner) {
            color: #000;
            border-radius: 5px;
          }
        }
      }
    }

    .region_text {
      display: inline-block;
      width: 100%;
      text-align: center;
      border-radius: 5px;
      background: #0b476b;
      height: 32px;
      cursor: pointer;
      position: relative;

      .checkbox1 {
        color: #fff;
        position: absolute;
        left: 10px;
      }

      .checkbox2 {
        color: #fff;
        position: absolute;
        right: 10px;
      }

      .icon_style {
        position: absolute;
        left: 90%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

// 右下侧边栏
.centerMenus {
  position: absolute;
  right: 0;
  bottom: 11rem;
  width: 0px;
  height: 0px;
  border-left: 1.625rem solid rgba(11, 79, 122, 0.6);
  border-right: 0 solid transparent;
  border-bottom: 1.25rem solid transparent;
  border-top: 1.25rem solid transparent;
  height: 7rem;
  transform: rotateY(180deg);
  cursor: pointer;

  .arrows {
    position: absolute;
    top: 1.5625rem;
    left: -1.375rem;
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
  }
}

.cesiumNav {
  position: absolute;
  right: 1.4375rem;
  bottom: 2.25rem;
  z-index: 22;

  .list {
    margin-bottom: 1.5rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .listLayout {
    width: 16.875rem;

    img {
      width: 2rem;
      height: 2rem;
      display: block;
    }
  }

  .listIcon {
    width: 18.625rem;

    img {
      width: 2rem;
      height: 2rem;
      display: block;
    }
  }

  .list:last-child {
    margin-bottom: 0;
  }
}

// 标点
/* popover重写内容样式 */
/deep/.popover_content {
  background: rgba(11, 79, 122, 0.9) !important;

  .el-form-item {
    margin: 0;
    padding: 0.5rem 0.875rem;
    border-bottom: 1px solid #1d73ab;

    .el-form-item__content {
      line-height: none;
      display: block;
    }
  }

  .el-form-item:last-child {
    border-bottom: 0px solid #1d73ab;
  }

  div {
    color: #00b1cb;
  }

  span {
    color: #35e1fb;
    margin-right: 0.8125rem;
  }

  .filePut {
    width: 10rem;
    height: 1.75rem;
    box-sizing: border-box;
    border: 1px solid #36e5ff;
    color: #ffffff;
    outline: 0;
    font-size: 0.875rem;
    background: #0d4460;
    border-radius: 0.25rem;
    padding-left: 0.6875rem;
  }

  input::-webkit-input-placeholder {
    color: #c9c9c9;
  }

  .el-input {
    padding: 0;
    display: initial;
  }

  .el-input__wrapper {
    background: #0d4460;
    box-shadow: 0 0 0 0;
    width: 10rem !important;
    border: 1px solid #36e5ff;
    height: 1.75rem !important;
    border-radius: 4px;
  }

  .el-input__inner {
    color: #ffffff;
  }

  .biaozu {
    .el-input__wrapper {
      width: 14.25rem !important;
    }
  }

  img {
    width: 3.125rem;
    height: 3.75rem;
  }

  .content_top {
    padding: 0.5rem 0.875rem !important;
    border-bottom: 1px solid #1d73ab;
    font-size: 1rem;
    color: #61ebff;
  }

  .colorSelect {
    margin-top: 5px;

    div {
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 14px;
      cursor: pointer;
    }

    .border_bg {
      border: 3px solid #ffffff;
    }
  }

  .el-button:first-child {
    background: rgba(11, 79, 122, 0.9);
    color: #61ebff;

    span {
      margin-right: 0;
    }
  }

  .el-button:last-child {
    background: #2b98de;

    span {
      color: white !important;
      margin-right: 0;
    }
  }
}

// 图层
.codeImg {
  width: 18.75rem;
  // height: 18.75rem;
  box-sizing: border-box;
  padding-top: 1.25rem;

  .elsearch {
    width: 100%;
    height: 2.25rem;
    padding: 0 0.875rem;

    // line-height: 2.25rem;
    .filePut {
      width: 13.25rem;
      height: 2.25rem;
      box-sizing: border-box;
      border: 0px solid #51d2ff;
      color: #c9c9c9;
      outline: 0;
      font-size: 0.875rem;
    }

    input::-webkit-input-placeholder {
      color: #c9c9c9;
    }

    .file_icon {
      width: 3.625rem;
      height: 2.25rem;
      color: #ffffff;
      background: #38adf9;
      border-radius: 0px 4px 4px 0px;
      font-weight: 500;
      font-size: 1.375rem;
    }

    /deep/.el-input {
      width: 100% !important;
    }

    /deep/.el-input__wrapper {
      width: 100% !important;
      border: 0px solid #51d2ff;
      box-shadow: 0 0 0 0;
      border-radius: 4px 0 0 4px;
    }

    /deep/.el-input__inner {
      width: 100% !important;
      height: 2.25rem !important;
    }
  }

  .laylist {
    height: 16.5625rem;
    overflow: hidden;
    overflow: auto;
    box-sizing: border-box;

    .itemTop {
      padding: 0 0.875rem;
      margin-top: 1.5rem;
      cursor: pointer;

      span {
        color: #00f5ff;
        font-size: 1rem;
      }
    }

    .itemTop:hover {
      background: #38adf9;

      span {
        color: #fff;
      }
    }
  }
}

// 地球搜索
.cesiumSearch {
  width: 25.625rem;
  height: 2rem;
  opacity: 0.7;
  position: absolute;
  right: 4.75rem;
  bottom: 23.125rem;
  z-index: 22;

  /deep/.common-input {
    border-radius: 0 !important;

    .el-textarea__inner,
    .el-input.is-disabled,
    .el-input__wrapper {
      border: 0px solid #51d2ff;
      box-shadow: 0 0 0 0;
      border-radius: 0px;
    }

    .el-input__inner {
      height: 2rem !important;
    }
  }

  .searchs {
    width: 100%;
    height: 2rem;
    line-height: 2.5rem;

    .filePut {
      width: 85%;
      height: 2rem;
      box-sizing: border-box;
      border: 0px solid #51d2ff;
      color: #c9c9c9;
      outline: 0;
      font-size: 1rem;
    }

    input::-webkit-input-placeholder {
      color: #c9c9c9;
    }

    .file_icon {
      width: 15%;
      height: 100%;
      font-size: 1.375rem;
      color: #ffffff;
      font-weight: 500;
    }
  }
}

// 标点
// .punctuation {
// }
// // 图层
// .layout {
// }
// 2D 3D
.two_three {
  width: 20.1875rem;
  height: 2rem;
  // opacity: 0.7;
  position: absolute;
  right: 4.75rem;
  bottom: 12.75rem;
  z-index: 22;

  .list {
    cursor: pointer;
    width: 6.4375rem;
    height: 100%;
    margin-right: 0.4375rem;
    background: rgba(50, 171, 249, 0.6);
    opacity: 0.7;
    line-height: 2rem;
    text-align: center;
    color: #ffffff;
  }

  .list:last-child {
    margin-right: 0;
  }

  .list_bg {
    background: #32abf9;
  }
}

// 测距
.stadio {
  width: 13.3125rem;
  height: 2rem;
  // opacity: 0.7;
  position: absolute;
  right: 4.75rem;
  bottom: 9.25rem;
  z-index: 22;

  .list {
    cursor: pointer;
    width: 6.4375rem;
    height: 100%;
    margin-right: 0.4375rem;
    background: rgba(50, 171, 249, 0.6);
    opacity: 0.7;
    line-height: 2rem;
    text-align: center;
    color: #ffffff;
  }

  .list:last-child {
    margin-right: 0;
  }

  .list_bg {
    background: #32abf9;
  }
}

//控制台
.control_box {
  width: 67.3125rem;
  position: fixed;
  bottom: 0;
  left: 1.875rem;
  right: 0;
  margin: 0 auto;
  z-index: 11;

  &_top {
    height: 2.875rem;
    width: 100%;

    display: flex;
    justify-content: space-between;

    .commonbtn {
      width: 7.6875rem;
      // height: 100%;
      background: rgba(4, 131, 194, 0.8);
      color: #ffffff;
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      text-shadow: 0px 2px 6px #091c3b;
      border: none;
    }

    .airplane_test {
      margin-left: 0.25rem;
    }

    .airplane_message {
      margin-left: 0.625rem;
      width: 42.875rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: radial-gradient(circle,
          #126a9c 0%,
          #0687ae 0%,
          rgba(70, 131, 246, 0.85) 100%);

      .common_span {
        color: #ffffff;
        text-shadow: 0px 2px 6px #091c3b;
        font-size: 1rem;
      }
    }

    .arrow {
      width: 5.625rem;
      height: 100%;
      border: none;
      margin-left: 0.25rem;
      background: rgba(4, 131, 194, 0.6);
      color: #ffffff;
      font-size: 1.5625rem;
    }

    .rotateBtn {
      transform: rotate(180deg);
    }
  }

  &_content {
    margin-top: 0.25rem;
    width: 100%;
    display: grid;
    grid-template-columns: 15.4375rem 14.3125rem 9.25rem 9.25rem 18.5625rem;
    grid-auto-rows: 2.6875rem 9.9375rem;
    gap: 0.125rem;

    .item:nth-child(-n + 5) {
      background: rgba(1, 97, 142, 0.8);
      padding: 0.75rem 1.0625rem;
      color: #57ed3f;
      font-size: 0.875rem;
    }

    .item:nth-child(n + 6) {
      background: rgba(1, 97, 142, 0.8);
    }

    .item6,
    .item10 {

      // user-select:none
      .sector {
        position: relative;
        user-select: none;
        width: 7.875rem;
        height: 7.875rem;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.5);

        .cool_background {
          position: absolute;
          top: -0.1875rem;
          width: 100%;
          height: 100%;
        }
      }

      .sector .box {
        position: absolute;
        width: 6.4375rem;
        height: 6.4375rem;
        border-radius: 50%;
        clip: rect(0px, 3.0625rem, 3.0625rem, 0px);
        background-color: #25b3ee;
      }

      .sector .box:hover {
        background: #777;
      }

      .sector .s1 {
        transform: rotate(45deg);
        cursor: pointer;
      }

      .sector .s1 p {
        transform: rotate(-45deg);
        position: absolute;
        top: 0.9375rem;
        left: 1.1875rem;
      }

      .sector .s2 {
        transform: rotate(135deg);
        cursor: pointer;
      }

      .sector .s5 {
        transform: rotate(135deg);
        cursor: pointer;
      }

      .sector .s2 p {
        transform: rotate(-130deg);
        position: absolute;
        top: 0.9375rem;
        left: 1.1875rem;
      }

      .sector .s5 p {
        transform: rotate(-135deg);
        position: absolute;
        top: 1.096rem;
        left: 1.06rem;
      }

      .sector .s3 {
        transform: rotate(-135deg);
        cursor: pointer;
      }

      .sector .s3 p {
        transform: rotate(135deg);
        position: absolute;
        top: 0.9375rem;
        left: 1.1875rem;
      }

      .sector .s4 {
        transform: rotate(-45deg);
        cursor: pointer;
      }

      .sector .s4 p {
        transform: rotate(45deg);
        position: absolute;
        top: 0.9375rem;
        left: 1.1875rem;
      }

      .sector .center {
        position: absolute;
      }

      // 变焦样式
      .zoom {
        width: 5.625rem;
        font-size: 0.875rem;
        justify-content: center;
        background: #1b92c3;
        border-radius: 4px;
        height: 7.6875rem;
        margin-left: 0.8125rem;

        .zoom-text {
          writing-mode: vertical-rl;
          font-size: 0.875rem;
          margin-right: 1rem;
        }

        .zoom-top,
        .zoom-center,
        .zoom-bottom {
          width: 1.4375rem;
          height: 1.4375rem;
          background: #25b3ee;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .zoom-top {
          border-radius: 0.3125rem 0.3125rem 0px 0px;
          margin-bottom: 0.875rem;
          font-size: 1.25rem;
        }

        .zoom-center {
          margin-bottom: 0.875rem;
          font-size: 1rem;
        }

        .zoom-bottom {
          border-radius: 0px 0px 0.3125rem 0.3125rem;
          font-size: 1.25rem;
        }
      }
    }

    .item7 {
      padding: 0.5rem 0.625rem;
      position: relative;

      .btnList {
        .item7_btn {
          width: 6.3125rem;
          height: 2.5rem;
          background: #1b92c3;
          border-radius: 4px;
          border: none;
          font-size: 0.875rem;
          font-family: Source Han Sans CN;
          color: #ffffff;
        }
      }

      .bottom {
        width: 13.125rem;
        height: 5.625rem;
        background: #1b92c3;
        border-radius: 4px;
        position: absolute;
        bottom: 0.5rem;

        .photo {
          height: 100%;
          color: #0ad845;

          span {
            font-size: 3.75rem;
          }
        }

        .video {
          height: 100%;
          width: 100%;
          position: relative;

          .record {
            position: absolute;
            top: 1px;
            left: 0.625rem;
          }

          .record-time {
            position: absolute;
            top: 4px;
            right: 0.625rem;
          }

          .record-icon {
            font-size: 3.125rem;
            color: #0ad845;
          }

          .stop {
            width: 2.625rem;
            height: 2.625rem;
            background: #f31e1e;
            border-radius: 50%;

            .stop-btn {
              width: 1rem;
              height: 1rem;
              background: #f4f4f4;
              border-radius: 2px;
            }
          }
        }
      }
    }

    .item8 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .common_btn {
        width: 7.875rem;
        height: 2.25rem;
        background: #1b92c3;
        border-radius: 4px;
        border: none;
        font-size: 0.875rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .item9 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .common_btn {
        width: 7.875rem;
        height: 2.25rem;
        background: #1b92c3;
        border-radius: 4px;
        border: none;
        font-size: 0.875rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  &_content1 {
    margin-top: 0.25rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 19.85%);
    grid-auto-rows: 2.6875rem 9.9375rem;
    gap: 0.125rem;

    .item:nth-child(-n + 5) {
      background: rgba(1, 97, 142, 0.8);
      padding: 0.75rem 1.0625rem;
      color: #57ed3f;
      font-size: 0.875rem;
    }

    .item:nth-child(n + 6) {
      background: rgba(1, 97, 142, 0.8);
      display: grid;
      grid-template-columns: repeat(2, 5.625rem);
      grid-auto-rows: 2.25rem 2.25rem;
      justify-content: center;
      grid-row-gap: 1.625rem;
      grid-column-gap: 0.4375rem;
      padding: 1.8125rem 0;

      button {
        width: 5.625rem;
        height: 2.25rem;
        background: #1b92c3;
        border-radius: 4px;
        border: none;
        font-size: 0.875rem;
        font-family: Source Han Sans CN;
        color: #ffffff;
      }
    }

    .hiddenScrollbar::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  // 相机
  /deep/.el-select {
    width: 7.5rem;

    .el-input__wrapper {
      background-color: #10698b;
      border: none;
    }
  }
}

// 推流视频区域

/deep/.el-dialog__wrapper {
  pointer-events: none;
}

/deep/.el-dialog {
  pointer-events: auto;
}

.right_menu_box {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0px, 0px);
  width: 120px;
  background: rgba(13, 68, 96, 0.7);
  z-index: 100;

  .right_menu {
    li {
      padding: 8px;
      cursor: pointer;
      text-align: center;
    }

    li:hover {
      background: #2ba5e3;
    }
  }
}

.model_box {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0px, 0px);
  min-width: 7.5rem;
  background: rgba(13, 68, 96, 0.7);
  z-index: 100;

  .ul_box {
    padding: 0.625rem;
    font-size: 0.875rem;
    color: #fff;
  }
}

.overflow_ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.task_name {
  max-width: 13rem;
}

/* 预览航线盒子 */
.preview_line_box {
  font-size: 0.875rem;

  .row {
    text-align: center;

    .col1,
    .col3,
    .col4 {
      width: 15%;
    }

    .col2 {
      width: 55%;
    }
  }

  .box_hd {
    height: 1.625rem;
    line-height: 1.625rem;
    background: #002737;
  }

  .iconfont {
    font-size: 1rem;
  }

  .icon-shanchu {
    font-size: 1.125rem;
  }

  .box_bd {
    height: 8.875rem;
    background: rgba(22, 82, 120, 0.7);
    overflow: auto;

    .row {
      height: 2.25rem;
      line-height: 2.25rem;
    }

    .row:not(:last-child) {
      border-bottom: 0.0625rem solid #0f6090;
    }
  }
}

// 详情
.preview {
  position: absolute;
  top: 0;
  left: -5.55rem;
  right: -3.25rem;
  bottom: -1.875rem;
  z-index: 888;
  background: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;

  .close {
    height: 3.5625rem;

    .cicon {
      color: #ffffff;
      font-size: 1.75rem;
      margin-right: 4.4375rem;
    }
  }

  .previewBox {
    position: absolute;
    top: 5.125rem;
    left: 12.1875rem;
    right: 12.125rem;
    bottom: 5.1875rem;
  }
}
</style>
<style lang="less">
//控制弹出框样式
.el-popover.resetPopover {
  border: 1px solid #098cb2;
  background: #001c31;
  padding: 0.25rem 0.25rem;
  display: flex;
}

.popover-btn-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.375rem;
  width: 8.125rem;

  button {
    width: 2.8125rem;
    height: 2.1875rem;
    color: #fff;
    background: #098cb2;
    border: none;
    border-radius: 5px;
  }
}

.allBtn {
  display: flex;
  align-items: center;

  button {
    height: 100%;
    width: 2.5rem;
    color: #fff;
    background: #098cb2;
    border: none;
    border-radius: 5px;
  }
}

.notice-select {
  /deep/.el-select-dropdown__item.selected {
    color: #ffff;
  }
}

.notice-select .el-select-dropdown__item {
  text-align: center;
  padding: 0 0.2rem 0 0.2rem;
  font-size: 16px;
}

.notice-select .el-select-dropdown__item.selected {
  background: #12caff;
  color: #ffffff;
}

.tip-class {
  color: #000;
}
</style>
