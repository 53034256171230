<template>
  <drag-dialog
    v-model:visible="dialogVisible"
    :right="49.3"
    :top="3.8"
    :width="32"
    :height="17.5625"
    :title="t('513')"
  >
    <el-row class="ul_header">
      <el-col :span="12">{{ t('399') }}</el-col>
      <el-col :span="4">{{ t('395') }}</el-col>
      <el-col :span="4" style="text-align: center">{{ t('597') }}</el-col>
      <el-col :span="4" style="text-align: center">{{ t('610') }}</el-col>
    </el-row>
    <ul class="task_ul">
      <li v-for="(item, index) in taskData" :key="index" class="task_ul_item">
        <el-row>
          <el-col :span="12" class="overflow_text">{{ item.taskName }}</el-col>
          <el-col :span="4" class="execTime">{{ item.execTime }}</el-col>
          <el-col :span="4" style="text-align: center">
            {{ item.taskStatus }}
          </el-col>
          <el-col :span="4" style="text-align: center">
            <span
              class="blue_btn"
              @click="cancelTask('TERMINATION_TASKS', item.recordId)"
              >{{ t('217') }}</span
            >
          </el-col>
        </el-row>
      </li>
    </ul>
  </drag-dialog>
</template>

<script setup>
import { t } from '../../../languages';
import { ref, toRefs, watch } from "vue";
import taskManage from "@/network/task";
const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  sn: {
    type: String,
    default: "",
  },
});
const taskData = ref([]);
const getExecutionList = async () => {
  let res = await taskManage.getExecution(props.sn);
  if (res.resultStatus) {
    taskData.value = res.resultData;
    console.log(taskData.value);
  }
};
getExecutionList();



const emit = defineEmits(["update:isVisible",'reloadTable']);
const { isVisible } = toRefs(props);
const dialogVisible = ref(null);
dialogVisible.value = isVisible.value;
watch(dialogVisible, (newProps) => {
  dialogVisible.value = newProps;
  emit("update:isVisible", false);
});

// 取消执行
const cancelTask = async (type, taskId) => {
  let res = await taskManage.airportControl({
    action: type,
    nestSn: props.sn,
  });
  const res1 = await taskManage.cancelTask(taskId);
  if (res1.resultStatus) {
    ElMessage.success(t("564"));
    getExecutionList();
    emit('reloadTable')
  }
};


</script>

<style scoped lang="less">
.ul_header {
  color: #ffffff;
  padding: 0.8125rem 0.875rem;
  border-bottom: 1px solid #146995;
}
.task_ul {
  height: 11.25rem;
  overflow-y: auto;
  color: #ffffff;
  &_item {
    border-bottom: 1px solid #146995;
    padding: 0.8125rem 0.625rem 0.8125rem 0.875rem;
  }
  .execTime {
    position: relative;
    top: 0.1875rem;
  }
}
</style>
