<template>
  <drag-dialog
    v-model:visible="dialogVisible"
    :right="32"
    :top="3.8"
    :width="26"
    :title="t('516')"
    @close="updateAirworthiness"
  >
    <div class="box">
      <div class="box_top flex-sa" style="padding: 10px 0">
        <div class="item">
          <img src="@/asset/img/control-box/wendu.png" />
          <p class="mb-p">{{ t('366') }}</p>
          <div class="edit_box">
            <div class="word_con">
              <b>{{ elForm.maxTemperature ? elForm.maxTemperature : "-" }}℃</b>
            </div>
            <input
              type="text"
              class="edit_input"
              v-model="elForm.maxTemperature"
              autofocus
              @keyup="elForm.maxTemperature=elForm.maxTemperature.replace(/[^\d\.]/g,'')"
            />
          </div>
          <p class="common-p">~</p>
          <div class="edit_box">
            <div class="word_con">
              <b>{{ elForm.minTemperature ? elForm.minTemperature : "-" }}℃</b>
            </div>
            <input
              type="text"
              class="edit_input"
              v-model="elForm.minTemperature"
              autofocus
              @keyup="elForm.minTemperature=elForm.minTemperature.replace(/[^\d\.]/g,'')"
            />
          </div>
        </div>
        <div class="item">
          <img src="@/asset/img/control-box/shidu.png" />
          <p class="mb-p">{{ t('367') }}</p>
          <div class="edit_box">
            <div class="word_con">
              <b
                >{{
                  elForm.maxRelativeHumidity ? elForm.maxRelativeHumidity : "-"
                }}%</b
              >
            </div>
            <input
              type="text"
              class="edit_input"
              v-model="elForm.maxRelativeHumidity"
              autofocus
              @keyup="elForm.maxRelativeHumidity=elForm.maxRelativeHumidity.replace(/[^\d\.]/g,'')"
            />
          </div>
          <p class="common-p">~</p>
          <div class="edit_box">
            <div class="word_con">
              <b
                >{{
                  elForm.minRelativeHumidity ? elForm.minRelativeHumidity : "-"
                }}%</b
              >
            </div>
            <input
              type="text"
              class="edit_input"
              v-model="elForm.minRelativeHumidity"
              autofocus
              @keyup="elForm.minRelativeHumidity=elForm.minRelativeHumidity.replace(/[^\d\.]/g,'')"
            />
          </div>
        </div>
        <div class="item">
          <img src="@/asset/img/control-box/fengsu.png" />
          <p class="mb-p">{{ t('368') }}</p>
          <div class="edit_box">
            <div class="word_con">
              <b>{{ elForm.maxWindSpeed ? elForm.maxWindSpeed : "-" }}m/s</b>
            </div>
            <input
              type="text"
              class="edit_input"
              v-model="elForm.maxWindSpeed"
              autofocus
               @keyup="elForm.maxWindSpeed=elForm.maxWindSpeed.replace(/[^\d\.]/g,'')"
            />
          </div>
          <p class="common-p">~</p>
          <div class="edit_box">
            <div class="word_con">
              <b>{{ elForm.minWindSpeed ? elForm.minWindSpeed : "-" }}m/s</b>
            </div>
            <input
              type="text"
              class="edit_input"
              v-model="elForm.minWindSpeed"
              autofocus
               @keyup="elForm.minWindSpeed=elForm.minWindSpeed.replace(/[^\d\.]/g,'')"
            />
          </div>
        </div>
        <div class="item">
          <img src="@/asset/img/control-box/yuliang.png" />
          <p class="mb-p">{{ t('836') }}</p>
          <div class="edit_box">
            <div class="word_con">
              <b>{{ elForm.maxRainfall ? elForm.maxRainfall : "-" }}mm</b>
            </div>
            <input
              type="text"
              class="edit_input"
              v-model="elForm.maxRainfall"
              autofocus
              @keyup="elForm.maxRainfall=elForm.maxRainfall.replace(/[^\d\.]/g,'')"
            />
          </div>
          <p class="common-p">~</p>
          <div class="edit_box">
            <div class="word_con">
              <b>{{ elForm.minRainfall ? elForm.minRainfall : "-" }}mm</b>
            </div>
            <input
              type="text"
              class="edit_input"
              v-model="elForm.minRainfall"
              autofocus
              @keyup="elForm.minRainfall=elForm.minRainfall.replace(/[^\d\.]/g,'')"
            />
          </div>
        </div>
        <div class="item">
          <img src="@/asset/img/control-box/time.png" />
          <p class="time mb-p">{{ t('395') }}</p>
          <div class="edit_box">
            <div class="word_con">
              <b>{{
                elForm.maxExecutionTime ? elForm.maxExecutionTime : "-"
              }}</b>
            </div>
            <input
              type="text"
              class="edit_input"
              v-model="elForm.maxExecutionTime"
              autofocus
              @keyup="elForm.maxExecutionTime=elForm.maxExecutionTime.replace(/[^\d\:]/g,'')"
            />
          </div>
          <p class="common-p">~</p>
          <div class="edit_box">
            <div class="word_con">
              <b>{{
                elForm.minExecutionTime ? elForm.minExecutionTime : "-"
              }}</b>
            </div>
            <input
              type="text"
              class="edit_input"
              v-model="elForm.minExecutionTime"
              autofocus
              @keyup="elForm.minExecutionTime=elForm.minExecutionTime.replace(/[^\d\:]/g,'')"
            />
          </div>
        </div>
      </div>
    </div>
  </drag-dialog>
</template>

<script setup>
import { t } from '../../../languages';
import { reactive, ref, toRefs, watch } from "vue";
import { ElMessage } from "element-plus";
import device from "@/network/device";

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  sn: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:isVisible"]);
const { isVisible, sn } = toRefs(props);
const dialogVisible = ref(null);
dialogVisible.value = isVisible.value;
watch(dialogVisible, (newProps) => {
  dialogVisible.value = newProps;
  emit("update:isVisible", false);
});

const elForm = reactive({
  maxTemperature: 0,
  minTemperature: 0,
  maxWindSpeed: "",
  minWindSpeed: "",
  maxRainfall: "",
  minRainfall:"",
  maxRelativeHumidity:"",
  minRelativeHumidity: "",
  maxExecutionTime: "",
  minExecutionTime: "",
  maxIllumination: "",
  minIllumination: "",
});

/* 获取备降点信息 */
async function getAirworthiness() {
  const data = await device.getAirworthiness(sn.value);
  if (data.resultStatus) {
    const resultData = data.resultData;
    elForm.maxTemperature = resultData.maxTemperature ? resultData.maxTemperature : "",
    elForm.minTemperature = resultData.minTemperature ? resultData.minTemperature : "",
    elForm.maxWindSpeed = resultData.maxWindSpeed ? resultData.maxWindSpeed : "",
    elForm.minWindSpeed = resultData.minWindSpeed ? resultData.minWindSpeed : "",
    elForm.maxRainfall = resultData.maxRainfall ? resultData.maxRainfall : "",
    elForm.minRainfall = resultData.minRainfall ? resultData.minRainfall : "",
    elForm.maxRelativeHumidity = resultData.maxRelativeHumidity ? resultData.maxRelativeHumidity :"",
    elForm.minRelativeHumidity = resultData.minRelativeHumidity ? resultData.minRelativeHumidity : "",
    elForm.maxExecutionTime = resultData.maxExecutionTime ? resultData.maxExecutionTime : '';
    elForm.minExecutionTime = resultData.minExecutionTime ? resultData.minExecutionTime : '';
  }
}

getAirworthiness();

/* 修改备降点信息 */
async function updateAirworthiness() {
  const data = await device.updateAirworthiness(sn.value, elForm);
  if (data.resultStatus) {
    ElMessage.success(t("751"));
  }
  console.log(data);
}
</script>

<style scoped lang="less">
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  margin-top: 1.125rem;
  p {
    color: #ffffff;
    font-size: 12px;
  }
  .time {
    margin-top: 0.3438rem;
  }
  .common-p {
    transform: rotate(90deg);
    padding: 0.625rem 0;
  }
  .mb-p {
    margin-bottom: 1.75rem;
  }
  > .edit_box {
    font-weight: normal;
    margin: 13px 0;
    .word_con {
      height: 20px;
      line-height: 20px;
      text-align: center;
    }
    .edit_input {
      display: none;
      border: 0;
      outline: none;
      width: 100%;
      height: 20px;
      padding-left: 5px;
      border-radius: 2px;
    }
  }
  > .edit_box:hover {
    .word_con {
      display: none;
    }
    .edit_input {
      display: block;
    }
  }
}
</style>
