<template>
  <drag-dialog
    v-model:visible="dialogVisible"
    :right="44.25"
    :top="23.8"
    :width="25.0625"
    :height="22.5"
    :title="t('387')"
  >
    <div class="box">
      <div class="energy-btn">
        <div>
          {{ t('925') }}<el-switch
            v-model="airport"
            class="ml-2"
            :active-value="1"
            :inactive-value="0"
            style="
              --el-switch-on-color: #18f621;
              --el-switch-off-color: #6f6f6f;
            "
            @change="airportChange"
          />
        </div>
        <!-- <div>
          气象杆低功耗<el-switch
            v-model="pole"
            class="ml-2"
            :active-value="1"
            :inactive-value="0"
            style="
              --el-switch-on-color: #18f621;
              --el-switch-off-color: #6f6f6f;
            "
            @change="poleChange"
          />
        </div> -->
      </div>
      <div class="content">
        <div class="content-item">
          <div class="content-items">
            <p>{{ t('926') }}</p>
            <span>{{ energyInfo.voltage?energyInfo.voltage + 'V':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('927') }}）</p>
            <span>{{ energyInfo.batteryPercent?energyInfo.batteryPercent + '%':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('928') }}）</p>
            <span>{{ energyInfo.batteryQuantily?energyInfo.batteryQuantily + 'KW.h':'--' }}</span>
          </div>
        </div>
        <div class="content-item">
          <div class="content-items">
            <p>{{ t('929') }}</p>
            <span>{{ energyInfo.chC?energyInfo.chC.toFixed(1) + 'A':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('930') }}</p>
            <span>{{ energyInfo.pvV?energyInfo.pvV.toFixed(1) + 'V':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('931') }}</p>
            <span>{{ energyInfo.pvC?energyInfo.pvC.toFixed(1) + 'A':'--' }}</span>
          </div>
        </div>
        <div class="content-item">
          <div class="content-items">
            <p>{{ t('932') }}</p>
            <span>{{ energyInfo.pvCP?energyInfo.pvCP + 'W':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('933') }}</p>
            <span>{{ energyInfo.chSta?energyInfo.chSta:'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('934') }}</p>
            <span>{{ energyInfo.chP?energyInfo.chP + 'W':'--' }}</span>
          </div>
        </div>
        <div class="content-item">
          <div class="content-items">
            <p>{{ t('935') }}</p>
            <span>{{ energyInfo.time?energyInfo.time:'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('936') }}</p>
            <span>{{ energyInfo.mSta?energyInfo.mSta:'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('937') }}</p>
            <span>{{ energyInfo.invV?energyInfo.invV.toFixed(1) + 'V':'--' }}</span>
          </div>
        </div>
        <div class="content-item">
          <div class="content-items">
            <p>{{ t('938') }}</p>
            <span>{{ energyInfo.invC?energyInfo.invC.toFixed(1) + 'A':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('939') }}</p>
            <span>{{ energyInfo.invF?energyInfo.invF.toFixed(2) + 'Hz':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('940') }}</p>
            <span>{{ energyInfo.ldC?energyInfo.ldC.toFixed(1) + 'A':'--' }}</span>
          </div>
        </div>
        <div class="content-item">
          <div class="content-items">
            <p>{{ t('941') }}</p>
            <span>{{ energyInfo.ldAP?energyInfo.ldAP + 'W':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('942') }}</p>
            <span>{{ energyInfo.ldRP?energyInfo.ldRP + 'VA':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('943') }}</p>
            <span>{{ energyInfo.ldRat?energyInfo.ldRat + '%':'--' }}</span>
          </div>
        </div>
        <div class="content-item">
          <div class="content-items">
            <p>{{ t('944') }}</p>
            <span>{{ energyInfo.tempA?energyInfo.tempA.toFixed(1) + '℃':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('945') }}</p>
            <span>{{ energyInfo.tempB?energyInfo.tempB.toFixed(1) + '℃':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('946') }}</p>
            <span>{{ energyInfo.chAH?energyInfo.chAH + 'AH':'--' }}</span>
          </div>
        </div>
        <div class="content-item">
          <div class="content-items">
            <p>{{ t('947') }}</p>
            <span>{{ energyInfo.dchAH?energyInfo.dchAH + 'AH':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('948') }}</p>
            <span>{{ energyInfo.genE?energyInfo.genE.toFixed(1) + 'kWh':'--' }}</span>
          </div>
          <div class="content-items">
            <p>{{ t('949') }}</p>
            <span>{{ energyInfo.useE?energyInfo.useE.toFixed(1) + 'kWh':'--' }}</span>
          </div>
        </div>
      </div>
    </div>
  </drag-dialog>
</template>

<script setup>
import { t } from '../../../languages';
import { ref, watch, toRefs, onBeforeUnmount } from "vue";
import emitter from "@/utils/mitt";
import taskManage from "@/network/task";

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  sn: {
    type: String,
    default: "",
  },
});
const { isVisible, sn } = toRefs(props);
const emit = defineEmits(["update:isVisible"]);
const dialogVisible = ref(null);
dialogVisible.value = isVisible.value;
watch(dialogVisible, (newProps) => {
  dialogVisible.value = newProps;
  emit("update:isVisible", false);
});

const airport = ref(0);
const pole = ref(0);
const energyInfo = ref({});
// 打开监听
emitter.on("energy-info", getEnergyInfo);

onBeforeUnmount(() => {
  // 取消监听
  emitter.off("energy-info", getEnergyInfo);
});

function getEnergyInfo(info) {
  energyInfo.value = info.data;
  airport.value = energyInfo.value.nestLp;
  pole.value = energyInfo.value.wsLp;
}
// 机场低功耗
async function airportChange(val) {
  let airportObj = {
    action: "NEST_ENERGY_CONSUMPTION_CONTROL",
    nestSn: sn.value,
    data: val,
  };
  await taskManage.airportControl(airportObj);
}
// 气象杆
async function poleChange(val) {
  let poleObj = {
    action: "WEATHER_ENERGY_CONSUMPTION_CONTROL",
    nestSn: sn.value,
    data: val,
  };
  await taskManage.airportControl(poleObj);
}
</script>

<style lang='less' scoped>
.box {
  width: 25.0625rem;
  height: 20.0625rem;
  .energy-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.125rem;
    line-height: 3.125rem;
    border-bottom: 1px solid #166fa8;
    padding: 0 2.5625rem 0 1rem;
    color: #fff;
    font-size: 0.875rem;
    div {
      display: flex;
      align-items: center;
    }
    /deep/.el-switch {
      margin-left: 0.625rem;
    }
  }
  .content {
    height: 16.875rem;
    overflow: hidden;
    overflow-y: auto;
    font-size: 0.75rem;
    .content-item {
      margin: 1.0625rem 0 0.5rem 0;
      display: flex;
    }
    .content-items {
      flex: 1;
      text-align: center;
      p {
        margin-bottom: 0.625rem;
      }
    }
  }
}
</style>