<template>
  <drag-dialog
    v-model:visible="dialogVisible"
    :right="47"
    :top="3.8"
    :width="21.75"
    :height="30"
    :title="t('517')"
  >
    <div class="box">
      <div class="box-img-list">
        <div class="one" v-for="(item, index) in picList" :key="index">
          <div class="one-title check_box">
            <div class="img-title flex-sb" v-show="item.itemArr.length !== 0">
              <button>{{ index + 1 }}</button>
              <div v-show="downloadFlag">
                <label class="check_label">{{ t('676') }}</label>
                <el-checkbox
                  v-model="item.checked"
                  @change="childAllCheck(item.checked, index)"
                ></el-checkbox>
              </div>
            </div>
            <div class="one-picList">
              <div
                class="one-pic"
                v-for="(childItem, childIndex) in item.itemArr"
                :key="childIndex"
              >
                <el-image
                  v-if="childItem.metadata.mediaType!=12"
                  :src="childItem.imageUrl + '!t270x150'"
                  fit="cover"
                  @click="viewPic(index, childIndex)"
                ></el-image>
                <video id="firstVideo" preload="meta" v-else class="my-video" @click="clickVideo(childItem)">
                  <source :src="childItem.imageUrl+'#t=0.1'" type="video/mp4">
                </video>
                <div class="pic-message flex-sb">
                  <el-tooltip :content="childItem.metadata.mediaType!=12?childItem.imageName:childItem.metadata.fileName" raw-content>
                    <span>{{ childItem.metadata.mediaType!=12?childItem.imageName:childItem.metadata.fileName }}</span>
                  </el-tooltip>
                  <el-icon
                    class="hover_opacity"
                    style="color: #00f1fa"
                    @click="
                      downloadPic(childItem.imageUrl, childItem.metadata.mediaType!=12?childItem.imageName:childItem.metadata.fileName)
                    "
                    ><Download
                  /></el-icon>
                </div>
                <div class="check_box img_check_box" v-show="downloadFlag">
                  <el-checkbox-group
                    v-model="item.checkArr"
                    @change="childCheck(item.checkArr, index)"
                  >
                    <el-checkbox :label="childItem.imageId">{{}}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="bottom-btn" v-show="!downloadFlag">
        <button class="hover_opacity" @click="downloadFlag = true">
          批量下载
        </button>
      </div> -->

      <div class="cancel-enter" v-show="downloadFlag">
        <button class="cancel hover_opacity" @click="downloadFlag = false">
          {{ t('217') }}
        </button>
        <button class="enter hover_opacity" @click="batchDownload()">
          {{ t('218') }}
        </button>
      </div>
    </div>
    <imageviewer
      v-model:isVisible="imageviewerIs"
      v-if="imageviewerIs"
      :previewSrcList="srcList"
      v-model:initialIndex="initialIndex"
    ></imageviewer>
    <ele-dialog-video
    v-if="isVisebleVideo"
    :isVisebleVideo="isVisebleVideo"
    :dataUrl="dataUrl"
    @onClose="onClose"
  >
  </ele-dialog-video>
  </drag-dialog>
</template>

<script setup>
import { t } from '../../../languages';
import { ref, watch, toRefs } from "vue";
import taskManage from "@/network/task";
import { ElMessage, ElNotification } from "element-plus";
import download from "@/network/download";
import imageviewer from "./image-viewer.vue";
import EleDialogVideo from "../../../components/package/ele-dialog-video.vue";

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  recordId: {
    type: String,
    default: "",
  },
});
const { isVisible, recordId } = toRefs(props);
const picList = ref([]); //展示列表
const previewSrcList = ref([]); //预览列表
const firstVideo = ref('');
async function getPicture() {
  console.log(recordId.value);
  if (recordId.value === "") {
    return;
  } else {
    const data = await taskManage.getPicture(recordId.value);
    if (data.resultData instanceof Array) {
      console.log(data.resultData);
      for (let liValue of data.resultData) {
        if (!previewSrcList.value[liValue.metadata.waypointIndex]) {
          previewSrcList.value[liValue.metadata.waypointIndex] = [];
        }
        previewSrcList.value[liValue.metadata.waypointIndex].push(
          liValue.imageUrl
        );
        if (!picList.value[liValue.metadata.waypointIndex]) {
          picList.value[liValue.metadata.waypointIndex] = {
            checked: false,
            checkArr: [],
            itemArr: [],
          };
        }
        picList.value[liValue.metadata.waypointIndex].itemArr.push(liValue);
      }
      // //给不存在的航点赋值默认值
      for (let i = 0; i < picList.value.length; i++) {
        if (!picList.value[i]) {
          picList.value[i] = {
            checked: false,
            checkArr: [],
            itemArr: [],
          };
        }
      }
    }
  }
}
getPicture();

/* 单个下载图片 */
function downloadPic(imgsrc, name) {
  const notification = ElNotification({
    title: t("1"),
    message: t("1008"),
    duration: 0,
    customClass: "notifyStyles",
  });
  fetch(imgsrc, {
    method: "get",
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((data) => {
      notification.close();
      let blob = new Blob([data], { type: "image/jpeg" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => {
      notification.close();
      console.error("Error:", error);
    });
}

const downloadFlag = ref(false);
function childAllCheck(checked, index) {
  picList.value[index].checked = checked;
  if (checked === true) {
    // 全选
    picList.value[index].checkArr = picList.value[index].itemArr.map((item) => {
      return item.imageId;
    });
  } else {
    picList.value[index].checkArr = [];
  }
}

/* 单个照片复选框选择 */
function childCheck(checked, index) {
  console.log(checked);
  if (checked.length === picList.value[index].itemArr.length) {
    // 选中数量等于照片数量--该航点全选
    picList.value[index].checked = true;
  } else {
    // 只要不是全选--取消全选
    picList.value[index].checked = false;
  }
}

/* 批量下载 */
async function batchDownload() {
  let checkedIds = [];
  // 遍历航点
  for (let i = 0; i < picList.value.length; i++) {
    checkedIds = [...checkedIds, ...picList.value[i].checkArr];
  }
  /* 批量下载 */
  if (checkedIds.length <= 0) {
    ElMessage({
      message: t("1009"),
      type: "warning",
    });
  } else {
    const notification = ElNotification({
      title: t("1"),
      message: t("1008"),
      duration: 0,
      customClass: "notifyStyles",
    });
    await download.downloadSource(
      "/api/v1/tasks/records/images/download",
      {
        ids: checkedIds,
      },
      "application/zip",
      "post"
    );
    ElMessage({
      message: t("1010"),
      type: "success",
    });
    notification.close();
  }
}

const emit = defineEmits(["update:isVisible"]);

const dialogVisible = ref(null);
dialogVisible.value = isVisible.value;
watch(dialogVisible, (newProps) => {
  dialogVisible.value = newProps;
  emit("update:isVisible", false);
});

const srcList = ref([]); //预览图片数组
const imageviewerIs = ref(false); //预览弹窗
const initialIndex = ref(0); //预览弹窗
const isVisebleVideo = ref(false);

function viewPic(index, childIndex) {
  srcList.value = picList.value[index].itemArr;
  initialIndex.value = childIndex;
  imageviewerIs.value = true;
}
const dataUrl = ref('');
function clickVideo(item) {
  isVisebleVideo.value = true;
  dataUrl.value = item.imageUrl;
}
function onClose() {
  isVisebleVideo.value = false;
}

</script>

<style lang='less' scoped>
.box {
  flex-direction: column;
  height: 27.5rem;
  position: relative;
  .box-img-list {
    height: 24.5rem;
    overflow: auto;
    .one {
      padding: 0 0.8125rem;
      &-title {
        button {
          width: 2rem;
          height: 2rem;
          color: #fff;
          background: rgba(35, 163, 236, 0.4);
          border: none;
          margin-top: 8px;
        }
      }
      &-picList {
        display: flex;
        margin-top: 0.3125rem;
        flex-wrap: wrap;
        gap: 0.3125rem;
        .one-pic {
          position: relative;
          width: 9.75rem;
          height: 6.5625rem;
          /deep/ .el-image {
            width: 100%;
            height: 100%;
          }
          .my-video{
            width: 100%;
            height: 100%;
            position: relative;
            cursor: pointer;
          }
          .pic-message {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 1.4375rem;
            background: rgba(0, 0, 0, 0.8);
            padding: 0 0.5rem;
            span {
              font-size: 0.75rem;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .img_check_box {
            position: absolute;
            top: 0.1875rem;
            left: 0.1875rem;
          }
        }
      }
    }
  }

  .bottom-btn {
    button {
      height: 3rem;
      width: 100%;
      color: #fff;
      background: rgba(35, 163, 236, 0.9);
      border: none;
      position: absolute;
      bottom: 0;
      font-size: 1rem;
    }
  }
}
.cancel-enter {
  button {
    height: 3rem;
    width: 50%;
    border: none;
    color: #fff;
  }
  .cancel {
    background: rgba(25, 113, 169, 0.9);
  }
  .enter {
    background: rgba(35, 163, 236, 0.9);
  }
}
//重设el-checkbox的样式
.check_box {
  .check_label {
    font-size: 1rem;
    vertical-align: middle;
    margin-right: 1em;
    color: #36e5ff;
  }
  /deep/ .el-checkbox {
    height: auto;
    vertical-align: middle;
    .el-checkbox__input {
      .el-checkbox__inner {
        width: 1.25rem;
        height: 1.25rem;
        background: transparent !important;
        border-color: #fff;
        border-width: 1px;
        border-radius: 50%;
      }
      .el-checkbox__inner::after {
        display: none;
        position: absolute;
        top: 0.25rem;
        left: 0.25rem;
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
        background: #0090da;
        transform: none;
        border: 0;
        transition: display 0.15s;
      }
    }
    .el-checkbox__input.is-checked {
      .el-checkbox__inner {
        box-shadow: 0px 0px 3px 1px #0090da;
        border-color: #0090da;
      }
      .el-checkbox__inner::after {
        display: block;
      }
    }
  }
}
</style>