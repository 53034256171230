<template>
    <drag-dialog @close="addDetourListHandle" v-model:visible="dialogVisible" :right="60" :top="3.8" :width="21.25"
        :height="25" :title="t('绕飞任务')">
        <div class="detour">
            <div class="detour_box">
                <div class="detour_title" v-show="editorVisiable">执行电量:
                    <span>{{ electricity }}%</span>
                    <img @click="editorHandle" class="editor" src="../../../asset/img/editor.png" alt="">
                    <img @click.stop="addHandle" class="add" src="../../../asset/img/addDetour.png" alt="">
                </div>
                <div class="detour_title" v-show="!editorVisiable">执行电量:
                    <el-input type="number" @blur="editorElectricity" v-model="electricity" ref="editorRef"
                        @keyup.enter="enterBlurHandle($event)" />%
                    <img @click.stop="addHandle" class="add" src="../../../asset/img/addDetour.png" alt="">
                </div>
                <div class="detour_list">
                    <TransitionGroup name="list" tag="div" class="container">
                        <div class="item" @dragstart="dragstart($event, i)" @dragenter="dragenter($event, i)"
                            @dragend="dragend" @dragover="dragover" v-for="(item, i) in detourMission.list" :key="item.id"
                            draggable="true">
                            <img class="drag_img" src="../../../asset/img/drag.png" alt="">
                            <span class="overflow_text taskName">{{ item.taskName }}</span>

                            <img @click="delDetourList(i, item)" class="del-img" src="../../../asset/img/del.png" alt="">
                        </div>
                    </TransitionGroup>
                </div>


            </div>
        </div>
    </drag-dialog>
    <drag-dialog v-show="taskVisiable" @close="closeTaskListHandle" :right="38" :top="3.8" :width="21.25" :height="25"
        :title="t('415')">
        <div class="renwu_box" v-loading="taskLoading">
            <div class="renwuList" v-if="taskList.length > 0">
                <el-collapse>
                    <el-collapse-item :name="index" v-for="(items, index) in taskList" :key="index">
                        <template #title>
                            <div class="renwuTitle flex-a">
                                <span>{{ items.createUserName }}&nbsp;</span>
                                <span>{{ t('416') }}</span>
                            </div>
                        </template>
                        <div class="listItem flex-sb  " v-for="(item, index) in items.children" :key="index" @click=" routeItemChange(index, 'UAVTASK', item.taskId)
                            ">
                            <div class="flex-a">
                                <span>
                                    <img src="../../../asset/img/putong_jh.png" alt="" /></span>
                                <div class="flex flex-dc  task_item">
                                    <div class="overflow_text" style="
                                      font-size: 14px;
                                      height: 1.5625rem;
                                      line-height: 1.5625rem;
                                    " :title="item.taskName">
                                        {{ item.taskName }}
                                    </div>
                                    <div class="overflow_text"
                                        style="font- size: 12px;height:1.5625rem;line-height:1.5625rem">
                                        {{ t('403') }}：{{ item.execTime }}
                                    </div>
                                </div>

                                <!-- <span >

                                </span> -->

                            </div>
                            <div class="tuo flex-fe">
                                <img @click="addDetourList(item, index)" :class="{ 'disabele': item.checked }"
                                    src="../../../asset/img/addDetour.png" alt="" />

                            </div>

                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div v-else class="empty flex-fac" style="font-size: 0.875rem">
                {{ t('418') }}
            </div>
        </div>
    </drag-dialog>
</template>
  


<script setup>
import { t } from '../../../languages';
import { ref, toRefs, watch, reactive, onMounted, nextTick } from "vue";
import task from '@/network/task'
import { ElMessage, ElMessageBox } from "element-plus";
import { children } from "cesium";

const props = defineProps({
    isVisible: {
        type: Boolean,
        default: false,
    },
    sn: {
        type: String,
        default: "",
    },

});
const { sn, isVisible } = toRefs(props);
/* 控制编辑按钮 */
const editorVisiable = ref(true)
/* 控制编辑电量按钮视图 */
const editorRef = ref()

/* 电量 */
const electricity = ref(80)
/* 绕飞任务 */
const detourMission = reactive({
    list: []
})
/* 实现绕飞任务列表拖拽效果 */
let dragIndex = 0
/* 获取任务列表 */
const taskList = ref("")
// 任务列表加载
const taskLoading = ref(true)

/* 控制任务列表显示与隐藏 */
const taskVisiable = ref(false)

/* 拖拽效果 */
function dragstart(e, index) {
    e.stopPropagation()
    dragIndex = index
    setTimeout(() => {
        console.log(e);
        e.target.classList.add('moveing')
    }, 0)
}
function dragenter(e, index) {
    e.preventDefault()
    // 拖拽到原位置时不触发
    if (dragIndex !== index) {
        const source = detourMission.list[dragIndex];
        detourMission.list.splice(dragIndex, 1);
        detourMission.list.splice(index, 0, source);

        // 更新节点位置
        dragIndex = index
    }
}
function dragover(e) {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'
}
function dragend(e) {
    e.preventDefault()
    e.target.classList.remove('moveing')
}
/* 控制编辑按钮显示与隐藏 */
function editorHandle() {
    editorVisiable.value = false
    nextTick(() => {
        editorRef.value.focus()
    })
}
/* 添加绕飞任务 */
function addHandle() {
    taskVisiable.value = true
}
/* 关闭绕飞任务弹出  添加绕飞任务 回传默认电量80 */
async function addDetourListHandle() {
    let taskIds = []
    detourMission.list.map((item, index) => {
        taskIds.push(item.taskId)
    })
    console.log(detourMission.list);
    let result = await task.addDetourMission({ sn: sn.value, taskIds: taskIds })
    if (result.resultStatus) {
        ElMessage.success(t("751"));
    } else {
        ElMessage.error(t("1221"));
    }
    if (editorVisiable.value && detourMission.list.length > 0) {
        await task.updateLowPower(sn.value, electricity.value)

    }

}

/* 获取绕飞任务列表 以及电量 */
async function getDetourMissionList() {
    await task.getDetourMissionList(sn.value).then(res => {
        if (res.resultStatus) {
            detourMission.list = res.resultData

        } else {
            detourMission.list = []
        }
    });
    await task.getLowPower(sn.value).then(res => {
        if (res.resultStatus) {
            electricity.value = res.resultData == 0 ? 80 : res.resultData
        }
    })

}


onMounted(() => {
    getDetourMissionList()
    getTaskMenu()



})


// 获取任务记录列表
async function getTaskMenu() {
    taskLoading.value = true
    let res = await task.getCenterTaskList({
        sn: sn.value,
        keyword: ''
    })
    if (res.resultStatus) {
        taskList.value = sort_pro(res.resultData, ["createUserName"]);
        taskLoading.value = false
        taskList.value = task_list(detourMission.list, taskList.value)
    } else {
        taskList.value = []
    }
}

function sort_pro(data, keys = []) {
    //keys可以传一个数组
    var c = [];
    var d = {};
    for (var element of data) {
        element["checked"] = false
        let element_keyStr = "";
        let element_key = [];
        let element_keyObj = {};
        for (var key of keys) {
            element_key.push(element[key]);
            element_keyObj[key] = element[key];
        }
        element_keyStr = element_key.join("_");
        if (!d[element_keyStr]) {
            c.push({
                ...element_keyObj,
                children: [element],
            });
            d[element_keyStr] = element;
        } else {
            for (var ele of c) {
                let isTrue = keys.some((key) => {
                    return ele[key] != element[key];
                });
                if (!isTrue) {
                    ele.children.push(element);
                }
            }
        }
    }
    return c;
}

// 关闭任务列表面板
function closeTaskListHandle() {
    taskVisiable.value = false
}

// 控制绕飞任务列表添加
function addDetourList(item) {
    detourMission.list.push(item)
    taskList.value = task_pro(item, taskList.value)


}
// 移除绕飞任务
function delDetourList(index, item) {
    detourMission.list.splice(index, 1)
    taskList.value = task_pro(item, taskList.value)
}
/* 对数据回传做处理 */
function task_pro(item, task) {
    for (var i in task) {
        for (var j in task[i].children) {
            if (task[i].children[j].taskId == item.taskId) {
                task[i].children[j].checked = !task[i].children[j].checked
            }
        }
    }
    return task
}


function task_list(proTask, endTask) {
    for (let i in endTask) {
        for (let j in endTask[i].children) {
            proTask.map((item) => {
                if (item.taskId == endTask[i].children[j].taskId) {

                    endTask[i].children[j].checked = !endTask[i].children[j].checked
                }
            })

        }
    }
    return endTask
}

// 保存电量 默认值
async function editorElectricity(e) {
    editorVisiable.value = true
    if (e.target.value && e.target.value < 50) {

        e.target.value = 50
    }
    if (e.target.value && e.target.value > 100) {
        e.target.value = 100
    }
    if (electricity.value == '') {
        electricity.value = 80
    }
    else {
        electricity.value = Math.round(e.target.value);
    }
    await task.updateLowPower(sn.value, electricity.value).then(res => {
        if (res.resultStatus) {
            ElMessage.success(t("714"));
        } else {
            ElMessage.error(t("修改失败"));
        }
    })





}
/* 解决 编辑电量按下回车以及失去焦点 触发2次问题 */
function enterBlurHandle(event) {
    event.target.blur()

}

const emit = defineEmits(["update:isVisible"]);
const dialogVisible = ref(null);
dialogVisible.value = isVisible.value;
watch(dialogVisible, (newProps) => {
    dialogVisible.value = newProps;
    emit("update:isVisible", false);
});
</script>
  


<style scoped lang="less">
.detour {
    height: 100%;
}

.detour_box {
    width: 100%;
    height: calc(100% - 2.8rem);
    overflow: auto;

    .detour_title {
        padding: 15px 0.875rem 20px;
        font-size: 14px;
        border-bottom: 1px solid #146995;

        span {
            color: #57ED3E;
            margin-left: 4px;
        }

        .editor {
            color: #00ECF7;
            margin-left: 10px;
            width: 13px;
            cursor: pointer;
            vertical-align: middle;
        }

        .add {
            cursor: pointer;
            width: 19px;
            float: right;
            right: 19px;
            color: #00ECF7;
        }

        // input[type='number'] {
          
        // }

        .el-input {

            margin-right: 5px;
            background-color: #001C31;
            width: 43px;
            height: 23px;
            border: 1px solid #00ECF7;

            ::v-deep .el-input__wrapper {
                border-radius: 0px;
                box-shadow: 0 0;
                background-color: #001C31;

                .el-input__inner {
                    line-height: 1px !important;
                    color: #FFFFFF;
                    font-size: 14px;
                }
            }
        }
    }

    .detour_list {

        .item {
            border-bottom: 1px solid #146995;
            width: 100%;
            line-height: 38px;
            height: 38px;
            // line-height: 40px;
            cursor: pointer;
            color: #fff;
            font-size: 14px;
            box-sizing: border-box;
            display: flex;
            align-items: center;


        }

        .container {
            position: relative;
            padding: 0;
        }

        .moveing {
            width: 400px;
            opacity: 0;
        }

        .list-move,
        .list-enter-active,
        .list-leave-active {
            transition: all 0.2s ease;
        }
    }


}

.detour_box:hover {
    .detour_ul::-webkit-scrollbar {
        width: 0.6rem;
    }
}


.taskName {
    padding: 0 15px 0 7px;
    display: inline-block;
    height: 100%;
    width: 100%;
}



.drag_img {
    float: left;
    width: 17px;
    height: 13px;
    margin-left: 10px;
}

.del-img {
    float: right;
    width: 19px;
    height: 19px;
    margin-right: 15px;
}

.taskList {
    // overflow: hidden;
    overflow-y: auto;
    height: 100%;
    padding: 0;
    margin: 0;

    ::v-deep .el-collapse {
        border: transparent;

    }

    ::v-deep .el-collapse-item__header {
        background-color: transparent;
        border-bottom: 1px solid #146995;
        color: #fff;

        .name {
            margin-left: 15px;
        }

    }

}

.disabele {
    cursor: not-allowed;
    pointer-events: none;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;

}

::v-deep .el-loading-mask {
    background-color: transparent;
}



.renwu_box {
    height: 100%;

    .renwuList {
        height: calc(100% - 2.8rem);
        overflow: hidden;
        overflow: auto;
        color: #ffffff;

        :deep(.el-collapse-item__header.is-active) {
            border-bottom-color: transparent;
            background-color: #135E8C;

        }

        :deep(.el-collapse-item__wrap) {
            background-color: transparent;
            border-bottom: 0px
        }

        :deep .el-collapse {
            border: 0px;
        }

        :deep(.el-collapse-item__header) {
            height: 2.8rem;
            background: transparent;
            border-bottom: 1px solid #146995;

        }

        :deep .el-collapse-item__arrow {
            font-weight: 500;
            color: #0e99c1;
            font-size: 1.25rem;
        }

        :deep(.el-collapse-item__content) {
            padding: 0;
        }

        .renwuTitle {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            height: 1.875rem;
            margin-left: 0.875rem;
            line-height: 1.875rem;
        }

        // 公共css
        .listItem {
            width: 100%;
            height: 100%;
            line-height: 2.625rem;
            font-size: 0.875rem;
            cursor: pointer;
            color: #fff;
            padding-top: 15px;
            margin-bottom: 3px;
            background-color: transparent;

            .task_item {
                padding: 0 8px 0 11px;
                max-width: 15rem;

            }

            span {
                width: 1.6875rem;
                height: 1.6875rem;
                display: block;
                margin-left: 0.875rem;
                // margin-right: 1.0625rem;

                img {

                    width: 24px;
                    height: 100%;
                    display: block;
                }
            }

            .tuo {
                height: 100%;
                width: 19px;
                margin-right: 8px;


                img {
                    height: 19px;
                    width: 19px;
                }
            }


        }

        .listItem:last-child {
            margin-bottom: 13px;
        }

        .listItem_bg {
            width: 100%;
            background: #0e99c1;
        }
    }
}
</style>
  