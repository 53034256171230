<template>
  <div class="main">
    <div class="word_div clearfix">
      <span class="fl">{{ t('1304') }}：{{ audioInfo.audioModel }}</span>
      <span class="fr">{{ t('1305') }}：{{ audioInfo.audioStatus === true ? t('521') : t('520') }}</span>
    </div>
    <div class="word_div clearfix">
      <span class="fl">{{ t('1306') }}：{{ audioInfo.audioStatus === true ? (audioInfo.audioStatus ? audioInfo.speakState : t('1301')) : t('1307') }}</span>
    </div>
    <div class="word_div">
      {{ t('1153') }}：
      <span v-for="(item, index) in playSource" :key="index">
        {{
        audioInfo.speakState === t('599') ? '-' : playSourceDic["" + item]
      }}&nbsp;</span>
      <span v-show="playSource.includes('0')">-{{ audioInfo.audioName }}</span>
      <span v-show="playSource.length === 0">-</span>
    </div>
    <div class="slider">
      <span style="color: #1b92c3">{{
        audioInfo.currTime === 0 ? "00:00" : secondToMinute(audioInfo.currTime)
      }}</span>
      <div class="slider_con">
        <el-slider
          v-model="audioInfo.currTime"
          :show-tooltip="true"
          :format-tooltip="formatTooltip"
          :max="audioInfo.duration"
          :disabled="true"
          @change="sliderChange"
        />
      </div>
      <span style="color: #1b92c3">{{
        audioInfo.duration === 0 || audioInfo.audioStatus !== true ? "00:00" : secondToMinute(audioInfo.duration)
      }}</span>
    </div>
    <div class="flex_box">
      <span
        class="icon iconfont icon-diyiyeshouyeshangyishou hover_opacity"
        :title="t('1308')"
        @click="cutAudio('prev')"
      ></span>
      <span
        class="icon iconfont icon-bofang hover_opacity"
        :title="t('1291')"
        v-show="!playSource.includes('0') || audioInfo.isPlay === false"
        @click="playPause(true)"
      ></span>
      <span
        class="icon iconfont icon-zanting1 hover_opacity"
        :title="t('430')"
        v-show="playSource.includes('0') && audioInfo.isPlay === true"
        @click="playPause(false)"
      ></span>
      <span
        class="icon iconfont icon-zuihouyiyemoyexiayishou hover_opacity"
        :title="t('1309')"
        @click="cutAudio('next')"
      ></span>
      <div
        class="recording hover_opacity"
        :title="t('1310')"
        @click="stopAll"
      ></div>
      <div class="volume">
        <span class="icon iconfont icon-laba hover_opacity" :title="t('1302')"></span>
        <div class="volume_box">
          <div class="slider_con">
            <el-slider
              v-model="volume"
              :show-tooltip="true"
              @change="volumeChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { t } from '../../../../languages';
import { onBeforeUnmount, reactive, ref } from "vue";
import { secondToMinute } from "@/utils/utilFun.js";
import { toRefs } from "vue";
import speaker from "@/network/speaker.js";
import emitter from "@/utils/mitt";

const props = defineProps({
  audioList: {
    type: Array,
    default: () => {
      return [];
    },
  },
  sn: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["cut-audio"]);
const { sn, audioList } = toRefs(props);

/* 滑块进度条 */
const audioInfo = reactive({
  audioName: "-",
  currTime: 0,
  duration: 0,
  isPlay: false,
  speakState: t("1301"),
  audioModel: '-',  //挂载型号
  audioStatus: false  //挂载状态（在线/离线）
});

let playInterval = 0;
let endTimes = 0;
function autoPlay() {
  clearInterval(playInterval);
  playInterval = setInterval(async () => {
    if (audioInfo.currTime < audioInfo.duration) {
      // audioInfo.currTime += 1;
      // const res = await speaker.getAudioStatus(sn.value);
    } else {
      // 播放结束
      // endTimes++;
      // if (endTimes === 1) {
      //   // 第一次播放结束  -- 追进度
      //   const res =  await speaker.getAudioStatus(sn.value)
      //   console.log(res);
      // } else if (endTimes === 2) {
      //   // 第二次播放结束
      //   console.log(t("1311"));
      //   clearInterval(playInterval);
      //   audioInfo.isPlay = false;
      //   audioInfo.speakState = t("1301");
      // }
      clearInterval(playInterval);
      // audioInfo.isPlay = false;
      // audioInfo.speakState = t("1301");
    }
  }, 1000);
}

function playAudio(data) {
  audioInfo.duration = data.duration;
  audioInfo.audioName = data.audioName;
  // audioInfo.isPlay = true;
  // audioInfo.speakState = t("1300");
  // audioInfo.currTime = 0;
  autoPlay();
}

/* 播放暂停 */
function playPause(flag) {
  // if (flag && audioInfo.duration === 0) {
  //   return false;
  // }
  speaker
    .operateAudio(sn.value, {
      action: 'MP_SET_PLAY_AUDIO_C_ACTION',
      data: flag ? 2 : 1,
    })
    .then((res) => {
      if (res.resultStatus) {
        // audioInfo.isPlay = flag;
        // if (audioInfo.isPlay === true) {
        //   autoPlay();
        // } else {
        //   clearInterval(playInterval);
        //   // audioInfo.speakState = t("1301");
        // }
      }
    });
}

/* 全部停止 */
function stopAll() {
  speaker
    .operateAudio(sn.value, {
      action: 'MP_SET_PLAY_AUDIO_C_ACTION',
      data: 0,
    })
    .then((res) => {
      if (res.resultStatus) {
      }
    });
}

/* 格式化滑块拖拽条 */
function formatTooltip(val) {
  return secondToMinute(val);
}

/* 拖拽条改变 */
function sliderChange(val) {
  console.log(val);
}

const deviceStatusDic = {
  0: t("599"),
  1: t("1300"),
  2: t("1312"),
  3: t("1313"),
};
/* 设备状态信息处理函数 */
function deviceStatusHandle(data) {
  const info = data.data;
  if (!info || info.length <= 0) return false;
  const deviceStatus = info[0];
  const currVolume = info[4];
  // 设备当前状态
  audioInfo.speakState = deviceStatusDic[deviceStatus];
  emitter.emit("speaker-bgm-play", {
    isPlay: audioInfo.speakState, 
    playSource: playSource.value,
    volume: info[5]
  });
  // 设备当前音量
  volume.value = parseInt(currVolume);
  if (deviceStatus == 1) {
    audioInfo.isPlay = true;
  } else {
    audioInfo.isPlay = false;
  }
}

const playSourceDic = {
  0: t("1314"),
  1: t("447"),
  2: "tts",
  3: t("1315"),
  4: t("1316"),
};
const playSource = ref([]); //当前播放源
/* 播放源信息处理函数 */
function sourceHandle(data) {
  console.log(t('1317') , data.data)
  playSource.value = data.data;
  emitter.emit("speaker-bgm-play", {
    isPlay: audioInfo.speakState, 
    playSource: playSource.value
  });
}

let lastAudioIndex = -1;
/* 播放音频状态信息处理函数 */
function audioHandle(data) {
  const info = data.data;
  if (!info || info.length <= 0) return false;
  const audioIndex = parseInt(info[0]) - 1; //当前播放列表音频序号
  const progressRate = parseInt(info[2]); //进度
  if (audioIndex === -1) {
    // 无播放
    audioInfo.audioName = "";
  } else {
    if (lastAudioIndex != audioIndex) {
      // 索引不一致，音频改变
      const item = audioList.value[audioIndex];
      audioInfo.audioName = item.audioName;
      audioInfo.duration = item.duration;
    }
  }
  audioInfo.currTime = progressRate;
}

//获取挂载型号和挂载状态
function audioInfoHandle(dataInfo){
  audioInfo.audioModel = dataInfo.data[4]
  audioInfo.audioStatus = dataInfo.status
}

emitter.on("speaker-device-status", deviceStatusHandle);
emitter.on("speaker-source-status", sourceHandle);
emitter.on("speaker-audio-status", audioHandle);
emitter.on("speaker-audio-info", audioInfoHandle);

onBeforeUnmount(() => {
  emitter.off("speaker-device-status", deviceStatusHandle);
  emitter.off("speaker-source-status", sourceHandle);
  emitter.off("speaker-audio-status", audioHandle);
  emitter.off("speaker-audio-info", audioInfoHandle);
});

/* 切换音频文件 */
function cutAudio(cutType) {
  emitter.emit("cut-audio", cutType);
}

const volume = ref(0); //音量
/* 音量改变回调 */
function volumeChange(val) {
  speaker.operateAudio(sn.value, {
    action: "MP_SET_PLAY_V_ACTION",
    data: val,
  });
}

defineExpose({
  playAudio,
});
</script>

<style scoped lang="less">
.main {
  padding: 0 1.5rem;
  font-size: 0.875rem;
  --main-color: rgba(36, 181, 238, 1);
  .word_div {
    padding-top: 0.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.warning_word {
  color: rgba(252, 255, 0, 1);
}
.slider {
  display: flex;
  align-items: center;
  padding: 0.875rem 0;
  > span {
    color: var(--main-color);
  }
  .slider_con {
    width: 100%;
    padding: 0 0.375rem;
  }
}
.slider_con {
  :deep(.el-slider) {
    height: 6px;
    .el-slider__button-wrapper {
      height: 6px;
      width: 6px;
      top: -0.54rem;
      .el-slider__button {
        height: inherit;
        width: inherit;
        border: none;
      }
    }
    .el-slider__runway {
      height: 2px;
      .el-slider__bar {
        height: inherit;
        background-color: var(--main-color);
      }
    }
  }
}
.flex_box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .icon {
    font-size: 2rem;
  }
  .icon-24gl-repeat2 {
    font-size: 1.625rem;
  }
  .icon-24gl-repeatDot2 {
    font-size: 1.625rem;
  }
  .icon-laba {
    font-size: 1.75rem;
  }
  .mode_icon {
    position: relative;
    > i {
      position: absolute;
      top: -0.125rem;
      left: 0.7675rem;
      width: 0.125rem;
      height: 1.8rem;
      background: #fff;
      transform: rotate(-53deg);
      border-radius: 0.25rem;
    }
  }
  .icon-icon- {
    > i {
      top: 0.1rem;
      left: 0.8875rem;
    }
  }
}
.recording {
  position: relative;
  width: 2.125rem;
  height: 2.125rem;
  border: 0.125rem solid #fff;
  border-radius: 50%;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ff0000;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.1875rem;
  }
}
.volume {
  position: relative;
  .volume_box {
    display: none;
    height: 1.875rem;
    position: absolute;
    top: -0.0625rem;
    left: 1.1875rem;
    width: 5.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    // background: yellow;
    .slider_con {
      height: 100%;
    }
  }
}
.volume:hover {
  .volume_box {
    display: block;
  }
}
</style>
