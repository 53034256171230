<template>
  <drag-dialog
    v-model:visible="dialogVisible"
    :right="6.75"
    :top="0"
    :width="20.6875"
    :title="t('479')"
  >
    <div class="container">
      <div class="box">
        <div class="common-item">
          <p>{{ t('399') }}:</p>
          <p>
            <el-input
              v-model.trim="queryForm.taskName"
              maxlength="19"
              :placeholder="t('414')"
              class="filePut m2 common-input"
              @blur="taskBlur"
              @keyup="queryForm.taskName = queryForm.taskName.replace(/[ ]/g, '')"
            />
            <span v-if="taskIsTrue">{{ t('806') }}</span>
          </p>
        </div>
        <div v-if="!uavStatus" class="common-item1">
          <p>{{ t('966') }}:</p>
          <p>{{ airportHeight !== undefined ? airportHeight.toFixed(2) + "m" : "-" + "m"}}</p>
        </div>
        <div v-if="uavStatus" class="common-item1">
          <p>{{ t('967') }}:</p>
          <p>{{ queryForm.alt ? queryForm.alt + "m" : "-" }}</p>
        </div>
        <div v-if="uavStatus"  class="common-item1">
          <p>{{ t('968') }}:</p>
          <p>{{ queryForm.rlt ? queryForm.rlt + "m" : "-" }}</p>
        </div>
        <div class="common-item1">
          <p>{{ t('969') }}</p>
        </div>
      </div> 
      <div class="box-middle">
        <div class="common-item">
          <div class="flex">
            <div>
              <div class="flex_content">
                <p>{{ t('970') }}:</p>
                <p>
                  <el-input
                    v-model.trim="queryForm.longitude"
                    class="filePut m2 common-input"
                    :placeholder="t('277')"
                    maxlength="19"
                    @input="changeLong"
                    @blur.prevent
                  />
                  <span v-if="longitudeIsTrue">{{ longitudeText }}</span>
                </p>
              </div>
              <div class="flex_content">
                <p>{{ t('971') }}:</p>
                <p>
                  <el-input
                    v-model="queryForm.latitude"
                    class="filePut m2 common-input"
                    :placeholder="t('278')"
                    maxlength="19"
                    @input="changeLat"
                    @blur.prevent
                  />
                  <span v-if="latitudeIsTrue">{{ latitudeText }}</span>
                </p>
              </div>
            </div>
          </div>
            <img src="@/asset/img/airlinePlan/ears.png" draggable="true" @dragover="allowDrop" />
          
        </div>
        <div class="common-item other-item">
          <p>{{ t('972') }}:</p>
          <p>
            <el-select
              v-model="queryForm.behavior"
              class="m-2"
              :placeholder="t('973')"
              @change="behaviorChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <span v-if="behaviesIsTrue">{{ t('973') }}</span>
          </p>
          <span class="danwei"></span>
        </div>
        <div class="common-item">
          <p>{{ t('305') }}:</p>
          <p>
            <el-input
              v-model.trim="queryForm.PTZangle"
              class="filePut m2 common-input"
              :placeholder="t('974')"
              @input="changePTZangle"
              @blur.prevent
            />
            <span v-if="longIsTrue">{{ longText }}</span>
          </p>
          <span class="danwei"></span>
        </div>
        <div class="common-item">
          <p>{{ t('737') }}:</p>
          <p>
            <el-input
              v-model.trim="queryForm.height"
              class="filePut m2 common-input"
              :placeholder="t('975')"
              @input="changeHeight"
              @blur.prevent
            />
            <span v-if="heightIsTrue">{{ heightText }}</span>
          </p>
          <span class="danwei">m</span>
        </div>
        <div class="common-item">
          <p>{{ t('246') }}:</p>
          <p>
            <el-input
              v-model.trim="queryForm.speed"
              class="filePut m2 common-input"
              :placeholder="t('976')"
              @input="changeSpeed"
              @blur.prevent
            />
            <span v-if="speedIsTrue">{{ speedText }}</span>
          </p>
          <span class="danwei">m/s</span>
        </div>
        <div class="common-item">
          <p>{{ t('839') }}:</p>
          <p>
            <el-input
              v-model.trim="queryForm.course"
              class="filePut m2 common-input"
              :placeholder="t('977')"
              @input="changeCourse"
              @blur.prevent
            />
            <span v-if="courseIsTrue">{{ courseText }}</span>
          </p>
          <span class="danwei"></span>
        </div>
        <div class="common-item other-item">
          <p>{{ t('978') }}:</p>
          <p>
            <el-select
              v-model="queryForm.action"
              class="m-2"
              :placeholder="t('979')"
              @change="actionChange"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <span v-if="actionIsTrue">{{ t('979') }}</span>
          </p>
          <span class="danwei"></span>
        </div>
      </div>
      <div class="box-bottom">
        <p v-if="uavStatus">
          <span></span> {{ t('980') }}
          <span>10{{ t('981') }}</span>{{ t('982') }}
        </p>
        <div>
          <el-button 
            type="primary"
            round 
            @click="executeImmediately"
          >
            {{ t('983') }}
          </el-button>
        </div>
      </div>
    </div>
  </drag-dialog>
</template>

<script setup>
import { t } from '../../../languages';
import { relative } from "path";
import { ref, toRefs, watch, onMounted, watchEffect } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { isNumber } from "@vueuse/shared";
import { nextTick } from "process";
import { tr } from "element-plus/lib/locale";

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  punctuationLat:{
    type: [String, Number],
  },
  punctuationLng:{
    type: [String, Number],
  },
  uavInfo:{
    type:Object,
    default:function(){
      return {}
    }
  },
  taskInfo:{
    type:Object,
    default:function(){
      return {}
    }
  },
  airportInfo:{
    type:Object,
    default:function(){
      return {}
    }
  }
});
const uavStatus = ref('')
const airportHeight = ref(0)


const queryForm = ref({
  behavior: "1",
  longitude: "",
  latitude: "",
  taskName: "",
  params: "",
  PTZangle: "-60",
  course: "0",
  action: "0",
  height:'',
  alt:'',
  rlt:'',
  speed:'5'
});


watch(() => _.cloneDeep(props.punctuationLng), (newValue, oldValue) => {
    queryForm.value.longitude = newValue
  }, {deep: true})
  watch(() => _.cloneDeep(props.punctuationLat), (newValue, oldValue) => {
    queryForm.value.latitude = newValue
  }, {deep: true})
 
//  import _ from "lodash";
//   _.cloneDeep 是解决多次watch 对象
queryForm.value.PTZangle = localStorage.getItem('PTZangle') != null ? localStorage.getItem('PTZangle') : '-60';
queryForm.value.height = localStorage.getItem('flightHeight') != null ? localStorage.getItem('flightHeight') : '';

  watchEffect(()=>{
      airportHeight.value = props.airportInfo.nAlt
      if(
        props.taskInfo.sta === "RECOVERING" ||
        props.taskInfo.sta === "READY_TO_UPLOAD" ||
        props.taskInfo.sta === "UPLOADING" ||
        props.taskInfo.sta === "DOWNLOAD_PHOTOS" ||
        props.taskInfo.sta === "EXECUTING"
      ){
        uavStatus.value = true
      }else{
        uavStatus.value = false
      }
      queryForm.value.alt = props.uavInfo.alt
      queryForm.value.rlt = props.uavInfo.rlt
    },
    {
        flush: 'post'
    }
    )
const options = ref([
  {
    value: "1",
    label: t("306"),
  },
  {
    value: "11",
    label: t("478"),
  },
]);
const options1 = ref([
  {
    value: "0",
    label: t("294"),
  },
  {
    value: "1",
    label: t("89"),
  },
]);


const emit = defineEmits(["update:isVisible","updateMarkParam"]);
const { isVisible } = toRefs(props);
const dialogVisible = ref(null);
dialogVisible.value = isVisible.value;
watch(dialogVisible, (newProps) => {
  dialogVisible.value = newProps;
  emit("update:isVisible", false);
})

function allowDrop(){
  if (queryForm.value.longitude !== '') {
    longitudeIsTrue.value = false
  }
  if (queryForm.value.latitude !== '') {
    latitudeIsTrue.value = false
  }
}

const behaviesIsTrue = ref(false)
const actionIsTrue = ref(false)
const taskIsTrue = ref(false)

//行为选择时
function behaviorChange(){
  behaviesIsTrue.value = false
}
//action选择时
function actionChange(){
  actionIsTrue.value = false
}
//任务名称输入完成时候
function taskBlur(){
  if (queryForm.value.taskName !== '') {
    taskIsTrue.value = false
  }
}
//立即执行
function executeImmediately() {
  changeLong(queryForm.value.longitude.toString())
  changeLat(queryForm.value.latitude.toString())
    if (queryForm.value.taskName === '') {
      taskIsTrue.value = true
      return
    }else if(queryForm.value.longitude === ''){
      longitudeIsTrue.value = true
      longitudeText.value = t('918')
      return
    }else if(queryForm.value.latitude === ''){
      latitudeIsTrue.value = true
      latitudeText.value = t('919')
      return
    }else if(queryForm.value.behavior === ''){
      behaviesIsTrue.value = true
      return
    }else if(queryForm.value.PTZangle === ''){
      longIsTrue.value = true
      longText.value = t('984')
      return
    }else if(queryForm.value.height === ''){
      heightIsTrue.value = true
      heightText.value = t('985')
      return
    }else if(queryForm.value.speed === ''){
      speedIsTrue.value = true
      speedText.value = t('986')
      return
    }else if(queryForm.value.course === ''){
      courseIsTrue.value = true
      courseText.value = t('987')
      return
    }else if(queryForm.value.action === ''){
      actionIsTrue.value = true
      return
    }else if(taskIsTrue.value || latitudeIsTrue.value || longitudeIsTrue.value || longIsTrue.value || heightIsTrue.value || speedIsTrue.value || courseIsTrue.value){
      return
    }
    emit("updateMarkParam",queryForm.value,'#de2d2d')
}

const longIsTrue = ref(false)//云台角度是否符合
const longText = ref(t('988'))
//云台角度验证
function changePTZangle(e){
  let a = e.replace(/[^\d.-]/g, '');
  var str = e;
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    longText.value = t('989')
    longIsTrue.value = true
  }else if(len1 == "."){
    longText.value = t('990')
    longIsTrue.value = true
  }else if(str.split(".").length-1 > 1){
    longText.value = t('991')
    longIsTrue.value = true
  }else if(str.split("-").length-1 > 1){
    longText.value = t('992')
    longIsTrue.value = true
  }else if(a != e){
    longText.value = t('988')
    longIsTrue.value = true
  }else if(a>0){
    longText.value = t('993')
    longIsTrue.value = true
  }else if(e < -90){
    longText.value = t('993')
    longIsTrue.value = true
  }else{
    longIsTrue.value = false
    localStorage.setItem('PTZangle',e)
  }
}
const heightIsTrue = ref(false)//高度是否符合
const heightText = ref(t('994'))
//高度验证
function changeHeight(e){
  let a = e.replace(/[^\d.]/g, '');
  var str = e;
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    heightText.value = t('989')
    heightIsTrue.value = true
  }else if(len1 == "."){
    heightText.value = t('990')
    heightIsTrue.value = true
  }else if(str.split(".").length-1 > 1){
    heightText.value = t('991')
    heightIsTrue.value = true
  }else if(a != e){
    heightText.value = t('994')
    heightIsTrue.value = true
  }else if(a<0){
    heightText.value = t('995')
    heightIsTrue.value = true
  }else if(e > 300){
    heightText.value = t('995')
    heightIsTrue.value = true
  }else{
    heightIsTrue.value = false
    localStorage.setItem('flightHeight',e)
  }
}
const speedIsTrue = ref(false)//速度是否符合
const speedText = ref(t('996'))
//速度验证
function changeSpeed(e){
  let a = e.replace(/[^\d.]/g, '');
  var str = e;
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    speedText.value = t('989')
    speedIsTrue.value = true
  }else if(len1 == "."){
    speedText.value = t('990')
    speedIsTrue.value = true
  }else if(str.split(".").length-1 > 1){
    speedText.value = t('991')
    speedIsTrue.value = true
  }else if(a != e){
    speedText.value = t('996')
    speedIsTrue.value = true
  }else if(a<1){
    speedText.value = t('997')
    speedIsTrue.value = true
  }else if(e > 15){
    speedText.value = t('997')
    speedIsTrue.value = true
  }else{
    speedIsTrue.value = false
  }
}
const courseIsTrue = ref(false)//航向是否符合
const courseText = ref(t('998'))
//航向验证
function changeCourse(e){
  let a = e.replace(/[^\d.-]/g, '');
  var str = e;
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    courseText.value = t('989')
    courseIsTrue.value = true
  }else if(len1 == "."){
    courseText.value = t('990')
    courseIsTrue.value = true
  }else if(str.split(".").length-1 > 1){
    courseText.value = t('991')
    courseIsTrue.value = true
  }else if(str.split("-").length-1 > 1){
    courseText.value = t('992')
    courseIsTrue.value = true
  }else if(a != e){
    courseText.value = t('998')
    courseIsTrue.value = true
  }else if(a>180){
    courseText.value = t('999')
    courseIsTrue.value = true
  }else if(e < -180){
    courseText.value = t('999')
    courseIsTrue.value = true
  }else{
    courseIsTrue.value = false
  }
}

const longitudeIsTrue = ref(false)//经度是否符合
const longitudeText = ref(t('1000'))
//经度验证
function changeLong(e){
  let a = e.replace(/[^\d.-]/g, '');
  var str = e;
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    longitudeText.value = t('1000')
    longitudeIsTrue.value = true
  }else if(len1 == "."){
    longitudeText.value = t('990')
    longitudeIsTrue.value = true
  }else if(str.split(".").length-1 > 1){
    longitudeText.value = t('991')
    longitudeIsTrue.value = true
  }else if(a != e){
    longitudeText.value = t('1000')
    longitudeIsTrue.value = true
  }else{
    longitudeIsTrue.value = false
  }
}
const latitudeIsTrue = ref(false)//经度是否符合
const latitudeText = ref(t('1000'))
//纬度验证
function changeLat(e){
  let a = e.replace(/[^\d.-]/g, '');
  var str = e;
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
   latitudeText.value = t('1001')
   latitudeIsTrue.value = true
  }else if(len1 == "."){
   latitudeText.value = t('990')
   latitudeIsTrue.value = true
  }else if(str.split(".").length-1 > 1){
   latitudeText.value = t('991')
   latitudeIsTrue.value = true
  }else if(a != e){
   latitudeText.value = t('1001')
   latitudeIsTrue.value = true
  }else{
   latitudeIsTrue.value = false
  }
}
</script>

<style scoped lang="less">
.container {
  overflow: auto;

  .box {
    color: #ffffff;

    .item-title {
      padding: 1.3125rem 0.8125rem 0 0.8125rem;
      font-size: 1rem;
      margin-bottom: 0.9375rem;
    }

    .common-item {
      padding: 0.375rem 0.8125rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.95rem;
        width: 75%;
        position: relative;
      }
      p:first-child {
        width: 25%;
      }
      span{
        color: #ee3131;
        font-size: .7rem;
        position: absolute;
        display: block;
      }
    }
    .common-item1 {
      padding: 0.275rem 0.8125rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 0.95rem;
      }
    }
    .common-item:first-child {
      padding: 1.375rem 0.8125rem;
    }
    .common-item1:last-child {
      border-bottom: 1px solid rgba(35, 163, 236, 0.4);
      padding-bottom: 0.8rem;
      padding-top: 0.8rem;
    }
  }

  .box-middle {
    color: #ffffff;

    .item-title {
      padding: 0.8125rem 0.8125rem 0 0.8125rem;
      font-size: 1rem;
      margin-bottom: 0.4375rem;
    }

    .common-item {
      padding: 0.675rem 0.125rem 0.675rem 0.825rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.95rem;
        width: 75%;
        position: relative;
        span{
          display: block;
          position: absolute;
          color: #ee3131;
          font-size: .7rem;
      }
      }
      p:first-child {
        width: 25%;
      }
      img {
        display: block;
      }
      
    }
    .common-item:first-child {
      padding-top: 1rem;
      padding-right: 0.8125rem;
    }
    .other-item {
      // padding: 1.275rem 0.8125rem;
    }
    .flex_content {
      display: flex;
      align-items: center;
      p {
        font-size: 0.85rem;
        width: 62%;
        position: relative;
        span{
          display: block;
          position: absolute;
          color: #ee3131;
          font-size: .7rem;
      }
      }
      p:first-child {
        width: 30%;
      }
    }
    .flex_content:first-child {
      padding-bottom: 1.2rem;
    }
  }

  .box-bottom {
    color: #ffffff;
    padding: 1.75rem 0.8125rem 1.275rem 0.8125rem;
    p{
      padding-bottom: 1rem;
      span{
        color: #00FF43;
      }
    }
    div{
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  :deep(.el-button.is-round) {
    padding: 1.2rem 1.5rem;
  }
  .el-select {
    width: 100%;
    /deep/.el-input__wrapper {
      border: 1px solid #36e5ff;
      border-radius: 0.25rem;
      background-color: @lightBackground;
      box-shadow: 0 0 0 0;
      .el-input__inner {
        color: #fff;
      }
    }
  }
  .danwei{
    display: inline-block;
    width: 2rem;
    margin-left: 0.4rem;
  }
}
</style>
