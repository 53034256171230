<template>
  <div class="main full_height">
    <div class="main_hd">
      <i
        class="icon iconfont icon-xiangzuo hover_opacity"
        @click="$emit('update:isVisible', false)"
      ></i>
      <span>{{ t('452') }}</span>
    </div>
    <div class="main_bd">
      <div class="bd_con full_height">
        <div class="switch_box">
          <div class="switch_con" @click="flashHandle" v-show="audioModel === 'ABL120'">
            <label for="flashSwitch">{{ t('1323') }}</label>
            <el-switch
              v-model="flashSwitch"
              class="ml-2"
              size="small"
              style="
                --el-switch-on-color: #13ce66;
                --el-switch-off-color: #ff4949;
              "
              name="flashSwitch"
            />
          </div>
          <!-- <div class="switch_con">
            <label for="ptzLinkage">{{ t('1285') }}</label>
            <el-switch
              v-model="ptzLinkage"
              class="ml-2"
              size="small"
              style="
                --el-switch-on-color: #13ce66;
                --el-switch-off-color: #ff4949;
              "
              name="ptzLinkage"
            />
          </div> -->
          <div class="switch_con" @click="rbFlashHandle">
            <label for="rbFlash">{{ t('453') }}</label>
            <el-switch
              v-model="rbFlash"
              class="ml-2"
              size="small"
              style="
                --el-switch-on-color: #13ce66;
                --el-switch-off-color: #ff4949;
              "
              name="rbFlash"
            />
          </div>
        </div>
        <div class="silder_box" v-show="audioModel === 'ABL120'">
          <img src="../../../../asset/img/command-center/sun.png" alt="">
          <div class="slider_con">
            <el-slider
              v-model="flashBrightness"
              :show-tooltip="true"
              :disabled="!flashSwitch"
              @change="brightnessChange"
            />
          </div>
          <img src="../../../../asset/img/command-center/sun.png" alt="">
        </div>
        <div class="img_box" v-show="audioModel === 'ABL120'">
          <div class="img_con flex_center_box">
            <img src="../../../../asset/img/command-center/light.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { t } from '../../../../languages';
import { ref, toRefs, onBeforeUnmount } from "vue";
import emitter from "@/utils/mitt";
import { ElMessage } from "element-plus";
import speaker from "@/network/speaker.js";

const props = defineProps({
  sn: {
    type: String,
    default: "",
  },
});
const { sn } = toRefs(props);

const flashSwitch = ref(false); //爆闪开关
// const ptzLinkage = ref(false); //云台联动
const rbFlash = ref(false); //红蓝爆闪
const flashBrightness = ref(0);   //探照灯亮度

/**
 * 探照灯状态信息
 */
function tzdInfoHandle(dataInfo) {
  const data = dataInfo.data;
  const lightMode = data[0]   //灯光模式
  const brightness = data[1]  //亮度
  // 0 没开    1 常量    2 爆闪     3 呼吸灯
  flashSwitch.value = (lightMode === '0' ? false : true)
  flashBrightness.value = parseInt(brightness)
}

/**
 * 警示灯状态信息
 */
function ctlInfoHandle(dataInfo) {
  const data = dataInfo.data;
  const lightMode = data[0]   //灯光模式
  // 0 关    1 开
  rbFlash.value = (lightMode === '0' ? false : true)
}

const audioModel = ref('')
//获取挂载型号和挂载状态
function audioInfoHandle(dataInfo){
  audioModel.value = dataInfo.data[4]
}

// 打开监听
emitter.on("speaker-tzd-info", tzdInfoHandle);
emitter.on("speaker-ctl-info", ctlInfoHandle);
emitter.on("speaker-audio-info", audioInfoHandle);

onBeforeUnmount(() => {
  // 取消监听
  emitter.off("speaker-tzd-info", tzdInfoHandle);
  emitter.off("speaker-ctl-info", ctlInfoHandle);
  emitter.off("speaker-audio-info", audioInfoHandle);
});


/* 亮度改变 */
function brightnessChange(){
  operateLight('MAP_SET_TZD_LUM_ACTION', flashBrightness.value)
}

/* 爆闪开关操作 */
function flashHandle(){
  operateLight('MAP_SET_TZD_MODE_ACTION', (flashSwitch.value === true ? 1 : 0))
}

/* 警示灯开关操作 */
function rbFlashHandle(){
  operateLight('CTL_ENABLE_ACTION', (rbFlash.value === true ? 1 : 0))
}

/* 操作灯 */
function operateLight(action, val) {
  speaker.operateBgm(sn.value, {
    action: action,
    data: val,
  }).then((res) =>{
    if(res.resultStatus === true){
      ElMessage.success(t('1324'))
    }
  });
}
</script>



<style scoped lang="less">
.main {
  position: relative;
  padding-top: 3.375rem;
}
.main_hd {
  position: absolute;
  top: 0;
  left: 1.5rem;
  right: 1.5rem;
  height: 3.375rem;
  padding: 1.125rem 0;
  text-align: center;
  .icon {
    position: absolute;
    top: 1.1875rem;
    left: 0;
    font-size: 1rem;
  }
}
.main_bd {
  position: relative;
  height: 100%;
  padding: 0 1.5rem 2rem;
  .bd_con {
    background: rgba(0, 61, 95, 1);
    border-radius: 0.3125rem;
  }
}
.switch_box{
  display: flex;
  justify-content: space-around;
  padding: .625rem 0;
  .switch_con{
    >label{
      padding-right: .625rem;
    }
  }
}
.img_box{
  .img_con{
    width: 5.25rem;
    height: 5.25rem;
    margin: auto;
    background: #1B92C3;
    border-radius: 50%;
    display: flex;
    >img{
      width: 3.875rem;
      height: 3.875rem;
    }
  }
}
.flex_center_box{
  display: flex;
  justify-content: center;
  align-items: center;
}
.silder_box{
  padding:0 2rem .625rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .slider_con{
    flex: 1;
    padding: 0 .625rem;
  }
  >img{
    width: 1.5rem;
    height: 1.5rem;
  }
}
.slider_con {
  :deep(.el-slider) {
    height: 6px;
    .el-slider__button-wrapper {
      height: 6px;
      width: 6px;
      top: -0.54rem;
      .el-slider__button {
        height: inherit;
        width: inherit;
        border: none;
      }
    }
    .el-slider__runway {
      height: 2px;
      .el-slider__bar {
        height: inherit;
        background-color: rgba(36, 181, 238, 1);
      }
    }
  }
}
</style>