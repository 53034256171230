<template>
  <drag-dialog
    v-model:visible="dialogVisible"
    :right="24"
    :top="3.8"
    :width="23.8125"
    :title="t('515')"
  >
    <div class="auto_box">
      <div class="airSn flex-sb">
        <p>{{ t('164') }}SN:</p>
        <p>{{sn}}</p>
      </div>
      <div class="longitude flex-sb common_mt">
        <p>{{ t('73') }}:</p>
        <p>{{ ruleForm.longitude ? ruleForm.longitude : '-' }}</p>
      </div>
      <div class="latitude flex-sb common_mt">
        <p>{{ t('74') }}:</p>
        <p>{{ ruleForm.latitude ? ruleForm.latitude : '-' }}</p>
      </div>
      <div class="sec_title">{{ t('915') }}</div>
      <div class="form">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          :size="formSize"
        >
          <el-form-item :label="t('916')" prop="alternateLongitude">
            <el-input v-model="ruleForm.alternateLongitude" maxlength="18" clearable/>
          </el-form-item>
          <el-form-item :label="t('917')" prop="alternateLatitude">
            <el-input v-model="ruleForm.alternateLatitude" maxlength="18" clearable/>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn_list">
        <button class="cancel hover_background" @click="cancel">{{ t('217') }}</button>
        <button class="save hover_background" @click="updateAlternate">
          {{ t('225') }}
        </button>
      </div>
    </div>
  </drag-dialog>
</template>

<script setup>
import { t } from '../../../languages';
import { ref, toRefs, watch, reactive } from "vue";
import device from "@/network/device";
import { ElMessage } from "element-plus";
import { isNumber, lngValid, latValid } from "@/utils/validate";

const props = defineProps({
  sn: {
    type: String,
    default: "",
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
});

const { sn } = toRefs(props);

const ruleForm = reactive({
  alternateLongitude: "",
  alternateLatitude: "",
  longitude: "",
  latitude: "",
});

const rules = reactive({
  alternateLongitude: [
    { required: true, message: t("918"), trigger: "blur" },
    {
      validator: isNumber,
      trigger: "blur",
    },
    {
      validator: lngValid,
      trigger: "blur",
    },
  ],
  alternateLatitude: [
    {
      required: true,
      message: t("919"),
      trigger: "blur",
    },
    {
      validator: isNumber,
      trigger: "blur",
    },
    {
      validator: latValid,
      trigger: "blur",
    },
  ],
});
const emit = defineEmits(["update:isVisible"]);
const { isVisible } = toRefs(props);
const dialogVisible = ref(null);
dialogVisible.value = isVisible.value;
watch(dialogVisible, (newProps) => {
  dialogVisible.value = newProps;
  emit("update:isVisible", false);
});

const cancel = () => {
  dialogVisible.value = false;
};

/* 获取备降点信息 */
async function getAlternatePoint() {
  const data = await device.getAlternatePoint(sn.value);
  if(data.resultStatus){
    ruleForm.alternateLongitude = data.resultData.alternateLongitude ? data.resultData.alternateLongitude : '';
    ruleForm.alternateLatitude = data.resultData.alternateLatitude ? data.resultData.alternateLatitude : '';
    ruleForm.longitude = data.resultData.longitude;
    ruleForm.latitude = data.resultData.latitude;
  }
}

getAlternatePoint();

const ruleFormRef = ref(null);
/* 修改备降点信息 */
function updateAlternate() {
  ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      const data = await device.updateAlternate({
        alternateLatitude: ruleForm.alternateLatitude,
        alternateLongitude: ruleForm.alternateLongitude,
        sn: sn.value,
      });
      if (data.resultStatus) {
        ElMessage.success(t("920"));
        emit("update:isVisible", false);
      }
    }
  });
}
</script>

<style scoped lang="less">
.auto_box {
  width: 100%;
  height: 100%;
  padding: 1.3125rem 0.8125rem 0.6875rem 0.8125rem;
  .common_mt {
    margin-top: 1.125rem;
  }
  p {
    font-size: 0.875rem;
    color: #ffffff;
  }
  .sec_title {
    color: #ffffff;
    margin-top: 1.7rem;
  }
  .form {
    margin-top: 1.1rem;
  }
  .btn_list {
    display: flex;
    justify-content: flex-end;
    .cancel {
      width: 6.25rem;
      height: 2.5rem;
      background: #001c31;
      border: 1px solid #00f5ff;
      color: #ffffff;
      margin-right: 1.625rem;
    }
    .save {
      width: 6.25rem;
      height: 2.5rem;
      background: #00959b;
      border: 1px solid #00f5ff;
      color: #ffffff;
    }
  }
}
//el样式重置
/deep/ .el-form-item__label {
  color: #ffffff;
  font-size: 0.875rem;
}
/deep/ .el-input__wrapper {
  border: 1px solid #51d2ff;
  background: #001c31;
  box-shadow: 0 0 0 0;
  height: 1.875rem;
}
/deep/ .el-input__inner {
  color: #fff;
}
/deep/ .el-form-item {
  margin-bottom: 1rem;
}
</style>
