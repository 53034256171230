<template>
  <div class="auth_dialog_box">
    <el-dialog
      v-model="visible"
      :width="'524px'"
      :custom-class="isCenter ? 'center_dialog common-dialog' : 'common-dialog'"
      :append-to-body="false"
      lock-scroll
      :top="'35Vh'"
      destroy-on-close
      ref="eleDialog"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <template #title>
        <div class="my-header">
          <span class="flex-sb"
            >{{ t('130') }}
            <span
              class="iconfont icon-guanbi1 close hover_opacity"
              @click="onCancel"
            ></span
          ></span>
        </div>
      </template>
      <div class="auth-content">
        <p>{{platformsInfo.zxHeader.orgName}}{{platformsInfo.zxHeader.userRealName}}{{ t('正在请求') }}{{UAVName}}{{ t('164') }}（{{sn}}）{{ t('的远程控制权限') }}</p>
        <div class="box flex-sb">
          <div class="check flex-a">
            <el-checkbox v-model="isChecked" label="" size="large" @change="saveAuth" />
            <span class="test">{{ t('记住授权状态，不再提示') }}</span>
          </div>
          <div class="btn flex-a">
            <div class="close hover_opacity" @click="cancelAuth">{{ t('取消授权（') }}{{isNumber}}s）</div>
            <div class="submit hover_opacity" @click="submitAuth">{{ t('确认授权') }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { t } from '../../../languages';
import { defineComponent, ref, toRefs } from "vue";
import platforms from "@/network/platforms"
export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    sn: {
      type: String,
      default: () => {},
    },
    UAVName:{
      type: String,
      default: () => {},
    },
    platformsInfo: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["closeAuth"],
  setup(props, { emit }) {
    const { isVisible,sn,platformsInfo } = toRefs(props);
    const visible = ref(false);
    visible.value = isVisible.value;
    const isChecked = ref(false);
    const isAuth = ref(false);
    const isNumber = ref(180);
     isNumber.value = platformsInfo.value.expiredTime;

    /**
     * @description: 倒计时
     * @return {*}
     */
    function percentage() {
      let test = setInterval(() => {
        if (isNumber.value  > 0){
          isNumber.value -=1
        }else {
          clearInterval(test);
          isNumber.value = 180;
          onCancel()
        }
      }, 1000)
    }
    percentage();
    /**
     * @description: 是否记住授权
     * @param {*}
     * @return {*}
     */
    async function saveAuth(val) {
      isChecked.value = val;
    }
    async function authSave() {
      let flyObj = {
        isRemember:isChecked.value,
        nestSn:sn.value,
        type:1,
        value:isAuth.value
      }
      const res = await platforms.flyControlPlatforms(flyObj);
      if(res.resultStatus){
      }
    }
    /**
     * @description: 取消授权
     * @param {*}
     * @return {*}
     */
    async function cancelAuth() {
      isAuth.value = false;
      authSave();
      let flyObj = {
        authVO:platformsInfo.value,
        isAuth:isAuth.value,
      }
      const res = await platforms.authCommandcenter(flyObj,sn.value);
      if(res.resultStatus){
        onCancel(false)
      }
    }
    /**
     * @description: 确认授权
     * @param {*}
     * @return {*}
     */
    async function submitAuth() {
      isAuth.value = true;
      authSave();
      let flyObj = {
        authVO:platformsInfo.value,
        isAuth:isAuth.value,
      }
      const res = await platforms.authCommandcenter(flyObj,sn.value);
      if(res.resultStatus){
        onCancel(true)
      }
    }

    function onCancel(status) {
      // visible.value = false;
      emit("closeAuth",'coltrol',status);
    }
    return {
      visible,
      isChecked,
      isAuth,
      isNumber, 
      saveAuth,
      submitAuth,
      cancelAuth,
      onCancel,
    };
  },
});
</script>

<style lang="less" scoped>
.auth_dialog_box :deep(.common-dialog) {
  background: #004375;

  .el-dialog__header {
    height: 3.0625rem;
    padding: 0.6875rem 1.25rem;
    margin-right: 0;
    background: #004375;
    text-align: center;
    .el-dialog__title {
      font-size: 1.25rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #fff;
    }
  }
  .el-dialog__body {
    padding: 0 1.0625rem 0.875rem 1.5rem;
    color: #fff;
  }
}

/* 居中弹窗 */
.auth_dialog_box /deep/ .center_dialog {
  margin-top: 0 !important;
  top: 50%;
  transform: translateY(-50%);
}
.my-header {
  height: 100%;
  font-size: 1.25rem;
  .close {
    width: 1.5rem;
    font-size: 1.125rem;
  }
}
.auth-content {
  .box {
    margin-top: 2.5rem;
    :deep(.check) {
        .el-checkbox__inner {
            background: #004375;
          border: 1px solid #00F5FF;
        }
        .el-checkbox__inner::after {
          border-bottom-color: #00F5FF;
          border-right-color: #00F5FF;
        }
      .test{
          margin-left: 0.1875rem;
      }
    }
    .btn {
      .close {
        width: 8.875rem;
        height: 2.0625rem;
        line-height: 2.0625rem;
        text-align: center;
        background: rgba(0, 28, 49, 0.7);
        border: 1px solid #00f5ff;
        margin-right: 0.875rem;
      }
      .submit {
        width: 5.8125rem;
        height: 2.0625rem;
        line-height: 2.0625rem;
        text-align: center;
        background: rgba(7, 112, 167, 0.7);
        border: 1px solid #00f5ff;
      }
    }
  }
}
</style>