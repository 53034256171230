import { t } from '../../../../languages';
/* 字符串转ArrayBuffer */
export function strToArrayBuffer(str, byteLen) {
    const buf = new ArrayBuffer(byteLen);
    const bufView = new Uint8Array(buf); //
    for (let i = 0; i < str.length; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

//合并ArrayBuffer
export function concatArrayBuffer(arrays) {
    let totalLen = 0;
    for (let arr of arrays) {
        totalLen += arr.byteLength;
    }
    const res = new Uint8Array(totalLen);
    let offset = 0;
    for (let arr of arrays) {
        const uit8Arr = new Uint8Array(arr)
        res.set(uit8Arr, offset);
        offset += arr.byteLength;
    }
    return res.buffer
}


// 把多个Float32Array，合并成一个，开始
export function mergeFloat32Array(bufferArr) {
    let length = bufferArr.length * bufferArr[0].length;
    let data = new Float32Array(length)
    let offset = 0;
    for (let i = 0; i < bufferArr.length; i++) {
        data.set(bufferArr[i], offset);
        offset += bufferArr[i].length;
    }
    return data;
}