<template>
  <div class="soundbyte_box">
    <p>{{ secondToMinute(recordTime) }}</p>
    <div class="soundbyte_con">
      <canvas
        class="visualizer"
        width="228"
        height="30"
        ref="canvasRef"
      ></canvas>
      <div class="static_line" v-show="!isRecording"></div>
    </div>
    <!-- <audio controls id="audio_player"></audio> -->
  </div>
  <div
    class="record_btn hover_opacity"
    :class="{ recording_btn: isRecording }"
    @click="recordVoice"
  >
    <span class="icon iconfont icon-maikefeng1"></span>
  </div>
</template>

<script setup>
import { t } from '../../../../languages';
import { onBeforeUnmount, onMounted, ref, toRefs } from "vue";
import { secondToMinute } from "@/utils/utilFun.js";
import { ElMessage } from "element-plus";
import {
  strToArrayBuffer,
  concatArrayBuffer,
  mergeFloat32Array,
} from "./bufferUtil.js";
import socket from "@/utils/websocket";
import speaker from "@/network/speaker.js";
import emitter from "@/utils/mitt";

const props = defineProps({
  sn: {
    type: String,
    default: "",
  },
});
const { sn } = toRefs(props);
const { sendArrayBuffer } = socket();

const recordTime = ref(0); //录制的时间
const isRecording = ref(false); //录制的状态
let recordTimer = null; //录制定时器
let sendTimer = null; //发送buffer定时器

let audioContext = null; //AudioContext对象
let recorder = null;

let buffer = [];

/* 麦克风录制音频 */
function recordVoice() {
  if (isRecording.value === false) {
    recordTime.value = 0; //清空已录制时间
    speaker
      .controlAudio(sn.value, {
        data: 3,
      })
      .then((res) => {
        if (res.resultStatus) {
          // 开始录制
          if (audioContext === null) {
            // 未授权
            register("speak", () => {
              timingRequest();
            });
          } else {
            // 已授权
            timingRequest();
          }
          isRecording.value = !isRecording.value;
        } else {
          // ElMessage({
          //   message: t("1318"),
          //   type: "warning",
          // });
        }
      });
  } else {
    // 结束录制
    speaker.stopSpeak(sn.value);
    clearInterval(recordTimer); //清空定时获取音频数据定时器
    clearInterval(sendTimer); //清空定时获取音频数据定时器
    recordTime.value = 0; //清空已录制时间
    if (audioContext === null) {
      // 未授权
      // ElMessage({
      //   message: t("1319"),
      //   type: "warning",
      // });
    } else {
      // 已授权
      // 释放 AudioContext 控制的资源（比如扬声器）可以理解为停止
      if (audioContext.state !== "closed") {
        recorder.disconnect();
        // 释放 AudioContext 控制的资源（比如扬声器）可以理解为停止
        audioContext.close();
        audioContext = null;
        // 发送剩余的buffer数据
        sendWsBuffer();
        ElMessage.success(t("1320"));
      }
    }
    isRecording.value = !isRecording.value;
  }
}

/* 定时器*/
function timingRequest() {
  recordTimer = setInterval(() => {
    recordTime.value += 1;
  }, 1000);
  sendTimer = setInterval(sendWsBuffer, 1000);
}

/* ws发送buffer数据 */
function sendWsBuffer() {
  if (buffer.length > 0) {
    const bufferData = toPcmArrayBuffer(buffer);
    // console.log(bufferData)
    // // 48kHz sampling rate, 20ms frame duration, stereo audio (2 channels)
    // const samplingRate = 16000;
    // const frameDuration = 20;
    // const channels = 2;

    // // Optimize encoding for audio. Available applications are VOIP, AUDIO, and RESTRICTED_LOWDELAY
    // const encoder = new OpusScript(samplingRate, channels, OpusScript.Application.AUDIO);

    // const frameSize = samplingRate * frameDuration / 1000;

    // // Get PCM data from somewhere and encode it into opus
    // const pcmData = new Buffer(bufferData);
    // const encodedPacket = encoder.encode(pcmData, frameSize);
    // encoder.delete();
    // console.log(encodedPacket)
    // sn buffer拼接
    const snBuffer = strToArrayBuffer(sn.value, 30);
    const concatBuffer = concatArrayBuffer([snBuffer, bufferData]);
    sendArrayBuffer(concatBuffer);
    buffer = [];
  }
}

/* float32Array转pcm数据 */
function toPcmArrayBuffer(float32ArrayData) {
  const audioData = mergeFloat32Array(float32ArrayData);
  let length = audioData.length;
  let volume = 1;
  let index = 0;
  let bufferTem = new ArrayBuffer(length * 2);
  // 需要用一个view来操控buffer
  let view = new DataView(bufferTem);
  for (let i = 0; i < length; i++) {
    view.setInt16(index, audioData[i] * (0x7fff * volume), true);
    index += 2;
  }
  return view.buffer;
}

/* 注册获取麦克风设备等 */
function register(device, callback) {
  if (!navigator.mediaDevices.getUserMedia) {
    ElMessage({
      message: t("1321"),
      type: "warning",
    });
    return false;
  }
  navigator.mediaDevices
    .getUserMedia({
      video: false,
      audio: true,
    })
    .then(
      (stream) => {
        if (device === "speak") {
          audioContext = new AudioContext({
            sampleRate: 16000,
          });
          // 创建音频处理的源节点，处理麦克风
          const source = audioContext.createMediaStreamSource(stream);
          // 创建声音的缓存节点
          // 参数：缓冲区大小：0，自适应；输入声道，默认2；输出声道，默认2
          recorder = audioContext.createScriptProcessor(0, 1, 1);

          // 创建音频处理的输出节点
          const dest = audioContext.createMediaStreamDestination();

          // 串联连接
          source.connect(recorder);
          // 这里connect destination之后，扬声器就会播放
          // source.connect(audioContext.destination);
          recorder.connect(dest);

          // 每次音频缓冲区已满，4k，需要您进行处理时，该节点都会发出一个 onaudioprocess 事件。
          // 此时，您可以将数据保存到自己的缓冲区内
          recorder.onaudioprocess = (e) => {
            // e.inputBuffer是AudioBuffer类型
            // getChannelData() 方法返回Float32Array
            // 其中包含与通道关联的 PCM 数据，通道参数定义 (0 表示第一个通道)

            // Float32Array，即数组里的每个数字都是32位的单精度浮点数
            // 表示 声音的强弱

            // 0 为单声道， 如果是双声道可以用e.inputBuffer.getChannelData(1)取第二个声道的数据
            // 当然，createScriptProcessor方法中的声道数也要设置成2。
            if (isRecording.value === true) {
              const demoData = e.inputBuffer.getChannelData(0);
              buffer.push(new Float32Array(demoData));
            }
          };
        } else {
          visualize(stream);
        }
        callback && callback();
      },
      (err) => {
        console.error(t("1322"), err);
      }
    )
    .catch((err) => {
      console.log(err);
    });
}
register("soundbyte");

/* 绘制波纹区域start */

const canvasRef = ref(null); //canvas容器
let canvasCtx = null; // 2D渲染的上下文

onMounted(() => {
  canvasCtx = canvasRef.value.getContext("2d");
});

/* 绘制波纹 */
function visualize(stream) {
  const audioCtx = new AudioContext();
  const source = audioCtx.createMediaStreamSource(stream);
  const analyser = audioCtx.createAnalyser();
  analyser.fftSize = 2048;
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  source.connect(analyser);

  draw();
  function draw() {
    if (!canvasRef.value) return false;
    const WIDTH = canvasRef.value.width;
    const HEIGHT = canvasRef.value.height;
    requestAnimationFrame(draw);

    /* 非录制状态不允许绘制波纹 */
    if (!isRecording.value) {
      return false;
    }

    analyser.getByteTimeDomainData(dataArray);

    canvasCtx.fillStyle = "#1b92c3";
    canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = "#fff";

    canvasCtx.beginPath();

    let sliceWidth = (WIDTH * 1.0) / bufferLength;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      let v = dataArray[i] / 128.0;
      let y = (v * HEIGHT) / 2;

      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasCtx.lineTo(canvasRef.value.width, canvasRef.value.height / 2);
    canvasCtx.stroke();
  }
}
/* 发送停止喊话指令 */
async function sendStopSpeak() {
  // speaker.stopSpeak(sn.value);
  // 解决刷新关闭系统 无法发送关闭异步请求
  if (isRecording.value === true) {
    navigator.sendBeacon(
      `${process.env.VUE_APP_BASE_API}/api/v1/audio/stop/${sn.value}`
    );
  }
}

onMounted(() => {
  window.addEventListener("beforeunload", sendStopSpeak);
});

/* 绘制波纹区域end */
onBeforeUnmount(() => {
  // 清理数据，释放内存
  clearInterval(recordTimer);
  clearInterval(sendTimer);
  recordTimer = null;
  sendTimer = null;
  canvasCtx = null;
  audioContext = null;
  recorder = null;
  buffer = [];
  sendStopSpeak();
  window.removeEventListener("beforeunload", sendStopSpeak);
});
</script>


<style scoped lang="less">
.soundbyte_box {
  height: 4.875rem;
  margin-bottom: 1.125rem;
  padding: 0 2.75rem;
  background: rgba(27, 146, 195, 1);
  border-radius: 0.625rem;
  text-align: center;
  > p {
    padding-top: 0.625rem;
  }
}
.record_btn {
  width: 4.5rem;
  height: 4.5rem;
  line-height: 4.5rem;
  margin: 0 auto;
  background: #1b92c3;
  border-radius: 50%;
  text-align: center;
  color: #003d5f;
  .icon {
    font-size: 2.25rem;
  }
}
.recording_btn {
  color: #fff;
}
.soundbyte_con {
  position: relative;
  .visualizer {
    width: 100%;
    height: 2.5rem;
  }
  .static_line {
    position: absolute;
    left: 0;
    right: 0;
    top: 1.1875rem;
    height: 2px;
    width: 100%;
    background: #fff;
  }
}
</style>
  