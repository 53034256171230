import { t } from '../languages';
import request from "./http.js";

const speaker = {
    //播放音频
    playAudioFile: (sn, params) => {
        return request({
            url: `/api/v1/audio/start/${sn}`,
            method: "post",
            params
        });
    },
    //控制音频暂停，继续
    controlAudio: (sn, params) => {
        return request({
            url: `/api/v1/audio/control/${sn}`,
            method: "post",
            params
        });
    },
    // 结束占用喊话通道喊话 
    stopSpeak: (sn) => {
        return request({
            url: `/api/v1/audio/stop/${sn}`,
            method: "post",
        });
    },
    // 操作音频（音量，上-下一首等） 
    operateAudio: (sn, params) => {
        return request({
            url: `/api/v1/audio/operate/${sn}`,
            method: "post",
            params
        });
    },
    // 获取音频播放状态 
    getAudioStatus: (sn) => {
        return request({
            url: `/api/v1/audio/getAudioStatus/${sn}`,
            method: "get",
        });
    },
    // 发送TTS消息 
    sendTts: (sn, data) => {
        return request({
            url: `/api/v1/audio/sendTts/${sn}`,
            method: "post",
            data
        });
    },
    // 保存实时喊话使用日志 
    openSpeakLog: (sn, params) => {
        return request({
            url: `/api/v1/audio/isOpen/${sn}`,
            method: "post",
            params
        });
    },
    // 上传音频文件
    uploadAudio: (sn, params) => {
        return new Promise((resolve, reject) => {
            request
                .post(`/api/v1/connect/uploadAudio/${sn}`, params, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => resolve(res))
                .catch(error => {
                    reject(error)
                    console.log('catch-error:', error)
                })
        })
    },
    // 保存实时喊话使用日志 
    delAudio: (sn, params) => {
        return request({
            url: `/api/v1/audio/delete/audioFile/${sn}`,
            method: "delete",
            params
        });
    },
    // 获取喊话器基础信息 
    getSpeakerBasicInfo: (sn) => {
        return request({
            url: `/api/v1/audio/getBaseStatusInfo/${sn}`,
            method: "get",
        });
    },
    /* 背景音 */
    //播放音频
    playBgmFile: (sn, params) => {
        return request({
            url: `/api/v1/bgm/audio/start/${sn}`,
            method: "post",
            params
        });
    },
    //控制音频暂停，继续
    controlBgm: (sn, params) => {
        return request({
            url: `/api/v1/bgm/audio/control/${sn}`,
            method: "post",
            params
        });
    },
    // 操作音频（音量，上-下一首等） 
    operateBgm: (sn, params) => {
        return request({
            url: `/api/v1/bgm/audio/operate/${sn}`,
            method: "post",
            params
        });
    },
    // 获取背景音播放状态 
    getBgmStatus: (sn) => {
        return request({
            url: `/api/v1//bgm/audio/getAudioStatus/${sn}`,
            method: "get",
        });
    },
    // 上传背景音文件
    uploadBgm: (sn, params) => {
        return new Promise((resolve, reject) => {
            request
                .post(`/api/v1/bgm/connect/uploadAudio/${sn}`, params, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => resolve(res))
                .catch(error => {
                    reject(error)
                    console.log('catch-error:', error)
                })
        })
    },
    // 删除背景音
    delBgm: (sn, params) => {
        return request({
            url: `/api/v1/bgm/audio/delete/audioFile/${sn}`,
            method: "delete",
            params
        });
    },
}
export default speaker;