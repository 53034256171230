import { t } from '../../languages';
import { ref, watch, onBeforeUnmount } from "vue";
import lodash from "lodash";
import { ElMessage } from "element-plus";
import emitter from "@/utils/mitt";
import domMessage from "@/utils/messageOnce";
import taskManage from "@/network/task";
const device = () => {
  const thirdNum = ref(-1); //油门第三位数
  const throttleStatu = ref(null); //记录油门状态 true代表上升 false代表下降
  const keepThrottle = ref(0); //油门持续按钮的值
  const compareNum = ref(null); // watch比较差值,大于0为上升,反之下降
  const throttleLock = ref(null); //油门锁 1为解锁 0为上锁
  const gimbalYaw = ref(null); //云台偏航 250-255右 0-5左 矮摇杆的云台数据
  const gimbalPitch = ref(null); //云台俯仰 0-5上 250-255下 矮摇杆的云台数据
  const planeIncline = ref(null); //无人机横滚 250-255右 0-5左
  const planePitch = ref(null); //无人机俯仰  0-5上 250-255下
  const planeYaw = ref(null); //无人机航向 0-1左 14-15右
  const cameraZoom = ref(null); //无人机变焦 0-20放大 235-255缩小
  const gimbalForHeight = ref(null); //云台偏航 72右 96左 高摇杆的云台数据
  const throttleForHeight = ref(null); //油门 1是上升 16是下降 高摇杆的油门数据

  let websocketsend = null; //用来接收mitt传过来的websocketsend方法
  let flag = null; //用来接收mitt传过来的无人机开关按钮的状态
  let sn = null; //sn
  let control = {
    action: "REMOTE_CONTROL",
    code: "",
    sn: "",
    data: {},
  }; //ws发送的数据模板
  emitter.on("ws-send", (data) => {
    websocketsend = data;
  });
  emitter.on("btn-switch", (data) => {
    flag = data;
  });
  emitter.on("send-sn", (data) => {
    sn = data;
  });
  const messageOnce = new domMessage();
  //监听第三位数的变化
  watch(thirdNum, (newValue, oldValue) => {
    compareNum.value = newValue - oldValue;
    if (compareNum.value > 0) {
      throttleStatu.value = false;
    } else {
      throttleStatu.value = true;
    }
    if (keepThrottle.value != 1) {
      throttleRiseOrDrop(newValue, oldValue); //摇杆推动 -> 油门上升下降的函数
    }
  });

  // 摇杆处理函数
  const stickHandle = lodash.throttle((buffArray) => {
    planeIncline.value = buffArray[1];
    planePitch.value = buffArray[3];
    planeYaw.value = buffArray[5];
    cameraZoom.value = buffArray[10];
    gimbalForHeight.value = buffArray[7];
    throttleForHeight.value = buffArray[6];
    console.log(buffArray);
    // console.log(throttleForHeight.value);
    // console.log(t("132"), buffArray);
    // console.log(t("133"), planeIncline.value);
    // console.log(t("134"), planePitch.value);
    // console.log(t("135"), planeYaw.value);
    //无人机倾斜
    if (planeIncline.value >= 250) {
      rockerControl("right");
    } else if (planeIncline.value <= 5) {
      rockerControl("left");
    }
    //无人机俯仰
    if (planePitch.value <= 5) {
      rockerControl("up");
    } else if (planePitch.value >= 250) {
      rockerControl("down");
    }
    //无人机航向
    if (planeYaw.value >= 14) {
      rockerControl("yawRight");
    } else if (planeYaw.value <= 1) {
      rockerControl("yawLeft");
    }
    //无人机变焦
    if (cameraZoom.value >= 235) {
      // 缩小
      rockerControl("zoomDec");
    } else if (cameraZoom.value <= 20) {
      // 放大
      rockerControl("zoomAdd");
    }
    //无人机云台摇杆 高
    if (gimbalForHeight.value === 68 || gimbalForHeight.value === 4) {
      rockerControl("gimbalUp");
    } else if (gimbalForHeight.value === 72 || gimbalForHeight.value === 8) {
      rockerControl("gimbalRight");
    } else if (gimbalForHeight.value === 80 || gimbalForHeight.value === 16) {
      rockerControl("gimbalDown");
    } else if (gimbalForHeight.value === 96 || gimbalForHeight.value === 32) {
      rockerControl("gimbalLeft");
    }
    //无人机油门
    if (throttleForHeight.value === 1) {
      rockerControl("throttleAdd");
    } else if (throttleForHeight.value === 16) {
      rockerControl("throttleDec");
    }
  }, 500);
  //摇杆处理函数
  const rockerControl = async (mode) => {
    console.log(mode);
    let action = JSON.parse(JSON.stringify(control));
    switch (mode) {
      case "right":
        if (flag) {
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["roll"] = 1;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "left":
        if (flag) {
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["roll"] = -1;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "up":
        if (flag) {
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["pitch"] = 1;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "down":
        if (flag) {
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["pitch"] = -1;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "yawRight":
        if (flag) {
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["yaw"] = 4;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "yawLeft":
        if (flag) {
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["yaw"] = -4;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "zoomDec":
        if (flag) {
          let zoomObj = {
            action: "TOGGLE_ZOOM",
            nestSn: sn,
            data: 2,
          };
          console.log(123123);
          await taskManage.airportControl(zoomObj);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "zoomAdd":
        if (flag) {
          let zoomObj = {
            action: "TOGGLE_ZOOM",
            nestSn: sn,
            data: 1,
          };
          await taskManage.airportControl(zoomObj);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "gimbalUp":
        if (flag) {
          action.code = "CONTROLLER_YUNTAI";
          action.data["pitch"] = 5;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
        case "gimbalRight":
        if (flag) {
          action.code = "CONTROLLER_YUNTAI";
          action.data["yaw"] = 5;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
        case "gimbalDown":
        if (flag) {
          action.code = "CONTROLLER_YUNTAI";
          action.data["pitch"] = -5;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
        case "gimbalLeft":
        if (flag) {
          action.code = "CONTROLLER_YUNTAI";
          action.data["yaw"] = -5;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "throttleAdd":
        if (flag) {
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["vt"] = 1;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "throttleDec":
        if (flag) {
          action.code = "CONTROLLER_NEST_ATTITUDE";
          action.data["vt"] = -1;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      default:
        break;
    }
  };

  // 油门处理函数
  const throttleHandle = lodash.throttle((buffArray) => {
    keepThrottle.value = buffArray[2] >>> 7; //油门持续按钮 1为持续
    throttleLock.value = (buffArray[6] >>> 4) & 0x1;
    gimbalYaw.value = buffArray[8];
    gimbalPitch.value = buffArray[10];
    console.log(buffArray);
    // console.log(t("137"), gimbalYaw.value); //255-250 0-5
    // console.log(t("138"), gimbalPitch.value); //255-250 0-5
    thirdNum.value = buffArray[2] & 0xf;
    //保持油门
    if (keepThrottle.value == 1) {
      keepRiseOrDrop(); //保持当前油门
    }
    //云台偏航
    if (gimbalYaw.value >= 200) {
      gimbalControl("right");
    } else if (gimbalYaw.value <= 30) {
      gimbalControl("left");
    }
    //云台俯仰
    if (gimbalPitch.value <= 30) {
      gimbalControl("up");
    } else if (gimbalPitch.value >= 200) {
      gimbalControl("down");
    }
  }, 500);

  //云台处理函数
  const gimbalControl = (mode) => {
    let action = JSON.parse(JSON.stringify(control));
    switch (mode) {
      case "right":
        if (flag) {
          action.code = "CONTROLLER_YUNTAI";
          action.data["yaw"] = 5;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "left":
        if (flag) {
          action.code = "CONTROLLER_YUNTAI";
          action.data["yaw"] = -5;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "up":
        if (flag) {
          action.code = "CONTROLLER_YUNTAI";
          action.data["pitch"] = 5;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      case "down":
        if (flag) {
          action.code = "CONTROLLER_YUNTAI";
          action.data["pitch"] = -5;
          action.sn = sn;
          websocketsend(action);
        } else {
          messageOnce.warning(t("136"));
        }
        break;
      default:
        break;
    }
  };

  //摇杆推动 -> 油门上升下降的函数
  const count = ref(0); //防止一上一下抖动设置的变量
  const throttleRiseOrDrop = (newNum, oldNum) => {
    if (oldNum !== -1) {
      if (newNum - oldNum > 0) {
        count.value--;
        if (count.value - 0 == -2 && throttleLock.value == 1) {
          let action = JSON.parse(JSON.stringify(control));
          if (flag) {
            action.code = "CONTROLLER_NEST_ATTITUDE";
            action.data["vt"] = -1;
            action.sn = sn;
            websocketsend(action);
          } else {
            messageOnce.warning(t("136"));
          }
          count.value = 0;
        } else if (count.value - 0 == -2 && throttleLock.value == 0) {
          console.log(t("139"));
          count.value = 0;
        }
      } else if (newNum - oldNum < 0) {
        count.value++;
        if (count.value - 0 == 2 && throttleLock.value == 1) {
          let action = JSON.parse(JSON.stringify(control));
          if (flag) {
            action.code = "CONTROLLER_NEST_ATTITUDE";
            action.data["vt"] = 1;
            action.sn = sn;
            websocketsend(action);
          } else {
            messageOnce.warning(t("136"));
          }
          count.value = 0;
        } else if (count.value - 0 == 2 && throttleLock.value == 0) {
          console.log(t("139"));
          count.value = 0;
        }
      }
    }
  };

  // 保持油门上升或下降的函数
  const keepRiseOrDrop = lodash.throttle(() => {
    if (throttleStatu.value && throttleLock.value == 1) {
      let action = JSON.parse(JSON.stringify(control));
      if (flag) {
        action.code = "CONTROLLER_NEST_ATTITUDE";
        action.data["vt"] = 1;
        action.sn = sn;
        websocketsend(action);
      } else {
        messageOnce.warning(t("136"));
      }
    } else if (throttleStatu.value === false && throttleLock.value == 1) {
      let action = JSON.parse(JSON.stringify(control));
      if (flag) {
        action.code = "CONTROLLER_NEST_ATTITUDE";
        action.data["vt"] = -1;
        action.sn = sn;
        websocketsend(action);
      } else {
        messageOnce.warning(t("136"));
      }
    } else {
      console.log(t("139"));
    }
  }, 500);

  /* 设备注册事件 */
  function deviceRegister(devices) {
    devices.forEach((device) => {
      const productName = devices[0].productName;
      if (productName.indexOf("Stick") !== -1) {
        // 摇杆
        (async function () {
          await device.open();
          watchStickReport(device);
        })();
        if (device.collections[0].featureReports.length > 0) {
          ElMessage.success(t("140"));
        }
      } else if (productName.indexOf("Throttle") !== -1) {
        //  油门
        (async function () {
          await device.open();
          watchThrottleReport(device);
        })();
        if (device.collections[0].featureReports.length > 0) {
          ElMessage.success(t("141"));
        }
      } else {
        // 其他设备
        console.log(t("142"), productName);
      }
    });
  }

  /* 监听油门设备传来的信息 */
  function watchThrottleReport(device) {
    device.addEventListener("inputreport", (event) => {
      const { data, device } = event;
      const buffArray = new Uint8Array(data.buffer);
      const productName = device.productName; // 设备名称
      if (productName.indexOf("Throttle") !== -1) {
        //  油门
        const arr = Array.from(buffArray);
        throttleHandle(arr);
        // 位数
      }
    });
  }

  /* 监听摇杆设备传来的信息 */
  function watchStickReport(device) {
    device.addEventListener("inputreport", (event) => {
      const { data, device } = event;
      const buffArray = new Uint8Array(data.buffer);
      const productName = device.productName; // 设备名称
      if (productName.indexOf("Stick") !== -1) {
        //  摇杆
        const arr = Array.from(buffArray);
        stickHandle(arr);
        // 位数
      }
    });
  }
  async function requestDevice() {
    if (!navigator.hid) {
      messageOnce.warning(t("143"));
      return false;
    }
    let devices = [];
    try {
      devices = await navigator.hid.getDevices();
      if (device.length <= 0) {
        devices = await navigator.hid.requestDevice({
          filters: [
            {
              vendorId: 1848,
            },
          ],
        });
      }
      deviceRegister(devices);
    } catch (error) {
      console.log("An error occurred.", error);
    }
    if (!devices) {
      console.log("No device was selected.");
    } else {
      console.log(`HID: ${devices}`);
    }
  }
  onBeforeUnmount(() => {
    emitter.off("ws-send");
    emitter.off("btn-switch");
    emitter.off("sn-send");
  });
  return {
    requestDevice,
  };
};

export default device;
