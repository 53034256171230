<template>
  <div class="main full_height">
    <div class="main_hd">
      <i
        class="icon iconfont icon-xiangzuo hover_opacity"
        @click="$emit('update:isVisible', false)"
      ></i>
      <span>TTS</span>
    </div>
    <div class="main_bd">
      <div class="bd_con full_height" id="editor">
        <!-- <el-input
            v-model="textarea"
            :rows="2"
            type="textarea"
            placeholder="Please input"
        /> -->
        <mavon-editor
          v-model="ttsWord"
          style="height: 100%"
          :toolbarsFlag="false"
          :subfield="false"
          :defaultOpen="'edit'"
          :fontSize="'12px'"
          :placeholder="t('446')"
        ></mavon-editor>
        <el-icon class="element_icon hover_opacity" @click="ttsWord = ''">
          <Delete />
        </el-icon>
        <div class="operate_box">
          <div>
            <!-- <span class="icon iconfont icon-zanting1 hover_opacity"></span> -->
            <span
              class="icon iconfont icon-fabu hover_opacity"
              @click="sendTTS"
            ></span>
          </div>
          <div>{{ ttsWord.length }}/999</div>
        </div>
      </div>
      <div class="btn_group">
        <div class="btn_box hover_opacity" @click="isLoop = Number(!isLoop)">
          <span class="icon iconfont icon-24gl-repeat2 mode_icon">
            <i v-show="isLoop === 0"></i>
          </span>
          <span>{{ isLoop === 0 ? t('1325') : t('441') }}</span>
        </div>
        <el-popover
          placement="top"
          trigger="click"
          popper-class="black_popper"
          :teleported="false"
          :offset="4"
        >
          <template #reference>
            <div class="btn_box hover_opacity">
              <div class="icon_circle">{{ currSpeed }}</div>
              <span>{{ t('1326') }}</span>
            </div>
          </template>
          <ul class="speed_ul">
            <li
              @click="switchSpeed(0.75)"
              class="hover_opacity"
              :class="{ active_li: currSpeed === 0.75 }"
            >
              0.75X
            </li>
            <li
              @click="switchSpeed(1)"
              class="hover_opacity"
              :class="{ active_li: currSpeed === 1 }"
            >
              1X
            </li>
            <li
              @click="switchSpeed(1.5)"
              class="hover_opacity"
              :class="{ active_li: currSpeed === 1.5 }"
            >
              1.5X
            </li>
            <li
              @click="switchSpeed(2)"
              class="hover_opacity"
              :class="{ active_li: currSpeed === 2 }"
            >
              2X
            </li>
          </ul>
        </el-popover>
        <el-popover
          placement="top"
          trigger="click"
          popper-class="black_popper"
          :teleported="false"
          :offset="4"
        >
          <template #reference>
            <div class="btn_box hover_opacity">
              <div class="icon_circle">{{ t('1327') }}</div>
              <span>{{ t('1328') }}</span>
            </div>
          </template>
          <div class="popper_box lang_box">
            <div class="box_hd">{{ t('443') }}</div>
            <div class="box_bd">
              <p>{{ t('1329') }}！</p>
              <!-- <p>{{ t('1330') }}</p> -->
              <ul class="lang_ul">
                <li
                  class="hover_opacity overflow_text"
                  v-for="item in languageList"
                  :key="item.id"
                  :class="{ active_li: currLanguage.includes(item.id) }"
                  @click="changeLang(item.id)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
        </el-popover>
        <el-popover
          placement="top"
          trigger="click"
          popper-class="black_popper"
          :teleported="false"
          :offset="4"
        >
          <template #reference>
            <div class="btn_box hover_opacity">
              <span class="icon iconfont icon-jilu mode_icon"> </span>
              <span>{{ t('1331') }}</span>
            </div>
          </template>
          <div class="popper_box tts_box">
            <div class="box_hd">{{ t('1331') }}</div>
            <div class="box_bd">
              <ul class="record_ul">
                <li
                  class="hover_opacity"
                  v-for="(item, index) in ttsRecordList"
                  :key="index"
                  @click="assignmentTts(item)"
                >
                  <p v-if="item.tts" :title="item.tts + t('1332') + item.speed + t('1333') + item.peopleType + t('1334') + (item.cycleStatus === 0 ? t('1325') : t('441'))">
                    {{ item.tts }} (<span class="text-color">{{ t('445') }}</span>:{{ item.speed }})(<span class="text-color">{{ t('1328') }}</span>:{{ item.peopleType }})(<span class="text-color">{{ t('1335') }}</span>:{{
                      item.cycleStatus === 0 ? t('1325') : t('441')
                    }})
                  </p>
                  <p v-else :title="item">{{ item }}</p>
                </li>
              </ul>
              <p>{{ t('1336') }}！</p>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script setup>
import { t } from '../../../../languages';
import { onBeforeUnmount, ref, toRefs } from "vue";
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import speaker from "@/network/speaker";
import { ElMessage } from "element-plus";

const props = defineProps({
  sn: {
    type: String,
    default: "",
  },
});
const { sn } = toRefs(props);

// 倍数对应关系
const speedObj = {
  0.5: 0,
  0.75: 25,
  1: 50,
  1.5: 75,
  2: 100,
};
const currSpeed = ref(1); // 倍数
/* 切换倍数 */
function switchSpeed(num) {
  currSpeed.value = num;
}

const isLoop = ref(0); //0--不循环，1--循环,
const languageList = [
  {
    id: 0,
    name: "普通话女",
  },
  {
    id: 1,
    name: "普通话男",
  },
  {
    id: 2,
    name: "广东话",
  },
  {
    id: 3,
    name: "台湾普通话",
  },
  {
    id: 4,
    name: "英文男",
  },
  {
    id: 5,
    name: "英文女",
  },
  {
    id: 6,
    name: "东北话",
  },
  {
    id: 7,
    name: "四川话",
  },
  {
    id: 8,
    name: "河南话",
  },
  {
    id: 9,
    name: "湖南话",
  },
  {
    id: 10,
    name: "陕西话",
  },
]; //语言列表
const currLanguage = ref([0]); //语种男女
// const currLanguageName = ref("普通话女"); //语种男女
/* 修改语言 */
function changeLang(id) {
  const langIndex = currLanguage.value.findIndex((item) => item === id);
  if (langIndex !== -1) {
    currLanguage.value.splice(langIndex, 1);
    return;
  }
  if (currLanguage.value.length < 3) {
    currLanguage.value.push(id);
  } else {
    currLanguage.value.shift();
    currLanguage.value.push(id);
  }
}

// tts记录-保存至localStorage
const ttsRecordListJson = localStorage.getItem("ttsRecordList");
const ttsRecordList = ref(
  ttsRecordListJson ? JSON.parse(ttsRecordListJson) : []
);

const ttsWord = ref(""); //tts输入内容
/* 发送tts */
async function sendTTS() {
  if (ttsWord.value.trim() === "") {
    ElMessage.warning("请输入内容");
    return false;
  }
  if (ttsWord.value.length > 999) {
    ElMessage.warning("下发内容不能大于999");
    return false;
  }
  const res = await speaker.sendTts(sn.value, {
    cycleStatus: isLoop.value,
    peopleType: currLanguage.value,
    speed: speedObj[currSpeed.value],
    tts: [ttsWord.value],
  });
  if (res.resultStatus) {
    ElMessage.success("已下发tts");
    const playLanguage = currLanguage.value
      .map((item) => languageList[item].name)
      .join(",");
    ttsRecordList.value.unshift({
      cycleStatus: isLoop.value,
      peopleType: playLanguage,
      peopleTypeVal: currLanguage.value,
      speed: currSpeed.value,
      tts: ttsWord.value,
    });
    if (ttsRecordList.value.length > 20) {
      ttsRecordList.value.length = 20;
    }
  }
}

/* 赋值tts */
function assignmentTts(item){
  if(item.tts){
    ttsWord.value = item.tts
    isLoop.value = item.cycleStatus
    currSpeed.value = item.speed
    if(item.peopleTypeVal){
      currLanguage.value = item.peopleTypeVal
    }
  } else {
    ttsWord.value = item
  }
}

onBeforeUnmount(() => {
  localStorage.setItem("ttsRecordList", JSON.stringify(ttsRecordList.value));
});
</script>

<style scoped lang="less">
.text-color {
  color: #24b5ee;
}
.main {
  position: relative;
  padding-top: 2.875rem;
}

.main_hd {
  position: absolute;
  top: 0;
  left: 1.5rem;
  right: 1.5rem;
  height: 2.875rem;
  padding: 1.125rem 0 0.75rem;
  text-align: center;

  .icon {
    position: absolute;
    top: 1.1875rem;
    left: 0;
    font-size: 1rem;
  }
}

.main_bd {
  position: relative;
  height: 100%;
  padding: 0 1.5rem 3.75rem;

  .bd_con {
    background: rgba(0, 61, 95, 1);
    border-radius: 0.3125rem;
    padding-bottom: 3.5rem;
    overflow: hidden;
  }

  .btn_group {
    height: 3.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.btn_group {
  .btn_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon_circle {
    width: 1.625rem;
    height: 1.625rem;
    line-height: 1.375rem;
    text-align: center;
    border: 2px solid #24b5ee;
    border-radius: 50%;
    font-size: 0.75rem;
    color: #24b5ee;
  }

  .iconfont {
    color: #24b5ee;
  }

  .icon-24gl-repeat2 {
    font-size: 1.625rem;
  }

  .icon-jilu {
    font-size: 1.75rem;
  }

  .mode_icon {
    position: relative;

    > i {
      position: absolute;
      top: -0.125rem;
      left: 0.7675rem;
      width: 0.125rem;
      height: 1.8rem;
      background: #24b5ee;
      transform: rotate(-53deg);
      border-radius: 0.25rem;
    }
  }
}

.bd_con {
  position: relative;

  .element_icon {
    position: absolute;
    bottom: 0.625rem;
    left: 0.625rem;
    font-size: 1.375rem;
    color: #24b5ee;
    z-index: 1600;
  }

  .operate_box {
    position: absolute;
    bottom: 0.625rem;
    right: 0.625rem;
    color: #24b5ee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1600;

    .icon {
      margin-bottom: 0.25rem;
      font-size: 1.875rem;
    }
  }
}

#editor {
  :deep(.v-note-wrapper) {
    min-height: auto;
    min-width: auto;
    background: transparent;
    box-shadow: none !important;

    .v-note-edit.divarea-wrapper,
    .auto-textarea-input,
    .v-note-edit.divarea-wrapper .content-input-wrapper {
      background: rgba(0, 61, 95, 1) !important;
      color: #fff;
    }

    .content-input-wrapper {
      padding: 10px;
    }

    textarea::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #24b5ee !important;
    }

    textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #24b5ee !important;
    }

    textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #24b5ee !important;
    }

    textarea::-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #24b5ee !important;
    }
  }
}

.speed_ul {
  padding: 6px;
  white-space: nowrap;

  li {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    border-width: 2px;
    border-color: #24b5ee;
    border-style: solid;
    color: #24b5ee;
    font-size: 12px;
    text-align: center;
  }

  li:not(:last-child) {
    margin-right: 6px;
  }

  .active_li {
    border-color: #fff;
    color: #fff;
  }
}

.btn_group {
  :deep(.black_popper) {
    width: auto !important;
    min-width: auto;
    background: #002238;
    padding: 0;
    border: none;

    .el-popper__arrow::before {
      display: none;
    }
  }
}

.popper_box {
  .box_hd {
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    border-bottom: 1px solid #24b5ee;
  }
}

.lang_box {
  .box_bd {
    padding: 10px 16px;

    > p {
      text-align: center;
      color: #24b5ee;
      padding-bottom: 10px;
    }
  }
}

.tts_box {
  width: 21rem;

  .box_bd {
    padding: 0 0.75rem 0.75rem;

    > p {
      text-align: center;
      color: #24b5ee;
      padding-top: 0.5rem;
    }
  }
}

.lang_ul {
  display: flex;
  flex-wrap: wrap;
  width: 294px;

  li {
    width: 60px;
    height: 26px;
    line-height: 26px;
    background: #24b5ee;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
    margin-right: 18px;
    font-size: 12px;
  }

  li:nth-of-type(4n) {
    margin-right: 0;
  }

  li.active_li {
    background: #fff;
    color: #24b5ee;
  }
}

.record_ul {
  height: 6.25rem;
  overflow: auto;

  li {
    padding: 0.375rem 0.625rem;
    line-height: 1;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
    overflow: hidden;

    > p {
      max-height: 52px;
      line-height: 1.2;
      overflow: hidden;
    }
  }
}
</style>
