<template>
  <drag-dialog
    v-model:visible="dialogVisible"
    :right="48"
    :top="10"
    :width="27.25"
    :height="34"
    titlePosition="center"
    :title="t('477')"
  >
    <div class="box">
      <div class="box_bd">
        <div class="con_hd">
          <play-music
            :audioList="audioList"
            :sn="sn"
            ref="playMusicRef"
            @changeMode="changeMode"
          ></play-music>
        </div>
        <div class="con_bd">
          <div
            class="full_height"
            v-show="
              audioFileShow === false &&
              searchLightShow === false &&
              ttsShow === false &&
              angleAdjustShow === false
            "
          >
            <div class="menu_list">
              <div class="menu_item hover_opacity choose_item">
                <span
                  class="icon iconfont icon-maikefeng1 hover_opacity"
                ></span>
                <p class="info">{{ t('921') }}</p>
              </div>
              <div class="menu_item tts hover_opacity" @click="ttsShow = true">
                <span class="icon iconfont icon-TTSwenzizhuanyuyin"></span>
                <p class="info" style="margin-top: 1.1px">TTS</p>
              </div>
              <div
                class="menu_item hover_opacity"
                @click="
                  audioFileShow = true;
                  getAudioList();
                "
              >
                <span class="icon iconfont icon-audio"></span>
                <p class="info">{{ t('440') }}</p>
              </div>
              <div
                class="menu_item hover_opacity"
                @click="searchLightShow = true"
              >
                <span class="icon iconfont icon-shoudiantong"></span>
                <p class="info">{{ t('452') }}</p>
              </div>
              <!-- <div
                class="menu_item right_item hover_opacity"
                @click="angleAdjustShow = true"
              >
                <span class="icon iconfont icon-a-ziyuan126"></span>
                <p class="info">{{ t('922') }}</p>
              </div> -->
            </div>
            <div class="record_box">
              <record-voice :sn="sn"></record-voice>
            </div>
          </div>
          <div class="abso_box" v-show="audioFileShow">
            <audio-file
              v-model:isVisible="audioFileShow"
              :sn="sn"
              :playMode="playMode"
              :audioList="audioList"
              @playAudio="childPlayAudio"
              @getAudioList="getAudioList"
            ></audio-file>
          </div>
          <div class="abso_box" v-show="searchLightShow">
            <search-light
              v-model:isVisible="searchLightShow"
              :sn="sn"
            ></search-light>
          </div>
          <div class="abso_box" v-if="ttsShow">
            <tts v-model:isVisible="ttsShow" :sn="sn"></tts>
          </div>
          <!-- <div class="abso_box" v-if="angleAdjustShow">
            <angle-adjust
              v-model:isVisible="angleAdjustShow"
              :sn="sn"
            ></angle-adjust>
          </div> -->
        </div>
      </div>
      <div class="box_ft">
        <bg-music :sn="sn"></bg-music>
      </div>
    </div>
  </drag-dialog>
</template>

<script setup>
import { t } from '../../../languages';
import playMusic from "./control-stage/play-music.vue";
import bgMusic from "./control-stage/bg-music.vue";
import audioFile from "./control-stage/audio-file.vue";
import searchLight from "./control-stage/search-light.vue";
import recordVoice from "./control-stage/record-voice.vue";
import tts from "./control-stage/tts.vue";
// import angleAdjust from "./control-stage/angle-adjust.vue";
import emitter from "@/utils/mitt";
import { ref, toRefs, watch, onBeforeUnmount, onMounted } from "vue";
import socket from "@/utils/websocket";
import { store } from "@/store";
import speaker from "@/network/speaker.js";
import { ElMessage } from "element-plus";

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  sn: {
    type: String,
    default: "",
  },
});

const { sn, isVisible } = toRefs(props);
if(!sn.value){
  ElMessage.warning(t('923'))
}
const { websocketsend } = socket();
const state = store.state;
const vuexUserId = state.user.userInfo.id; //用户id
const vuexUserName = state.user.userInfo.username; //用户名

// 请求获取音频列表数据
function getAudioList() {
  websocketsend({
    action: "GET_AUDIO_LIST",
    code: vuexUserId,
    sn: sn.value,
  });
}
getAudioList();
const topics = [
  "OPUS_LIST",
  "MEGAPHONE_STA_WS",
  "MEGAPHONE_PLAY_SOURCE_WS",
  "MEGAPHONE_PLAY_STA_WS",
];
/* 订阅主题 */
const subscribeTopic = {
  action: "ACCEPT",
  code: "",
  data: topics,
};
websocketsend(subscribeTopic);

/* 获取喊话器基础信息 */
async function getSpeakerBasicInfo() {
  const res = await speaker.getSpeakerBasicInfo(sn.value);
  if (res.resultStatus) {
    console.log(res);
    const resultData = res.resultData;
    // 设备状态信息
    emitter.emit("speaker-device-status", {
      data: resultData.deviceStatus
    });
    // 播放源信息
    emitter.emit("speaker-source-status", {
      data: resultData.playSourceStatus
    });
    // 播放音频状态信息
    emitter.emit("speaker-audio-status", {
      data: resultData.playStatus
    });
  }
}
getSpeakerBasicInfo();

const audioList = ref(); //音频文件列表
function wsMessage(dataInfo) {
  if (dataInfo.target === sn.value) {
    // 机场sn一致
    const newList = [];
    for (let i = 0; i < dataInfo.data.length; i++) {
      const splitList = dataInfo.data[i].split("||");
      const item = {};
      item.audioId = "id" + i;
      item.audioName = splitList[0];
      item.author = splitList[1];
      item.duration = parseInt(splitList[2]);
      newList.push(item);
    }
    audioList.value = newList;
  }
}

emitter.on("speaker-audio-list", wsMessage);

onBeforeUnmount(() => {
  emitter.off("speaker-audio-list", wsMessage);
});

const playMusicRef = ref(null);
/* 播放组件播放 */
function childPlayAudio(data) {
  playMusicRef.value.playAudio(data);
}

const emit = defineEmits(["update:isVisible"]);
const dialogVisible = ref(null);
dialogVisible.value = isVisible.value;
watch(dialogVisible, (newProps) => {
  dialogVisible.value = newProps;
  emit("update:isVisible", false);
});

const audioFileShow = ref(false); //音频文件开关
const searchLightShow = ref(false); //探照灯开关
const ttsShow = ref(false); //TTS开关
const angleAdjustShow = ref(false); //角度调节开关

const playMode = ref("1"); //播放模式
function changeMode(mode) {
  playMode.value = mode;
}

speaker.openSpeakLog(sn.value, {
  operateLog: vuexUserName + t("924"),
});

/* 关闭喊话器 */
async function sendCloseSpeak() {
  for (let i = 0; i < topics.length; i++) {
    websocketsend({
      action: "UN_ACCEPT",
      code: "",
      data: topics[i],
    });
  }

  // 解决刷新关闭系统 无法发送关闭异步请求
  navigator.sendBeacon(
    t(`${process.env.VUE_APP_BASE_API}/api/v1/audio/isOpen/${sn.value}?operateLog=${vuexUserName}  关闭喊话器`)
  );
}

onMounted(() => {
  window.addEventListener("beforeunload", sendCloseSpeak);
});

onBeforeUnmount(() => {
  sendCloseSpeak();
  window.removeEventListener("beforeunload", sendCloseSpeak);
});
</script>

<style scoped lang="less">
.box {
  position: absolute;
  top: 2.4375rem;
  bottom: 0;
  left: 0;
  right: 0;
  .box_bd {
    position: relative;
    height: 100%;
    padding-bottom: 4.25rem;
    padding-top: 10.625rem;
    // border-bottom: 1px solid rgba(35, 163, 236, 0.18);
    .con_hd {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 10.625rem;
      border-bottom: 0.0625rem solid rgba(35, 163, 236, 0.18);
    }
    .con_bd {
      position: relative;
      height: 100%;
      font-size: 0.875rem;
      .abso_box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }
  }
  .box_ft {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4.25rem;
    background: rgba(0, 0, 0, 0.1);
  }
}

.menu_list {
  padding: 1.125rem 1.125rem 0;
  display: flex;
  justify-content: flex-start;
  .menu_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 0.75rem;
    width: 48px;
    .icon {
      padding-bottom: 0.375rem;
      font-size: 1.75rem;
      color: #24b5ee;
    }
  }
  .menu_item:not(:last-child) {
    margin-right: 0.75rem;
  }
  .right_item {
    margin-left: auto;
  }
  .choose_item {
    .icon {
      color: #fff;
    }
  }
}
.record_box {
  padding: 1.875rem 3.75rem 0;
}
</style>
  