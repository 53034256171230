import { t } from '../../languages';
import ufi3d from "@/utils/ufi-cesium";
import { inject, onBeforeUnmount, ref } from "vue";
import { v4 as uuidv4 } from "uuid";

import ConstantProperty from "cesium/Source/DataSources/ConstantProperty";
import html2canvas from "html2canvas";
import CoordTransform from "@/utils/cesium/CoordTransform";

/**
 *
 * @param {*测距/面右击回调函数} rightCallback
 * @returns
 */
const cesiumOperation = (rightCallback) => {
  let viewer = null;
  let cesiumDom = "";
  let handler = null;
  const Cesium = inject("Cesium");

  /**
   * 初始化地球
   * @param {* cesium dom的id} el --string
   */
  function initEarth(el) {
    cesiumDom = el;
    Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
      90.0, // 东
      30.0, // 南
      110.0, // 西
      45.0 // 北
    );
    const tiandituConSess = localStorage.getItem('tiandituCon')
    viewer = ufi3d.initCesium(el, {
      // 影像地图
      // imageryProvider: new Cesium.UrlTemplateImageryProvider({
      //     url: window.g.satelliteMapUrl + "/{z}/{x}/{y}.png",
      //     tilingScheme: new Cesium.WebMercatorTilingScheme(),
      // }),
      // 地形
      terrainProvider: setTerrain(),
      // 截屏配置，防止空白
      contextOptions: {
        webgl: {
          alpha: true,
          depth: false,
          stencil: true,
          antialias: true,
          premultipliedAlpha: true,
          preserveDrawingBuffer: true,
          failIfMajorPerformanceCaveat: true,
        },
        allowTextureFilterAnisotropic: true,
      },
    });
    // viewer.scene.debugShowFramesPerSecond = true;
    // 需要带上，不然会加载默认地图
    viewer.imageryLayers.removeAll();
    if (tiandituConSess === 'true') {
      // 天地图
      const subdomains = ["0", "1", "2", "3", "4", "5", "6", "7"];
      const layers = viewer.imageryLayers;
      layers.addImageryProvider(
        new Cesium.WebMapTileServiceImageryProvider({
          //影像底图
          url:
            "https://t{s}.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=" +
            "47421dd0051ada3957f279b484932789",
          subdomains: subdomains, //URL模板中用于{s}占位符的子域。如果该参数是单个字符串，则字符串中的每个字符都是一个子域。如果它是一个数组，数组中的每个元素都是一个子域
          layer: "tdtImgLayer",
          style: "default",
          format: "image/jpeg",
          tileMatrixSetID: "GoogleMapsCompatible", //使用谷歌的瓦片切片方式
          maximumLevel: 18,
          show: true,
        })
      );
      layers.addImageryProvider(
        new Cesium.WebMapTileServiceImageryProvider({
          //影像注记
          url:
            "https://t{s}.tianditu.gov.cn/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=" +
            "47421dd0051ada3957f279b484932789",
          subdomains: subdomains,
          layer: "tdtCiaLayer",
          style: "default",
          format: "image/jpeg",
          tileMatrixSetID: "GoogleMapsCompatible",
          maximumLevel: 18,
          show: true,
        })
      );
    } else {
      // 谷歌地图
      const layers = viewer.imageryLayers;
      layers.addImageryProvider(
        new Cesium.UrlTemplateImageryProvider({
          url: window.g.satelliteMapUrl + "/{z}/{x}/{y}.png",
          tilingScheme: new Cesium.WebMercatorTilingScheme(),
        })
      );
    }
    handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
    registerNormalEvent();
  }

  /* 设置地形 */
  function setTerrain() {
    const mars3dTerrain = localStorage.getItem('mars3dTerrain')
    const terrainUrl = (mars3dTerrain == 'true') ? 'https://data.mars3d.cn/terrain' : window.g.terrainUrl
    return new Cesium.CesiumTerrainProvider({
        url: terrainUrl
    })
  }

  //创建禁飞区多边形体
  function createNoFlyCylinder(data, height, color) {
    var ellipsoid = viewer.scene.globe.ellipsoid;
    let positionArr = [];
    data.coordinateObj.forEach((e) => {
      //经纬度转世界坐标
      var cartographic = Cesium.Cartographic.fromDegrees(e.lng, e.lat, e.alt);
      var cartesian3 = ellipsoid.cartographicToCartesian(cartographic);
      positionArr.push(cartesian3);
    });
    var cuboid = viewer.entities.add({
      name: data.fenceName,
      id: data.regionId,
      polygon: {
        hierarchy: new Cesium.PolygonHierarchy(positionArr),
        height: 0,
        extrudedHeight: data.height === null ? 500 : data.height,
        outline: true,
        outlineWidth: 100,
        outlineColor: Cesium.Color.fromCssColorString("#ffffff").withAlpha(0.4),
        arcType: Cesium.ArcType.RHUMB,
        material:
          color === "red"
            ? new Cesium.ColorMaterialProperty(
                Cesium.Color.fromCssColorString("#e85368").withAlpha(0.4)
              )
            : new Cesium.ColorMaterialProperty(
                Cesium.Color.fromCssColorString("#eec817").withAlpha(0.4)
              ),
      },
    });
  }
  //删除实体
  function delNoFlyCylinder(data) {
    viewer.entities.removeById(data.regionId);
  }
  //
  function createOrNocreateCylinder(data, color) {
    if (viewer.entities.getById(data.regionId) === undefined) {
      createNoFlyCylinder(data, 0, color);
    }
  }
  function createOrNocreateYesFlyCylinder(
    data,
    lng,
    lat,
    radius,
    height,
    Gird
  ) {
    console.log(viewer.entities.getById("yes_fly"));
    if (viewer.entities.getById("yes_fly") !== undefined) {
      viewer.entities.removeById("yes_fly");
    }
    createYesFlyCylinder(data, lng, lat, radius, height, Gird);
  }
  //创建可飞区圆柱体
  function createYesFlyCylinder(data, lng, lat, radius, height, Gird) {
    if (!data) {
      viewer.entities.removeById("yes_fly");
      return;
    }
    var cuboid = viewer.entities.add({
      name: t("69"),
      id: "yes_fly",
      position: Cesium.Cartesian3.fromDegrees(lng, lat),
      cylinder: {
        length: height, //圆锥高度
        topRadius: radius, //顶部半径
        bottomRadius: radius, //底部半径
        material: new Cesium.ColorMaterialProperty(
          Cesium.Color.fromCssColorString("#03caca").withAlpha(0.2)
        ),
      },
    });
  }
  const entityGird = ref([]);
  //创建可飞区网格
  function createYesFlyGrid(status, airportData, yesFlyList) {
    if (!status) {
      if (entityGird.value.length !== 0) {
        // 删除所有的点实体
        entityGird.value.forEach((item) => {
          viewer.entities.remove(item);
        });
        entityGird.value = [];
        return;
      }
      return;
    }
    var ellipsoid = viewer.scene.globe.ellipsoid;
    var cartographic = Cesium.Cartographic.fromDegrees(
      airportData.resultData.longitude,
      airportData.resultData.latitude,
      0
    );
    var cartesian3 = ellipsoid.cartographicToCartesian(cartographic);
    let newRPosition = getNorthPointByDistance(
      cartesian3,
      yesFlyList.coordinateObj.radius,
      "y"
    );
    //正方形右上角坐标
    const newRPositionX = getNorthPointByDistance(
      newRPosition,
      yesFlyList.coordinateObj.radius,
      "x"
    );
    const cartographicX = ellipsoid.cartesianToCartographic(newRPositionX);
    const lngX = Cesium.Math.toDegrees(cartographicX.longitude); //右上角经度
    const latX = Cesium.Math.toDegrees(cartographicX.latitude); //右上角纬度
    //正方形左上角坐标
    const newRPositionWest = getNorthPointByDistance(
      newRPosition,
      -yesFlyList.coordinateObj.radius,
      "x"
    );
    const cartographicWest =
      ellipsoid.cartesianToCartographic(newRPositionWest);
    const lngWest = Cesium.Math.toDegrees(cartographicWest.longitude); //左上角经度
    //正方形左下角坐标
    const newRPositionNorth = getNorthPointByDistance(
      newRPositionWest,
      -(yesFlyList.coordinateObj.radius * 2),
      "y"
    );
    const cartographicNorth = Cesium.Cartographic.fromCartesian(
      newRPositionNorth,
      viewer.scene.globe.ellipsoid
    );
    const latNorth = Cesium.Math.toDegrees(cartographicNorth.latitude); //左下角纬度
    const latTureM = 0.00000899; //纬度（南北方向）1M实际度
    let tempLng = yesFlyList.coordinateObj.interval * latTureM;
    let spaceLng = Number(tempLng.toFixed(4));
    let cartographicWestc = ellipsoid.cartesianToCartographic(newRPositionWest);
    let cartographicWesty = Cesium.Math.toDegrees(cartographicWestc.latitude);
    let cartographicNorthc =
      ellipsoid.cartesianToCartographic(newRPositionNorth);
    let cartographicNorthy = Cesium.Math.toDegrees(cartographicNorthc.latitude);
    let lngAllLong = cartographicWesty - cartographicNorthy;
    //分成了多少份
    let allSpaceShare = Math.ceil(lngAllLong / spaceLng);
    const alphas = [];
    let start = "A".charCodeAt(0); //65
    let last = "Z".charCodeAt(0); //90

    for (start; start <= last; start++) {
      alphas.push(String.fromCharCode(start));
    }
    //每隔20读绘制一条经度线和经度标注,自己控制间隔
    let flag = 0;
    let flagZM = 0;
    for (let lang = lngWest; lang <= lngX + spaceLng; lang = lang + spaceLng) {
      flag = flag + 1;
      let link = viewer.entities.add({
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArray([
            lang,
            latX,
            lang,
            latNorth,
          ]),
          width: 3.0,
          material: Cesium.Color.fromCssColorString("#FFFFFF").withAlpha(1),
          clampToGround: true,
        },
      });
      entityGird.value.push(link);
      //取线postions集合，取其1/2处的postion
      var position =
        link._polyline._positions._value[
          link._polyline._positions._value.length - 2 / 2
        ];
      //生成点
      if (flag <= allSpaceShare + 1) {
        let point = viewer.entities.add({
          //点
          position: position,
          point: {
            pixelSize: 1, //设置小点  基本看不见
            color: new Cesium.Color(0.0, 0.0, 0.0, 0.0), //颜色设置透明
          },
          label: {
            text: `${flag}`, //描述内容
            font: "normal 18px MicroSoft YaHei",
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            fillColor: Cesium.Color.WHITE,
            pixelOffset: new Cesium.Cartesian2(25, 0), //偏移量
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
              5.0,
              5000.0
            ),
          },
        });
        entityGird.value.push(point);
      }
    }
    //每隔10读绘制一条纬度线和纬度标注,自己控制间隔
    for (let lat = latNorth; lat <= latX; lat = lat + spaceLng) {
      flagZM = flagZM + 1;
      let link = viewer.entities.add({
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArray([
            lngWest,
            lat,
            lngX,
            lat,
          ]),
          width: 1.0,
          material: Cesium.Color.fromCssColorString("#FFFFFF").withAlpha(1),
          clampToGround: true,
        },
      });
      entityGird.value.push(link);
      //取线postions集合，取其1/2处的postion
      var position = link._polyline._positions._value[0];
      //生成点
      if (flagZM < allSpaceShare) {
        let point = viewer.entities.add({
          //点
          position: position,
          point: {
            pixelSize: 1, //设置小点  基本看不见
            color: new Cesium.Color(0.0, 0.0, 0.0, 0.0), //颜色设置透明
          },
          label: {
            text: `${alphas[flagZM - 1]}`, //描述内容
            font: "normal 18px MicroSoft YaHei",
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            fillColor: Cesium.Color.WHITE,
            pixelOffset: new Cesium.Cartesian2(-25, -20), //偏移量
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
              5.0,
              5000.0
            ),
          },
        });
        entityGird.value.push(point);
      }
    }
  }
  function getNorthPointByDistance(position, distance, data) {
    //以点为原点建立局部坐标系（东方向为x轴,北方向为y轴,垂直于地面为z轴），得到一个局部坐标到世界坐标转换的变换矩阵
    var localToWorld_Matrix =
      Cesium.Transforms.eastNorthUpToFixedFrame(position);
    if (data === "y") {
      return Cesium.Matrix4.multiplyByPoint(
        localToWorld_Matrix,
        Cesium.Cartesian3.fromElements(0, distance, 0),
        new Cesium.Cartesian3()
      );
    } else if (data === "x") {
      return Cesium.Matrix4.multiplyByPoint(
        localToWorld_Matrix,
        Cesium.Cartesian3.fromElements(distance, 0, 0),
        new Cesium.Cartesian3()
      );
    } else if (data === "z") {
      return Cesium.Matrix4.multiplyByPoint(
        localToWorld_Matrix,
        Cesium.Cartesian3.fromElements(0, 0, distance),
        new Cesium.Cartesian3()
      );
    }
  }
  /* 销毁地球 */
  function destoryEearth() {
    viewer.entities.removeAll();
    viewer = null;
    document.querySelector("#" + cesiumDom).innerHTML = "";
  }

  function getModelByType(type) {
    let modelUrl = "x3huan.glb";
    let scale = 0.000001;
    // switch (type) {
    //     case "XNest 3 - E":
    //         // 御3充电
    //         modelUrl = "x3chong.glb";
    //         scale = 0.000001;
    //         break;
    //     case "XNest 3Plus - E":
    //         // 御3换电
    //         modelUrl = "x3huan.glb";
    //         scale = 0.000001;
    //         break;
    //     case "XNest 2":
    //     // 御2平开
    //     case "XNest 2Plus - E":
    //         // 御2换电平开
    //         modelUrl = "x2ping.glb";
    //         scale = 0.000001;
    //         break;
    //     default:
    //         modelUrl = "airport.glb";
    //         scale = 1;
    //         break;
    // }
    return {
      modelUrl,
      scale,
    };
  }

  const pointColorMap = {
    0: "#BEE7E9",
    1: "#00F729",
  };
  const airportColorMap = {
    0: "#00E5EE",
    1: "#008616",
  };
  const modelSn = ref("");
  const modelName = ref("");

  let circleWave = null;

  function createAirportEntity(list) {
    // circleWave = new ufi3d.CircleWave(viewer);
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      // if (!item.lat && !item.lng) {
      //     break;
      // }
      const lng = item.lng ? Number(item.lng) : 0;
      const lat = item.lat ? Number(item.lat) : 0;
      const altitude = item.altitude ? Number(item.altitude) : 0;
      const direction = item.direction ? Number(item.direction) : 90;
      const pointColor = pointColorMap[item.state];
      const color = airportColorMap[item.state];
      const position = Cesium.Cartesian3.fromDegrees(lng, lat, altitude);
      const { modelUrl, scale } = getModelByType(item.modelType);
      // 模型贴地
      const terrainProvider = setTerrain();
      const ellipsoid = viewer.scene.globe.ellipsoid;
      const pointCartographic = ellipsoid.cartesianToCartographic(position);
      const promise = Cesium.sampleTerrainMostDetailed(terrainProvider, [
        pointCartographic,
      ]);
      Promise.resolve(promise).then(function (updatedPositions) {
        if (updatedPositions[0].height > altitude) {
          pointCartographic.height = updatedPositions[0].height;
        } else {
          pointCartographic.height = altitude;
        }
        const newPosition =
          ellipsoid.cartographicToCartesian(pointCartographic);
        viewer.entities.add({
          id: "model-" + item.sn,
          type: "model",
          sn: item.sn,
          name: item.deviceName,
          position: newPosition,
          orientation: Cesium.Transforms.headingPitchRollQuaternion(
            newPosition,
            new Cesium.HeadingPitchRoll(
              Cesium.Math.toRadians(direction), // 设置这个属性即可（顺时针旋转的角度值）
              Cesium.Math.toRadians(0),
              Cesium.Math.toRadians(0)
            )
          ),
          point: {
            pixelSize: 10,
            color: Cesium.Color.fromCssColorString(pointColor),
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
              100000,
              Number.MAX_VALUE
            ),
          },
          model: {
            uri: "public/model/" + modelUrl,
            minimumPixelSize: 20,
            // scale: 6,
            scale: scale,
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
              0,
              100000
            ),
          },
        });
      });
      // circleWave.add("circle-" + item.sn, [lng - 0.0000015, lat - 0.000015], color, 5, 20000);
    }
  }

  /**
   * 修改机场模型颜色
   * @param {* 机场ssn} sn  --string
   * @param {* 机场状态 } state --string(0 | 1)
   * @returns
   */
  function updateCircle(sn, state) {
    if (!circleWave) {
      return;
    }
    const pointEntity = viewer.entities.getById("model-" + sn);
    if (pointEntity) {
      pointEntity.point.color = Cesium.Color.fromCssColorString(
        pointColorMap[state]
      );
    }
    circleWave.updateCircleParam("circle-" + sn, airportColorMap[state], 20000);
  }

  /**
   * 定位到机场位置
   * @param {* 机场sn} airportSn
   * @returns
   */
  function positionAirport(airportSn) {
    console.log(t("70"), airportSn);
    if (!airportSn) {
      return;
    }
    const airportEntity = viewer.entities.getById("model-" + airportSn);
    if (airportEntity) {
      ufi3d.viewerFlyTo(viewer, {
        entity: airportEntity,
        heading: 0,
        pitch: -45,
        range: 20,
        duration: 2,
      });
    }
  }

  function dbPositionAirport(airportSn) {
    if (!airportSn) {
      return;
    }
    const airportEntity = viewer.entities.getById("model-" + airportSn);
    if (airportEntity) {
      ufi3d.viewerFlyTo(viewer, {
        entity: airportEntity,
        heading: 0,
        pitch: -45,
        range: 500,
        duration: 2,
      });
    }
  }

  /* 放大地球 */
  function enlargeEarth() {
    let position = viewer.camera.position;
    let cameraHeight =
      viewer.scene.globe.ellipsoid.cartesianToCartographic(position).height;
    // 每次缩小 20 倍，参数可改
    let moveRate = cameraHeight / 5.0;
    viewer.camera.moveForward(moveRate);
  }

  /* 缩小地球 */
  function shrinkEarth() {
    let position = viewer.camera.position;
    let cameraHeight =
      viewer.scene.globe.ellipsoid.cartesianToCartographic(position).height;
    // 每次缩小 20 倍，参数可改
    let moveRate = cameraHeight / 5.0;
    viewer.camera.moveBackward(moveRate);
  }
  let trackLine; //轨迹对象
  let hasTrackline = false; //是否有轨迹

  let isFlying = false;
  /**
   * 切换2D/3D
   * @param {* 模式类型 } modeType -- string (2D | 3D)
   */
  function toggleSceneMode(modeType) {
    if (isFlying) return false;
    // 根据切换模式决定最终相机俯仰角度  -- -90为视角平行于地面  -15为近似垂直于海平面
    const finallyPitch = modeType === "2D" ? -90 : -15;
    // 弧度表示
    const pitchRadian = Cesium.Math.toRadians(finallyPitch);

    const camera = viewer.camera; // 相机
    const position = camera.position; // 相机位置
    const heading = camera.heading; // 相机朝向
    const pitch = camera.pitch; // 相机俯仰
    const roll = camera.roll; // 相机翻滚

    const pitchAngle = (pitch * 180) / Math.PI; // 相机俯仰角度表示

    // 计算屏幕中心点位置
    // 屏幕中心点二维坐标
    const center = new Cesium.Cartesian2(
      viewer.canvas.clientWidth / 2,
      viewer.canvas.clientHeight / 2
    );
    // 获取中心点的射线
    const ray = viewer.camera.getPickRay(center);
    // 根据射线和场景 获取 射线与椭球交叉的点（即中心点拾取到的三维坐标）
    const intersection = viewer.scene.globe.pick(ray, viewer.scene);
    isFlying = true;
    setTimeout(() => {
      isFlying = false;
    }, 2200);
    if (intersection === undefined) {
      // 没有拾取到  -- 此时中心点可能为天空
      viewer.camera.flyTo({
        destination: position,
        orientation: {
          heading: heading,
          pitch: pitchRadian,
          roll: roll,
        },
      });
    } else {
      // 拾取到  -- 判断俯仰角是否过于贴近地平线
      if (pitchAngle > -15) {
        // 过于贴近地面 -- 直接旋转相机
        console.log(pitchAngle);
        viewer.camera.flyTo({
          destination: position,
          orientation: {
            heading: heading,
            pitch: pitchRadian,
            roll: roll,
          },
        });
      } else {
        // 绕中心点进行俯仰切换
        const distance = Cesium.Cartesian3.distance(position, intersection);
        const entity = viewer.entities.add({
          show: true,
          position: intersection,
          // 不能去掉point，去掉无法执行flyTo
          point: {},
        });
        viewer.flyTo(entity, {
          offset: new Cesium.HeadingPitchRange(heading, pitchRadian, distance),
          duration: 2,
        });
        setTimeout(() => {
          viewer.entities.remove(entity);
        }, 3000);
      }
    }
    return true;
  }

  /**
   * 切换卫星图/行政图
   * @param {* 地图类型} mapType -- string （satellite-卫星图 | administration-行政图）
   */
  function updateMap(mapType) {
    let mapPath = "";
    if (mapType === "satellite") {
      // 卫星图
      mapPath = window.g.satelliteMapUrl;
    } else {
      //行政图
      mapPath = window.g.administrativeMapUrl;
    }
    viewer.imageryLayers.remove(viewer.imageryLayers._layers[0]);
    const imageryProvider = new Cesium.UrlTemplateImageryProvider({
      url: mapPath + "/{z}/{x}/{y}.png",
      tilingScheme: new Cesium.WebMercatorTilingScheme(),
    });
    viewer.imageryLayers.addImageryProvider(imageryProvider, 0);
  }

  /**
   * 相机定位
   * @param {* 位置} position  Array
   */
  function cameraPosition(position) {
    if (position.length < 0) {
      return;
    }
    const arr = CoordTransform.GCJ02ToWGS84(
      Number(position[0]),
      Number(position[1])
    );
    const center = Cesium.Cartesian3.fromDegrees(arr[0], arr[1], 1000);
    viewer.camera.flyTo({
      destination: center,
      orientation: {
        heading: Cesium.Math.toRadians(0),
        pitch: Cesium.Math.toRadians(-90.0),
        roll: 0.0,
      },
    });
  }

  let customImagery = null; //tif影像对象
  /**
   * 添加影像图
   * @param {* 影像图id} id
   * @param {* 影像图地址} addr
   * @param {* 影像图元信息} tifInfo
   */
  function addImagery(id, addr, tifInfo, isZoomTo) {
    if (!customImagery) {
      customImagery = new ufi3d.CustomImagery(viewer);
    }
    customImagery.addImagery(id, addr, tifInfo, isZoomTo);
  }

  /**
   * 切换影像图显示方式
   * @param {* 影像图id} id
   * @param {* 是否显示} flag
   */
  function toggleImagery(id, flag) {
    customImagery.showHideImagery(id, flag);
  }

  /**
   * 定位影像图
   * @param {* 影像图id} id
   */
  function zoomToImagery(id) {
    customImagery.zoomToImagery(id);
  }

  let pointCloud = null; //点云对象
  /**
   * 添加图层
   * @param {* 图层id} id
   * @param {* 图层地址} addr
   * @param {* 是否定位} displayFlag
   */
  function add3DTiles(id, addr, displayFlag) {
    if (!pointCloud) {
      pointCloud = new ufi3d.PointCloud(viewer);
    }
    pointCloud.addPointCloud(id, addr, 0, displayFlag);
  }

  /**
   * 切换图层显示方式
   * @param {* 图层id} id
   * @param {* 是否显示} flag
   */
  function toggle3DTiles(id, flag) {
    pointCloud.showHidePointCloud(id, flag);
  }

  /**
   * 定位影像图
   * @param {* 图层id} id
   */
  function zoomTo3DTiles(id) {
    pointCloud.jumpCloudPoint(id);
  }

  const canMark = ref(false);
  let currMarkPoint = null;
  let markName = "";
  let markColor = "#de2d2d";

  /* 取消-标记名称和颜色默认值 */
  function emptyMark() {
    markName = "";
    markColor = "#de2d2d";
  }

  /**
   * 注册标记点事件
   * @param {* 回调函数} callback
   *      @param {* 经度} lng
   *      @param {* 纬度} lat
   *      @param {* 高度} height
   */
  function registerMarkPoint(callback) {
    unRegisterNormalEvent();
    unRegisterMarkPoint();
    handler.setInputAction((e) => {
      // 返回具有' primitive'属性的对象（是否拾取到实体）
      if (canMark.value === true) {
        const position = viewer.scene.pickPosition(e.endPosition);
        if (!position) {
          return;
        }
        if (currMarkPoint !== null) {
          currMarkPoint.position = position;
        } else {
          currMarkPoint = createMark("", position, markName, markColor, false);
        }
        const cartographic = Cesium.Cartographic.fromCartesian(
          position,
          viewer.scene.globe.ellipsoid
        );
        // 位置回调
        callback(
          Cesium.Math.toDegrees(cartographic.longitude),
          Cesium.Math.toDegrees(cartographic.latitude),
          cartographic.height
        );
        canMark.value = false;
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  /* 取消注册标记点事件 */
  function unRegisterMarkPoint() {
    handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE); // 解除viewer的MOUSE_MOVE事件监听器
    registerNormalEvent();
    emptyMark();
  }

  /**
   * 提交标记
   * @param {* 唯一id} id
   * @param {* 经度} lng
   * @param {* 纬度} lat
   * @param {* 回调函数} callback
   *      @param {* 高度} height
   */
  function comfirmMarkPoint(id, lng, lat, callback) {
    if (currMarkPoint) {
      const position = currMarkPoint.position._value;
      //删除拖拽生成的点
      viewer.entities.remove(currMarkPoint);
      if (!position) return;
      //生成新的点
      createMark(id, position, markName, markColor, true);
      const ellipsoid = viewer.scene.globe.ellipsoid;
      const hei = Cesium.Cartographic.fromCartesian(position, ellipsoid).height;
      callback(hei);
      currMarkPoint = null;
    } else {
      if (lng && lat) {
        // const color = Cesium.Color.fromCssColorString(markColor);
        const cartographic = Cesium.Cartographic.fromDegrees(
          Number(lng),
          Number(lat)
        );
        // 地形提供者
        const terrainProvider = setTerrain();
        const promise = Cesium.sampleTerrainMostDetailed(terrainProvider, [
          cartographic,
        ]);
        // 获取经纬度所在点高度
        Promise.resolve(promise).then(function (updatedPositions) {
          const hei = updatedPositions[0].height;
          const position = Cesium.Cartesian3.fromDegrees(
            Number(lng),
            Number(lat),
            hei
          );
          if (!position) return;
          createMark(id, position, markName, markColor, true);
          callback(hei);
        });
      }
    }
  }
  /**
   * 绘制同步--添加标注点
   * @param {*点对象id} id
   * @param {*经度} lng
   * @param {*纬度} lat
   * @param {*高度} hei
   * @param {*文本名字} markName
   * @param {*颜色} markColor
   */
  function drawMark(id, lng, lat, hei, markName, markColor) {
    const position = Cesium.Cartesian3.fromDegrees(
      Number(lng),
      Number(lat),
      Number(hei)
    );
    if (!position) return;
    createMark(id, position, markName, markColor, true);
  }

  /**
   * 公共函数--地图添加点
   * @param {*点id} id
   * @param {*点位置信息经、纬、高度} position
   * @param {*点文本信息} markName
   * @param {*点颜色} markColor
   * @param {*判断是否有id值传进来} flag
   */
  function createMark(id, position, markName, markColor, flag) {
    const color = Cesium.Color.fromCssColorString(markColor);
    let pointData = {
      id: id,
      position: position,
      billboard: {
        scale: 0.5,
        image: require("./../../asset/img/centerPlan/map_mark.png"),
        color: color,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 20000),
      },
      label: {
        text: markName,
        font: "14px sans-serif",
        fillColor: Cesium.Color.fromCssColorString("#ffffff"),
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        pixelOffset: new Cesium.Cartesian2(14, -10),
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 20000),
      },
      type: "markPoint",
    };
    flag || delete pointData.id;
    return viewer.entities.add(pointData);
  }

  /**
   * 修改当前标记信息
   * @param {* 选项} option
   *      @param {* 经度} lng
   *      @param {* 纬度} lat
   *      @param {* 文本} text
   *      @param {* 颜色} color
   */
  function updateCurrMark(option) {
    if (option.text) {
      markName = option.text;
    }
    if (option.color) {
      markColor = option.color;
    }
    if (currMarkPoint !== null) {
      if (option.lng && option.lat) {
        const ellipsoid = viewer.scene.globe.ellipsoid;
        const oldPosition = currMarkPoint.position._value;
        const cartographic = Cesium.Cartographic.fromCartesian(
          oldPosition,
          ellipsoid
        );
        const markHei = cartographic.height;
        currMarkPoint.position = Cesium.Cartesian3.fromDegrees(
          Number(option.lng),
          Number(option.lat),
          markHei,
          ellipsoid
        );
      }
      currMarkPoint.label.text = markName;
      const color = Cesium.Color.fromCssColorString(markColor);
      currMarkPoint.billboard.color = color;
      currMarkPoint.label.fillColor =
        Cesium.Color.fromCssColorString("#ffffff");
    }
  }

  /* 获取标记信息 */
  function getMarkInfo(id, callback) {
    const entity = viewer.entities.getById(id);
    if (entity) {
      const ellipsoid = viewer.scene.globe.ellipsoid;
      const cartesian3 = entity.position._value;
      const cartographic = ellipsoid.cartesianToCartographic(cartesian3);
      const lat = Cesium.Math.toDegrees(cartographic.latitude);
      const lng = Cesium.Math.toDegrees(cartographic.longitude);
      const alt = cartographic.height;
      const mapDom = document.getElementById(cesiumDom);
      const text = entity.label.text._value;
      html2canvas(mapDom, {
        backgroundColor: null,
        useCORS: true, // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
        logging: false, // 日志开关，便于查看html2canvas的内部执行流程
      })
        .then((canvas) => {
          canvas.toBlob((blob) => {
            // 以时间戳作为文件名 实时区分不同文件 按需求自己定义就好
            const filename = `${text}.jpg`;
            // 转换canvas图片数据格式为formData
            const picFile = new File([blob], filename, {
              type: "image/jpg",
            });
            callback({
              id: id,
              name: text,
              lng: lng,
              lat: lat,
              alt: alt,
              file: picFile,
            });
          });
        })
        .catch(() => {
          callback({});
        });
    } else {
      callback({});
    }
  }

  let positionMarkEntity = null;
  /* 定位标记点 */
  function positionMark(lng, lat, hei) {
    const position = Cesium.Cartesian3.fromDegrees(
      Number(lng),
      Number(lat),
      Number(hei)
    );
    if (positionMarkEntity) {
      positionMarkEntity.position = position;
    } else {
      positionMarkEntity = viewer.entities.add({
        show: true,
        position: position,
        label: {
          text: "",
        },
      });
    }
    ufi3d.viewerFlyTo(viewer, {
      entity: positionMarkEntity,
      heading: 0,
      pitch: -45,
      range: 200,
      duration: 2,
    });
  }

  let pointPrimitive; //航线点对象
  let linePrimitive; //航线线对象
  let startEntity = null, //开始节点entity
    endEntity = null; //结束节点entity
  let hasAirline = false;

  /* 添加航线 */
  function addAirportLine(firstPoint, lastPoint, cloneArr, airwayData) {
    startEntity = new ufi3d.PointObject.BillboardEntity(
      viewer,
      [firstPoint.longitude, firstPoint.latitude, firstPoint.altitude],
      require("@/asset/img/position_icon.png"),
      1.1
    );
    endEntity = new ufi3d.PointObject.BillboardEntity(
      viewer,
      [lastPoint.longitude, lastPoint.latitude, lastPoint.altitude],
      require("@/asset/img/position_icon.png"),
      1.1
    );

    pointPrimitive = new ufi3d.PointObject.PointPrimitive(
      viewer,
      cloneArr,
      "#FF0000",
      6,
      2,
      "#00FF00"
    );
    linePrimitive = new ufi3d.lineObject.linePrimitive(
      viewer,
      airwayData,
      "dash",
      // "glow",
      "#00FF00",
      2
    );

    hasAirline = true;
  }

  /* 画历史轨迹 */
  function drawLineH(trajectoryData) {
    trackLine = new ufi3d.TrackLine(viewer);
    if (
      trajectoryData.longAndLatInfoList instanceof Array &&
      trajectoryData.longAndLatInfoList.length > 0
    ) {
      hasTrackline = trackLine.addTrackLine(
        trajectoryData.longAndLatInfoList,
        "public/model/drone.glb",
        "",
        "#00E4FF",
        4,
        2
      );
    }
  }

  /**
   * 添加实时轨迹
   * @param {*位置} position
   * @param {* 时间差} timeInterval
   * @param {* 机场最小高度} minTerrainHeight
   */
  function addTrajectory(position, timeInterval) {
    if (trackLine) {
      // if (position.altitude < minTerrainHeight) {
      //     position.altitude = minTerrainHeight;
      // }
      if (hasTrackline) {
        trackLine.addPoint(position, timeInterval);
      } else {
        const arr = [];
        arr.push(position);
        hasTrackline = trackLine.addTrackLine(
          arr,
          "public/model/drone.glb",
          "",
          "#00E4FF",
          4,
          2
        );
      }
    }
  }

  /* 结束任务清空轨迹 */
  function finishTask() {
    if (hasTrackline) {
      trackLine.clearTrackLine();
      // state.positionUavFlag = false;
      hasTrackline = false;
      trackLine.exchangeAngle(false);
    }
  }

  /* 结束任务清空航线 */
  function clearAirway() {
    if (hasAirline) {
      pointPrimitive.clear();
      linePrimitive.clear();
      viewer.entities.remove(startEntity);
      viewer.entities.remove(endEntity);
      hasAirline = false;
    }
  }

  /* 两点最小距离 */
  function posiMinDistance(prePosition, currPosition, minDistance) {
    if (prePosition && prePosition !== null) {
      const distance = ufi3d.spaceDistance(prePosition, currPosition);
      if (distance < minDistance) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  /* 两点最大距离 */
  function posiMaxDistance(prePosition, currPosition, maxDistance) {
    if (prePosition && prePosition !== null) {
      const distance = ufi3d.spaceDistance(prePosition, currPosition);
      if (distance > maxDistance) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  onBeforeUnmount(() => {
    // measureDistanceObj = null;
    // measureAreaObj = null;
    positionMarkEntity = null;
    measureHoverEntity = null;
    routeHoverEntity = null;
    routePointObj = null;
  });

  const lineArr = [];
  let currLine = {};
  let isAdd = false;
  let isFinish = true;
  let entityArr = [];
  let hoverEntityId = "";
  let drawType = ""; //绘制类型，用于区分绘线和会面

  function createPoint(lineId, pointId, position) {
    const cartographic = Cesium.Cartographic.fromCartesian(position);
    const lng = Cesium.Math.toDegrees(cartographic.longitude);
    const lat = Cesium.Math.toDegrees(cartographic.latitude);
    const hei = cartographic.height;
    const lngDirection = lat > 0 ? "E" : "W";
    const latDirection = lat > 0 ? "N" : "S";
    const entity = viewer.entities.add({
      id: pointId,
      position: position,
      billboard: {
        image: require("./../../asset/img/centerPlan/circle.png"),
        scale: 1,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      },
      label: {
        show: false,
        font: "14px sans-serif",
        text: `GPS:${lat.toFixed(9)}°${latDirection} ${lng.toFixed(
          9
        )}°${lngDirection}\nASL:${hei.toFixed(1)}m`,
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        showBackground: true,
        backgroundColor: new Cesium.Color(0, 0, 0, 0.6),
        backgroundPadding: new Cesium.Cartesian2(8, 8),
        pixelOffset: new Cesium.Cartesian2(14, -10),
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      },
      lineId: lineId,
      type: "point",
    });
    entityArr.push(entity);
  }

  function createText(lastPoint, nextPoint, pointId) {
    const textEntity = viewer.entities.getById("text" + pointId);
    if (textEntity) {
      return;
    }
    const midPoint = Cesium.Cartesian3.midpoint(
      lastPoint,
      nextPoint,
      new Cesium.Cartesian3()
    );
    const distance = Cesium.Cartesian3.distance(lastPoint, nextPoint);
    const entity = viewer.entities.add({
      id: "text" + pointId,
      position: midPoint,
      label: {
        font: "12px sans-serif",
        text: distance.toFixed(2) + t("42"),
        showBackground: true,
        backgroundColor: new Cesium.Color(0, 0, 0, 0.6),
        backgroundPadding: new Cesium.Cartesian2(8, 8),
        pixelOffset: new Cesium.Cartesian2(0, -20),
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 20000),
      },
      type: "text",
    });
    // entityArr.push(entity);
  }

  function createLineEntity(lineId, positions, active) {
    const newData = JSON.parse(JSON.stringify(positions));
    let lineWidth = 0;
    let material = null;
    if (active) {
      lineWidth = 6;
      material = new Cesium.PolylineOutlineMaterialProperty({
        color: Cesium.Color.fromCssColorString("#2d8cf0"),
        outlineColor: Cesium.Color.WHITE,
        outlineWidth: 3,
      });
    } else {
      lineWidth = 4;
      material = Cesium.Color.fromCssColorString("#2d8cf0");
    }
    viewer.entities.add({
      id: lineId,
      type: "line",
      polyline: {
        positions: newData,
        width: lineWidth,
        material: material,
        clampToGround: true,
      },
    });
  }

  function createFaceEntity(lineId, positions, active) {
    const newData = JSON.parse(JSON.stringify(positions));
    let lineWidth = 0;
    let material = null;
    if (active) {
      lineWidth = 6;
      material = new Cesium.PolylineOutlineMaterialProperty({
        color: Cesium.Color.fromCssColorString("#2d8cf0"),
        outlineColor: Cesium.Color.WHITE,
        outlineWidth: 3,
      });
    } else {
      lineWidth = 4;
      material = Cesium.Color.fromCssColorString("#2d8cf0");
    }
    viewer.entities.add({
      id: lineId,
      type: "face",
      polyline: {
        positions: newData,
        width: lineWidth,
        material: material,
        clampToGround: true,
        // depthFailMaterial: Cesium.Color.YELLOW
      },
      // 新增了此处
      polygon: {
        // hierarchy: new Cesium.CallbackProperty(() => {
        //     return new Cesium.PolygonHierarchy(newData);
        // }, false),
        hierarchy: new Cesium.PolygonHierarchy(newData),
        material: Cesium.Color.fromCssColorString("rgba(25,190,107,0.3)"),
        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      },
    });
  }

  /* 修改线的位置 */
  function updateLinePosition(lineId, positions) {
    const lineEntity = viewer.entities.getById(lineId);
    if (lineEntity) {
      const newData = JSON.parse(JSON.stringify(positions));
      lineEntity.polyline.positions = new Cesium.CallbackProperty(() => {
        return newData;
      }, false);
      if (drawType === "face") {
        // 新增了此处
        lineEntity.polygon.hierarchy = new Cesium.CallbackProperty(() => {
          return new Cesium.PolygonHierarchy(newData);
        }, false);
        // lineEntity.polygon.hierarchy = new Cesium.PolygonHierarchy(newData);
      }
    } else {
      if (drawType === "face") {
        createFaceEntity(lineId, positions);
      } else {
        createLineEntity(lineId, positions);
      }
    }
  }

  /* 修改线的激活状态 */
  function updateLineActive(lineId, active) {
    const lineEntity = viewer.entities.getById(lineId);
    if (lineEntity) {
      if (active) {
        //  激活状态
        lineEntity.polyline.width = 6;
        lineEntity.polyline.material =
          new Cesium.PolylineOutlineMaterialProperty({
            color: Cesium.Color.fromCssColorString("#2d8cf0"),
            outlineColor: Cesium.Color.WHITE,
            outlineWidth: 3,
          });
      } else {
        // 非激活状态
        lineEntity.polyline.width = 4;
        lineEntity.polyline.material =
          Cesium.Color.fromCssColorString("#2d8cf0");
      }
    }
  }

  function updatePolygonUnActive(lineId) {
    const lineEntity = viewer.entities.getById(lineId);
    if (lineEntity && lineEntity.type === "face") {
      const lineItem = lineArr.find((i) => {
        return i.lineId === lineId;
      });
      if (!lineItem) return;
      const positionArr = lineItem.positionArr;
      const newData = JSON.parse(JSON.stringify(positionArr));
      lineEntity.polygon.hierarchy = new Cesium.PolygonHierarchy(newData);
    }
  }

  function getPolygonArea(lineId, positionArr) {
    const lineEntity = viewer.entities.getById(lineId);
    let area = 0;
    if (lineEntity && lineEntity.type === "face") {
      const newData = JSON.parse(JSON.stringify(positionArr));
      let hierarchy = new Cesium.PolygonHierarchy(newData);
      let indices = Cesium.PolygonPipeline.triangulate(
        hierarchy.positions,
        hierarchy.holes
      );
      for (let i = 0; i < indices.length; i += 3) {
        let vector1 = hierarchy.positions[indices[i]];
        let vector2 = hierarchy.positions[indices[i + 1]];
        let vector3 = hierarchy.positions[indices[i + 2]];
        let vectorC = Cesium.Cartesian3.subtract(
          vector2,
          vector1,
          new Cesium.Cartesian3()
        );
        let vectorD = Cesium.Cartesian3.subtract(
          vector3,
          vector1,
          new Cesium.Cartesian3()
        );
        let areaVector = Cesium.Cartesian3.cross(
          vectorC,
          vectorD,
          new Cesium.Cartesian3()
        );
        area += Cesium.Cartesian3.magnitude(areaVector) / 2.0;
      }
    }
    return area;
  }

  /* 根据线id删除线 */
  function delLineById(lineId) {
    const lineEntity = viewer.entities.getById(lineId);
    if (lineEntity) {
      viewer.entities.remove(lineEntity);
    }
  }

  let measureHoverEntity = null;
  let routeHoverEntity = null;
  /* 创建绘制元素悬浮文本实体 */
  function createHoverEntity() {
    if (measureHoverEntity || routeHoverEntity) return;
    measureHoverEntity = viewer.entities.add({
      label: {
        show: false,
        font: "14px sans-serif",
        text: "",
        showBackground: true,
        backgroundColor: new Cesium.Color(0, 0, 0, 0.6),
        backgroundPadding: new Cesium.Cartesian2(8, 8),
        pixelOffset: new Cesium.Cartesian2(0, -20),
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      },
    });
    routeHoverEntity = viewer.entities.add({
      label: {
        show: false,
        font: "14px sans-serif",
        text: "",
        showBackground: true,
        backgroundColor: new Cesium.Color(0, 0, 0, 0.6),
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        verticalOrigin: Cesium.VerticalOrigin.CENTER,
        pixelOffset: new Cesium.Cartesian2(-30, -100),
      },
    });
  }

  let lastTime = 0;

  function chooseMeasure(id) {
    const now = new Date();
    if (now - lastTime < 300) {
      lastTime = now;
      return;
    }
    lastTime = now;
    if (hoverEntityId === id) {
      return;
    }
    const lineItem = lineArr.find((item) => {
      return item.lineId === id;
    });
    const text = lineItem.measureArea
      ? `${ t('71') }：${Number(lineItem.measureArea).toFixed(2)}m²`
      : `${ t('72') }：${Number(lineItem.perimeter).toFixed(2)}m`;
    measureHoverEntity.label.show = true;
    measureHoverEntity.label.text = text;
    hoverEntityId = id;
    updateLineActive(hoverEntityId, true);
  }

  function unChooseMeasure() {
    if (hoverEntityId !== currLine.lineId) {
      updateLineActive(hoverEntityId, false);
      hoverEntityId = "";
    }
    measureHoverEntity.label.show = false;
  }

  /* 注册普通情况下的鼠标移动时间，用于绘制实体的hover选中效果 */
  function registerMoveNormalEvent() {
    handler.setInputAction((e) => {
      const entityDrag = viewer.scene.pick(e.endPosition); //选取当前的entity
      const modelBox = document.getElementById("modelText");
      if (entityDrag && entityDrag.id) {
        viewer._element.style.cursor = "pointer";
        if (entityDrag.id.type === "line" || entityDrag.id.type === "face") {
          const position = viewer.scene.pickPosition(e.endPosition);
          if (!position) {
            return;
          }
          measureHoverEntity.position = position;
          chooseMeasure(entityDrag.id.id);
        } else if (entityDrag.id.type === "routePoint") {
          const pointPosition = entityDrag.id.pointPosition;
          const position = viewer.scene.pickPosition(e.endPosition);
          if (!position) {
            return;
          }
          routeHoverEntity.position = position;
          const text = `${entityDrag.id.routeName}\n${ t('73') }:${pointPosition[0]}\n${ t('74') }:${pointPosition[1]}\n${ t('75') }:${pointPosition[2]} m`;
          routeHoverEntity.label.show = true;
          routeHoverEntity.label.text = text;
        }
      } else {
        viewer._element.style.cursor = "default";
        unChooseMeasure();
        routeHoverEntity.label.show = false;
        modelBox.style.display = "none";
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  /* 注册普通情况下的鼠标点击事件 */
  function registerClickNormalEvent() {
    handler.setInputAction((e) => {
      const entityDrag = viewer.scene.pick(e.position); //选取当前的entity
      const modelBox = document.getElementById("modelText");
      if (entityDrag && entityDrag.id) {
        // 线
        if (entityDrag.id.type === "line" || entityDrag.id.type === "face") {
          drawType = entityDrag.id.type;
          // 航线id
          const lineId = entityDrag.id.id;
          const item = lineArr.find((i) => {
            return i.lineId === lineId;
          });
          if (!item) {
            return;
          }
          // 第一个点 -- 新线
          if (currLine.lineId && currLine.lineId !== "") {
            // 在这里
            updateLineActive(currLine.lineId, false);
            updatePolygonUnActive(currLine.lineId);
          }

          for (let i = 0; i < entityArr.length; i++) {
            viewer.entities.remove(entityArr[i]);
          }

          currLine = item;
          const idArr = currLine.idArr;
          const positionArr = currLine.positionArr;
          for (let i = 0; i < idArr.length; i++) {
            createPoint(lineId, idArr[i], positionArr[i]);
            if (i > 0) {
              createText(positionArr[i - 1], positionArr[i], idArr[i]);
            } else {
              if (entityDrag.id.type === "face") {
                createText(
                  positionArr[positionArr.length - 1],
                  positionArr[positionArr.length - 2],
                  idArr[i]
                );
              }
            }
          }
          updateLineActive(lineId, true);
          isAdd = false;
        }
        // 模型
        if (entityDrag.id.type === "model") {
          modelBox.style.display = "block";
          modelSn.value = entityDrag.id.sn;
          modelName.value = entityDrag.id.name;
          modelBox.style.transform = `translate(${e.position.x}px, ${e.position.y}px)`;
          // const position = viewer.scene.pickPosition(e.position);
          // modelClickEntity.position = position;
          // const text = `机场名称：${entityDrag.id.name}\n机场SN：${entityDrag.id.sn}`;
          // modelClickEntity.label.show = true;
          // modelClickEntity.label.text = text;
        }
      } else {
        // 没有选取到实体，移除编辑状态
        if (currLine.lineId && currLine.lineId !== "") {
          // 在这里
          updateLineActive(currLine.lineId, false);
          updatePolygonUnActive(currLine.lineId);
        }
        for (let i = 0; i < entityArr.length; i++) {
          viewer.entities.remove(entityArr[i]);
        }
        currLine = {};
        modelBox.style.display = "none";
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  }

  /* 注册普通情况下的双击事件 */
  function registerDbclickNormalEvent() {
    handler.setInputAction((e) => {
      // 不允许双击选中实体
      viewer.trackedEntity = undefined;
    }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
  }

  /* 注册普通情况下的鼠标按下事件 */
  function registerDownEvent(callback) {
    handler.setInputAction((e) => {
      // 非新增绘制状态
      if (!isAdd) {
        //  编辑状态拖拽点
        // 返回具有' primitive'属性的对象（是否拾取到实体）
        const entityDrag = viewer.scene.pick(e.position); //选取当前的entity
        if (entityDrag && entityDrag.id && entityDrag.id.type === "point") {
          // 鼠标移动
          const lineId = entityDrag.id.lineId;
          const pointId = entityDrag.id.id;
          const index = currLine.idArr.findIndex((i) => {
            return i === pointId;
          });
          if (index === -1) return;
          viewer.scene.screenSpaceCameraController.enableRotate = false;
          viewer.scene.screenSpaceCameraController.enableTranslate = false;
          measureHoverEntity.label.show = false;
          handler.setInputAction((downE) => {
            let position = viewer.scene.pickPosition(downE.endPosition);
            if (!position) {
              // 椭球位置
              const ellipsoid = viewer.scene.globe.ellipsoid;
              position = viewer.scene.camera.pickEllipsoid(
                downE.endPosition,
                ellipsoid
              );
            }
            if (!position) return;
            entityDrag.id.position = position;
            const cartographic = Cesium.Cartographic.fromCartesian(position);
            const lng = Cesium.Math.toDegrees(cartographic.longitude);
            const lat = Cesium.Math.toDegrees(cartographic.latitude);
            const hei = cartographic.height;
            const lngDirection = lat > 0 ? "E" : "W";
            const latDirection = lat > 0 ? "N" : "S";
            entityDrag.id.label.show = false;
            entityDrag.id.label.text = `GPS:${lat.toFixed(
              9
            )}°${latDirection} ${lng.toFixed(
              9
            )}°${lngDirection}\nASL:${hei.toFixed(1)}m`;
            currLine.positionArr[index] = position;
            // 新增了此处
            if (drawType === "face") {
              if (index === 0) {
                currLine.positionArr[currLine.positionArr.length - 1] =
                  position;
              }
            }
            updateLinePosition(lineId, currLine.positionArr);
            if (currLine.idArr.length >= 2) {
              if (index === 0) {
                // 第一个
                const nextId = currLine.idArr[index + 1];
                const nextPosition = currLine.positionArr[index + 1];
                const nextEntity = viewer.entities.getById("text" + nextId);
                const midPoint = Cesium.Cartesian3.midpoint(
                  position,
                  nextPosition,
                  new Cesium.Cartesian3()
                );
                const distance = Cesium.Cartesian3.distance(
                  position,
                  nextPosition
                );
                nextEntity.position = midPoint;
                nextEntity.label.text = distance.toFixed(2) + t("42");

                if (drawType === "face") {
                  // 更新与最后一个的距离
                  const prevPosition =
                    currLine.positionArr[currLine.positionArr.length - 2];
                  const currEntity = viewer.entities.getById("text" + pointId);
                  const midPoint1 = Cesium.Cartesian3.midpoint(
                    prevPosition,
                    position,
                    new Cesium.Cartesian3()
                  );
                  const distance1 = Cesium.Cartesian3.distance(
                    prevPosition,
                    position
                  );
                  currEntity.position = midPoint1;
                  currEntity.label.text = distance1.toFixed(2) + t("42");
                }
              } else if (index === currLine.idArr.length - 1) {
                // 最后一个
                const prevPosition = currLine.positionArr[index - 1];
                const currEntity = viewer.entities.getById("text" + pointId);
                const midPoint = Cesium.Cartesian3.midpoint(
                  prevPosition,
                  position,
                  new Cesium.Cartesian3()
                );
                const distance = Cesium.Cartesian3.distance(
                  prevPosition,
                  position
                );
                currEntity.position = midPoint;
                currEntity.label.text = distance.toFixed(2) + t("42");

                if (drawType === "face") {
                  // 更新与第一个的距离
                  const nextId = currLine.idArr[0];
                  const nextPosition = currLine.positionArr[0];
                  const nextEntity = viewer.entities.getById("text" + nextId);
                  const midPoint1 = Cesium.Cartesian3.midpoint(
                    position,
                    nextPosition,
                    new Cesium.Cartesian3()
                  );
                  const distance1 = Cesium.Cartesian3.distance(
                    position,
                    nextPosition
                  );
                  nextEntity.position = midPoint1;
                  nextEntity.label.text = distance1.toFixed(2) + t("42");
                }
              } else {
                //   其他情况
                const nextId = currLine.idArr[index + 1];
                const prevPosition = currLine.positionArr[index - 1];
                const nextPosition = currLine.positionArr[index + 1];
                const currEntity = viewer.entities.getById("text" + pointId);
                const nextEntity = viewer.entities.getById("text" + nextId);

                const midPoint1 = Cesium.Cartesian3.midpoint(
                  prevPosition,
                  position,
                  new Cesium.Cartesian3()
                );
                const distance1 = Cesium.Cartesian3.distance(
                  prevPosition,
                  position
                );
                currEntity.position = midPoint1;
                currEntity.label.text = distance1.toFixed(2) + t("42");

                const midPoint2 = Cesium.Cartesian3.midpoint(
                  position,
                  nextPosition,
                  new Cesium.Cartesian3()
                );
                const distance2 = Cesium.Cartesian3.distance(
                  position,
                  nextPosition
                );
                nextEntity.position = midPoint2;
                nextEntity.label.text = distance2.toFixed(2) + t("42");
              }
            }
          }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
          // 鼠标弹起
          handler.setInputAction(() => {
            handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE); // 解除viewer的MOUSE_MOVE事件监听器
            handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP); // 解除viewer的LEFT_UP事件监听器
            // 调用回调 -- 因为移除了鼠标移动事件，所以要重新注册
            callback();
            saveLineData();
            viewer.scene.screenSpaceCameraController.enableRotate = true;
            viewer.scene.screenSpaceCameraController.enableTranslate = true;
          }, Cesium.ScreenSpaceEventType.LEFT_UP);
        }
      }
    }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
  }

  function registerRightNormalEvent() {
    handler.setInputAction((e) => {
      // 返回具有' primitive'属性的对象（是否拾取到实体）
      // const entityDrag = viewer.scene.pick(e.position); //选取当前的entity
      // if (entityDrag && entityDrag.id) {
      //     const type = entityDrag.id.type;
      //     if (type === 'markPoint' || type === 'line' || type === 'face') {
      //         const rightMenuBox = document.getElementById('rightMenuBox');
      //         rightMenuBox.style.display = 'block';
      //         rightMenuBox.style.transform = `translate(${e.position.x}px, ${e.position.y}px)`;
      //         rightMenuBox.setAttribute('data-id', entityDrag.id.id);
      //         rightMenuBox.setAttribute('data-entityType', type);
      //     }
      // }
      const pickedEntities = viewer.scene.drillPick(e.position); //选取当前的entity
      entityLoop: for (let i = 0; i < pickedEntities.length; i++) {
        const pickEntity = pickedEntities[i];
        const type = pickEntity.id.type;
        if (
          pickEntity.id &&
          (type === "markPoint" || type === "line" || type === "face")
        ) {
          const rightMenuBox = document.getElementById("rightMenuBox");
          rightMenuBox.style.display = "block";
          rightMenuBox.style.transform = `translate(${e.position.x}px, ${e.position.y}px)`;
          rightMenuBox.setAttribute("data-id", pickEntity.id.id);
          rightMenuBox.setAttribute("data-entityType", type);

          const markChat = document.getElementById("markChat");
          if (type === "markPoint") {
            markChat.style.display = "block";
          } else {
            markChat.style.display = "none";
          }
          break entityLoop;
        }
      }
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
  }

  function delLineEntity(lineId) {
    const lineEntity = viewer.entities.getById(lineId);
    currMarkPoint !== null &&
      lineId === currMarkPoint.id &&
      (currMarkPoint = null); //判断删除的实体是否为拖拽产生的点
    if (lineEntity) {
      viewer.entities.remove(lineEntity);
      if (currLine.lineId && lineId === currLine.lineId) {
        for (let i = 0; i < entityArr.length; i++) {
          viewer.entities.remove(entityArr[i]);
        }
        currLine = {};
      }
      const index = lineArr.findIndex((i) => {
        return i.lineId === lineId;
      });
      if (index !== -1) {
        const idArr = lineArr[index].idArr;
        for (let i = 0; i < idArr.length; i++) {
          const textEntity = viewer.entities.getById("text" + idArr[i]);
          if (textEntity) {
            viewer.entities.remove(textEntity);
          }
        }
        lineArr.splice(index, 1);
      }
    }
  }

  /* 注册鼠标滚轮事件 */
  function regsterMouseWheelEvent() {
    handler.setInputAction((e) => {
      const modelBox = document.getElementById("modelText");
      modelBox.style.display = "none";
    }, Cesium.ScreenSpaceEventType.WHEEL);
  }

  /* 注册普通情况下的事件 */
  function registerNormalEvent() {
    registerMoveNormalEvent();
    registerClickNormalEvent();
    registerDbclickNormalEvent();
    registerDownEvent(() => {
      registerMoveNormalEvent();
    });
    registerRightNormalEvent();
    createHoverEntity();
    regsterMouseWheelEvent();
  }

  /* 取消注册普通情况下的事件 */
  function unRegisterNormalEvent() {
    handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
    handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN);
    handler.removeInputAction(Cesium.ScreenSpaceEventType.WHEEL);
  }

  /* 注册绘制情况下的点击事件 */
  function registerClickMapEvent() {
    viewer._element.style.cursor = "crosshair";
    //左击添加点
    handler.setInputAction((e) => {
      // 地形位置
      let position = viewer.scene.pickPosition(e.position);
      if (!position) {
        // 椭球位置
        const ellipsoid = viewer.scene.globe.ellipsoid;
        position = viewer.scene.camera.pickEllipsoid(e.position, ellipsoid);
      }
      if (!position) return;
      // 创建点和线对象
      if (isFinish) {
        // 第一个点 -- 新线
        if (currLine.lineId && currLine.lineId !== "") {
          // 在这里
          updateLineActive(currLine.lineId, false);
          updatePolygonUnActive(currLine.lineId);
        }

        const lineId = uuidv4();
        const pointId = uuidv4();
        currLine = {
          lineId: lineId,
          idArr: [pointId],
          positionArr: [position],
        };
        lineArr.push(currLine);
        for (let i = 0; i < entityArr.length; i++) {
          viewer.entities.remove(entityArr[i]);
        }
        entityArr = [];
        createPoint(lineId, pointId, position);
        updateLinePosition(lineId, [position]);
        isFinish = false;
      } else {
        // 非第一个点 -- 正在编辑中的线
        const pointId = uuidv4();
        currLine.idArr.push(pointId);
        currLine.positionArr.push(position);
        createPoint(currLine.lineId, pointId, position);
        // 新增了此处
        if (drawType === "face") {
          const closePolygon = currLine.positionArr.concat(
            currLine.positionArr[0]
          );
          updateLinePosition(currLine.lineId, closePolygon);
        } else {
          updateLinePosition(currLine.lineId, currLine.positionArr);
        }
        const posiLen = currLine.positionArr.length;
        createText(currLine.positionArr[posiLen - 2], position, pointId);
      }
      // 正在编辑中
      isAdd = true;
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  }

  /* 处理鼠标移动 */
  function handleMoveEvent(position) {
    // 不存在线id，位置数少于1直接返回
    if (!currLine.lineId && currLine.positionArr.length < 1) {
      return;
    }
    // 当前线连接上鼠标的实时位置
    // 新增了此处
    if (drawType === "face") {
      const tempPositions = currLine.positionArr.concat(
        position,
        currLine.positionArr[0]
      );
      updateLinePosition(currLine.lineId, tempPositions);
      if (movePrevEntity) {
        //   最后一个点的位置
        const left = currLine.positionArr[currLine.positionArr.length - 1];
        const midPoint = Cesium.Cartesian3.midpoint(
          left,
          position,
          new Cesium.Cartesian3()
        );
        const distance = Cesium.Cartesian3.distance(left, position);
        movePrevEntity.show = true;
        movePrevEntity.position = midPoint;
        movePrevEntity.label.text = distance.toFixed(2) + t("42");
      }
      if (moveLastEntity) {
        //   最后一个点的位置
        const right = currLine.positionArr[0];
        const midPoint = Cesium.Cartesian3.midpoint(
          position,
          right,
          new Cesium.Cartesian3()
        );
        const distance = Cesium.Cartesian3.distance(right, position);
        moveLastEntity.show = true;
        moveLastEntity.position = midPoint;
        moveLastEntity.label.text = distance.toFixed(2) + t("42");
      }
    } else {
      const tempPositions = currLine.positionArr.concat(position);
      updateLinePosition(currLine.lineId, tempPositions);
      if (moveEntity) {
        //   最后一个点的位置
        const left = currLine.positionArr[currLine.positionArr.length - 1];
        const distance = Cesium.Cartesian3.distance(left, position);
        moveEntity.show = true;
        moveEntity.position = position;
        moveEntity.label.text = distance.toFixed(2) + t("42");
      }
    }
  }

  /* 注册绘制情况下的鼠标移动事件 */
  function registerMouseMapEvent() {
    let lastDragEntity = null;
    handler.setInputAction((e) => {
      // 非添加状态直接返回
      if (isAdd) {
        // 添加模式 粘性线和文本
        let position = viewer.scene.pickPosition(e.endPosition);
        if (!position) {
          // 椭球位置
          const ellipsoid = viewer.scene.globe.ellipsoid;
          position = viewer.scene.camera.pickEllipsoid(
            e.endPosition,
            ellipsoid
          );
        }
        if (!position) return;
        handleMoveEvent(position);
      } else {
        // 编辑模式
        // 返回具有' primitive'属性的对象（是否拾取到实体）
        const entityDrag = viewer.scene.pick(e.endPosition); //选取当前的entity
        if (entityDrag && entityDrag.id && entityDrag.id.type === "point") {
          // 航点可拖拽
          viewer._element.style.cursor = "pointer";
          entityDrag.id.label.show = true;
          lastDragEntity = entityDrag.id;
        } else {
          viewer._element.style.cursor = "crosshair";
          if (lastDragEntity) {
            lastDragEntity.label.show = false;
          }
        }
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  let movePrevEntity = null;
  let moveLastEntity = null;
  let moveEntity = null;
  /* 创建绘制情况下的实时点位置距离实体 */
  function createMoveEntity() {
    movePrevEntity = viewer.entities.add({
      label: {
        font: "12px sans-serif",
        text: "",
        showBackground: true,
        backgroundColor: new Cesium.Color(0, 0, 0, 0.6),
        backgroundPadding: new Cesium.Cartesian2(4, 4),
        pixelOffset: new Cesium.Cartesian2(0, -20),
      },
    });
    moveLastEntity = viewer.entities.add({
      label: {
        font: "12px sans-serif",
        text: "",
        showBackground: true,
        backgroundColor: new Cesium.Color(0, 0, 0, 0.6),
        backgroundPadding: new Cesium.Cartesian2(4, 4),
        pixelOffset: new Cesium.Cartesian2(0, -20),
      },
    });
    moveEntity = viewer.entities.add({
      label: {
        font: "12px sans-serif",
        text: "",
        showBackground: true,
        backgroundColor: new Cesium.Color(0, 0, 0, 0.6),
        backgroundPadding: new Cesium.Cartesian2(4, 4),
        pixelOffset: new Cesium.Cartesian2(0, -20),
      },
    });
  }

  function saveLineData() {
    const coordinates = [];
    const arr = currLine.positionArr;
    let totalLength = 0;
    for (let i = 0; i < arr.length; i++) {
      const cartographic = Cesium.Cartographic.fromCartesian(arr[i]);
      const longitude = Cesium.Math.toDegrees(cartographic.longitude);
      const latitude = Cesium.Math.toDegrees(cartographic.latitude);
      const altitude = cartographic.height;
      coordinates.push({
        longitude,
        latitude,
        altitude,
      });

      if (i > 0) {
        const prevDistance = Cesium.Cartesian3.distance(arr[i - 1], arr[i]);
        totalLength += prevDistance;
      }
    }
    const extraData = {};
    currLine.perimeter = totalLength;
    extraData.perimeter = totalLength;
    let saveType = "LINE";
    if (drawType === "face") {
      saveType = "NOODLES";
      const measureArea = getPolygonArea(currLine.lineId, arr);
      currLine.measureArea = measureArea;
      extraData.measureArea = measureArea;
    } else {
      saveType = "LINE";
    }
    rightCallback(saveType, currLine.lineId, coordinates, extraData);
  }

  /* 创建绘制情况下的右键结束绘制事件 */
  function registerRightMapEvent() {
    handler.setInputAction(() => {
      if (isAdd) {
        // 更新线
        // 新增了此处
        if (drawType === "face") {
          currLine.positionArr.push(currLine.positionArr[0]);
          const len = currLine.positionArr.length;
          createText(
            currLine.positionArr[len - 2],
            currLine.positionArr[len - 1],
            currLine.idArr[0]
          );
        }
        if (movePrevEntity) {
          //   最后一个点的位置
          movePrevEntity.show = false;
        }
        if (moveLastEntity) {
          //   最后一个点的位置
          moveLastEntity.show = false;
        }
        if (moveEntity) {
          //   最后一个点的位置
          moveEntity.show = false;
        }
        updateLinePosition(currLine.lineId, currLine.positionArr);
        saveLineData();
        isAdd = false;
        isFinish = true;
      }
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
  }

  /* 取消注册绘制情况下的事件 */
  function unRegisterMapEvent() {
    handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
    handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN);
    handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    viewer.entities.remove(movePrevEntity);
    viewer.entities.remove(moveLastEntity);
    viewer.entities.remove(moveEntity);
    if (isFinish) {
      // 线已结束绘制 -- 直接变为不可编辑
      if (currLine.lineId && currLine.lineId !== "") {
        // 在这里
        updateLineActive(currLine.lineId, false);
        updatePolygonUnActive(currLine.lineId);
      }
    } else {
      // 线还未结束编辑 -- 直接舍弃该线
      if (currLine.lineId && currLine.lineId !== "") {
        delLineById(currLine.lineId);
        const index = lineArr.findIndex((i) => {
          return i.lineId === currLine.lineId;
        });
        lineArr.splice(index, 1);
      }
    }
    currLine = {};
    // 清除编辑状态
    for (let i = 0; i < entityArr.length; i++) {
      viewer.entities.remove(entityArr[i]);
    }
    // 置空一系列变量
    entityArr = [];
    isFinish = true;
    isAdd = false;
    viewer._element.style.cursor = "default";
  }

  /* 注册绘制情况下的事件 */
  function registerMapEvent() {
    // 先清空事件，防止重叠
    unRegisterMapEvent();
    registerClickMapEvent();
    registerMouseMapEvent();
    createMoveEntity();
    registerRightMapEvent();
    registerDownEvent(() => {
      registerMouseMapEvent();
    });
    viewer._element.style.cursor = "crosshair";
  }

  /**
   * 进入绘制
   * @param {* 绘制类型} type ( 'line' | 'face' )
   */
  function startMap(type) {
    drawType = type;
    unRegisterNormalEvent();
    registerMapEvent();
  }

  /* 退出绘制 */
  function endMap() {
    unRegisterMapEvent();
    registerNormalEvent();
  }

  function drawLine(list) {
    for (let i = 0; i < list.length; i++) {
      const spotList = list[i].spotList;
      const listItem = list[i];
      const lineId = list[i].id;
      const idArr = [];
      const positionArr = [];
      for (let j = 0; j < spotList.length; j++) {
        const pointId = uuidv4(); //点的id
        idArr.push(pointId);
        const spotItem = spotList[j];
        const position = Cesium.Cartesian3.fromDegrees(
          Number(spotItem.longitude),
          Number(spotItem.latitude),
          Number(spotItem.altitude)
        );
        positionArr.push(position);
      }
      const lineEntity = viewer.entities.getById(lineId);
      if (lineEntity) {
        const newData = JSON.parse(JSON.stringify(positionArr));
        lineEntity.polyline.positions = newData;
        const lineItem = lineArr.find((i) => {
          return i.lineId === lineId;
        });
        lineItem.positionArr = positionArr;
      } else {
        createLineEntity(lineId, positionArr, false);
        lineArr.push({
          lineId: lineId,
          idArr: idArr,
          positionArr: positionArr,
          perimeter: listItem.perimeter ? listItem.perimeter : 0,
        });
      }
    }
  }

  function drawFace(list) {
    for (let i = 0; i < list.length; i++) {
      const spotList = list[i].spotList;
      const listItem = list[i];
      const lineId = list[i].id;
      const idArr = [];
      const positionArr = [];
      for (let j = 0; j < spotList.length; j++) {
        const pointId = uuidv4(); //点的id
        idArr.push(pointId);
        const spotItem = spotList[j];
        const position = Cesium.Cartesian3.fromDegrees(
          Number(spotItem.longitude),
          Number(spotItem.latitude),
          Number(spotItem.altitude)
        );
        positionArr.push(position);
      }
      const lineEntity = viewer.entities.getById(lineId);
      if (lineEntity) {
        const newData = JSON.parse(JSON.stringify(positionArr));
        lineEntity.polyline.positions = new Cesium.CallbackProperty(() => {
          return newData;
        }, false);
        lineEntity.polygon.hierarchy = new Cesium.PolygonHierarchy(newData);
        const lineItem = lineArr.find((i) => {
          return i.lineId === lineId;
        });
        lineItem.positionArr = positionArr;
      } else {
        createFaceEntity(lineId, positionArr, false);
        lineArr.push({
          lineId: lineId,
          idArr: idArr,
          positionArr: positionArr,
          perimeter: listItem.perimeter ? listItem.perimeter : 0,
          measureArea: listItem.measureArea ? listItem.measureArea : 0,
        });
      }
    }
  }

  let routePointObj = {};
  /* 创建航点 */
  function createRoutePoint(routeId, pointList, routeName) {
    const degreeArr = [];
    const routePointArr = [];
    for (let i = 0; i < pointList.length; i++) {
      const point = pointList[i];
      const longt = Number(point.wayPointLongitude);
      const lat = Number(point.wayPointLatitude);
      const alt = Number(point.wayPointAltitude);
      const pointArr = [longt, lat, alt];
      degreeArr.push(...pointArr);
      const center = Cesium.Cartesian3.fromDegrees(longt, lat, alt);
      let labelUrl = "";
      let pointColor = "";
      if (pointList[i].takePhoto === false) {
        labelUrl = "label";
        pointColor = "#0090DA";
      } else {
        labelUrl = "label_green";
        pointColor = "#0FDA00";
      }
      const entity = viewer.entities.add({
        position: center,
        billboard: {
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          scale: 0.6,
          pixelOffset: new Cesium.Cartesian2(0, -10),
        },
        point: {
          color: Cesium.Color.fromCssColorString(pointColor),
          outlineColor: Cesium.Color.fromCssColorString("#ffffff"), //轮廓颜色
          outlineWidth: 3, //轮廓宽度
          pixelSize: 12,
        },
        pointPosition: pointArr,
        routeName: routeName,
        type: "routePoint",
      });
      ufi3d
        .combineIconAndLabel(
          require("../../asset/img/" + labelUrl + ".png"),
          i + 1,
          64
        )
        .then((res) => {
          entity.billboard.image = res;
        });
      routePointArr.push(entity);
    }
    routePointObj[routeId] = routePointArr;
    return degreeArr;
  }

  /* 创建航线 */
  function createRouteLine(routeId, degreeArr) {
    const entity = viewer.entities.add({
      id: "routeLine" + routeId,
      polyline: {
        width: 4,
        positions: Cesium.Cartesian3.fromDegreesArrayHeights(degreeArr),
        material: Cesium.Color.fromCssColorString("#0090DA"),
      },
      type: "routeLine",
    });
    return entity;
  }

  /* 定位航线 -- by entity */
  function zoomRouteLine(entity) {
    viewer.flyTo(entity, {
      offset: new Cesium.HeadingPitchRange(0, Cesium.Math.toRadians(-15), 200),
      duration: 0,
    });
  }

  /* 定位航线 -- by id */
  function findRouteLine(routeId) {
    const entity = viewer.entities.getById("routeLine" + routeId);
    if (entity) {
      zoomRouteLine(entity);
    }
  }

  /* 创建预览航线 */
  function createPreviewLine(routeId, waypointList, routeName) {
    const degreeArr = createRoutePoint(routeId, waypointList, routeName);
    const entity = createRouteLine(routeId, degreeArr);
    zoomRouteLine(entity);
  }

  /* 删除预览航线 */
  function delPreviewLine(routeId) {
    const list = routePointObj[routeId];
    if (!list) return;
    for (let i = 0; i < list.length; i++) {
      viewer.entities.remove(list[i]);
    }
    viewer.entities.removeById("routeLine" + routeId);
  }

  return {
    initEarth,
    destoryEearth,
    createAirportEntity,
    positionAirport,
    enlargeEarth,
    shrinkEarth,
    toggleSceneMode,
    updateMap,
    cameraPosition,
    addImagery,
    toggleImagery,
    zoomToImagery,
    add3DTiles,
    toggle3DTiles,
    zoomTo3DTiles,
    canMark,
    registerMarkPoint,
    unRegisterMarkPoint,
    comfirmMarkPoint,
    updateCurrMark,
    getMarkInfo,
    positionMark,
    updateCircle,
    addAirportLine,
    drawLineH,
    addTrajectory,
    posiMinDistance,
    posiMaxDistance,
    clearAirway,
    finishTask,
    dbPositionAirport,
    drawMark,
    startMap,
    endMap,
    drawLine,
    delLineEntity,
    drawFace,
    createPreviewLine,
    delPreviewLine,
    findRouteLine,
    modelName,
    modelSn,
    createNoFlyCylinder,
    delNoFlyCylinder,
    createOrNocreateCylinder,
    createOrNocreateYesFlyCylinder,
    createYesFlyCylinder,
    createYesFlyGrid,
  };
};

export default cesiumOperation;
