<template>
  <div class="parent" :id="playerId">
    <div
      class="position_btn gradient_btn hover_opacity"
      :style="[
        { position: 'absolute' },
        { zIndex: '6666' },
        {
          right:
            playerPosition > 3
              ? -playerPosition * 12 + 'rem'
              : -playerPosition * 8 + 'rem',
        },
        {
          top:
            playerPosition > 3
              ? (8 - playerPosition) * 4 + 'rem'
              : -playerPosition * 4 + 'rem',
        },
      ]"
      v-show="toggleVideo === false"
      @click="toggleVideoHandle"
      :title="
        !flowTypeObj.isAi
          ? optionArr[optionArr.findIndex((item) => item.id === flowType)]
              .liveName +
            '-' +
            UAVName
          : flowTypeObj.liveName
      "
    >
      <span class="iconfont icon-record-video-full"></span>
      <span class="title">{{
        !flowTypeObj.isAi
          ? optionArr[optionArr.findIndex((item) => item.id === flowType)]
              .liveName +
            "-" +
            UAVName
          : flowTypeObj.liveName
      }}</span>
    </div>

    <DraggableContainer
      :referenceLineColor="lineColor"
      v-show="toggleVideo === true"
    >
      <Vue3DraggableResizable
        :initW="w"
        :initH="h"
        v-model:w="w"
        v-model:h="h"
        :minW="355"
        :minH="185"
        :draggable="!isPlaneContorl"
        :resizable="true"
        :handles="['bl']"
        @drag-start="clickAddZIndex"
        @resize-end="resizeHandle"
      >
        <div class="containers" @click="clickAddZIndex" ref="V1">
          <div
            v-if="commandTitle === 'flv' ? true : false"
            id="container"
            ref="container"
          ></div>
          <video
            v-if="commandTitle === 'rtc' ? true : false"
            id="container"
            ref="containerVideo"
            controls
            autoplay
          ></video>

          <div style="display: flex" class="position_box clearfix">
            <div
              class="toolbar"
              @mouseenter="enter"
              @mouseleave="leave"
              v-show="!isPlaneContorl"
            >
              <transition name="Fade">
                <div v-show="controlShow">
                  <div
                    class="icon_con hover_opacity"
                    @click.stop="destroyBox"
                    :title="t('408')"
                  >
                    <span class="iconfont icon-cuocha_kuai"></span>
                  </div>

                  <div
                    :title="t('601')"
                    class="icon_con hover_opacity"
                    @click="reload"
                  >
                    <span class="iconfont icon-shuaxin"></span>
                  </div>

                  <div
                    v-if="!flowTypeObj.isAi"
                    class="icon_con hover_opacity"
                    :title="t('952')"
                  >
                    <el-switch
                      style="margin-top: -3px"
                      v-model="flowTypeObj.Isclose"
                      class="ml-2"
                      size="small"
                      @change="pauseJessibuca"
                    />
                  </div>

                  <div
                    class="icon_con hover_opacity"
                    @click="openMonitoringCenter"
                    v-if="!flowTypeObj.isAi"
                    :title="t('953')"
                  >
                    <span class="iconfont icon-31zhuanfa"></span>
                  </div>

                  <div
                    class="icon_con hover_opacity"
                    :title="t('954')"
                    @click="showClick"
                  >
                    <span style="display: block">{{ commandTitle }}</span>
                    <el-dropdown
                      ref="dropdown1"
                      trigger="contextmenu"
                      @command="switchType"
                    >
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item command="flv">flv</el-dropdown-item>
                          <el-dropdown-item command="rtc">rtc</el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>

                  <el-dropdown
                    v-if="!flowTypeObj.isAi"
                    popper-class="harbour-dropdown"
                    :teleported="false"
                  >
                    <div class="icon_con hover_opacity" title="ai">
                      <span style="display: block">
                        <img
                          v-if="!aiIsPlayIng"
                          src="../../../asset/img/ai.png"
                          alt=""
                        />
                        <img
                          v-else
                          src="../../../asset/img/chooseAi.png"
                          alt=""
                        />
                      </span>
                    </div>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          v-for="o in aiArr"
                          :key="o.id"
                          @click="chooseType(o)"
                          :class="(o.isPlaying)? 'active' : ''"
                        >
                          <span class="title">{{ o.liveName }}</span>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>

                  <div
                    class="icon_con hover_opacity"
                    v-if="jsHasPlaneContorl"
                    :title="t('955')"
                  >
                    <el-switch
                      style="margin-top: -3px"
                      v-model="isPlaneContorl"
                      class="ml-2"
                      size="small"
                      @change="openPlaneContorl"
                    />
                  </div>
                </div>
              </transition>
            </div>

            <div style="width: 50%" v-show="!isPlaneContorl">
              <div style="width: 100%" v-if="!flowTypeObj.isAi">
                <el-select v-model="flowType" size="small">
                  <el-option
                    v-for="item in optionArr"
                    :key="item.id"
                    :label="item.liveName"
                    :value="item.id"
                    :disabled="item.isShow"
                  >
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="item.liveName"
                      :disabled="item.liveName.length < 9"
                      placement="left"
                    >
                      <span class="span1" @click.stop="selectChange(item)">{{
                        item.liveName
                      }}</span>
                    </el-tooltip>
                    <span
                      v-if="!item.isShow"
                      class="iconfont icon-fenxiangfangshi span2"
                      @click.stop="addJec(item)"
                    ></span>
                  </el-option>
                </el-select>
              </div>
              <div style="width: 60%" class="aiClass" v-else>
                {{ flowTypeObj.liveName }}
              </div>
            </div>
          </div>
          <div
            v-if="loading"
            v-loading="loading"
            :element-loading-text="t('956')"
            style="position: absolute; top: 38px; width: 100%; height: 75%"
          ></div>
          <div
            v-if="isPauseShow"
            @click="reload"
            style="
              cursor: pointer;
              position: absolute;
              top: 43%;
              left: 46%;
              width: 10%;
              height: 12%;
            "
          >
            <span
              style="font-size: 3rem"
              class="icon iconfont icon-zanting"
            ></span>
          </div>
          <div
            v-show="!isPlaneContorl"
            class="position_bottom clearfix"
            :style="{ opacity: commandTitle === 'rtc' ? 0.4 : 1 }"
          >
            <div class="fl">
              <div class="icon_con1 hover_opacity">
                <span class="iconfont icon-fangda"></span>
              </div>
              <span class="title">{{ UAVName }}</span>
            </div>
            <div class="fr">
              <div class="icon_con hover_opacity" @click="toggleVideoHandle">
                <span class="iconfont icon-shouqi"></span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="dialog"
          v-show="isPlaneContorl"
          @click.self="contorlFlyThrottle"
        >
          <el-tooltip
            class="box-item"
            popper-class="codeOutToolTip"
            placement="right"
          >
            <template #content>
              <span>{{ t('955') }}</span>
            </template>
            <div class="box-switch hover_opacity">
              <el-switch
                style="margin-top: -3px"
                v-model="isPlaneContorl"
                class="ml-2"
                size="small"
                @change="closePlaneContorl"
              />
            </div>
          </el-tooltip>
        </div>
      </Vue3DraggableResizable>
    </DraggableContainer>
  </div>
</template>

<script setup>
import { t } from '../../../languages';
import {
  ref,
  watchEffect,
  onMounted,
  nextTick,
  onUnmounted,
  getCurrentInstance,
  watch,
  toRefs,
  defineEmits,
  onBeforeUnmount,
} from "vue";
import Vue3DraggableResizable from "vue3-draggable-resizable";
import "vue3-draggable-resizable/dist/Vue3DraggableResizable.css";
import { DraggableContainer } from "vue3-draggable-resizable";
import emitter from "../../../utils/mitt";
import taskManage from "@/network/task";
import device from "../../../network/device";
import { ElMessage } from "element-plus";
import axios from "axios";

const Isclose = ref(false);
const emits = defineEmits([
  "onSubmit",
  "destroy",
  "addCurrentPlayJec",
  "addCurrentPlaySel",
  "planeSendWs",
  "bindContorlWS",
  "closeFlyContorl",
]);
const dropdown1 = ref(null);
function showClick() {
  dropdown1.value.handleOpen();
}
function chooseType(data) {
  emits("addAiPlay", data, flowTypeObj.value);
}

const w = ref(null);
const h = ref(null);
const lineColor = "#F64040";
const props = defineProps({
  sn: {
    type: String,
    default: "",
  },
  // 机场名称
  UAVName: {
    type: String,
    default: "",
  },
  // 机场ID
  playerId: {
    type: String,
    default: "",
  },
  // 收缩盒子的位置
  playerPosition: {
    type: Number,
    default: 0,
  },
  width: {
    type: Number,
    default: 0,
  },
  height: {
    type: Number,
    default: 0,
  },
  currentPlay: {
    type: Object,
    default: function () {
      return {};
    },
  },
  optionArr: {
    type: Array,
    default: function () {
      return [];
    },
  },
  aiArr: {
    type: Array,
    default: function () {
      return [];
    },
  },
  isStop: {
    type: Boolean,
    default: false,
  },
  aiIsPlayIng: {
    type: Boolean,
    default: false,
  },
});

const {
  sn,
  optionArr,
  aiArr,
  currentPlay,
  playerId,
  playerPosition,
  height,
  width,
} = toRefs(props);
watchEffect(() => {
  w.value = width.value;
  h.value = height.value;
  nextTick(() => {
    const dragParent = document.getElementById(playerId.value);
    dragParent.style.width = w.value + "px";
    dragParent.style.height = h.value + "px";
  });
});
watch(
  () => props.isStop,
  (newVal) => {
    if (newVal) {
      if (jessibuca) {
        var result = jessibuca.isPlaying();
        if (result) {
          jessibuca.pause();
          isPauseShow.value = true;
        }
      }
    } else {
      if (jessibuca) {
        var result = jessibuca.isPlaying();
        if (!result && isPauseShow.value) {
          jessibuca.play();
          isPauseShow.value = false;
        }
      }
    }
  },
  {
    immediate: true, // 这个属性是重点啦
    deep: true, // 深度监听的参数
  }
);

const allPlayUrl = ref([]); //无人机推流地址
//拼接地址
function getUrl() {
  for (let key in optionArr.value) {
    // const middle = optionArr.value[key].playPath.replace("rtmp", "ws");
    // if (middle.lastIndexOf(":") < 4) {
    //   const urlAdress = middle.concat(".flv");
    //   allPlayUrl.value.push(urlAdress);
    // } else {
    //   const urlAdress = middle
    //     .replace(
    //       middle.split(":")[2].split("/")[0],
    //       optionArr.value[key].rtcApi + "/jessica"
    //     )
    //     .concat(".flv");
    //   allPlayUrl.value.push(urlAdress);
    // }
    // allPlayUrl.value.push(
    //   "http://120.76.178.0:1936/live/NEST0000001_in.flv"
    // );
    allPlayUrl.value.push(optionArr.value[key].playPath)
  }
}
getUrl();
// getLiveMessage();
const loading = ref(false);
let jessibuca = {};
// let jessibuca = null;
const container = ref(null);
const flag = ref(0);
const createJessibuca = () => {
  jessibuca = new window.jessibuca({
    container: container.value,
    videoBuffer: 0.2, // 缓存时长
    isResize: false,
    // useWCS: true,
    // useMSE: true,
    text: "",
    loadingText: t("957"),
    // hasAudio:false,
    debug: false,
    supportDblclickFullscreen: true,
    // hasAudio: false,
    isFlv: true,
    operateBtns: {
      fullscreen: false,
      screenshot: false,
      play: false,
    },
    forceNoOffscreen: true,
    isNotMute: false,
    timeout: 5,
  });
  // jessibuca.on("loadingTimeout", function () {
  //         setTimeout(()=>{
  //           if (jessibuca.player) {
  //             console.log('loadingTimeoutloadingTimeoutloadingTimeoutloadingTimeout');
  //           jessibuca.play()
  //           }
  //         },2500)
  //       })
  jessibuca.on("error", function (error) {
    if (error === "websocketError") {
      flag.value = flag.value + 1;
      if (flag.value === 4) {
        if (jessibuca) {
          jessibuca.destroy();
        }
        createJessibuca();
        for (let i = 0; i < optionArr.value.length; i++) {
          setTimeout(() => {
            if (optionArr.value[i].id === flowType.value) {
              if (jessibuca) {
                jessibuca.play(allPlayUrl.value[i]);
                flag.value = 0;
              } else {
                return;
              }
            }
          }, 1000 * (optionArr.value.findIndex((item) => item.id == flowType.value) + 1));
        }
      }
    }
  });
  jessibuca.on("delayTimeout", function () {
    if (jessibuca.player) {
      jessibuca.clearView();
      optionArr.value.forEach((e, index) => {
        if (e.id === flowType.value) {
          if (jessibuca) {
            jessibuca
              .pause()
              .then(() => {
                jessibuca.play(allPlayUrl.value[index]);
              })
              .catch((e) => {
                console.log("pause error", e);
              });
          } else {
            return;
          }
        }
      });
    }
  });
};

onBeforeUnmount(() => {
  flag.value = 0;
});
onUnmounted(() => {
  if (flowTypeObj.value.isAi) {
    axios({
      method: "get",
      url: `https://${window.g.serverUrl}:9090/personAI/closePersonAI?rtmpUrl=${flowTypeObj.value.playUrl}`,
    });
  }
  flag.value = 0;
  // emitter.off('stopJessibuca')
  // emitter.off('startJessibuca')
  // window.onresize = null;
  if (jessibuca) {
    // var result = jessibuca.isPlaying()
    // console.log(result);
    //   if(jessibuca.isPlaying()){
    jessibuca.destroy();
    //         }else{
    //         }
    // jessibuca.destroy() || jessibuca.close();
  }
});
//销毁
const destroyBox = () => {
  flag.value = 0;
  if (jessibuca) {
    if (jessibuca.player) {
      // jessibuca.close();
      if (jessibuca.isPlaying()) {
        jessibuca.destroy();
      } else {
        jessibuca.close();
      }
    }
  }
  emits("destroy", flowType.value);
};

//重新加载
const reload = async () => {
  if (flowTypeObj.value.isAi) {
    if (!jessibuca) {
      createJessibuca();
      jessibuca.play(aiPlayUrl.value);
    } else {
      let result = jessibuca.isPlaying();
      if (result) {
        jessibuca
          .pause()
          .then(() => {
            jessibuca.play(aiPlayUrl.value);
          })
          .catch((e) => {
            console.log("pause error", e);
          });
      } else {
        jessibuca.play(aiPlayUrl.value);
      }
    }

    return;
  }
  isPauseShow.value = false;
  if (commandTitle.value === "flv") {
    optionArr.value.forEach((e, index) => {
      if (e.id === flowType.value) {
        let result = jessibuca.isPlaying();
        if (!jessibuca) {
          createJessibuca();
          jessibuca.play(allPlayUrl.value[index]);
        } else {
          if (result) {
            jessibuca
              .pause()
              .then(() => {
                jessibuca.play(allPlayUrl.value[index]);
              })
              .catch((e) => {
                console.log("pause error", e);
              });
          } else {
            jessibuca.play(allPlayUrl.value[index]);
          }
        }
      }
    });
  } else {
    playRtc();
  }
};
emitter.on("controlStreaing", (dataInfo) => {
  loading.value = false;
  optionArr.value.forEach((e, index) => {
    if (e.liveType === "OUT_PUSH") {
      e.Isclose = dataInfo.data.outdoor;
    } else if (e.liveType === "IN_PUSH") {
      e.Isclose = dataInfo.data.indoor;
    } else if (e.liveType === "DRONE_PUSH") {
      e.Isclose = dataInfo.data.uav;
    }
  });
});
//开关流
const pauseJessibuca = async (val) => {
  let liveTypeObj = {};
  if (commandTitle.value === "flv") {
    if (!val) {
      //关
      optionArr.value.forEach((e, index) => {
        if (e.id === playerId.value) {
          if (e.liveType === "OTHER_PUSH") {
            e.Isclose = true;
          } else if (e.liveType === "OUT_PUSH") {
            liveTypeObj.outdoor = false;
            loading.value = false;
          } else if (e.liveType === "IN_PUSH") {
            liveTypeObj.indoor = false;
            loading.value = false;
          } else if (e.liveType === "DRONE_PUSH") {
            liveTypeObj.uav = false;
            loading.value = false;
          }
        }
      });
      let obj = {
        nestLiveInfo: liveTypeObj,
        protocolTypeEnum: "PROTOCOL_STANDARD",
      };
      device.pushLiveStatus(sn.value, obj);
      setTimeout(() => {
        jessibuca.clearView();
      }, 200);
    } else {
      //开
      optionArr.value.forEach((e) => {
        if (e.id === playerId.value) {
          if (e.liveType === "OTHER_PUSH") {
            liveTypeObj.otherdoor = true;
            let obj = {
              nestLiveInfo: liveTypeObj,
              protocolTypeEnum: "PROTOCOL_STANDARD",
            };
            loading.value = true;
            device.pushLiveStatus(sn.value, obj).then((res) => {
              if (!res.resultData) {
                ElMessage.info(t("958"));
                e.Isclose = false;
                loading.value = false;
              }
            });
          } else if (e.liveType === "OUT_PUSH") {
            liveTypeObj.outdoor = true;
            let obj = {
              nestLiveInfo: liveTypeObj,
              protocolTypeEnum: "PROTOCOL_STANDARD",
            };
            loading.value = true;
            device.pushLiveStatus(sn.value, obj).then((res) => {
              if (!res.resultData) {
                ElMessage.info(t("958"));
                e.Isclose = false;
                loading.value = false;
              }
            });
          } else if (e.liveType === "IN_PUSH") {
            liveTypeObj.indoor = true;
            let obj = {
              nestLiveInfo: liveTypeObj,
              protocolTypeEnum: "PROTOCOL_STANDARD",
            };
            loading.value = true;
            device.pushLiveStatus(sn.value, obj).then((res) => {
              if (!res.resultData) {
                ElMessage.info(t("958"));
                e.Isclose = false;
                loading.value = false;
              }
            });
          } else if (e.liveType === "DRONE_PUSH") {
            liveTypeObj.uav = true;
            let obj = {
              nestLiveInfo: liveTypeObj,
              protocolTypeEnum: "PROTOCOL_STANDARD",
            };
            loading.value = true;
            device.pushLiveStatus(sn.value, obj).then((res) => {
              if (!res.resultData) {
                ElMessage.info(t("958"));
                e.Isclose = false;
                loading.value = false;
              }
            });
          }
        }
      });
    }
  } else {
    if (!val) {
      WebrtcObj.value.close();
    } else {
      playRtc();
    }
  }
};

//监听loading
watch(loading, (newVal, oldVal) => {
  if (newVal) {
    container.value.querySelector(".jessibuca-loading").style.display = "none";
    setTimeout(() => {
      loading.value = false;
      container.value.querySelector(".jessibuca-loading").style.display =
        "flex";
    }, 1000);
  }
});

const isCenter = ref(true);
const isPauseShow = ref(false);
//投屏
const openMonitoringCenter = async () => {
  if (JSON.parse(sessionStorage.getItem("streamingList")) !== null) {
    let tempDataList = JSON.parse(sessionStorage.getItem("streamingList"));
    device.queryStreamingArray("AIRPORT", "").then(async (res) => {
      if (res.resultStatus) {
        let tempData = res.resultData[0].dataList;
        //把现在要投屏的数据设为isAdd = true
        tempData.forEach((e) => {
          e.liveInfoList.forEach((a) => {
            if (a.id === flowType.value) {
              a.isAdd = true;
              a.isMute = true;
              a.isLuzhi = true;
              a.isPause = true;
              a.isFlv = true;
            }
          });
        });
        tempData.forEach((a) => {
          a.liveInfoList.forEach((b) => {
            tempDataList.forEach((e) => {
              e.liveInfoList.forEach((c) => {
                if (c.isAdd === true && b.id === c.id) {
                  b.isAdd = c.isAdd;
                  b.isMute = true;
                  b.isLuzhi = true;
                  b.isPause = true;
                  b.isFlv = true;
                }
              });
            });
          });
        });
        //把之前的isAdd的数据拿出来和最新的比较，拿之前的isAdd的数据给到最新的
        sessionStorage.setItem("streamingList", JSON.stringify(tempData));
      }
    });
  } else {
    device.queryStreamingArray("AIRPORT", "").then(async (res) => {
      if (res.resultStatus) {
        let tempData = res.resultData[0].dataList;
        tempData.forEach((e) => {
          e.liveInfoList.forEach((a) => {
            if (a.id === flowType.value) {
              a.isAdd = true;
              a.isMute = true;
              a.isLuzhi = true;
              a.isPause = true;
              a.isFlv = true;
            }
          });
        });
        sessionStorage.setItem("streamingList", JSON.stringify(tempData));
      }
    });
  }
  let tempDataArr = JSON.parse(sessionStorage.getItem("videoArr"));
  if (tempDataArr !== null && tempDataArr !== undefined) {
    if (!tempDataArr.includes(flowType.value)) {
      tempDataArr.push(flowType.value);
      sessionStorage.setItem("videoArr", JSON.stringify(tempDataArr));
    }
  } else {
    sessionStorage.setItem("videoArr", JSON.stringify([flowType.value]));
  }
  emitter.emit("openMonitoring");
  // pauseJessibuca(false)
  jessibuca.pause();
  isPauseShow.value = true;
};

const commandTitle = ref("flv");
const tempSdp = ref("");
//切换类型
const switchType = (command) => {
  commandTitle.value = command;
  if (command === "rtc") {
    jessibuca.destroy();
    nextTick(() => {
      playRtc();
    });
  } else {
    nextTick(() => {
      if (flowTypeObj.value.isAi) {
        createJessibuca();
        jessibuca.play(aiPlayUrl.value);
        return;
      }
      createJessibuca();
      optionArr.value.forEach((e, index) => {
        if (e.id === playerId.value) {
          if (jessibuca) {
            jessibuca
              .play(allPlayUrl.value[index])
              .then(() => {
                ElMessage.success(t("959"));
              })
              .catch((e) => {
                ElMessage.error(t("960"));
              });
          }
        }
      });
    });
  }
};
//播放RTC
const playRtc = () => {
  querySdp()
    .then(async (res) => {
      let submit = {};
      optionArr.value.forEach((e, index) => {
        if (e.id === flowType.value) {
          submit.liveName = e.liveName;
          submit.nestSn = e.sn;
        }
      });
      // let index = tempUrl.indexOf("/live/");
      // let index2 = tempUrl.indexOf(".flv");
      //现在是写死的测试    到时候要改
      submit.sdp = res;
      // submit.liveName = tempUrl.substring(index + 1, index2);
      // submit.liveName = "live/abc";
      submit.type = "offer";
      const response = await taskManage.queryM7sSdp(submit);
      if (response.resultStatus) {
        tempSdp.value = response.resultData.sdp;
        await WebrtcObj.value.setRemoteDescription(
          // 在两个对等点之间协商连接的过程涉及来回交换对象，每个描述都表示描述的发送者支持的连接配置选项的一个组合。一旦两个对等方就连接的配置达成一致，协商就完成了。
          new RTCSessionDescription({
            type: "answer",
            sdp: tempSdp.value,
          })
        );
      } else {
        commandTitle.value = "rtc";
      }
    })
    .catch((e) => {
      ElMessage.error(t("960"));
    });
};
const WebrtcObj = ref(null);
//获取sdp
const containerVideo = ref(null);
const querySdp = async () => {
  WebrtcObj.value = new RTCPeerConnection(null);
  // 添加流后赋给vedio进行播放
  WebrtcObj.value.onaddstream = (event) => {
    let video = document.getElementById("container");
    video.srcObject = event.stream;
  };
  // addTransceiver()创建一个新的 RTCRtpTransceiver并将其添加到与RTCPeerConnection. 每个收发机表示的双向流，既具有RTCRtpSender和 RTCRtpReceiver与它相关联。
  WebrtcObj.value.addTransceiver("audio", {
    direction: "recvonly",
  });
  WebrtcObj.value.addTransceiver("video", {
    direction: "recvonly",
  });

  // 请求连接
  const offer = await WebrtcObj.value.createOffer();
  // setLocalDescription 存储本地 offer 描述
  await WebrtcObj.value.setLocalDescription(offer);
  return offer.sdp;
};
const flowType = ref("");
const flowTypeObj = ref("");
const liveStatus = ref({});
const aiPlayUrl = ref("");
flowType.value = currentPlay.value.id;
flowTypeObj.value = currentPlay.value;
onMounted(() => {
  let jessibucaZindex = sessionStorage.getItem("jessibucaZindex");
  V1.value.style.zIndex = Number(jessibucaZindex) + 1;
  sessionStorage.setItem("jessibucaZindex", Number(jessibucaZindex) + 1);
  if (flowTypeObj.value.isAi) {
    createJessibuca();
    axios({
      method: "get",
      url: `https://${window.g.serverUrl}:9090/personAI/openPersonAI?rtmpUrl=${flowTypeObj.value.playUrl}&outputUrl=${flowTypeObj.value.wsPlayUrl}`,
    })
      .then(async (res) => {
         aiPlayUrl.value = res.data.aiOutputUrl
        if (jessibuca) {
          jessibuca.play(aiPlayUrl.value);
        }
      })
      .catch(() => {
        ElMessage.error(t("961"));
      });
    return;
  }
  device.getLiveStatus(sn.value).then((res) => {
    optionArr.value.forEach((e, index) => {
      if (
        e.id === currentPlay.value.id &&
        e.liveName === currentPlay.value.liveName
      ) {
        if (e.liveType === "OTHER_PUSH") {
          e.Isclose = true;
        } else if (e.liveType === "OUT_PUSH") {
          e.Isclose = res.resultData.outdoor;
        } else if (e.liveType === "IN_PUSH") {
          e.Isclose = res.resultData.indoor;
        } else if (e.liveType === "DRONE_PUSH") {
          e.Isclose = res.resultData.uav;
          jsHasPlaneContorl.value = true;
        }

        createJessibuca();
        if (jessibuca) {
          jessibuca
            .play(allPlayUrl.value[index])
            .then(() => {
              ElMessage.success(t("959"));
            })
            .catch((e) => {
              ElMessage.error(t("960"));
            });
        }
      }
    });
  });
});
let arrJessibuca = ref([]);
/* 下拉改变增加窗口 */
function addJec(data) {
  emits("addCurrentPlayJec", data);
}
function selectChangeItem(val) {
  let oldId = "";
  let newObj = {};
  optionArr.value.forEach((e) => {
    if (e.isShow) {
      oldId = e.id;
    } else if (e.id === val) {
      newObj = e;
    }
  });
  if (newObj.isShow) {
    return;
  } else {
    if (commandTitle.value === "flv") {
      nextTick(() => {
        if (jessibuca[flowType.value].player) {
          jessibuca.destroy();
        }
        createJessibuca();
        optionArr.value.forEach((e, index) => {
          if (
            e.id === currentPlay.value.id &&
            e.liveName === currentPlay.value.liveName
          ) {
            if (jessibuca) {
              jessibuca
                .play(allPlayUrl.value[index])
                .then(() => {
                  ElMessage.success(t("959"));
                })
                .catch((e) => {
                  ElMessage.error(t("960"));
                });
            }
          }
        });
      });
      emits("addCurrentPlaySel", oldId, newObj);
    } else {
      playRtc();
      emits("addCurrentPlaySel", oldId, newObj);
    }
    flowType.value = newObj.id;
  }
}
// const instance = getCurrentInstance();
function selectChange(val) {
  if (val.isShow) {
    return;
  } else {
    if (commandTitle.value === "flv") {
      nextTick(() => {
        if (jessibuca.player) {
          jessibuca.destroy();
        }
        // createJessibuca();
        // optionArr.value.forEach((e, index) => {
        //   if (
        //     e.id === currentPlay.value.id &&
        //     e.liveName === currentPlay.value.liveName
        //   ) {
        //     if(jessibuca){
        //       jessibuca
        //         .play(allPlayUrl.value[index])
        //         .then(() => {
        //           ElMessage.success(t("959"));
        //         })
        //         .catch((e) => {
        //           ElMessage.error(t("960"));
        //         });

        //     }
        //   }
        // });
      });
      emits("addCurrentPlaySel", flowType.value, val);
    } else {
      // playRtc();
      emits("addCurrentPlaySel", flowType.value, val);
    }
    flowType.value = val.id;
  }
}

const toggleVideo = ref(true); //切换推流开关
let toggleVideoTimer = null; //切换推流定时器
/* 切换推流方法 */
function toggleVideoHandle(val) {
  if (toggleVideo.value) {
    if (jessibuca.player) {
      jessibuca.pause();
    }
  } else {
    if (jessibuca) {
      jessibuca.play();
    }
  }

  toggleVideo.value = !toggleVideo.value;
}
const V1 = ref(null);
//点击jessibuca z-index 增加
function clickAddZIndex(index) {
  let jessibucaZindex = sessionStorage.getItem("jessibucaZindex");
  if (jessibucaZindex) {
    V1.value.style.zIndex = Number(jessibucaZindex) + 1;
    sessionStorage.setItem("jessibucaZindex", Number(jessibucaZindex) + 1);
  } else {
    sessionStorage.setItem("jessibucaZindex", 12);
  }
}

const controlShow = ref(true);
setTimeout(() => {
  controlShow.value = false;
}, 2000);
//鼠标进入
function enter() {
  controlShow.value = true;
}
//鼠标移出
function leave() {
  controlShow.value = false;
}

/* cgt---面板控制代码 */

let jsHasPlaneContorl = ref(false); //推流类型字段是否为无人机推流

let isPlaneContorl = ref(false); //是否开启面板控制

//拖拽放大缩小处理
let V1Top, V1left;
function resizeHandle() {
  const position = V1.value.getBoundingClientRect();
  V1left = position.left;
  V1Top = position.top;
}

//打开面板控制
function openPlaneContorl() {
  if (jessibuca && jessibuca.isPlaying()) {
    isPlaneContorl.value = true;
    resizeHandle();
    emits("bindContorlWS");
  } else {
    isPlaneContorl.value = false;
    ElMessage({
      grouping: true,
      message: t("962"),
      type: "warning",
    });
  }
}

//关闭面板控制判断
function closePlaneContorl() {
  emits("closeFlyContorl");
}

//传感器尺寸
let transducerWidth = 6.4;
let transducerHight = 4.8;
let cmos = 4.43;

//面板点击控制
const contorlFly = (event) => {
  const currentX = event.clientX - V1left;
  const currentY = event.clientY - V1Top;

  const offsetX = (currentX || 1) - w.value / 2;
  const offsetY = (currentY || 1) - h.value / 2;

  const VFOV = (180 / Math.PI) * 2 * Math.atan(transducerHight / 2 / cmos);
  const HFOV = (180 / Math.PI) * 2 * Math.atan(transducerWidth / 2 / cmos);

  const angleOffsetX = offsetX * (HFOV / w.value);
  const angleOffsetY = -(offsetY * (VFOV / h.value));
  emits("planeSendWs", angleOffsetX, angleOffsetY);
};

function throttle(fn, delay) {
  let canRun = true; // 通过闭包保存一个标记
  return function () {
    if (!canRun) return;
    canRun = false;
    setTimeout(() => {
      fn.apply(this, arguments);
      canRun = true;
    }, delay);
  };
}

const contorlFlyThrottle = throttle(contorlFly, 300);
/* cgt---end */
</script>

<style lang="less" scoped>
/deep/.el-loading-mask {
  background-color: #333333;
  opacity: 0.4;
}
.rtc {
  width: 100%;
  height: 500px;
  margin-top: 20px;
}
/deep/.vdr-handle-bl {
  bottom: 1px;
  width: 0px;
  height: 0px;
  left: 1px;
  cursor: sw-resize;
  z-index: 9999;
  opacity: 0;
  border-width: 0 28px 28px 0;
  border-style: solid;
}
.containers {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 20;

  #container {
    background: #333333;
    width: 100%;
    height: 100%;
  }
  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
  //音量按钮
  video::-webkit-media-controls-mute-button {
    display: none;
  }
  video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
  }
  //音量的控制条
  video::-webkit-media-controls-volume-slider {
    display: none;
  }
  /deep/.jessibuca-container.jessibuca-controls-show .jessibuca-controls {
    background: none;
  }

  .position_box {
    position: absolute;
    top: 10px;
    left: 8px;
    right: 8px;
    z-index: 100;
    .toolbar {
      opacity: 1;
      width: 50%;
      height: 2.5rem;
      display: flex;
    }
    .icon_con {
      display: inline-block;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background: #374056;
      border-radius: 2px;
      vertical-align: middle;
      margin-right: 0.375rem;
      opacity: 0.5;
      .iconfont {
        font-size: 1.0769rem;
      }
    }

    .icon_con:first-child {
      margin-right: 0.375rem;
    }

    .title {
      height: 28px;
      line-height: 28px;
      margin-left: 12px;
      color: #fff;
      width: 9rem;
      display: inline-block;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }

    /deep/ .el-select .el-input__wrapper {
      background: #404d61;
      opacity: 0.5;
      border: 0.5px solid #7290a2;
      box-shadow: 0 0 0 0px;
    }
    /deep/ .el-select .el-input__inner {
      color: #fff;
    }
    /deep/ .el-select__popper.el-popper[role="tooltip"] {
      background: #0d43d6 !important;
    }
    /deep/.el-select-dropdown {
      border: none;
      background-color: rgba(1, 28, 82, 0.8);
    }
    /deep/.el-select-dropdown__item {
      padding: 0px 0.76923rem;
    }
  }
  .position_bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    line-height: 38px;
    .icon_con {
      display: inline-block;
      width: 2.153846rem;
      height: 2.153846rem;
      line-height: 2.153846rem;
      text-align: center;
      background: #374056;
      border-radius: 2px;
      vertical-align: middle;
      margin-right: 0.375rem;
      .iconfont {
        font-size: 0.92307rem;
      }
    }
    .icon_con1 {
      width: 0;
      height: 0;
      border-color: #374056 transparent; /*上下颜色 左右颜色*/
      border-width: 0 2.92307rem 2.92307rem 0;
      border-style: solid;
      display: inline-block;
      line-height: 4.861538rem;
      vertical-align: middle;
      .iconfont {
        font-size: 0.92307rem;
      }
    }
    .title {
      height: 28px;
      color: #fff;
      // width: 9rem;
      display: inline-block;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
  }
}
.dialog {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 999;
  & > div {
    width: 100%;
    height: 2.5rem;
  }
  .box-switch {
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background: #374056;
    border-radius: 2px;
    vertical-align: middle;
    margin-right: 0.375rem;
    opacity: 0.5;
    margin-top: 10px;
    margin-left: 8px;
  }
}
.parent {
  width: 425px;
  position: relative;
}
.LiuSelect {
  width: 25%;
  position: absolute;
  right: 10px;
}
//下拉框

.darkSelect {
  margin-right: 6px;
  padding: 4px 5px;
  background: transparent;
  border: 1px solid transparent;
  color: #fff;
  outline: none;

  > option {
    height: 40px;
    color: rgba(0, 0, 0, 0.7);
  }
}
// @media screen and (max-width: 1920px) {
//   .position_btn {
//     position: absolute;
//     right: 0rem;
//     top: 0rem;
//   }
// }
// @media screen and (max-width: 1536px) {
//   .position_btn {
//     position: absolute;
//     right: 0rem;
//     top: 0rem;
//   }
// }
.position_btn {
  width: 8.75rem;
  height: 3.125rem;
  line-height: 3.125rem;
  background: linear-gradient(90deg, rgba(70, 131, 246, 0.2) 0%, #0786ac 100%);
  z-index: 10;

  .iconfont {
    margin: 0 0.5rem 0 0.25rem;
    font-size: 1.125rem;
    vertical-align: middle;
  }

  .title {
    display: inline-block;
    width: 6.6rem;
    vertical-align: middle;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.gradient_btn::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 0.125rem;
  background: linear-gradient(90deg, #5fa0f3 0%, rgba(36, 97, 181, 0.33) 100%);
}

.gradient_btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1875rem;
  background: linear-gradient(90deg, #489bea 0%, #1d4e95 100%);
}
/deep/.el-select {
  width: 100%;
}
.span1 {
  width: 100%;
  position: absolute;
  left: 0;
  font-size: 1.0769rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 0.83rem;
}
.span2 {
  position: absolute;
  right: 1.23rem;
  font-size: 1.0769rem;
}
.span2:hover {
  color: #409eff;
}
.Fade-enter,
.Fade-leave-to {
  opacity: 0;
}
.Fade-enter-to,
.Fade-leave {
  opacity: 1;
}

.Fade-enter-active,
.Fade-leave-active {
  transition: all 1.2s;
}
.active {
  background-color: #333333;
}
.aiClass {
  background-image: url("../../../asset/img/aiBac.png");
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
  padding: 8px 0px;
  float: right;
  text-align: center;
}
</style>
<style lang="less">
.harbour-dropdown {
  background-color: transparent !important; /*设置矩形框的背景颜色*/
  padding: 0px 6px;
  margin: -6px !important;
  font-weight: 400;
  border: none !important;
  border-radius: 0px;
  .active {
    color: #ffffff !important;
    background-color: rgba(18, 108, 218, 0.82);
  }
  .el-dropdown-menu {
    background-color: #05162b !important;
    max-height: 160px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: initial;
      width: 2px;
      height: 2px;
      background-color: transparent;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #1fa3f6;
      &:vertical {
        border-radius: 4px;
      }
    }
  }
  .el-dropdown-menu__item {
    width: 120px;
    color: #6bbeee;
    padding-left: 6px;
    padding-right: 6px;
  }
  .el-dropdown-menu__item:not(.is-disabled):focus {
    background-color: #1c64bb !important;
    color: #fff !important;
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 15px;
  }
  .el-popper__arrow::before {
    display: none !important;
  }
}
</style>
