<template>
  <div class="main full_height">
    <div class="main_hd">
      <i class="icon iconfont icon-xiangzuo hover_opacity" @click="$emit('update:isVisible', false)"></i>
      <span>{{ t('440') }}</span>
      <i class="icon iconfont icon-a-ziyuan60 hover_opacity" @click="uploadAudio"></i>
      <input ref="uploadFile" type="file" style="display: none" accept="audio/mp3" @change="fileChange" />
    </div>
    <div class="main_bd">
      <div class="waist">
        <div class="hover_opacity" @click="playAll()">
          <span class="icon iconfont icon-icon_bofang-xian"></span>
          <span>{{ t('1286') }}({{ currSongIndex + 1 }}/{{ audioList.length }})</span>
        </div>
        <div>
          <span class="icon iconfont icon-24gl-repeat2 hover_opacity mode_icon" :title="currMode === '1' ? t('1287') : currMode === '2' ? t('1288') : ''
            " v-show="currMode === '1' || currMode === '2'" @click="switchMode">
            <i v-show="currMode === '1'"></i>
          </span>
          <span class="icon iconfont icon-24gl-repeatDot2 hover_opacity mode_icon"
            v-show="currMode === '3' || currMode === '4'" :title="currMode === '3' ? t('1289') : currMode === '4' ? t('1290') : ''
              " @click="switchMode">
            <i v-show="currMode === '3'"></i>
          </span>
        </div>
      </div>
      <div class="bd_con">
        <ul class="song_ul">
          <li class="hover_opacity" v-for="(item, index) in audioList" :key="index" :class="{
            active_li:
              isPlay && playSource.includes('0') && currSongIndex === index,
            curr_li: currSongIndex === index,
            }"
            @click="playSong(index)"
            >
            <div class="box_left">
              <span class="serial_num">{{ index + 1 }}</span>
              <img src="../../../../asset/img/command-center/playing.gif" alt="" />
            </div>
            <div class="info">
              <div class="song_name">
                <span class="name">{{
                  item.audioName
                }}</span>
                <div class="hover_box">
                  <span class="icon iconfont icon-qita hover_opacity"></span>
                  <div class="hover_con">
                    <ul class="song_operate_ul">
                      <!-- <li>{{ t('1291') }}</li> -->
                      <!-- <li>{{ t('1292') }}</li> -->
                      <!-- <li @click="downloadAudio">{{ t('393') }}</li> -->
                      <li @click.stop="delAudio(item.audioName)">{{ t('210') }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="author">
                <span>{{ item.author ? item.author : t("1293") }}</span>
                <span>{{ secondToMinute(item.duration) }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { t } from '../../../../languages';
import { secondToMinute } from "@/utils/utilFun.js";
import { ref, toRefs, onBeforeUnmount } from "vue";
import speaker from "@/network/speaker.js";
import emitter from "@/utils/mitt";
import { ElMessage } from "element-plus";
import { debounce } from "@/utils/utilFun.js";

const props = defineProps({
  audioList: {
    type: Array,
    default: () => {
      return [];
    },
  },
  sn: {
    type: String,
    default: "",
  },
  playMode: {
    type: String,
    default: "1",
  },
});
const { sn, playMode, audioList } = toRefs(props);
const emit = defineEmits(["playAudio", "getAudioList", "changeMode"]);

const currSongIndex = ref(-1);
/* 播放歌曲 */
function playSong(index) {
  ElMessage.success(t("1294"));
  speaker
    .playAudioFile(sn.value, {
      data: index + 1 + "," + (parseInt(playMode.value) - 1),
    })
    .then((res) => {
      if (res.resultStatus) {
        currSongIndex.value = index;
      }
    });
}

const currMode = ref("1");
let currModeIndex = 0;
const modeArr = ["1", "2", "3", "4"]; //循环模式 -- [单曲单次，单曲循环，列表不循环，列表循环]
/* 发送循环模式 -- 防抖操作 */
const modeSend = debounce(() => {
  speaker.operateAudio(sn.value, {
    action: "MP_SET_CY_C_ACTION",
    data: parseInt(currMode.value) - 1,
  });
  emit("changeMode", currMode.value);
}, 700);

/* 切换循环模式 */
function switchMode() {
  const len = modeArr.length;
  if (currModeIndex >= len - 1) {
    currModeIndex = 0;
  } else {
    currModeIndex += 1;
  }
  currMode.value = modeArr[currModeIndex];
  modeSend();
}

/* 播放模式改变 */
function playModeChange(modeVal) {
  const index = modeArr.findIndex(i => {
    return i == modeVal;
  })
  if (index === -1) return false;
  currModeIndex = index;
  currMode.value = modeArr[currModeIndex];
  emit("changeMode", currMode.value);
}




/* 播放全部 */
function playAll() {
  const len = audioList.value.length;
  if (len <= 0) {
    return false;
  }
  const index = 0; //从第一首开始播放
  const playMode = 3; //列表不循环
  ElMessage.success(t("1295"));
  speaker
    .playAudioFile(sn.value, {
      data: index + 1 + "," + (playMode - 1),
    })
    .then((res) => {
      if (res.resultStatus) {
        currSongIndex.value = index;
        emitter.emit("play-mode-change", playMode + "");
      }
    });
}

/* 切换上一首/下一首 */
function cutAudioFile(type) {
  const len = audioList.value.length;
  if (len <= 0) {
    return false;
  }
  if (currSongIndex.value === -1) {
    playSong(0);
    return false;
  }
  if (type === "prev") {
    // 上一首
    const index = currSongIndex.value <= 0 ? len - 1 : currSongIndex.value - 1;
    const item = audioList.value[index];
    playSong(index);
  } else {
    // 下一首
    const index = currSongIndex.value >= len - 1 ? 0 : currSongIndex.value + 1;
    const item = audioList.value[index];
    playSong(index);
  }
}

/* 播放音频状态信息处理函数 */
function audioHandle(data) {
  const info = data.data;
  if (info.length <= 0) return false;
  const audioIndex = parseInt(info[0]) - 1; //当前播放列表音频序号
  if (audioIndex !== currSongIndex.value) {
    // 索引不一致
    currSongIndex.value = audioIndex;
  }
}

const isPlay = ref(false);
/* 设备状态信息处理函数 */
function deviceStatusHandle(data) {
  const info = data.data;
  if (info.length <= 0) return false;
  const deviceStatus = info[0];
  // 播放状态
  isPlay.value = deviceStatus == "1" ? true : false;
}

const playSource = ref([]); //当前播放源
/* 播放源信息处理函数 */
function sourceHandle(data) {
  playSource.value = data.data;
}

emitter.on("cut-audio", cutAudioFile);
emitter.on("speaker-device-status", deviceStatusHandle);
emitter.on("speaker-source-status", sourceHandle);
emitter.on("speaker-audio-status", audioHandle);
emitter.on("play-mode-change", playModeChange);

onBeforeUnmount(() => {
  emitter.off("cut-audio", cutAudioFile);
  emitter.off("speaker-device-status", deviceStatusHandle);
  emitter.off("speaker-source-status", sourceHandle);
  emitter.off("speaker-audio-status", audioHandle);
  emitter.off("play-mode-change", playModeChange);
});

const uploadFile = ref(null);
/* 上传音频文件 */
function uploadAudio() {
  uploadFile.value.click();
}

/* 上传音频文件选择框改变 */
function fileChange(e) {
  // 获取上传后的文件信息
  const files = e.target.files;
  if (files.length > 0) {
    const file = files[0];
    // 上传音频
    const formData = new FormData();
    formData.append("file", file);
    const fileType = file.type;
    // 只允许上传mp3
    if (!["audio/mpeg"].includes(fileType)) {
      ElMessage.warning(t("1296"));
      return false;
    }
    speaker
      .uploadAudio(sn.value, formData)
      .then((res) => {
        uploadFile.value.value = "";
        if (res.resultStatus) {
          ElMessage.success(t("1297"));
          setTimeout(() => {
            emit("getAudioList");
          }, 3000);
        }
      })
      .catch((err) => {
        uploadFile.value.value = "";
      });
  }
}

/* 下载音频 */
function downloadAudio() { }

/* 删除音频 */
function delAudio(fileName) {
  speaker
    .delAudio(sn.value, {
      fileName: fileName,
    })
    .then((res) => {
      if (res.resultStatus) {
        ElMessage.success(t("1298"));
        setTimeout(() => {
          emit("getAudioList");
        }, 3000);
      }
    });
}
</script>

<style scoped lang="less">
.main {
  position: relative;
  padding-top: 3.375rem;
}
</style>
<style scoped lang="less">
@import url("./music-list.less");
</style>

