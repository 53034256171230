<template>
  <div class="flex_box full_height clearfix">
    <div
      class="play_box"
      v-show="bgmInfo.isPlay === false"
      @click="playPause(true)"
    >
      <i
        class="icon iconfont icon-icon_bofang-xian hover_opacity"
        :title="t('1291')"
      ></i>
    </div>
    <div
      class="play_box"
      v-show="bgmInfo.isPlay === true"
      @click="playPause(false)"
    >
      <i class="icon iconfont icon-zanting1 hover_opacity" :title="t('430')"></i>
    </div>
    <div class="info">
      <div class="info_title">
        <span class="icon iconfont icon-yinle"></span>
        <span class="title">{{ currSongName }}</span>
      </div>
      <p>{{ t('1299') }}:{{ bgmInfo.isPlay === true ? t('1300') : t('1301') }}</p>
    </div>
    <div class="icon_group">
      <div class="volume">
        <span class="icon iconfont icon-laba hover_opacity" :title="t('1302')"></span>
        <div class="volume_box">
          <div class="slider_con">
            <el-slider
              v-model="volume"
              :show-tooltip="true"
              @change="volumeChange"
            />
          </div>
        </div>
      </div>
      <span
        class="icon iconfont icon-24gl-repeat2 hover_opacity mode_icon"
        :title="
          currMode === '1' ? t('1287') : currMode === '2' ? t('1288') : ''
        "
        v-show="currMode === '1' || currMode === '2'"
        @click="switchMode"
      >
        <i v-show="currMode === '1'"></i>
      </span>
      <span
        class="icon iconfont icon-24gl-repeatDot2 hover_opacity mode_icon"
        v-show="currMode === '3' || currMode === '4'"
        :title="
          currMode === '3' ? t('1289') : currMode === '4' ? t('1290') : ''
        "
        @click="switchMode"
      >
        <i v-show="currMode === '3'"></i>
      </span>

      <span
        class="icon iconfont icon-liebiao hover_opacity"
        @click="bgmListShow = !bgmListShow"
      ></span>
    </div>
    <div class="inside" v-show="bgmListShow">
      <div class="inside_hd">
        {{ t('447') }}
        <span
          class="iconfont icon-cuocha_kuai hover_opacity"
          style="color: #00ecf7"
          @click="bgmListShow = false"
        ></span>
      </div>
      <div class="inside_bd">
        <div class="main_bd">
          <div class="waist">
            <div class="hover_opacity" @click="playSong()">
              <span class="icon iconfont icon-icon_bofang-xian"></span>
              <span
                >{{ t('1286') }}({{ currSongIndex + 1 }}/{{ bgmList.length }})</span
              >
            </div>
            <div class="">
              <i
                class="icon iconfont icon-a-ziyuan60 hover_opacity"
                @click="uploadAudio"
              ></i>
              <input
                ref="uploadFile"
                type="file"
                style="display: none"
                accept="audio/mp3"
                @change="fileChange"
              />
            </div>
          </div>
          <div class="bd_con">
            <ul class="song_ul">
              <li
                class="hover_opacity"
                v-for="(item, index) in bgmList"
                :key="index"
                :class="{
                  active_li: bgmInfo.isPlay === true && currSongIndex === index,
                  curr_li: currSongIndex === index,
                }"
                @click="playSong(index)"
              >
                <div class="box_left">
                  <span class="serial_num">{{ index + 1 }}</span>
                  <img
                    src="../../../../asset/img/command-center/playing.gif"
                    alt=""
                  />
                </div>
                <div class="info">
                  <div class="song_name">
                    <span class="name">{{
                      item.audioName
                    }}</span>
                    <div class="hover_box">
                      <span
                        class="icon iconfont icon-qita hover_opacity"
                      ></span>
                      <div class="hover_con">
                        <ul class="song_operate_ul">
                          <li @click.stop="delAudio(item.audioName)">{{ t('210') }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="author">
                    <span>{{ item.author ? item.author : t('1293') }}</span>
                    <span>{{ secondToMinute(item.duration) }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { t } from '../../../../languages';
import { onBeforeUnmount, reactive, ref, toRefs } from "vue";
import emitter from "@/utils/mitt";
import { secondToMinute } from "@/utils/utilFun.js";
import { ElMessage } from "element-plus";
import speaker from "@/network/speaker.js";

const props = defineProps({
  sn: {
    type: String,
    default: "",
  },
});
const { sn } = toRefs(props);   // sn

const bgmListShow = ref(false);    //是否显示音频列表

const bgmList = ref([]); //音频文件列表
/* 背景音列表处理程序 */
function bgmListHandle(dataInfo) {
  if (dataInfo.target === sn.value) {
    // 机场sn一致
    const newList = [];
    for (let i = 0; i < dataInfo.data.length; i++) {
      const splitList = dataInfo.data[i].split("||");
      const item = {};
      item.audioId = "id" + i;
      item.audioName = splitList[0];
      item.author = splitList[1];
      item.duration = parseInt(splitList[2]);
      newList.push(item);
    }
    bgmList.value = newList;
  }
}

const currSongName = ref("-");
/* 背景音状态处理程序 */
function bgmStatusHandle(dataInfo) {
  const data = dataInfo.data;
  currSongIndex.value = Number(data[0]) ? parseInt(data[0]) - 1 : -1;
  currSongName.value = bgmList.value[currSongIndex.value].audioName;
  playMode.value = parseInt(data[1]);
}

/**
 * 更新背景音播放状态
 * data.isplay   -- 是否在播放中
 * data.playSource   -- 播放源
 * data.volume   -- 背景音音量
 */
function updateBgmStatus(data) {
  if (data.isPlay === t("1300") && data.playSource.includes("1")) {
    // 播放中且播放源为背景音时
    bgmInfo.isPlay = true;
  } else {
    bgmInfo.isPlay = false;
  }
  // 音量是从别的ws主题推过来的
  if(data.volume){
    volume.value = parseInt(data.volume)
  }
}

// 打开监听
emitter.on("speaker-bgm-list", bgmListHandle);
emitter.on("speaker-bgm-status", bgmStatusHandle);
emitter.on("speaker-bgm-play", updateBgmStatus);

onBeforeUnmount(() => {
  // 取消监听
  emitter.off("speaker-bgm-list", bgmListHandle);
  emitter.off("speaker-bgm-status", bgmStatusHandle);
  emitter.off("speaker-bgm-play", updateBgmStatus);
});

/* 循环模式start */
const currMode = ref("1");   //当前循环模式
let currModeIndex = 0;
const modeArr = ["1", "2", "3", "4"]; //循环模式 -- [单曲单次，单曲循环，列表不循环，列表循环]
/* 切换循环模式 */
function switchMode() {
  const len = modeArr.length;
  if (currModeIndex >= len - 1) {
    currModeIndex = 0;
  } else {
    currModeIndex += 1;
  }
  currMode.value = modeArr[currModeIndex];
  speaker
    .operateBgm(sn.value, {
      action: "MP_SET_CY_C_BGM_ACTION",
      data: parseInt(currMode.value) - 1,
    })
    .then((res) => {});
}
/* 循环模式end */

/* 操作音频文件start */
const uploadFile = ref(null);    //隐藏文件域
/* 上传背景音文件 */
function uploadAudio() {
    uploadFile.value.click();
}
/* 上传背景音选择框改变 */
function fileChange(e) {
    // 获取上传后的文件信息
  const files = e.target.files;
  if (files.length > 0) {
    const file = files[0];
    // 上传音频
    const formData = new FormData();
    formData.append("file", file);
    const fileType = file.type;
    // 只允许上传mp3
    if (!["audio/mpeg"].includes(fileType)) {
      ElMessage.warning(t("1296"));
      return false;
    }
    speaker
      .uploadBgm(sn.value, formData)
      .then((res) => {
        uploadFile.value.value = "";
        if (res.resultStatus) {
          ElMessage.success(t("1297"));
        }
      })
      .catch((err) => {
        uploadFile.value.value = "";
      });
  }
}

/* 删除音频 */
function delAudio(fileName) {
  speaker
    .delBgm(sn.value, {
      fileName: fileName,
    })
    .then((res) => {
      if (res.resultStatus) {
        ElMessage.success(t("1298"));
      }
    });
}
/* 操作音频文件end */


const currSongIndex = ref(-1);
const playMode = ref(1);
/* 播放歌曲 */
function playSong(index) {
  let mode = playMode.value - 1;
  if (index === undefined) {
    // 播放全部
    const len = bgmList.value.length;
    if (len <= 0) {
      return false;
    }
    index = 0;  //第一首
    mode = 3; //列表不循环
  }
  ElMessage.success(t("1303"));
  speaker
    .playBgmFile(sn.value, {
      data: index + 1 + "," + mode,
    })
    .then((res) => {
      if (res.resultStatus) {
        currSongIndex.value = index;
      }
    });
}

const bgmInfo = reactive({
  isPlay: true,   //是否播放
});   
/* 控制背景音播放 */
function playPause(flag) {
  speaker
    .controlBgm(sn.value, {
      data: flag ? 2 : 1,
    })
    .then((res) => {
      if (res.resultStatus) {
      }
    });
}

const volume = ref(0); //音量
/* 音量改变回调 */
function volumeChange(val) {
  speaker.operateBgm(sn.value, {
    action: "MP_SET_PLAY_V_BGM_ACTION",
    data: val,
  });
}
</script>

<style scoped lang="less">
.flex_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1.5rem;
  color: #24b3ec;
  .play_box {
    margin-right: 1rem;
    color: #24b3ec;
  }
  .icon_group {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
  }
}
.play_box {
  .icon {
    font-size: 1.875rem;
  }
}
.info {
  font-size: 0.75rem;
  .info_title {
    margin-bottom: 0.625rem;
    .icon {
      color: #24b3ec;
      font-size: 1rem;
    }
    .title {
      padding-left: 0.625rem;
    }
  }
}
.icon_group {
  color: #24b3ec;
  .icon {
    font-size: 1.375rem;
  }
  .icon:not(:last-child) {
    margin-right: 1.25rem;
  }
}
.inside {
  position: absolute;
  right: -19.25rem;
  bottom: 0;
  height: 22.25rem;
  width: 18.75rem;
  background: rgba(11, 79, 122, 0.9);
  color: #fff;
  padding-top: 1.875rem;
  .inside_hd {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1.875rem;
    line-height: 1.875rem;
    background: rgba(35, 163, 236, 0.4);
    text-align: center;
    font-size: 0.875rem;
    .iconfont {
      position: absolute;
      right: 0.875rem;
      top: 0;
    }
  }
  .inside_bd {
    height: 100%;
    font-size: 0.875rem;
  }
}
.volume {
  position: relative;
  .volume_box {
    display: none;
    height: 1.875rem;
    position: absolute;
    top: 0.9375rem;
    left: 50%;
    transform: translate(-50%);
    width: 5.75rem;
    padding-left: 0.75rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    // background: yellow;
    .slider_con {
      height: 100%;
    }
  }
}
.volume:hover {
  .volume_box {
    display: block;
  }
}
.slider {
  display: flex;
  align-items: center;
  padding: 0.875rem 0;
  > span {
    color: rgba(36, 181, 238, 1);
  }
  .slider_con {
    width: 100%;
    padding: 0 0.375rem;
  }
}
.slider_con {
  :deep(.el-slider) {
    height: 6px;
    .el-slider__button-wrapper {
      height: 6px;
      width: 6px;
      top: -0.54rem;
      .el-slider__button {
        height: inherit;
        width: inherit;
        border: none;
      }
    }
    .el-slider__runway {
      height: 2px;
      .el-slider__bar {
        height: inherit;
        background-color: rgba(36, 181, 238, 1);
      }
    }
  }
}
.mode_icon {
  position: relative;
  > i {
    position: absolute;
    top: -0.125rem;
    left: .625rem;
    width: .125rem;
    height: 1.625rem;
    background: #fff;
    transform: rotate(-53deg);
    border-radius: .25rem;
  }
}
</style>
<style scoped lang="less">
@import url("./music-list.less");
.main_bd {
  padding-top: 2.125rem;
  .waist {
    margin-top: 1rem;
    .icon-a-ziyuan60{
      margin-right: 0.2rem;
    }
  }
}
</style>

