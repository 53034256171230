import { t } from '../languages';
/* 纯数字校验 */
export function isNumber(rule, value, callback) {
    if (value) {
        let strRegex = /^(\-|\+)?\d+(\.\d+)?$/;
        return !strRegex.test(value) ?
            callback(new Error(t(`请输入纯数字`))) :
            callback();
    } else {
        callback();
    }
}
/* 经度校验(180) */
export function lngValid(rule, value, callback) {
    if (value) {
        return parseFloat(value) < -180 || parseFloat(value) > 180 ?
            callback(new Error(t(`经度范围-180~180`))) :
            callback();
    } else {
        callback();
    }
}
/* 纬度校验(90) */
export function latValid(rule, value, callback) {
    if (value) {
        return parseFloat(value) < -90 || parseFloat(value) > 90 ?
            callback(new Error(t(`纬度范围-90~90`))) :
            callback();
    } else {
        callback();
    }
}